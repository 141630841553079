import { type z } from 'zod';
import { ERROR_SOURCE, GameError, queryClient } from '../..';
import { GetRuntimeConfigQuery } from './query';
import { useRemoteConfigStore } from './store';
import { CONFIG_ERROR_CODE } from '../errors/type';

export async function loadConfig() {
  const config = await queryClient.fetchQuery(GetRuntimeConfigQuery);

  // TODO/Alvis: remove the store and Configurable after fixing query.clear issue of launch screen on web
  useRemoteConfigStore.getState().setConfig(config);
  useRemoteConfigStore.getState().setVersion(config.version);

  return config;
}

/** Transposes the remote config to the config data model of interest.
 * @param {z.Schema<T> | z.ZodEffects<z.Schema>} schema Zod schema that defines the conversion from remote config to
 * parsed configuration model.
 * @returns Config data model. */
export function getConfigFromRemote<T>(schema: z.Schema<T> | z.ZodEffects<z.Schema>): T {
  const { config } = useRemoteConfigStore.getState();

  if (!config) return {} as T;

  const parsedConfig = schema.safeParse(config);

  if (parsedConfig.success) {
    return parsedConfig.data;
  }

  throw new GameError(ERROR_SOURCE.CONFIG, CONFIG_ERROR_CODE.REMOTE, parsedConfig.error.message);
}
