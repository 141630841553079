import { BITMOVIN, VIDEO_PLAYER_NAME } from '../../config/build-config';
import { GameConfig } from '../../config/game-config';
import type { ContentType, Playback } from '../content';
import type { Content } from '../content/type';
import { getTVEProvider } from '../tve';
import type { User } from '../user';

type GetAnalyticsConfigProps = {
  user: User;
  content: Content;
  playback: Playback;
  overrideOrigin?: string;
};

type ContentTransformer = {
  getTitle: (content: Content) => string;
  getCustomData4: (content: Content) => CustomData4 | undefined;
  getCustomData5: (content: Content) => CustomData5 | undefined;
};

type CustomData4 = 'Live' | 'Replay';
type CustomData5 = 'Game' | 'Other';

type AnalyticsConfig = {
  key: string;
  customUserId: string;
  title: string;
  videoId: string;
  cdnProvider: string;
  customData1: string;
  customData2: string;
  customData3?: string;
  customData4?: CustomData4;
  customData5?: CustomData5;
  customData6: string; // spt_lg in live event response MLB | NBA | MLS | WNBA | NHL
  customData7: string;
  customData8?: string;
  customData9?: string;
  config?: CollectorConfig;
};

type CollectorConfig = {
  origin?: string;
};

const getContentTransformMap = new Map<ContentType, ContentTransformer>();

function toUndefined() {
  return undefined;
}

function toCustomData8(playback: Playback) {
  return playback.trackingId;
}

function toCustomData9(playback: Playback) {
  return playback.streamId;
}

getContentTransformMap.set('linear', {
  getTitle: (content: Content) => {
    return `${content.title} | ${content.startDate?.toISOString()} | ${content.isLive ? 'LIVE' : 'RECORDED'}`;
  },
  getCustomData4: toUndefined,
  getCustomData5: (content: Content) => (content.isGame ? 'Game' : 'Other')
});
getContentTransformMap.set('overflow', {
  getTitle: (content: Content) => {
    return content.title;
  },
  getCustomData4: () => 'Live',
  getCustomData5: () => 'Game'
});
getContentTransformMap.set('vod', {
  getTitle: (content: Content) => content.title,
  getCustomData4: toUndefined,
  getCustomData5: toUndefined
});

type ContentMetadata = {
  title: string;
  // Program Air Type
  customData4?: CustomData4;
  // is Game
  customData5?: CustomData5;
};

type UserMetadata = {
  spAccountId: string;
  type: string;
  // User Type
  customData2: string;
  // MVPD Name
  customData3?: string;
};

type PlaybackMetadata = {
  // X Tracking id
  customData8?: string;
  // stream id
  customData9?: string;
};

function getContentMetadata(content: Content): ContentMetadata {
  const contentTransformer = getContentTransformMap.get(content.type);
  if (!contentTransformer) throw new Error(`${content.type} contentTransformer must be defined`);
  return {
    title: contentTransformer.getTitle(content),
    customData4: contentTransformer.getCustomData4(content),
    customData5: contentTransformer.getCustomData5(content)
  };
}

function getUserMetadata(user: User): UserMetadata {
  const { type, mvpdId } = user;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- user must have at least one type
  const userType = type.values().next().value!;
  const tve = getTVEProvider(mvpdId);
  return {
    spAccountId: user.spAccountId,
    type: userType,
    customData2: userType,
    customData3: tve?.displayName
  };
}

function getPlaybackMetadata(playback: Playback): PlaybackMetadata {
  return {
    customData8: toCustomData8(playback),
    customData9: toCustomData9(playback)
  };
}

export function getPlayerName() {
  switch (GameConfig.get.platform) {
    case 'web':
    case 'web:mobile':
      return VIDEO_PLAYER_NAME.WEB;
    case 'lg':
      return VIDEO_PLAYER_NAME.LG;
    case 'samsung':
      return VIDEO_PLAYER_NAME.SAMSUNG;
    case 'vizio':
      return VIDEO_PLAYER_NAME.VIZIO;
    default:
      throw new Error(`Unable to get bitmovin player name for Platform ${GameConfig.get.platform}`);
  }
}

export function getPlayerLicenseKey() {
  return BITMOVIN.LICENSE_KEY;
}

export function getAnalyticsConfig(props: GetAnalyticsConfigProps): AnalyticsConfig {
  const { user, content, playback, overrideOrigin } = props;
  const { title, customData4, customData5 } = getContentMetadata(content);
  const { spAccountId, customData2, customData3 } = getUserMetadata(user);
  const { customData8, customData9 } = getPlaybackMetadata(playback);

  const analyticsConfig: AnalyticsConfig = {
    key: BITMOVIN.ANALYTICS_LICENSE_KEY,

    // user values
    customUserId: spAccountId,

    // content values
    title: title,
    videoId: content.id,

    cdnProvider: BITMOVIN.CDN_PROVIDER,
    // Player Name
    customData1: getPlayerName(),
    // User Type
    customData2: customData2,
    // MVPD Name
    customData3: customData3,
    // Program Air Type
    customData4: customData4,
    // is Game
    customData5: customData5,
    // FUTURE: League Type
    customData6: '', // spt_lg in live event response MLB | NBA | MLS | WNBA | NHL
    // User UID
    customData7: spAccountId,
    // x Tracking id
    customData8: customData8,
    // stream id
    customData9: customData9
  };

  if (overrideOrigin) {
    analyticsConfig.config = {
      origin: overrideOrigin
    };
  }

  return analyticsConfig;
}
