import { getConfigFromRemote } from '@game/core';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { debugConfigSchema } from '../../../../../lib/config';
import { getPagePath } from '../../../../../lib/routing';

export const Route = createFileRoute('/_private/_home/account/debug-panel')({
  beforeLoad: ({ context: { user } }) => {
    if (user.role !== 'QA') throw redirect({ to: getPagePath('home') });
  },
  loader: ({ context: { userLocation } }) => {
    const { debug } = getConfigFromRemote(debugConfigSchema);
    const { locations } = debug;
    return { locations, userLocation };
  }
});
