import { z } from 'zod';

export const manageSubscriptionSchema = z
  .object({
    settings: z.object({
      'platform-description': z.string()
    }),
    'cancel-subscription-instructions': z.object({
      'cancel-instructions-title': z.string()
    }),
    'cancel-instructions': z.object({
      AmazonPay: z.record(z.string()),
      AppleAppStore: z.record(z.string()),
      ApplePay: z.record(z.string()),
      CreditCard: z.record(z.string()),
      GooglePay: z.record(z.string()),
      PayPal: z.record(z.string()),
      RokuPay: z.record(z.string()),
      SamsungPay: z.record(z.string()),
      VizioAccount: z.record(z.string()),
      TVE: z.record(z.string()),
      Unknown: z.record(z.string())
    }),
    manageSubscriptionScreen: z.object({
      manageSubscription: z.string(),
      currentPlan: z.string(),
      renewsOn: z.string(),
      subscribedOn: z.string(),
      changePlan: z.string(),
      payment: z.string(),
      purchaseHistory: z.string(),
      managePayment: z.string(),
      cancelSubscription: z.string(),
      month: z.string(),
      year: z.string(),
      cancellationEffective: z.string(),
      resumeSubscriptionModalDescription: z.string(),
      iDontWantToResume: z.string(),
      resumeNow: z.string()
    }),
    cancelSubscriptionScreen: z.object({
      'resume-subscription': z.string()
    })
  })
  .transform((values) => {
    const { 'platform-description': platformDescription } = values.settings;
    const { 'cancel-instructions-title': cancelInstructionsTitle } = values['cancel-subscription-instructions'];
    const { 'resume-subscription': resumeSubscription } = values['cancelSubscriptionScreen'];
    const {
      AmazonPay,
      AppleAppStore,
      ApplePay,
      CreditCard,
      GooglePay,
      PayPal,
      RokuPay,
      SamsungPay,
      VizioAccount,
      TVE,
      Unknown
    } = values['cancel-instructions'];
    return {
      ...values.manageSubscriptionScreen,
      platformDescription,
      cancelInstructionsTitle,
      resumeSubscription,
      cancelInstructionsByPaymentMethod: {
        AmazonPay,
        AppleAppStore,
        ApplePay,
        CreditCard,
        GooglePay,
        PayPal,
        RokuPay,
        SamsungPay,
        VizioAccount,
        TVE,
        Unknown
      }
    };
  });

export type ManageSubscriptionLocale = z.infer<typeof manageSubscriptionSchema>;
