import { z } from 'zod';
import { GameConfig } from '../../config/game-config';

export const queryConfigSchema = z
  .object({
    pageSize: z.object({
      watch: z.number()
    }),
    railsVideoLimit: z.number(),
    lg: z.object({
      heroCarouselFrequency: z.number(),
      mainRailsRefreshInterval: z.number().default(1800000)
    }),
    samsung: z.object({
      heroCarouselFrequency: z.number(),
      mainRailsRefreshInterval: z.number().default(1800000)
    }),
    vizio: z.object({
      heroCarouselFrequency: z.number(),
      mainRailsRefreshInterval: z.number().default(1800000)
    }),
    web: z.object({
      heroCarouselFrequency: z.number(),
      mainRailsRefreshInterval: z.number().default(1800000)
    })
  })
  .transform((values) => {
    const {
      railsVideoLimit: railContentSize,
      pageSize: { watch: containersPageSize },
      ...rest
    } = values;

    const platform = GameConfig.get.basePlatform;
    const heroCarouselRefreshTime = rest[platform].heroCarouselFrequency;
    const railsRefreshTime = rest[platform].mainRailsRefreshInterval;

    return {
      railContentSize,
      containersPageSize,
      heroCarouselRefreshTime,
      railsRefreshTime
    };
  });
