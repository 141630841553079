import { createContext, type ReactNode, useContext, useState } from 'react';

type SelectContextProps = {
  value?: string;
  onValueChange: (newValue: string | undefined) => void;
};

type SelectProviderProps = {
  children: ReactNode;
};

const SelectContext = createContext({} as SelectContextProps);

export const SelectContextProvider = (props: SelectProviderProps) => {
  const [value, setValue] = useState<string | undefined>(undefined);

  const onValueChange = (newValue: string | undefined) => {
    setValue(newValue);
  };

  return <SelectContext.Provider value={{ value, onValueChange }}>{props.children}</SelectContext.Provider>;
};

export const useSelectContext = () => {
  return useContext(SelectContext);
};
