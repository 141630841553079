import * as RadixCheckbox from '@radix-ui/react-checkbox';
import type { ReactNode } from 'react';
import { twMerge } from '../../utils/tailwind-libs';
import { Icon } from '../icon';

type CheckboxProps = RadixCheckbox.CheckboxProps & {
  name: string;
  label?: ReactNode;
  classes?: {
    container?: string;
    input?: string;
    indicator?: string;
    label?: string;
  };
};

/**
 * Checkbox component
 * @param {String} name used to set name prop of input, in order to catch on the form
 * @param {String} [label] the string or React component for rendering the label of the input
 * @param {String} [type] input type. Note that if you pass type="password", the input will have an extra icon to handle typed information visibility
 * @param {Object} [classes] an object with the components as key to pass classes to overwriting/extending css
 *
 * @example
 *  <Checkbox
 *    name="newsletter"
 *    value="true"
 *    label="Never miss a moment! Share your email to receive our newsletter and updates."
 *  />
 */
function Checkbox(props: CheckboxProps) {
  const { className, label, name, id, classes, ...rest } = props;

  const reference = id ?? name;

  return (
    <div className={twMerge('flex items-center gap-2', classes?.container)}>
      <RadixCheckbox.Root
        {...rest}
        name={name}
        id={reference}
        className={twMerge('border-primary-500 h-9 w-9 shrink-0 rounded-md border-2', className, classes?.input)}
      >
        <RadixCheckbox.Indicator
          className={twMerge('flex h-full w-full items-center justify-center', classes?.indicator)}
        >
          <Icon name="check" width={36} height={30} />
        </RadixCheckbox.Indicator>
      </RadixCheckbox.Root>
      <label htmlFor={reference} className={classes?.label}>
        {label}
      </label>
    </div>
  );
}

Checkbox.displayName = 'Checkbox';

export { Checkbox };
