import { Icon, Typography, type IconProps } from '@game/core-ui';
import { Link } from '@tanstack/react-router';
import { getPagePath } from '../../lib/routing';

type SidebarLinkParams = {
  path: Parameters<typeof getPagePath>[number];
  iconName: IconProps['name'];
  label: string;
  routeParams?: Record<string, string>;
  onClick?: () => void;
};

export function SidebarLink(params: SidebarLinkParams) {
  const { iconName, path, label, routeParams, onClick } = params;

  return (
    <Link
      onClick={onClick}
      to={getPagePath(path)}
      aria-label={label}
      params={routeParams}
      resetScroll={path !== 'watch'}
      preload={false}
      className="hover:text-primary-500 group/link flex items-center gap-4"
      activeProps={{ className: 'text-primary-800 hover:text-primary-800' }}
      inactiveProps={{ className: 'text-light' }}
      activeOptions={{
        exact: true
      }}
    >
      <Icon
        name={iconName}
        width={32.5}
        height={32.5}
        className="group-data-[status=active]/link:fill-primary-800 group-hover/link:fill-primary-500 transition"
      />
      <Typography className="text-inherit opacity-0 transition group-data-[expanded=true]/sidebar:opacity-100">
        {label}
      </Typography>
    </Link>
  );
}
