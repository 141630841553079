import { getConfigFromRemote } from '../../config-manager';

import { errorConfigSchema } from '../schema/config';

export function useGameErrorConfig() {
  const config = getConfigFromRemote(errorConfigSchema);

  return {
    config
  };
}
