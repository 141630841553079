import { z } from 'zod';

export const socialLoginSchema = z
  .object({
    'social-login': z.object({
      'login-with': z.string(),
      'we-recognize': z.string(),
      'continue-apple': z.string(),
      'continue-google': z.string(),
      'continue-facebook': z.string()
    })
  })
  .transform((values) => {
    const {
      'social-login': {
        'login-with': loginWith,
        'we-recognize': weRecognize,
        'continue-apple': continueApple,
        'continue-google': continueGoogle,
        'continue-facebook': continueFacebook
      }
    } = values;

    return {
      loginWith,
      weRecognize,
      continueApple,
      continueGoogle,
      continueFacebook
    };
  });

export type SocialLoginLocale = z.infer<typeof socialLoginSchema>;
