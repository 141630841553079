export type Platform = 'androidtv' | 'appletv' | 'firetv' | 'lg' | 'roku' | 'samsung' | 'vizio' | 'web' | 'web:mobile';

// TODO: get display caption from build.json
export function getPlatformCaption(platform: Platform) {
  switch (platform) {
    case 'androidtv':
      return 'AndroidTV';
    case 'appletv':
      return 'Apple TV';
    case 'firetv':
      return 'FireTV';
    case 'lg':
      return 'LGTV';
    case 'roku':
      return 'Roku';
    case 'samsung':
      return 'SamsungTV';
    case 'vizio':
      return 'VizioTV';
    case 'web':
    case 'web:mobile':
      return 'Web';
    default:
      return platform;
  }
}
