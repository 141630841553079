import { type EventToHandler, handlerPrefix } from './type';
import type { AnalyticsEventHandlers } from '../../features/analytics/handler';
import type { UseCaseEventHandlers } from '../../features/use-cases/handler';

type ActionEventHandlers = AnalyticsEventHandlers & UseCaseEventHandlers;
/**
 * Interface for action handlers. Implementing any handler method
 * only implemented methods will be called when an event is emitted.
 * You can create handler instance for different services for different RSN
 */
export type ActionEventHandler = Partial<ActionEventHandlers>;

const handlers = new Set<ActionEventHandler>();

export function emit<K extends keyof EventToHandler<ActionEventHandlers>>(
  event: K,
  ...args: Parameters<EventToHandler<ActionEventHandlers>[K]>
): void {
  handlers.forEach((handler) => {
    // TODO: Simplify TypeScript workarounds
    const h = Reflect.get(handler, `${handlerPrefix as string}${String(event)}`);
    if (h) {
      Reflect.apply(h, handler, args);
    }
  });
}

type Unregister = () => void;

export function register(handler: ActionEventHandler): Unregister {
  handlers.add(handler);

  return () => {
    handlers.delete(handler);
  };
}
