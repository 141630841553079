export const enum RESPONSE_CODES {
  ERROR = '0',
  SUCCESS = '1'
}

export type BaseErrorResponse = {
  responseCode: RESPONSE_CODES.ERROR;
  failureMessage: FailureMessage[];
};

export type FailureMessage = {
  errorCode: string;
  errorMessage: string;
};

export type SearchAccountSuccessResponse = {
  accountID: string;
  accountType: string;
  accountRole?: EvergentAccountRole;
  channelPartnerID: string;
  contactUserName?: string;
  cpCustomerID: string;
  email: string;
  description?: string;
  // directAccount denotes an array index values of a RSN in a multi-rsn configuration
  directAccount?: number;
  firstName?: string;
  isEmailVerified: boolean;
  isMobileVerified: string;
  isPasswordExists: string;
  lastName: string;
  responseCode: RESPONSE_CODES.SUCCESS;
  socialLoginType?: string;
  status: string;
  termsAndConditionsChecked: boolean;
};

export type EvergentAccountRole = 'QA User' | 'VIP User';

export type OAuthSuccessResponse = {
  responseCode: RESPONSE_CODES.SUCCESS;
  accessToken: string;
  refreshToken: string;
  expiresIn: string;
  cpCustomerID: string;
  accountRole?: EvergentAccountRole;
};

export type LoginResponse = {
  tokens: {
    accessToken: string;
    refreshToken: string;
    expiresIn: string;
  };
  metadata: {
    accountRole?: EvergentAccountRole;
  };
};

export type OAuthResponse = {
  GetOAuthAccessTokenv2ResponseMessage: OAuthSuccessResponse;
};

export type AppChannel = {
  appID: string;
  appName: string;
  appChannel: string;
};

export type ProductServiceType = 'SVOD' | 'TVOD';

type Promotion = {
  amount: number;
  promotionalPrice: number;
  promotionExpiry: number;
};

type ProductMessage = {
  productName: string;
  displayName: string;
  retailPrice: number;
  displayOrder: number;
  productID: string;
  skuORQuickCode: string;
  ovpSKU: string;
  appChannels?: AppChannel[];
  serviceType: ProductServiceType;
  promotions: Promotion[];
};

type ProductSuccessResponse = {
  responseCode: RESPONSE_CODES.SUCCESS;
  productsResponseMessage: ProductMessage[];
};

export type ProductResponse = {
  GetProductsResponseMessage: ProductSuccessResponse;
};

// generateDeviceActivationCode
type AccessTokenMessage = {
  responseCode: RESPONSE_CODES.SUCCESS;
  message: string;
  sessionToken: string;
  channelPartnerID: string;
  expiresIn: string;
  accessToken: string;
  refreshToken?: string;
  status: string;
};

type ActivationCodeMessage = {
  responseCode: RESPONSE_CODES.SUCCESS;
  message: string;
  activationCode: string;
  status: string;
};

export type GenerateDeviceActivationCodeResponse = {
  GenerateDeviceActivationCodeResponseMessage: ActivationCodeMessage | AccessTokenMessage | BaseErrorResponse;
};

export type TokensOrActivationCode =
  | {
      authenticated: true;
      accessToken: string;
      refreshToken?: string;
      expiresIn: string;
    }
  | {
      authenticated: false;
      activationCode: string;
    };

type ContactMessage = {
  contactID: string;
  email: string;
  firstName: string;
  lastName: string;
  main: boolean;
  nickName: string;
  userName: string;
  dateOfBirth?: number;
  gender?: string;
};

type GetContactSuccessResponse = {
  responseCode: RESPONSE_CODES.SUCCESS;
  contactMessage: ContactMessage[];
  cpCustomerID: string;
  spAccountID: string;
  billingAddress?: {
    zipCode: string;
  };
};

export type GetContactResponse = {
  GetContactResponseMessage: GetContactSuccessResponse | BaseErrorResponse;
};

export type AddOrRemoveFavouritesResponse = {
  responseCode: RESPONSE_CODES.SUCCESS;
  message: string;
  success: string;
};

export type AddOrRemoveFavoriteRequest =
  | {
      favouriteType: 'favoriteTeams';
      favoriteTeamsToAdd?: string[];
      favoriteTeamsToRemove?: string[];
    }
  | {
      favouriteType: 'favoritePlayers';
      favoritePlayersToAdd?: { playerId: string }[];
      favoritePlayersToRemove?: { playerId: string }[];
    };

export type CreateUserResponse = {
  cpCustomerID: string;
  spAccountID: string;
  accessToken: string;
  refreshToken: string;
  expiresIn: string;
  responseCode: RESPONSE_CODES.SUCCESS;
  message: string;
  contactID: string;
};

export type CreateUser = {
  customerUsername: string;
  customerPassword: string;
  email: string;
  firstName?: string;
  lastName?: string;
  isGenerateJWT?: boolean;
  dmaId?: string;
  dateOfBirth?: number;
  gender?: string;
  referralCode: string | null;
  deviceMessage?: {
    serialNo: string;
    deviceName: string;
    deviceType: string;
    modelNo: string;
    appType: string;
    locale: string;
    userAgent?: string;
  };
};

export type UpdateUser = {
  email: string;
  firstName?: string;
  dateOfBirth?: number | null;
  gender?: string;
};

type SearchAccountNonExistent = {
  exists: false;
};

type SearchAccountExistent = {
  exists: true;
  directAccount: number;
  accountType: string;
  isSocialLogin: boolean;
  accountRole?: EvergentAccountRole;
  hasPassword: boolean;
};

export type SearchAccountResult = SearchAccountExistent | SearchAccountNonExistent;

export type GetFavouritesSuccessResponse = {
  responseCode: RESPONSE_CODES.SUCCESS;
  message: string;
  favourites: {
    favoriteTeams?: Array<string>;
    favoritePlayers?: Array<Record<'playerId', string>>;
  };
};

export type GetFavouritesResponse = {
  GetFavouritesResponseMessage: GetFavouritesSuccessResponse;
};

export type AddSubscriptionService = {
  serviceId: string;
  serviceType: string;
  quantity: string;
  appServiceId?: string;
};

type ApplePayMessage = {
  xid: 'ApplePay';
  txRefNo: string;
  subscriptionId: string;
  paymentInterface: string;
};

type PaypalMessage = {
  token: string;
  payerID: string;
  externalTransactionId: string;
  email?: string;
  amount: string;
  billingAddress: {
    zipCode: string;
  };
};

type SamsungMessage = {
  txID: string;
  storeCustomerID: string;
  billingAddress: {
    zipCode: string;
    country: string;
  };
};

type VizioMessage = {
  txID: string;
  billingAddress: {
    zipCode: string;
  };
};

type PaypalPaymentMethodInfo = {
  label: 'PayPalAccount';
  paypalAccountMsg: PaypalMessage;
};

type ApplePayPaymentMethodInfo = {
  label: 'ApplePay';
  makeAutoPayment: boolean;
  applePayMessage: ApplePayMessage;
  billingAddress: {
    zipCode: string;
  };
};

type VizioPaymentMethodInfo = {
  label: 'Vizio';
  makeAutoPayment: boolean;
  transactionReferenceMsg: VizioMessage;
};

type SamsungPaymentMethodInfo = {
  label: 'Samsung Checkout';
  transactionReferenceMsg: SamsungMessage;
};

export type AddSubscriptionsBaseRequest = {
  dmaID: string;
  serviceID: string;
};

export type RemoveSubscriptionMutationRequest = {
  serviceID: string;
  serviceType: string;
  reasonCode: string;
};

export type RemoveSubscriptionMutationResponse = {
  serviceID: string;
  reasonCode: string;
};

export type AddSubscriptionsVizioRequest = {
  channel: 'Vizio';
  appServiceId: string;
  paymentInfo: VizioPaymentMethodInfo;
};

export type AddSubscriptionsSamsungRequest = {
  channel: 'Samsung Checkout';
  appServiceId: string;
  paymentInfo: SamsungPaymentMethodInfo;
};

export type AddSubscriptionsApplePayRequest = {
  channel: 'ApplePay';
  couponCode?: string;
  paymentInfo: ApplePayPaymentMethodInfo;
};

export type AddSubscriptionsPaypalRequest = {
  channel: 'PayPalAccount';
  couponCode?: string;
  paymentInfo: PaypalPaymentMethodInfo;
};

export type AddSubscriptionsRequest = AddSubscriptionsBaseRequest &
  (
    | AddSubscriptionsVizioRequest
    | AddSubscriptionsSamsungRequest
    | AddSubscriptionsApplePayRequest
    | AddSubscriptionsPaypalRequest
  );

export type AddTVODOrderBaseRequest = {
  dmaID: string;
  purchaseToken: string;
};

export type AddTVODOrderVizioRequest = {
  channel: 'Vizio';
  paymentInfo: VizioPaymentMethodInfo;
};

export type AddTVODOrderSamsungRequest = {
  channel: 'Samsung Checkout';
  price: string;
  paymentInfo: SamsungPaymentMethodInfo;
};

export type AddTVODOrderApplePayRequest = {
  channel: 'ApplePay';
  paymentInfo: ApplePayPaymentMethodInfo;
};

export type AddTVODOrderPaypalRequest = {
  channel: 'PayPalAccount';
  paymentInfo: PaypalPaymentMethodInfo;
};

export type AddTVODOrderRequest = AddTVODOrderBaseRequest &
  (AddTVODOrderVizioRequest | AddTVODOrderSamsungRequest | AddTVODOrderApplePayRequest | AddTVODOrderPaypalRequest);

export type RemoveSubscriptionRequest = {
  serviceID: string;
  serviceType: string;
  reasonCode: string;
};

export type ResumeSubscriptionRequest = {
  serviceID: string;
  serviceType: string;
};

export type ChangeServiceRequest = {
  oldServiceID: string;
  newServiceID: string;
};

export type EvergentPaymentMethodType =
  | 'Amazon App Store Billing'
  | 'App Store Billing' // iOS
  | 'Credit/Debit Card' // Web evergent payment form
  | 'Google Wallet'
  | 'Operator Billing' // Specifies payment via MVPD subscription
  | 'Roku Payment'
  | 'Vizio'
  | 'Samsung Checkout'
  | 'ApplePay' // Web Safari purchase
  | 'PayPal Account' // Web PayPal purchase
  | 'Coupon'; // TODO: This is a temporary measure until the user migration is completed. Remove after that.

export type ServiceType = 'TVE' | 'DTC' | 'TVOD' | 'VIP' | 'FREE';

export type EvergentAccountServiceDetail = {
  isContent: boolean;
  isPackage: boolean;
  serviceName: string;
  serviceID: string;
  startDate: number;
  validityTill: number;
  description: string;
  validityEndDate: string;
  status: string;
  name: string;
  paymentMethod: EvergentPaymentMethodType;
  isUpgradeAllowed: boolean;
  ovpSKU: string;
  isRenewal: boolean;
  vodItems?: vodItem[];
};

type GetEntitlementsSuccessResponse = {
  responseCode: RESPONSE_CODES.SUCCESS;
  message: string;
  ovatToken: string;
  tvodToken: string;
  billingZipCode: string;
  ovatTokenExpiry: number;
  dmaID: string;
  AccountServiceMessage: EvergentAccountServiceDetail[];
};

export type GetEntitlementsResponse = {
  GetEntitlementsResponseMessage: GetEntitlementsSuccessResponse;
};

type vodItem = {
  assetId: string;
  title: string;
  startTime: number;
};

export type GetCouchRightsResponse = {
  GetCouchRightsResponseMessage: {
    responseCode: RESPONSE_CODES;
    status: string;
    endDate?: number;
    failureMessage?: FailureMessage[];
  };
  success: boolean;
};

export type AddOrUpdateCouchRightsResponse = {
  AddOrUpdateCouchRightsResponseMessage: {
    responseCode: RESPONSE_CODES;
    status: string;
    endDate?: number;
    failureMessage?: FailureMessage[];
  };
  success: boolean;
};

export type ForgotPasswordResponse = {
  ForgotContactPasswordResponseMessage: {
    responseCode: number;
    message: string;
    status: string;
  };
  success: boolean;
};

export type ResetPasswordResponseSuccess = {
  responseCode: RESPONSE_CODES.SUCCESS;
  message: string;
  status: string;
};
export type ChangePasswordResponseSuccess = {
  responseCode: RESPONSE_CODES.SUCCESS;
  message: string;
  status: string;
};

export type ResetPasswordResponse = {
  ResetPasswordResponseMessage: ResetPasswordResponseSuccess | BaseErrorResponse;
};
export type ChangePasswordResponse = {
  ChangePasswordResponseMessage: ChangePasswordResponseSuccess | BaseErrorResponse;
};

export type AddTVESubscriptionRequest = {
  encryptedZip: string;
  mvpdID: string;
  adobeId: string;
  adobeResource: string[];
};

type ReferralCodeSuccess = {
  referralCode: string;
  responseCode: RESPONSE_CODES.SUCCESS;
};

export type ReferralCodeResponse = {
  GenerateReferralCodeResponseMessage: ReferralCodeSuccess;
};

export type GenerateReferralCodeParams = {
  referralProgram: string;
};

type SubscriptionType = 'Monthly' | 'Yearly';

export type ActiveSubscription = {
  id: string;
  sku: string; // also known as serviceID to evergent in EvergentActiveSubscriptionDetail response.
  isValid: boolean;
  name: string;
  displayName: string;
  ovpSKU?: string; // TVOD subscriptions do not have this value set.
  opId: string;
  paymentMethod: EvergentPaymentMethodType;
  subscriptionType: SubscriptionType;
  price: number;
  serviceType: ProductServiceType;
  startDate: Date;
  endDate: Date;
  upgradablePlans?: string[];
  isCancelled: boolean;
};

export type EvergentActiveSubscriptionDetail = {
  displayName: string;
  serviceType: ProductServiceType;
  subscriptionType: SubscriptionType;
  retailPrice: number;
  serviceName: string;
  serviceID: string;
  opId: string;
  startDate: number;
  validityTill?: number;
  paymentMethod: EvergentPaymentMethodType;
  ovpSku?: string;
  // upgradablePlans?: string[]; // May come in this form ["Gotham_bundle_annual","Gotham_bundle_monthly","Gotham_YES_annual"]
  cancellable: boolean;
  cancellationDate: number;
};

type GetActiveSubscriptionsSuccessResponse = {
  responseCode: RESPONSE_CODES.SUCCESS;
  message: string;
  billingZipCode: string;
  AccountServiceMessage?: EvergentActiveSubscriptionDetail[];
};

export type GetActiveSubscriptionsResponse = {
  GetActiveSubscriptionsResponseMessage: GetActiveSubscriptionsSuccessResponse;
};

export type Payment = {
  orderedProductID: string;
  maskedCardNumber: string;
  paymentMethodID: string;
};

export type GetPaymentsResponse = {
  payments: Payment[];
};

export type UpdateCardParams = {
  status: string;
  label: string;
  isAutoPayment: boolean;
  CardInfoMessage: {
    expiryDate: string;
    nameOnCard: string;
    cardNumber: string;
    securityCode: string;
    methodType: string;
    zipCode: string;
  };
};

export type CardInfoMessage = {
  cardType: string;
  name: string;
  cardNumber: string;
  expiryDate: string;
  methodType: string;
  paymentMethodId: string;
  billingAddress: {
    zip: string;
  };
  nickName: string;
  cardReference: string;
};

export type PaymentMethod = {
  canShowAddPaymentMethod: boolean;
  status: string;
  label: string;
  isAutoPayment: boolean;
  modifiedTs: number;
  CardInfoMessage?: CardInfoMessage;
  OtherPaymentMethod?: {
    paymentType: string;
    paymentMethodId: string;
  };
};

export type SubscriptionHistoryDetails = {
  orderID: string;
  isContent: boolean;
  isFreemium: boolean;
  planPriceWithTax: number;
  serviceType: string;
  paymentMethodDetails: {
    label: string;
    paymentMethodID: string;
  };
  validityTillWithTZ: string;
  isSubscription: boolean;
  serviceName: string;
  serviceID: string;
  startDate: number;
  validityTill: number;
  retailPrice: number;
  status: string;
  currencyCode: string;
  currencySymbol: string;
  cancellable: boolean;
  hasAction: boolean;
  displayName: string;
  isRenewal: boolean;
  period: string;
  duration: number;
  paymentMethod: string;
  opId: string;
  priceCharged: number;
  validityPeriod: string;
  validityDuration: number;
  serviceOfType: string;
  isInFreeTrail: boolean;
  startDateInMillis: number;
  productCategory: string;
  basicService: boolean;
  isPackage: boolean;
  planPrice: number;
  subscriptionStatus: string;
  ovpSku: string;
  isDiscountedPrice: boolean;
  quantity: number;
  formattedValidityEndDateWithTZ: string;
  orderDateTimeInMillis: number;
  typeOfService: string;
};

export type GetSubscriptionHistorySuccessResponse = {
  AccountServiceMessage: SubscriptionHistoryDetails[];
  responseCode: RESPONSE_CODES.SUCCESS;
  message: string;
};

export type GetSubscriptionHistoryResponse = {
  GetSubscriptionHistoryResponseMessage: GetSubscriptionHistorySuccessResponse | BaseErrorResponse;
};

export type GetProrateSuccessResponse = {
  responseCode: RESPONSE_CODES.SUCCESS;
  totalPriceAfterTax: number;
};

export type GetProrateResponse = {
  GetProrateResponseMessage: GetProrateSuccessResponse;
};
