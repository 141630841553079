import * as SelectPrimitive from '@radix-ui/react-select';
import { useCallback } from 'react';
import { twMerge } from '../../utils/tailwind-libs';
import { Icon } from '../icon';
import { Option } from './option';
import { SelectContextProvider, useSelectContext } from './selectContext';

type SelectProps = SelectPrimitive.SelectProps & {
  placeholder: string;
  options: {
    value: string;
    label: string;
  }[];
};

/**
 *
 * @param {String} placeholder the text to be displayed when no option was selected
 * @param {Array} options array of objects { value, label } used to render the options in the select
 *
 * @example
 * <Select
 *    placeholder="Gender (optional)"
 *    name="gender"
 *    options={[
 *      { value: 'male', label: 'Male' },
 *      { value: 'female', label: 'Female' },
 *      { value: 'other', label: 'Other' },
 *    ]}
 * />
 */
function SelectInner(props: SelectProps) {
  const { options, placeholder, onValueChange: externalValueChange, ...rest } = props;

  const { value, onValueChange } = useSelectContext();

  const handleValueChange = useCallback(
    (value: string) => {
      onValueChange(value);
      externalValueChange?.(value);
    },
    [externalValueChange, onValueChange]
  );

  return (
    <SelectPrimitive.Root {...rest} value={value} onValueChange={handleValueChange}>
      <SelectPrimitive.Trigger
        className={twMerge(
          'h-input-default border-secondary-300 bg-secondary-600 WebBody1 group flex w-full items-center justify-between gap-2 rounded-md border px-5 py-4',
          'data-[placeholder]:text-light/60 WebBody1'
        )}
      >
        <SelectPrimitive.Value placeholder={placeholder} />
        <SelectPrimitive.Icon className="transition-transform group-data-[state=open]:rotate-180">
          <Icon name="chevron-down" width={26} height={14} />
        </SelectPrimitive.Icon>
      </SelectPrimitive.Trigger>

      <SelectPrimitive.Portal>
        <SelectPrimitive.Content
          side="bottom"
          sideOffset={-4}
          position="popper"
          className={twMerge(
            'border-secondary-300 bg-secondary-600 z-50 max-h-[--radix-select-content-available-height] w-[--radix-select-trigger-width] overflow-hidden rounded-md border',
            'data-[side=bottom]:rounded-t-none data-[side=top]:rounded-b-none data-[side=bottom]:border-t-0 data-[side=top]:border-b-0'
          )}
        >
          <SelectPrimitive.Viewport className="outline-none">
            {options.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </SelectPrimitive.Viewport>
        </SelectPrimitive.Content>
      </SelectPrimitive.Portal>
    </SelectPrimitive.Root>
  );
}

function Select(props: SelectProps) {
  return (
    <SelectContextProvider>
      <SelectInner {...props} />
    </SelectContextProvider>
  );
}

Select.displayName = 'Select';

export { Select };
