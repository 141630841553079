import { getConfigFromRemote } from '@game/core';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';
import { playersConfigSchema } from '../../../../../../lib/config/pick-favorite-players';

const search = z.object({
  teamId: z.number().optional()
});

export const Route = createFileRoute('/_private/_home/account/_favorites/edit/players')({
  validateSearch: search,
  loader: () => {
    const config = getConfigFromRemote(playersConfigSchema);

    return {
      config
    };
  }
});
