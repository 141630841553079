import { queryClient } from '../../lib/query';
import type {
  GetAssetSignedUrlArgs,
  QuickplayCatalogType,
  HeartbeatUpdateResponse
} from '../../services/api/quickplay';
import { createStream, deleteStream, getAssetSignedUrl, updateHeartbeat } from '../../services/api/quickplay';
import { getSSAIAdParameters } from '../advertising';
import { getSingleContent, type Content } from '../content';
import { getFlatToken } from '../entitlements/lib';
import { getEvergentTVODToken } from '../entitlements/token-storage';
import { getMediaToken } from '../tve';
import { getUserData } from '../user';
import { playbackStore } from './store';
import type { GetAssetPlaybackDataParams } from './types';
import { getDeviceId } from '../device/lib';

export async function getVideoData(params: { catalogType: QuickplayCatalogType; videoId: string }) {
  return getSingleContent({ catalogType: params.catalogType, id: params.videoId });
}

/**
 *
 * @throws {UserNotAuthenticatedError} If the user is a tve user, and tve token is expired
 * @throws {ResourceAccessDeniedError} If access to the resource is denied (any other failing status).
 */
export async function getAssetPlaybackData(args: GetAssetPlaybackDataParams) {
  const { video: media, resourceId, mvpd, ads } = args;
  const deviceId = await getDeviceId();

  try {
    const [mediaToken, token, user] = await Promise.all([
      getMediaToken({ deviceId, media, resourceId, mvpd }),
      getFlatToken(),
      getUserData()
    ]);
    const tvodToken = getEvergentTVODToken();

    const assetSignedUrlArgs: GetAssetSignedUrlArgs = {
      ...args,
      mediaToken,
      flatToken: token.token,
      tvodToken,
      deviceId
    };
    if (ads) {
      assetSignedUrlArgs.urlParameters = getSSAIAdParameters(media, ads, user);
    }

    return getAssetSignedUrl(assetSignedUrlArgs);
  } catch {
    throw new Error('Could not retrieve asset playback data');
  }
}

export async function sendHeartbeatUpdate(itemId: string, heartbeatToken: string): Promise<HeartbeatUpdateResponse> {
  const flatToken = await getFlatToken();
  if (!flatToken) throw new Error('Cannot send heartbeat update');
  const deviceId = await getDeviceId();
  return updateHeartbeat(deviceId, itemId, flatToken.token, heartbeatToken);
}

export async function startStreaming() {
  const flatToken = await getFlatToken();
  if (!flatToken) throw new Error('Cannot create stream');
  const deviceId = await getDeviceId();
  return createStream(deviceId, flatToken.token);
}

export async function stopStreaming() {
  const flatToken = await getFlatToken();
  if (!flatToken) throw new Error('Cannot delete stream');
  const deviceId = await getDeviceId();
  return deleteStream(deviceId, flatToken.token);
}

export async function getQueryVideoData(params: { catalogType?: QuickplayCatalogType; id?: string }) {
  const { id, catalogType } = params;
  if (!id || !catalogType) return;
  return await queryClient.ensureQueryData({
    queryKey: ['video_data', id, catalogType],
    queryFn: async () => getSingleContent({ catalogType, id })
  });
}

export function setContent(content: Content) {
  playbackStore.getState().setContent(content);
}

export function clearContent() {
  playbackStore.getState().clearContent();
}

export function getContent() {
  return playbackStore.getState().content;
}
