import { queryClient } from '../../lib/query';
import { GetDeviceDetailsQuery, GetDeviceIdQuery } from './query';

export async function getDeviceId() {
  return await queryClient.fetchQuery(GetDeviceIdQuery);
}

export async function getDeviceDetails() {
  return await queryClient.fetchQuery(GetDeviceDetailsQuery);
}
