import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export type ActivateDeviceStore = {
  activationCode?: string;
  regCode?: string;
  platform?: string;
  spAccountId?: string;
  setActivationCode: (activationCode?: string) => void;
  setRegCode: (regCode?: string) => void;
  setPlatform: (platform?: string) => void;
  setSpAccountId: (spAccountId?: string) => void;
};

export const useActivateDeviceStore = create<ActivateDeviceStore>()(
  persist(
    (set) => ({
      activationCode: undefined,
      regCode: undefined,
      platform: undefined,
      spAccountId: undefined,
      setActivationCode: (activationCode?: string) => set({ activationCode }),
      setRegCode: (regCode?: string) => set({ regCode }),
      setPlatform: (platform?: string) => set({ platform }),
      setSpAccountId: (spAccountId?: string) => set({ spAccountId })
    }),
    {
      name: 'activate-device',
      storage: createJSONStorage(() => localStorage)
    }
  )
);
