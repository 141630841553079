import z from 'zod';

export const forgotPasswordSchema = z
  .object({
    'forgot-password': z.object({
      email: z.string(),
      'email-sent-header': z.string(),
      'enter-the-email': z.string(),
      'forgot-password-error': z.string(),
      'forgot-password-header': z.string(),
      ok: z.string(),
      okay: z.string(),
      'once-you-change': z.string(),
      submit: z.string(),
      'back-to-login': z.string(),
      'a-password-reset': z.string(),
      'note-be-sure': z.string()
    })
  })
  .transform((values) => {
    const {
      'forgot-password': {
        'email-sent-header': emailSentHeader,
        'enter-the-email': enterTheEmail,
        'forgot-password-error': forgotPasswordError,
        'forgot-password-header': forgotPasswordHeader,
        'once-you-change': onceYouChange,
        'back-to-login': backToLogin,
        'a-password-reset': aPasswordReset,
        'note-be-sure': noteBeSure,
        ...rest
      }
    } = values;

    return {
      ...rest,
      emailSentHeader,
      enterTheEmail,
      forgotPasswordError,
      forgotPasswordHeader,
      onceYouChange,
      backToLogin,
      aPasswordReset,
      noteBeSure
    };
  });

export type ForgotPasswordLocale = z.infer<typeof forgotPasswordSchema>;
