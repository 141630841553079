import { API_PROXY_BASE_URL, RSN_ID } from '../../../config/build-config';
import { getTokens } from '../../../features/auth';
import { refreshTokenService } from '../../../features/auth/refresh-token';
import { UserNotAuthenticatedError } from '../../../lib/error';
import { transformGAMEResponse } from '../game-proxy';
import { fetcher } from '../../../lib/fetcher';
import { EVERGENT_ERROR_CODE } from './error';
import { GameAPIError } from '../../../features/errors';

type Metadata = {
  /**
   * Whether to include OAuth token of evergent in the request.
   */
  withToken?: boolean;
};

const MAX_REFRESH_TOKEN_RETRY = 1;

export const api = fetcher.create<Metadata>({
  baseURL: API_PROXY_BASE_URL,
  headers: {
    'gg-rsn-id': RSN_ID
  },
  transformResponse: transformGAMEResponse,
  hook: {
    beforeRequest: (request, options) => {
      if (!options?.metadata?.withToken) return;

      const tokens = getTokens();
      if (!tokens?.accessToken) {
        throw new UserNotAuthenticatedError('Missing access token.');
      }

      request.headers.set('Authorization', `Bearer ${tokens.accessToken}`);
    }
  },
  retry: async (error, retryCount) => {
    if (!(error instanceof GameAPIError) || retryCount >= MAX_REFRESH_TOKEN_RETRY) return false;

    if (
      error.response.status === 401 ||
      error.response.status === 403 ||
      error.code === EVERGENT_ERROR_CODE.INVALID_SESSION_TOKEN || // this error is coming from 200 response
      error.code === EVERGENT_ERROR_CODE.AUTHENTICATION_FAILED
    ) {
      // TODO/Alvis: create a token manager in persistent layer to handle manage token for both quickplay and evergent
      await refreshTokenService.refresh();
      return true;
    }
    return false;
  }
});
