import { type z } from 'zod';
import { useLocale } from '../../localization';
import type { UseCases } from '../../use-cases';
import { getReferenceCode } from '../referenceCode';
import { GameError } from '../errors';
import { useGameErrorConfig } from './useGameErrorConfig';
import { ERROR_SOURCE, GENERIC_ERROR_CODE } from '../type';

export function useGameError(schema: z.Schema) {
  const { config } = useGameErrorConfig();
  const { locales } = useLocale({ schema });

  const getErrorMeta = (error: Error | GameError, useCase?: UseCases) => {
    let gameError: GameError;

    if (error instanceof GameError) {
      gameError = error;
    } else {
      const isOffline = error.name === 'TypeError' && !navigator.onLine;
      const errorCode = isOffline ? GENERIC_ERROR_CODE.NO_NETWORK_ERROR : GENERIC_ERROR_CODE.GENERIC_ERROR;
      gameError = new GameError(ERROR_SOURCE.GENERIC, errorCode, error.message);
    }

    const { source, code } = gameError;
    const vendorMap = config[source];
    const errorKey = vendorMap?.[code];
    const referenceCode = getReferenceCode({
      error: gameError,
      useCase
    });
    const endUserMessage = locales[errorKey] || locales[`default-${source}-error`] || locales['default-generic-error'];
    return {
      title: locales['generic-title'],
      endUserMessage: endUserMessage?.replace('{code}', referenceCode),
      gameError
    };
  };

  return {
    getErrorMeta
  };
}
