import { queryClient } from '../../lib/query';
import { SERVICE_AVAILABLE_COUNTRIES } from '../../config/build-config';
import { GetCheckLocationQuery } from './query';
import type { LocationData } from './types';

export async function getUserLocation() {
  return queryClient.fetchQuery(GetCheckLocationQuery());
}

export const isUserOutOfCountry = (locationData: LocationData) => {
  return !SERVICE_AVAILABLE_COUNTRIES.includes(locationData.country);
};

export const isUserOutOfMarket = (locationData: LocationData) => {
  return locationData.zone === 'unavailable';
};
