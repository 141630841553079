import type { GetReferenceCodeProps } from './type';

import { GAME_ID } from '../../config/build-config';
import { GameConfig } from '../../config/game-config';
import { getUseCaseCode } from '../use-cases/lib';

/**
 * Generates the reference code for the given error and use case.
 * @param {GetReferenceCodeProps} props
 * @returns {string} */
export function getReferenceCode(props: GetReferenceCodeProps): string {
  const {
    error: { code },
    useCase = 'UNKNOWN'
  } = props;
  return `${GAME_ID}-v${GameConfig.get.versionSummary}-${getUseCaseCode(useCase)}-${code}-${GameConfig.get.platform}`;
}
