import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { AFFILIATE, type Affiliate, type AffiliateStore } from './types';

export const useAffiliateStore = create<AffiliateStore>()(
  persist(
    (set) => ({
      affiliate: AFFILIATE.enum.Web,
      setAffiliate: (affiliate: Affiliate) => set(() => ({ affiliate }))
    }),
    { name: 'affiliate', storage: createJSONStorage(() => localStorage) }
  )
);
