import { QUICKPLAY_OAUTH_TOKEN, QUICKPLAY_OAUTH_TOKEN_EXPIRY } from '../../../bootstrap';
import { getFromStorage, saveToStorage } from '../../lib/utils/storage';

export const getQuickPlayOAuthToken = () => {
  const token = getFromStorage(QUICKPLAY_OAUTH_TOKEN);
  return token
    ? {
        token,
        expiresAt: Number(getFromStorage(QUICKPLAY_OAUTH_TOKEN_EXPIRY))
      }
    : null;
};

export const setQuickPlayOAuthToken = (accessToken: string, expiresInSeconds: number) => {
  saveToStorage(QUICKPLAY_OAUTH_TOKEN, accessToken);
  saveToStorage(QUICKPLAY_OAUTH_TOKEN_EXPIRY, (Date.now() + expiresInSeconds * 1000).toString());
};
