import { toURL } from '../../lib/utils';
import type { ActiveSubscription, EvergentPaymentMethodType } from '../../services/api/evergent';
import type {
  AddSubscriptionsRequest,
  AddTVODOrderRequest,
  EvergentAccountServiceDetail,
  ServiceType
} from '../../services/api/evergent/types';
import {
  type ActiveSubscriptionPlan,
  type PlanTypes,
  type SubscriptionPlan,
  type SubscriptionType,
  type TVODOrder,
  type Location,
  type Subscription,
  subscriptionTypes
} from './types';
import { getConfigFromRemote } from '../config-manager';
import { configSchema, type RSNLogoType } from './schemas';
import dayjs from 'dayjs';
import { SERVICE_TYPE } from './constants';
import { findRSN, getRSNMap, getRSNs, sortByRSN } from '../rsns';
import { getActiveSubscriptions } from './services';
import { getTeamsMap } from '../teams';
import { GetActiveSubscriptionsQuery } from './query';
import { queryClient } from '../../lib/query';
import { getUserLocation } from '../check-location';
import type { PaymentMethod } from '../payments';
import { GameConfig } from '../../config/game-config';

function getRsnIdFromOvpSku(ovpSKU: string) {
  const config = getConfigFromRemote(configSchema);
  for (const skuMatcher of config.skuMatcher) {
    if (ovpSKU.includes(skuMatcher.skuRegex)) {
      return skuMatcher.id;
    }
  }
  return null;
}

/**
 * Returns collection of RSN IDs for a given product name (ovpSKU).
 * if the product name do not have corresponding RSN, it will return all RSNs (Gotham specific logic)
 * @param subscription
 */
export function getRSN(subscription: ActiveSubscription) {
  const { ovpSKU } = subscription;

  if (!ovpSKU) return [];

  const rsnId = getRsnIdFromOvpSku(ovpSKU);
  const rsn = findRSN(rsnId);
  return rsn ? [rsn] : getRSNs();
}

function sortByRSNLogo(a: RSNLogoType, b: RSNLogoType) {
  const rsnMap = getRSNMap();
  return sortByRSN(undefined, rsnMap.get(a.rsnID), rsnMap.get(b.rsnID));
}

function sortRSNLogos(logos: RSNLogoType[]) {
  logos.sort(sortByRSNLogo);
}

export function getSubscriptionTypeLogos(logos: Record<string, URL[]>, subscription?: ActiveSubscription) {
  const subscriptionType = subscription?.ovpSKU?.split(':')[3];
  return (subscriptionType && logos?.[subscriptionType]) || [];
}

type Team = {
  id: number;
};

function compareTeams(a: Team, b: Team) {
  const teamsMap = getTeamsMap('id');
  const teamA = teamsMap.get(a.id);
  const teamB = teamsMap.get(b.id);
  if (!teamA && !teamB) {
    return 0;
  }
  if (!teamA) {
    return -1;
  }
  if (!teamB) {
    return 1;
  }
  return teamA.rank - teamB.rank;
}

export function getSubscriptionPlansLogos(zone: string) {
  const config = getConfigFromRemote(configSchema);

  const { baseURL, skuMatcher, teams, assets } = config;

  const { logos: rsnLogos } = assets.companyLogos;

  const SKU_ID_NETWORKS_LOGOS = new Map<number, { rsnID: number; url: URL; size: number[] }[]>();

  const SKU_ID_TEAMS_LOGOS = new Map<
    number,
    {
      id: number;
      url: URL;
    }[]
  >();

  const SKU_REGEX_TO_ID = new Map<string, number>();

  for (const sku of skuMatcher) {
    // Map of regex to have the id in the for-loop below
    SKU_REGEX_TO_ID.set(sku.skuRegex, sku.id);

    // Team logos for the package
    const teamLogosInSku = teams
      .filter((team) => team.rsn.includes(sku.id) && team.zone?.includes(zone))
      .map((team) => ({ id: team.id, url: toURL(baseURL, team.logo) }))
      .sort(compareTeams);

    SKU_ID_TEAMS_LOGOS.set(sku.id, teamLogosInSku);

    // RSN logos for the package - if in the assets there are no rsnID to match
    // it will be treated as bundle, which includes all the RSNs in it
    const rsnLogo = rsnLogos.find((rsnLogo) => rsnLogo.rsnID === sku.id);

    const currentRSNLogos = rsnLogo ? [rsnLogo] : rsnLogos;
    sortRSNLogos(currentRSNLogos);

    SKU_ID_NETWORKS_LOGOS.set(
      sku.id,
      currentRSNLogos.map((rsnLogo) => ({
        rsnID: rsnLogo.rsnID,
        size: rsnLogo.full.size,
        url: toURL(baseURL, rsnLogo.full.url)
      }))
    );
  }

  return {
    SKU_ID_NETWORKS_LOGOS,
    SKU_ID_TEAMS_LOGOS,
    SKU_REGEX_TO_ID
  };
}

/** Gets the service type which is specified by the payment method. */
function getActiveSubscriptionType(subscription: ActiveSubscription): ServiceType {
  return subscription.paymentMethod === 'Operator Billing' ? 'TVE' : 'DTC';
}

export function userHasSVODSubscriptions(activeSubscriptionMap: Map<SubscriptionType, ActiveSubscription[]>) {
  if (!activeSubscriptionMap.size) {
    return false;
  }
  const TVESubscriptions = activeSubscriptionMap.get('TVE');
  const DTCSubscriptions = activeSubscriptionMap.get('DTC');

  return !!TVESubscriptions?.length || !!DTCSubscriptions?.length;
}

export function getCurrentActiveSubscription(activeSubscriptionsMap: Map<SubscriptionType, ActiveSubscriptionPlan[]>) {
  if (!userHasSVODSubscriptions(activeSubscriptionsMap)) return;

  const currentSubscriptions = [] as ActiveSubscriptionPlan[];
  // A user can have multiple subscribed plans
  const activeDTCSubscriptions = activeSubscriptionsMap.get('DTC');
  const activeTVESubscriptions = activeSubscriptionsMap.get('TVE');
  if (activeDTCSubscriptions) {
    currentSubscriptions.push(...Array.from(activeDTCSubscriptions.values()));
  }
  if (activeTVESubscriptions) {
    currentSubscriptions.push(...Array.from(activeTVESubscriptions.values()));
  }

  return currentSubscriptions.length
    ? currentSubscriptions.reduce((previousSubscription, currentSubscription) => {
        return dayjs(previousSubscription.endDate).isAfter(currentSubscription.endDate)
          ? previousSubscription
          : currentSubscription;
      })
    : undefined;
}

export function getPlanToChangeFromCurrent(allPlans: SubscriptionPlan[], activePlan?: SubscriptionPlan) {
  const activePlanType = activePlan?.ovpSKU.split(':')[3];
  const planToChange = allPlans.filter((plan) => {
    const planType = plan.ovpSKU.split(':')[3];
    return activePlanType === planType;
  });

  return planToChange.pop();
}

export function getActiveSubscriptionsMap(zone: string, subscriptions?: ActiveSubscription[]) {
  const { SKU_ID_NETWORKS_LOGOS, SKU_ID_TEAMS_LOGOS, SKU_REGEX_TO_ID } = getSubscriptionPlansLogos(zone);
  const activeSubscriptionMap = new Map<SubscriptionType, ActiveSubscriptionPlan[]>();

  if (!subscriptions) return activeSubscriptionMap;

  for (const subscription of subscriptions) {
    const network = subscription.ovpSKU?.split(':')[3];
    const skuId = network ? SKU_REGEX_TO_ID.get(network) : -1;
    if (!skuId) continue;
    const subscriptionType: SubscriptionType =
      subscription.serviceType === 'TVOD'
        ? 'GAME_PASS'
        : getActiveSubscriptionType(subscription) === 'TVE'
          ? 'TVE'
          : 'DTC';
    const subscriptionToPush = {
      ...subscription,
      teamLogos: SKU_ID_TEAMS_LOGOS.get(skuId) ?? [],
      rsnsLogo: SKU_ID_NETWORKS_LOGOS.get(skuId) ?? []
    };
    activeSubscriptionMap.has(subscriptionType)
      ? activeSubscriptionMap.get(subscriptionType)?.push(subscriptionToPush)
      : activeSubscriptionMap.set(subscriptionType, [subscriptionToPush]);
  }
  return activeSubscriptionMap;
}

// TODO: This needs to be generic and not specific to Yes and Msg.
export function userHasBothYesAndMsgSubscriptions(DTCSubscriptions?: ActiveSubscriptionPlan[]) {
  if (!DTCSubscriptions) return false;
  if (DTCSubscriptions.some((subscription) => subscription.ovpSKU?.includes('bundle'))) return true;
  let hasYesSubscription = false;
  let hasMsgSubscription = false;
  DTCSubscriptions.forEach((subscription) => {
    if (subscription.ovpSKU?.includes('msg')) hasMsgSubscription = true;
    if (subscription.ovpSKU?.includes('yes')) hasYesSubscription = true;
  });
  return hasMsgSubscription && hasYesSubscription;
}

export async function hasActiveSubscription(type?: SubscriptionType) {
  const { subscriptions } = await queryClient.ensureQueryData(GetActiveSubscriptionsQuery);
  const userLocation = await getUserLocation();
  const activeSubscriptionsMap = getActiveSubscriptionsMap(userLocation.zone, subscriptions);
  return type
    ? !!activeSubscriptionsMap.get(type)?.length
    : subscriptionTypes.some((st) => {
        return !!activeSubscriptionsMap.get(st)?.length;
      });
}

// TODO: Refactor this logic for game. This is specific for GOTHAM.
export type RSNs = 'yes' | 'msg' | 'bundle';

// TODO: Refactor this logic for game. This is specific for GOTHAM.
export type PlanDescription =
  | 'tve:yes'
  | 'tve:msg'
  | 'dtc:yes:annual'
  | 'dtc:yes:monthly'
  | 'dtc:msg:annual'
  | 'dtc:msg:monthly'
  | 'dtc:bundle:annual'
  | 'dtc:bundle:monthly'
  | 'msg:gamepass';

// TODO: Refactor this logic for game. This is specific for GOTHAM.
const AllowedPlansUpgradeMap = new Map<PlanDescription, PlanDescription[]>([
  ['dtc:yes:monthly', ['dtc:yes:annual', 'dtc:bundle:monthly', 'dtc:bundle:annual', 'msg:gamepass']],
  ['dtc:yes:annual', ['dtc:bundle:annual', 'msg:gamepass']],
  ['dtc:msg:monthly', ['dtc:msg:annual', 'dtc:bundle:monthly', 'dtc:bundle:annual']],
  ['dtc:msg:annual', ['dtc:bundle:annual']],
  ['dtc:bundle:monthly', ['dtc:bundle:annual']],
  ['tve:yes', ['dtc:msg:monthly', 'dtc:msg:annual', 'msg:gamepass']],
  ['tve:msg', ['dtc:yes:monthly', 'dtc:yes:annual']]
]);

// TODO: Refactor this logic for game. This is specific for GOTHAM.
export function getPlanDescriptionFromSubscription(subscription: ActiveSubscription | ActiveSubscriptionPlan) {
  const rsn = subscription.ovpSKU?.split(':')[3] as RSNs;
  const planType = subscription.ovpSKU?.split(':')[4] as PlanTypes;
  if (isTVESubscription(subscription))
    return isYESSubscription(subscription) ? 'tve:yes' : ('tve:msg' as PlanDescription);

  return `dtc:${rsn}:${planType}` as PlanDescription;
}

// TODO: Refactor this logic for game. This is specific for GOTHAM.
export function getPlanDescriptionFromPlan(plan: SubscriptionPlan) {
  const rsn = plan.ovpSKU?.split(':')[3] as RSNs;
  const planType = plan.type;
  if (isTVESubscription(plan)) {
    return (isYESSubscription(plan) ? 'tve:yes' : 'tve:msg') as PlanDescription;
  }
  if (plan.isGamePass) return 'msg:gamepass' as PlanDescription;

  return `dtc:${rsn}:${planType}` as PlanDescription;
}

// TODO: Refactor this logic for game. This is specific for GOTHAM.
export function getUpgradablePlans(currentSubscription?: ActiveSubscription | ActiveSubscriptionPlan) {
  const allowedPlans = new Set<PlanDescription>();
  if (!currentSubscription) {
    return allowedPlans;
  }
  const currentPlanRSNPlanType = getPlanDescriptionFromSubscription(currentSubscription);

  const upgradablePlans = AllowedPlansUpgradeMap.get(currentPlanRSNPlanType);

  if (!upgradablePlans) return allowedPlans;

  upgradablePlans.forEach((plan) => {
    allowedPlans.add(plan);
  });

  return allowedPlans;
}

export function getPlanTypesFromUpgradablePlans(upgradablePlans: Set<PlanDescription>) {
  const allowedPlanTypes = new Set<PlanTypes>();
  upgradablePlans.forEach((plan) => {
    if (plan.includes('annual')) allowedPlanTypes.add('annual');
    if (plan.includes('monthly')) allowedPlanTypes.add('monthly');
    if (plan.includes('gamepass')) allowedPlanTypes.add('gamePass');
  });
  return allowedPlanTypes;
}

// TODO: Refactor this logic for game. This is specific for GOTHAM.
export function isYESSubscription(activeSubscription: ActiveSubscription | ActiveSubscriptionPlan | SubscriptionPlan) {
  return activeSubscription.ovpSKU?.includes('yes');
}

// TODO: Refactor this logic for game. This is specific for GOTHAM.
export function isMSGSubscription(activeSubscription: ActiveSubscription | ActiveSubscriptionPlan | SubscriptionPlan) {
  return activeSubscription.ovpSKU?.includes('msg');
}

export function canManageSubscription(activeSubscription?: ActiveSubscription | ActiveSubscriptionPlan) {
  return (
    !isCouponSubscription(activeSubscription) &&
    isPlatformSubscription(activeSubscription) &&
    !activeSubscription?.isCancelled
  );
}

export function isCouponSubscription(activeSubscription?: ActiveSubscription | ActiveSubscriptionPlan) {
  return activeSubscription?.paymentMethod === 'Coupon';
}

export function isPlatformSubscription(activeSubscription?: ActiveSubscription | ActiveSubscriptionPlan) {
  return activeSubscription ? GameConfig.get.paymentMethods.has(getPaymentMethod(activeSubscription)) : false;
}

export async function hasActiveRsnSubscriptions(rsns: Exclude<RSNs, 'bundle'>[]) {
  const rsnSet = await getRSNsSetFromActiveSubscriptions();
  if (!rsnSet) return false;
  let result = true;
  rsns.forEach((rsn) => {
    if (!rsnSet.has(rsn)) {
      result = false;
    }
  });
  return result;
}

type SubscriptionRSNIdentifier = Pick<ActiveSubscription | ActiveSubscriptionPlan | SubscriptionPlan, 'ovpSKU'>;

export function getRSNsFromSubscriptions(subscriptions?: SubscriptionRSNIdentifier[]) {
  return subscriptions ? subscriptions.map(getRSNFromSubscription).filter((rsn) => !!rsn) : null;
}

export async function getRSNsSetFromActiveSubscriptions() {
  const { subscriptions } = await queryClient.fetchQuery(GetActiveSubscriptionsQuery);
  const rsns = getRSNsFromSubscriptions(subscriptions);
  if (!rsns?.length) return null;
  return rsns.reduce((acc, rsn) => {
    if (!acc.has(rsn)) {
      if (rsn === 'bundle') {
        getRSNs().forEach((r) => {
          acc.add(r.name.toLowerCase());
        });
      } else {
        acc.add(rsn);
      }
    }
    return acc;
  }, new Set<string>());
}

export function getRSNFromSubscription(subscription: SubscriptionRSNIdentifier) {
  const { ovpSKU } = subscription;
  if (!ovpSKU) return null;
  return ovpSKU.split(':')[3] as RSNs;
}

export function isTVESubscription(activeSubscription: ActiveSubscription | ActiveSubscriptionPlan | SubscriptionPlan) {
  return activeSubscription.ovpSKU?.includes('tve');
}

export function isDTCSubscription(activeSubscription?: ActiveSubscription | ActiveSubscriptionPlan) {
  return !!activeSubscription?.ovpSKU?.includes('dtc');
}

export function isSubscriptionNotExpired(activeSubscription: ActiveSubscription) {
  return activeSubscription.isValid;
}

/* Creates a service map based on the current services: SERVICE_TYPE */
export function createServiceMap() {
  return new Map<ServiceType, Map<string, EvergentAccountServiceDetail>>(
    Object.values(SERVICE_TYPE).map((serviceType) => [serviceType, new Map<string, EvergentAccountServiceDetail>()])
  );
}

/** Gets the service type which is specified by the payment method
 * @retuns SERVICE_TYPE based on the service that the user is subscribed to
 */
function getServiceType(service: EvergentAccountServiceDetail) {
  if (service.paymentMethod === 'Operator Billing') return SERVICE_TYPE.TVE;
  if (service.ovpSKU?.includes('superuser')) return SERVICE_TYPE.VIP;
  if (service.ovpSKU?.includes('dtc')) return SERVICE_TYPE.DTC;
  if (service.isContent) return SERVICE_TYPE.TVOD;
  return SERVICE_TYPE.FREE;
}

/** Gets the service map based on the service type.
 * @param activeServiceMap usually a map created with the function createServiceMap()
 * @param service that the user is subscribed to
 * @returns activeServiceMap or new map in case it is not found */
export function getServiceMapFromService(
  service: EvergentAccountServiceDetail,
  activeServiceMap: Map<ServiceType, Map<string, EvergentAccountServiceDetail>>
): Map<string, EvergentAccountServiceDetail> {
  const serviceType = getServiceType(service);
  return activeServiceMap.get(serviceType) ?? new Map<string, EvergentAccountServiceDetail>();
}

export const findActiveSubscription = async (plan: SubscriptionPlan) => {
  const { subscriptions } = await getActiveSubscriptions();
  const activeSubscription = (subscriptions || []).filter((s) => s.ovpSKU === plan.ovpSKU);
  return activeSubscription?.[0];
};

/** Determines if plan payment can be broken down to installments */
export function canPayByInstallments(plan: SubscriptionPlan) {
  return plan.type === 'annual';
}

export const getTVODOrderCTV = (
  channel: 'Samsung Checkout' | 'Vizio',
  contentId: string,
  transactionId: string,
  price?: string,
  transactionUId?: string
) => {
  const tvodOrder = {} as TVODOrder;
  tvodOrder.channel = channel;
  tvodOrder.contentId = contentId;
  tvodOrder.contentType = 'live';
  if (tvodOrder.channel === 'Samsung Checkout' && transactionUId && price) {
    tvodOrder.price = price;
    tvodOrder.storeCustomerID = transactionUId;
    tvodOrder.transactionId = transactionId;
  }
  if (tvodOrder.channel === 'Vizio') {
    tvodOrder.transactionId = transactionId;
  }
  return tvodOrder;
};

export const getSubscriptionCTV = (
  channel: 'Samsung Checkout' | 'Vizio',
  appServiceId: string,
  serviceId: string,
  transactionId: string,
  storeCustomerId?: string,
  price?: string
) => {
  const subscription = {} as Subscription;
  subscription.channel = channel;
  subscription.serviceID = serviceId;
  if (subscription.channel === 'Samsung Checkout' && storeCustomerId && price) {
    subscription.transactionId = transactionId;
    subscription.appServiceId = appServiceId;
    subscription.storeCustomerID = storeCustomerId;
    subscription.price = price;
  }
  if (subscription.channel === 'Vizio') {
    subscription.appServiceId = appServiceId;
    subscription.transactionId = transactionId;
  }
  return subscription;
};

export const getSubscriptionRequest = (subscription: Subscription, location: Location) => {
  const request = {} as AddSubscriptionsRequest;
  request.dmaID = location.dmaID;
  request.serviceID = subscription.serviceID;
  request.channel = subscription.channel;

  if (subscription.channel === 'Vizio' && request.channel === 'Vizio') {
    request.appServiceId = subscription.appServiceId;
    request.paymentInfo = {
      label: subscription.channel,
      makeAutoPayment: true,
      transactionReferenceMsg: {
        txID: subscription.transactionId,
        billingAddress: {
          zipCode: location.zip
        }
      }
    };
  }

  if (subscription.channel === 'Samsung Checkout' && request.channel === 'Samsung Checkout' && location.country) {
    request.appServiceId = subscription.appServiceId;
    request.paymentInfo = {
      label: subscription.channel,
      transactionReferenceMsg: {
        txID: subscription.transactionId,
        storeCustomerID: subscription.storeCustomerID,
        billingAddress: {
          zipCode: location.zip,
          country: location.country
        }
      }
    };
  }

  if (subscription.channel === 'ApplePay' && request.channel === 'ApplePay') {
    request.couponCode = subscription.couponCode;
    request.paymentInfo = {
      label: subscription.channel,
      makeAutoPayment: true,
      applePayMessage: {
        xid: subscription.channel,
        txRefNo: subscription.token,
        subscriptionId: subscription.token,
        paymentInterface: 'Checkout'
      },
      billingAddress: {
        zipCode: location.zip
      }
    };
  }

  if (subscription.channel === 'PayPalAccount' && request.channel === 'PayPalAccount') {
    request.couponCode = subscription.couponCode;
    request.paymentInfo = {
      label: subscription.channel,
      paypalAccountMsg: {
        token: subscription.paypalFacilitatorAccessToken,
        payerID: subscription.payerId,
        externalTransactionId: subscription.paymentId,
        email: subscription.payerEmail,
        amount: subscription.amount,
        billingAddress: {
          zipCode: location.zip
        }
      }
    };
  }

  return request;
};

export const getTVODOrderRequest = (order: TVODOrder, location: Location, purchaseToken: string) => {
  const request = {} as AddTVODOrderRequest;
  request.channel = order.channel;
  request.dmaID = location.dmaID;
  request.purchaseToken = purchaseToken;
  if (request.channel === 'Vizio' && order.channel === 'Vizio') {
    request.paymentInfo = {
      label: request.channel,
      makeAutoPayment: true,
      transactionReferenceMsg: {
        txID: order.transactionId,
        billingAddress: {
          zipCode: location.zip
        }
      }
    };
  }
  if (request.channel === 'Samsung Checkout' && order.channel === 'Samsung Checkout') {
    request.paymentInfo = {
      label: request.channel,
      transactionReferenceMsg: {
        txID: order.transactionId,
        storeCustomerID: order.storeCustomerID,
        billingAddress: {
          zipCode: location.zip,
          country: location.country ?? 'US'
        }
      }
    };
  }
  if (request.channel === 'ApplePay' && order.channel === 'ApplePay') {
    request.paymentInfo = {
      label: request.channel,
      makeAutoPayment: true,
      applePayMessage: {
        xid: 'ApplePay',
        txRefNo: order.token,
        subscriptionId: order.token,
        paymentInterface: 'Checkout'
      },
      billingAddress: {
        zipCode: location.zip
      }
    };
  }
  if (request.channel === 'PayPalAccount' && order.channel === 'PayPalAccount') {
    request.paymentInfo = {
      label: request.channel,
      paypalAccountMsg: {
        token: order.paypalFacilitatorAccessToken,
        payerID: order.payerId,
        externalTransactionId: order.paymentId,
        email: order.payerEmail,
        amount: order.amount,
        billingAddress: {
          zipCode: location.zip
        }
      }
    };
  }
  return request;
};

const evergentPaymentMethodMap = new Map<EvergentPaymentMethodType, PaymentMethod>();
evergentPaymentMethodMap.set('Amazon App Store Billing', 'AmazonPay');
evergentPaymentMethodMap.set('ApplePay', 'ApplePay');
evergentPaymentMethodMap.set('App Store Billing', 'AppleAppStore');
evergentPaymentMethodMap.set('Credit/Debit Card', 'CreditCard');
evergentPaymentMethodMap.set('Google Wallet', 'GooglePay');
evergentPaymentMethodMap.set('PayPal Account', 'PayPal');
evergentPaymentMethodMap.set('Roku Payment', 'RokuPay');
evergentPaymentMethodMap.set('Samsung Checkout', 'SamsungPay');
evergentPaymentMethodMap.set('Vizio', 'VizioAccount');
evergentPaymentMethodMap.set('Operator Billing', 'TVE');

export function getPaymentMethod(subscription: ActiveSubscription | ActiveSubscriptionPlan): PaymentMethod {
  return evergentPaymentMethodMap.get(subscription.paymentMethod) ?? 'Unknown';
}
