import { z } from 'zod';
import { toURL } from '../../lib/utils';

const platformSchema = z.object({
  commonAssetsPath: z.string()
});

export const channelsSchema = z
  .object({
    channels: z.array(
      z.object({
        analyticsName: z.string().optional(),
        rank: z.number(),
        channelID: z.string(),
        epgCardType: z.string(),
        gamePassAvailable: z.boolean(),
        logoLarge: z.string(),
        logoSmall: z.string(),
        name: z.string().optional(),
        qpNet: z.string(),
        qpNetId: z.string(),
        rsn: z.number(),
        zone: z.array(z.string()).optional()
      })
    ),
    baseURL: z.string(),
    web: platformSchema,
    vizio: platformSchema,
    lg: platformSchema,
    samsung: platformSchema
  })
  .transform((values) => {
    const { baseURL, channels, lg, samsung, vizio, web } = values;

    return channels.map((channel) => ({
      analyticsName: channel?.analyticsName,
      channelId: channel.channelID,
      rsnId: channel.rsn,
      rank: channel.rank,
      zones: channel?.zone,
      name: channel?.name,
      gamePassAvailable: channel.gamePassAvailable,
      channelLogos: {
        web: toURL(baseURL, `${web.commonAssetsPath}${channel.logoSmall}`),
        vizio: toURL(baseURL, `${lg.commonAssetsPath}${channel.logoSmall}`),
        lg: toURL(baseURL, `${vizio.commonAssetsPath}${channel.logoSmall}`),
        samsung: toURL(baseURL, `${samsung.commonAssetsPath}${channel.logoSmall}`)
      },
      quickplayNetwork: channel.qpNet,
      quickplayNetworkId: Number(channel.qpNetId)
    }));
  });
