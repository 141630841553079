import type { UseCases } from '../use-cases';
import type { GameError } from './errors';

export type GetReferenceCodeProps = {
  error: GameError;
  useCase?: UseCases;
};

export const enum ERROR_SOURCE {
  CONFIG = 'config',
  ADOBE = 'adobe',
  QUICKPLAY = 'quickplay',
  EVERGENT = 'evergent',
  SPORTRADAR = 'sportradar',
  HEARTBEAT = 'heartbeat',
  BITMOVIN = 'bitmovin',
  GENERIC = 'generic'
}

// TODO/Alvis: use this const enum type as the type of locale key of the error code
export const enum GENERIC_ERROR_CODE {
  NO_NETWORK_ERROR = 'gg1000',
  PLAYBACK_ERROR = 'gg1001',
  ACCOUNT_DOESNT_EXISTS = 'gg1002',
  GAME_PASS_TOKEN_UNAVAILABLE = 'gg1005',
  GENERIC_ERROR = 'gg9999'
}

export const enum CONFIG_ERROR_CODE {
  LOCALIZATION = 'gg91127',
  REMOTE = 'gg91234'
}

export const enum HEART_BEAT_ERROR_CODE {
  OUTSIDE_REGION_ROAMING = 'gg3001',
  UNAUTHORIZED = 'gg3002',
  BLACKOUT = 'gg3003',
  ENDED = 'gg3004'
}

export const enum ADOBE_ERROR_CODE {
  UNAUTHORIZED = 'ad4010'
}
