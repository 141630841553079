import { SubscriptionUtil, getSubscribedProducts } from '@game/core';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { getPagePath } from '../../../../../lib/routing';

export const Route = createFileRoute('/_private/_home/account/change-plan')({
  loader: async () => {
    const subscribedProducts = await getSubscribedProducts();
    const currentSubscription = subscribedProducts.subscriptions?.find((subscription) =>
      SubscriptionUtil.isDTCSubscription(subscription)
    );
    if (!currentSubscription || !SubscriptionUtil.canManageSubscription(currentSubscription)) {
      throw redirect({ to: getPagePath('account') });
    }
    return { currentSubscription };
  }
});
