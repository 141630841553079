import { queryOptions } from '@tanstack/react-query';
import { getConfigFromRemote } from '../config-manager';
import { GetUserQuery } from '../user';
import {
  getAllLiveContent,
  getContentsData,
  getHeroCarousel,
  getRails,
  getSeeAllRail,
  getUpcoming,
  getVODUpNext
} from './lib';
import { queryConfigSchema } from './schemas';
import { getAirings, getContainers, getStorefront } from './services';
import type {
  Content,
  GetAiringsQueryParams,
  GetContainersQueryParams,
  GetContentsDataQueryParams,
  GetSeeAllRailQueryParams,
  GetUpcomingGamesQueryParams
} from './type';

/**
 * the query to get the content metadata for the currently running program on a live or overflow channel,
 * for example if a game is currently playing on a live channel, it will return a content object that includes gameDetails
 * this hook automatically does a refetch at the time the current program is supposed to end
 */
export const GetCurrentProgramQuery = ({ id, channelId, contentTypeId }: Content) =>
  queryOptions({
    queryKey: ['current-program', id],
    queryFn: async () => {
      const all = await getAllLiveContent();

      all.sort((a) => (a.status === 'LIVE' ? -1 : 1));
      // this additional sort might not be necessary! we want to find the first LIVE program on a channel; but,
      // if no program is currently LIVE it means that the current program on the channel is not yet updated by content vendor
      // with this sort we won't exclude POST programs, but LIVE programs will have priority over other programs on the channel

      return all.find((p) => p.channelId === channelId || p.channelId === id) ?? null;
    },
    refetchInterval(query) {
      const currentProgram = query.state.data;
      const end = currentProgram?.endDate;

      // don't refetch if no program was not found or it doesn't have an end date
      if (!end) return false;

      // schedule the next refetch for when the program is supposed to end;
      const nextRefetch = end.getTime() - Date.now();

      // if current program is already finished but the next program is not yet available in content APIs,
      // retry in 2 minutes (cache-control header in this API response is set to 120 seconds)
      if (nextRefetch <= 0) return PROGRAM_STALE_TIME; // TODO: parse this value from the cache-control header

      // next refetch is padded by 30 seconds to ensure new program is updated in content vendors
      return nextRefetch + 30_000;
    },
    enabled: contentTypeId === 'live',
    staleTime: PROGRAM_STALE_TIME,
    refetchOnWindowFocus: false
  });

const PROGRAM_STALE_TIME = 120_000;
const VOD_UP_NEXT_STALE_TIME = 5 * 60 * 1000;

export const GetVODUpNextQuery = (assetId: string, playlist?: Content[], fetchMoreLikeThis?: boolean) =>
  queryOptions({
    queryKey: ['vod-up-next', assetId, playlist, fetchMoreLikeThis],
    queryFn: () => getVODUpNext(assetId, playlist, fetchMoreLikeThis),
    staleTime: VOD_UP_NEXT_STALE_TIME
  });

export const GetStorefrontQuery = (region: string) =>
  queryOptions({
    queryKey: ['storefront', region],
    queryFn: async () => getStorefront(region),
    staleTime: getConfigFromRemote(queryConfigSchema).railsRefreshTime
  });

// TODO/Alvis: cirular dependency query.ts <-> services.ts. this is a internal query, maybe be moved to internal.ts?
export function GetContainersQuery(params: GetContainersQueryParams) {
  const { region, user, pageNumber } = params;
  const { containersPageSize: pageSize } = getConfigFromRemote(queryConfigSchema);

  return queryOptions({
    queryKey: [...GetUserQuery.queryKey, 'containers', { region, pageSize, pageNumber }],
    queryFn: async () => getContainers({ region, user, pageSize, pageNumber }),
    staleTime: 30 * 1000,
    gcTime: Infinity
  });
}

export function GetHeroCarouselQuery() {
  const { heroCarouselRefreshTime: refreshTime } = getConfigFromRemote(queryConfigSchema);

  return queryOptions({
    queryKey: [...GetUserQuery.queryKey, 'hero-carousel'],
    queryFn: getHeroCarousel,
    staleTime: refreshTime,
    refetchInterval: refreshTime,
    refetchOnWindowFocus: 'always'
  });
}

export function GetRailsQuery() {
  const { railContentSize, railsRefreshTime: refreshTime } = getConfigFromRemote(queryConfigSchema);

  return queryOptions({
    queryKey: [...GetUserQuery.queryKey, 'rails'],
    queryFn: async () => getRails({ railContentSize }),
    staleTime: refreshTime,
    refetchInterval: refreshTime,
    refetchOnMount: 'always',
    refetchOnWindowFocus: 'always'
  });
}

export function GetSeeAllRailQuery(params: GetSeeAllRailQueryParams) {
  return queryOptions({
    queryKey: [...GetUserQuery.queryKey, 'see-all', 'rail', params],
    queryFn: async () => getSeeAllRail(params)
  });
}

export function GetUpcomingGamesQuery(params?: GetUpcomingGamesQueryParams) {
  const { railContentSize } = getConfigFromRemote(queryConfigSchema);
  const pageNumber = params?.pageNumber ?? 1;
  const pageSize = params?.pageSize ?? railContentSize;

  return queryOptions({
    queryKey: [...GetUserQuery.queryKey, 'upcoming', pageNumber],
    queryFn: async () => getUpcoming({ pageSize, pageNumber })
  });
}

export function GetContentsDataQuery(params: GetContentsDataQueryParams) {
  return queryOptions({
    queryKey: [...GetUserQuery.queryKey, 'content-ids', params.contentIds],
    queryFn: async () => getContentsData(params)
  });
}

export function GetAiringsQuery(params: GetAiringsQueryParams) {
  const { region, user } = params;
  const { heroCarouselRefreshTime: staleTime } = getConfigFromRemote(queryConfigSchema);

  return queryOptions({
    queryKey: [...GetUserQuery.queryKey, 'airings', region, user],
    queryFn: async () => getAirings(region, user),
    staleTime
  });
}
