import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import type { ComponentPropsWithoutRef, ElementRef, ReactNode } from 'react';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { twMerge } from '../../utils/tailwind-libs';
import { Icon } from '../icon';

/**
 * Returns the className of the scroll-area component container
 * @callback getContainerClassName
 * @param {boolean} [scrollable]
 * @returns {string}
 */

/**
 * scroll-area component
 * @param {String} [className] className to apply to the outmost container of the component
 * @param {getContainerClassName} [getContainerClassName] className to apply to the container of the chidren. Whether the scroll-area is scrollable would be passed as argument to the callback
 * @param {String} [navButtonClassName] className to apply to the left and right arrows
 * @param {ReactNode} children content of the scroll-area
 * @param {RefObject} [ref] a ref object referring to the outmost container of the component
 * @param {Array} [data] The source data that is being used to build the children elements. It must be an array of object with "id" as one of the keys. It is just needed if the list must reset the position when data changes.
 *
 * @example
 *  <ScrollArea>
 *    {items.map((item, index) => (
 *      <div className='mx-2 w-[19.8125rem]' tabIndex={0} key={item.number} >
 *        <Image width={317} height={317} src="/imageurl" className='p-2.5' />
 *        <div className='border-box w-full pt-2.5 px-3'>
 *          <Typography variant='body1'>{item.position}</Typography>
 *          <Typography variant='h11'>{item.name}</Typography>
 *          <Typography variant='h7'>{item.number}</Typography>
 *        </div>
 *      </div>))}
 *  </ScrollArea>
 */
const ScrollArea = forwardRef<
  ElementRef<typeof ScrollAreaPrimitive.Root>,
  ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root> & {
    getContainerClassName?: (scrollable: boolean) => string;
    children: ReactNode;
    navButtonClassName?: string;
    data?: GenericData;
  }
>(({ className, getContainerClassName, navButtonClassName, children, data }, ref) => {
  const viewportRef = useRef<HTMLDivElement>(null);
  const [scrollable, setScrollable] = useState(false);
  const [isRightArrowActive, setIsRightArrowActive] = useState(true);
  const [isLeftArrowActive, setIsLeftArrowActive] = useState(false);
  const [currentDataKeys, setCurrentDataKeys] = useState(getDataKeys(data));

  const updateScrollable = useCallback(() => {
    if (!viewportRef.current) {
      return;
    }
    const viewportClientWidth = viewportRef.current.clientWidth;
    const viewportScrollWidth = viewportRef.current.scrollWidth;
    setScrollable(viewportScrollWidth > viewportClientWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateScrollable);
    return () => {
      window.removeEventListener('resize', updateScrollable);
    };
  }, [updateScrollable]);

  // check if it's scrollable when children are changed
  useEffect(() => {
    updateScrollable();
  }, [updateScrollable, children]);

  // Scrolls to start of viewport on children change.
  useEffect(() => {
    const newData = getDataKeys(data);

    if (!newData || JSON.stringify(newData) === JSON.stringify(currentDataKeys)) return;

    setCurrentDataKeys(newData);

    viewportRef.current?.scrollTo({
      left: 0,
      behavior: 'smooth'
    });
  }, [currentDataKeys, data]);

  const scrollTo = useCallback(
    (direction: 'left' | 'right') => {
      if (!viewportRef.current || !children) {
        return;
      }
      const { scrollLeft, clientWidth } = viewportRef.current;
      const left = direction === 'left' ? scrollLeft - clientWidth : scrollLeft + clientWidth;
      viewportRef.current.scrollTo({
        left,
        top: 0,
        behavior: 'smooth'
      });
    },
    [children]
  );

  const onScroll = useDebouncedCallback(() => {
    if (!viewportRef.current) {
      return;
    }
    const { scrollLeft, scrollWidth, clientWidth } = viewportRef.current;
    setIsLeftArrowActive(scrollLeft > 0);
    setIsRightArrowActive(Math.ceil(scrollLeft + clientWidth) < scrollWidth);
  }, 400);

  return (
    <ScrollAreaPrimitive.Root ref={ref} className={twMerge('relative', className)}>
      <ScrollAreaPrimitive.Viewport
        className="group/scroll flex h-full w-full text-center"
        ref={viewportRef}
        onScroll={onScroll}
      >
        <button
          title="scroll left"
          type="button"
          className={twMerge(
            'absolute left-0 top-0 z-10 flex hidden h-full items-center px-3 md:block',
            'pointer-events-none cursor-pointer bg-[linear-gradient(90deg,_rgba(16,16,16,0.8)_17.65%,_rgba(16,16,16,0)_95.75%)] opacity-0 transition duration-200',
            scrollable && isLeftArrowActive && 'pointer-events-auto group-hover/scroll:opacity-100',
            navButtonClassName
          )}
          onClick={() => scrollTo('left')}
        >
          <Icon name="chevron-left" width={17} height={34} fill="none" className="mx-3 my-1" />
        </button>
        <div className={twMerge('inline-flex', getContainerClassName ? getContainerClassName(scrollable) : '')}>
          {children}
        </div>
        <button
          title="scroll right"
          type="button"
          className={twMerge(
            'absolute right-0 top-0 z-10 flex hidden h-full items-center px-3 md:block',
            'pointer-events-none cursor-pointer bg-[linear-gradient(270deg,_rgba(16,16,16,0.8)_17.65%,_rgba(16,16,16,0)_95.75%)] opacity-0 transition duration-200',
            scrollable && isRightArrowActive && 'pointer-events-auto group-hover/scroll:opacity-100',
            navButtonClassName
          )}
          onClick={() => scrollTo('right')}
        >
          <Icon name="chevron-right" width={17} height={34} fill="none" className="mx-3 my-1" />
        </button>
      </ScrollAreaPrimitive.Viewport>
      <ScrollBar orientation="horizontal" />
      <ScrollBar orientation="vertical" />
    </ScrollAreaPrimitive.Root>
  );
});
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

const ScrollBar = forwardRef<
  ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
  ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>
>(({ className, orientation }, ref) => (
  <ScrollAreaPrimitive.ScrollAreaScrollbar
    ref={ref}
    orientation={orientation}
    className={twMerge(
      'flex touch-none select-none transition-colors',
      'h-2.5 flex-col border-t border-t-transparent p-px',
      className
    )}
  >
    <ScrollAreaPrimitive.ScrollAreaThumb className="relative flex-1 rounded-full" />
  </ScrollAreaPrimitive.ScrollAreaScrollbar>
));
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;

export { ScrollArea, ScrollBar };

type GenericData = Array<{ id: string | number } & Record<string, unknown>>;

function getDataKeys(data?: GenericData) {
  return data?.map((item) => item.id);
}
