import { z } from 'zod';

const schema = z
  .object({
    baseURL: z.string().url(),
    accountLinks: z.object({
      help: z.object({
        faqURL: z.string().url(),
        picknPlayfaqURL: z.string().url(),
        oneTrustfaqURL: z.string().url(),
        contactURL: z.string().url(),
        callUsNumber: z.string(),
        contactEmail: z.string(),
        liveChat: z.object({
          URL: z.string().url(),
          display: z.string()
        })
      })
    })
  })
  .transform((parsed) => {
    const { baseURL, accountLinks } = parsed;

    return {
      baseURL,
      accountLinks: {
        faqURL: new URL(accountLinks.help.faqURL),
        picknPlayfaqURL: new URL(accountLinks.help.picknPlayfaqURL),
        oneTrustfaqURL: new URL(accountLinks.help.oneTrustfaqURL),
        contactURL: new URL(accountLinks.help.contactURL),
        liveChatURL: new URL(accountLinks.help.liveChat.URL)
      },
      contactEmail: accountLinks.help.contactEmail,
      callUsNumber: accountLinks.help.callUsNumber
    };
  });

export type HelpConfig = z.infer<typeof schema>;

export const help = { schema };
