import { createFileRoute } from '@tanstack/react-router';
import { setDevice } from '@game/core';
import { z } from 'zod';
import { SecondScreen } from '../../../app/screens/second-screen/SecondScreen';
import { GetTVProvidersQuery } from '../../../app/screens/second-screen/util';

const search = z.object({
  spAccountId: z.string().optional()
});

export const Route = createFileRoute('/_public/authenticate/$regCode/$platform/$activationCode')({
  validateSearch: search,
  beforeLoad: async ({ search, params }) => {
    const { activationCode, regCode, platform } = params;
    const { spAccountId } = search;
    if (activationCode && regCode && platform && spAccountId) {
      void setDevice({
        activationCode,
        regCode,
        platform,
        spAccountId: atob(spAccountId)
      });
    }
  },
  loader: async ({ params: { regCode }, context: { queryClient } }) => {
    const providers = await queryClient.ensureQueryData(GetTVProvidersQuery);
    return { ...providers, regCode };
  },
  component: RouteComponent
});

function RouteComponent() {
  const props = Route.useLoaderData();
  return <SecondScreen {...props} external />;
}
