export class APIError extends Error {
  override name = 'APIError';

  status: number;

  statusText: string;

  url: string;

  headers: Record<string, string>;

  constructor(
    response: Response,
    message: string,

    public body: any | null
  ) {
    super(message);
    this.status = response.status;
    this.statusText = response.statusText;
    this.url = response.url;
    this.headers = Object.fromEntries(response.headers.entries());
  }
}

export class ConfigError extends APIError {
  override name = 'ConfigFetchError';

  constructor(response: Response) {
    super(response, `Failed to fetch config from ${response.url}`, null);
  }
}

export class UserNotAuthenticatedError extends Error {
  override name = 'UserNotAuthenticatedError';

  constructor(reason: string) {
    super(reason);
    this.name = 'UserNotAuthenticatedError';
  }
}

export class TokenRefreshError extends Error {
  override name = 'TokenRefreshError';

  constructor(
    message: string,
    public data?: any
  ) {
    super(message);
  }
}
