import type { HeartbeatActions } from '../../services/api/quickplay';
import { ERROR_SOURCE, HEART_BEAT_ERROR_CODE } from './type';

export class GameError extends Error {
  override name = 'GameError';

  constructor(
    public source: string, // vendor
    public code: string, // vendor error code
    public description: string // use as fallback error message if no corresponding locale key for the error code is found
  ) {
    super(description);
  }
}

// TODO/Alvis: Replace all APIError with this error so we can handle errors better
export class GameAPIError extends GameError {
  override name = 'GameAPIError';

  constructor(
    override source: string,
    override code: string,
    override description: string,
    public request: Request,
    public response: Response
  ) {
    super(source, code, description);
  }
}

export class HeartBeatError extends GameError {
  override name = 'HeartBeatError';

  constructor(action: Exclude<HeartbeatActions, 'CONTINUE'>) {
    super(ERROR_SOURCE.HEARTBEAT, toHeartBeatErrorCode(action), action);
  }
}

function toHeartBeatErrorCode(action: Exclude<HeartbeatActions, 'CONTINUE'>): HEART_BEAT_ERROR_CODE {
  switch (action) {
    case 'ABORT_ROAMING':
      return HEART_BEAT_ERROR_CODE.OUTSIDE_REGION_ROAMING;
    case 'ABORT_NOT_ENTITLED':
      return HEART_BEAT_ERROR_CODE.UNAUTHORIZED;
    case 'ABORT_BLACKOUT':
      return HEART_BEAT_ERROR_CODE.BLACKOUT;
    case 'GRACEFUL_STOP':
      return HEART_BEAT_ERROR_CODE.ENDED;
    default:
      throw new Error(`Do not throw HeartBeatError on ${action} action`);
  }
}
