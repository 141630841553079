import { GameAPIError } from '../../features/errors';
import { GENERIC_ERROR_CODE } from '../../features/errors';

export type GameErrorBaseResponse = {
  gameSuccess: boolean;
  gameError?: {
    source: 'evergent' | 'quickplay';
    code: string;
    description?: string;
  };
  message?: string;
  gameErrorCode?: string;
};

export async function transformGAMEResponse(response: Response, request: Request) {
  const contentType = response.headers.get('Content-Type');
  if (!contentType?.startsWith('application/json')) {
    throw new Error(`Expected application/json but got ${contentType}`);
  }

  const proxyData = (await response.json()) as GameErrorBaseResponse;

  if (proxyData.gameSuccess) {
    return proxyData;
  }

  if (!proxyData.gameError) {
    throw new GameAPIError(
      'generic',
      GENERIC_ERROR_CODE.GENERIC_ERROR,
      `Missing gameError in proxy error response from ${request.url}`,
      request,
      response
    );
  }

  const { gameError } = proxyData;
  throw new GameAPIError(
    gameError.source,
    gameError.code,
    gameError.description ?? proxyData.message ?? gameError.code,
    request,
    response
  );
}
