import { isFeatureEnabled } from '@game/core';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_public/bets_/odds')({
  beforeLoad: async () => {
    const isOddsEnabled = await isFeatureEnabled('odds');

    if (!isOddsEnabled) throw new Error();
  }
});
