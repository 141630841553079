import * as braze from '@braze/web-sdk';
import { BRAZE, emit } from '@game/core';

export const initializeBraze = () => {
  const { API_KEY, SDK_URL } = BRAZE.web;
  braze.initialize(API_KEY, {
    baseUrl: SDK_URL,
    enableLogging: import.meta.env.DEV
  });

  // Enable push notifications
  if (braze.isPushSupported()) {
    braze.requestPushPermission(
      () => {
        emit('NotificationPreferenceUpdate', true);
      },
      () => {
        emit('NotificationPreferenceUpdate', false);
      }
    );
  }

  // Enable in app messages
  braze.automaticallyShowInAppMessages();

  braze.openSession();

  return true;
};
