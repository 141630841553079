import { getPaymentMethods } from '@game/core';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { getPagePath } from '../../../../../lib/routing';

export const Route = createFileRoute('/_private/_home/account/_subscription/subscription/payment')({
  beforeLoad: async ({ context: { queryClient, user, currentPayment } }) => {
    const paymentMethods = await queryClient.fetchQuery({
      queryKey: ['paymentMethods', user.userId],
      queryFn: getPaymentMethods
    });

    const currentPaymentMethod = paymentMethods.find(
      (paymentMethod) => paymentMethod?.CardInfoMessage?.paymentMethodId === currentPayment?.paymentMethodID
    );

    if (!currentPaymentMethod?.CardInfoMessage) throw redirect({ to: getPagePath('manageSubscription') });

    const { CardInfoMessage, ...rest } = currentPaymentMethod;

    return {
      paymentMethodDetails: CardInfoMessage,
      paymentMethodMetadata: rest
    };
  },
  loader: ({ context: { paymentMethodDetails, paymentMethodMetadata, currentActiveSubscription } }) => ({
    paymentMethodDetails,
    paymentMethodMetadata,
    currentActiveSubscription
  })
});
