import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import type { League } from '../../services/api/sports-radar/types';
import { getTeams, type Content, type MediaFormat } from '../content';
import type { EaseLiveRemoteConfig } from './schema';
import { GameConfig } from '../../config/game-config';
import { getPlatformCaption } from '../../lib/platform';

interface Team {
  name: string;
  tricode?: string;
}

export interface Game {
  start?: Date;
  leagueType: League | string;
  liveFlag: boolean;
  homeTeam: Team;
  awayTeam: Team;
}

dayjs.extend(utc);
dayjs.extend(timezone);

function formatGameDate(d?: Date) {
  return dayjs(d).tz('America/New_York').format('YYYYMMDD');
}

/**
 * if the content is a live game, generates the program id to initialize ease-live overlay
 * @param {Content} content content
 * program id format:
 *   <date>-<league_name?>-<away_abbreviation>-<home_abbreviation>-<1?>
 * @returns programId string or null
 * @example return values
 *   NBA: 20231018-NBA-TOR-NYC
 *   NHL: 20231018-NHL-TOR-NYC-1
 *   MLB: 20231018-TOR-NYC-1
 */

export function getProgramId(content: Content) {
  const { gameDetails, startDate, status, isPreRecorded } = content;
  if (!gameDetails || !startDate || isPreRecorded || status !== 'LIVE') return null;

  const { awayTeam, homeTeam } = getTeams(gameDetails);

  const league = gameDetails.league?.toUpperCase();
  const start = formatGameDate(startDate);
  const home = homeTeam?.tricode;
  const away = awayTeam?.tricode;
  if (!home || !away) return;

  switch (league) {
    case 'NBA':
      return `${start}-${league}-${away}-${home}`;
    case 'NHL':
      return `${start}-${league}-${away}-${home}-1`;
    case 'MLB':
      return `${start}-${away}-${home}-1`;
    default:
      return null;
  }
}

function getStreamId(mediaFormat: MediaFormat) {
  return `${getPlatformCaption(GameConfig.get.platform)}-${mediaFormat}`;
}

/**
 * generates the content-specific config to create an EaseLive instance
 * @param {Content} content the video content data that is playing
 * @param {EaseLiveRemoteConfig} config EaseLive runtime configuration
 * @param {MediaFormat} mediaFormat Media format.
 * @returns
 *  - if the content is a live game returns the config to be used in EaseLive constructor
 *  - if content is not a live game returns undefined
 */
export function getLiveStatsConfig(content: Content, config: EaseLiveRemoteConfig, mediaFormat: MediaFormat) {
  const { easeLiveDefaultStateOn: onByDefault, environment: env, accountId } = config;
  const programId = getProgramId(content);
  if (!programId) return;
  const streamId = getStreamId(mediaFormat);
  return { programId, onByDefault, env, accountId, streamId };
}
