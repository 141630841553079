import { z } from 'zod';

export type WatchTabLocale = z.infer<typeof watchTabLocaleSchema>;

export const watchTabLocaleSchema = z
  .object({
    watchScreen: z.object({
      'see-all': z.string(),
      on: z.string(),
      'on-now': z.string(),
      watch: z.string(),
      teams: z.string(),
      play: z.string(),
      rewards: z.string(),
      odds: z.string(),
      search: z.string(),
      schedule: z.string(),
      account: z.string(),
      'starts-in': z.string(),
      live: z.string(),
      final: z.string(),
      'upcoming-games': z.string(),
      'unlocked-upcoming-event-cta': z.string(),
      purchase: z.string()
    })
  })
  .transform((values) => {
    const {
      watchScreen: {
        'see-all': seeAll,
        'on-now': onNow,
        'starts-in': startsIn,
        'upcoming-games': upcomingGames,
        'unlocked-upcoming-event-cta': upcomingGameCTA,
        ...rest
      }
    } = values;

    return {
      ...rest,
      seeAll,
      onNow,
      startsIn,
      upcomingGames,
      upcomingGameCTA
    };
  });
