import { z } from 'zod';

export const outOfCountrySchema = z
  .object({
    'outside-broadcast-area': z.object({
      'out-of-country-title': z.string(),
      'out-of-country-explanation': z.string(),
      'out-of-country-action': z.string()
    })
  })
  .transform(({ 'outside-broadcast-area': strings }) => ({
    title: strings['out-of-country-title'],
    explanation: strings['out-of-country-explanation'],
    action: strings['out-of-country-action']
  }));

export type OutOfCountryLocale = z.infer<typeof outOfCountrySchema>;
