import { z } from 'zod';

const schema = z
  .object({
    baseURL: z.string().url(),
    accountLinks: z.object({
      legal: z.object({
        termsOfServiceURL: z.string().url(),
        privacyPolicyURL: z.string().url(),
        privacyPolicyIframeURL: z.string().url(),
        privacyChoicesIframeURL: z.string().url(),
        termsOfServiceIframeURL: z.string().url(),
        smsTermsAndConditionsIframeURL: z.string().url()
      })
    })
  })
  .transform((parsed) => {
    const { baseURL, accountLinks } = parsed;
    return {
      baseURL,
      accountLinks: {
        termsOfService: new URL(accountLinks.legal.termsOfServiceURL),
        privacyPolicy: new URL(accountLinks.legal.privacyPolicyURL)
      },
      privacyPolicyIframeURL: new URL(accountLinks.legal.privacyPolicyIframeURL),
      privacyChoicesIframeURL: new URL(accountLinks.legal.privacyChoicesIframeURL),
      termsOfServiceIframeURL: new URL(accountLinks.legal.termsOfServiceIframeURL),
      smsTermsAndConditionsIframeURL: new URL(accountLinks.legal.smsTermsAndConditionsIframeURL)
    };
  });

export type LegalConfig = z.infer<typeof schema>;

export const legal = { schema };
