import * as adobe from '../../services/api/adobe';
import * as evergent from '../../services/api/evergent';
import { toUserRole } from '../../services/api/evergent/lib';
import { getUserMetadata, resetXDeviceId } from '../auth';
import { saveFlatToken } from '../entitlements/lib';
import { setEvergentTVODToken } from '../entitlements/token-storage';
import type { CreateUserInput, Device, UpdateUserInput, User } from './types';
import { getDeviceId } from '../device/lib';

export async function getUser(): Promise<User> {
  const deviceId = await getDeviceId();

  const [contactResult, entitlementsResult, userMetadataResult] = await Promise.allSettled([
    evergent.getContact(),
    evergent.getEntitlements(),
    adobe.getUserMetadata(deviceId)
  ]);

  if (contactResult.status === 'rejected') {
    throw contactResult.reason;
  }
  if (entitlementsResult.status === 'rejected') {
    throw entitlementsResult.reason;
  }
  const contact = contactResult.value;
  const { entitlements, activeServiceMap, dmaID, tvodToken, ovatToken, ovatTokenExpiry } = entitlementsResult.value;

  resetXDeviceId();
  setEvergentTVODToken(tvodToken);
  saveFlatToken(ovatToken, ovatTokenExpiry);

  const mvpdId = userMetadataResult.status === 'fulfilled' ? userMetadataResult.value?.mvpd : undefined;

  const userType = evergent.getUserType(activeServiceMap);
  const userRole = toUserRole(getUserMetadata()?.accountRole);
  const entitlementsZone = userType.has('FREE') || userRole === 'QA' ? '' : dmaID;

  return {
    type: userType,
    fullName: contact.fullName,
    lastName: contact.lastName,
    firstName: contact.firstName,
    email: contact.email,
    userId: contact.userId,
    dob: contact.dob,
    gender: contact.gender,
    cpCustomerId: contact.cpCustomerId,
    spAccountId: contact.spAccountId,
    mvpdId,
    entitlements,
    entitlementsZone,
    role: userRole,
    zipCode: contact.zipCode
  };
}

export async function create(user: CreateUserInput, zoneId: string, device?: Device) {
  const zone = zoneId !== 'unavailable' ? zoneId : undefined;
  return evergent.createUser(user, zone, device);
}

export async function update(user: UpdateUserInput) {
  return evergent.updateUserProfile(user);
}
