import { API_PROXY_BASE_URL, RSN_ID } from '../../../config/build-config';
import { fetcher } from '../../../lib/fetcher';
import { transformGAMEResponse } from '../game-proxy';
import { getOAuthToken } from './token-storage';

type Metadata = {
  /**
   * Whether to include OAuth token of quickplay in the request.
   */
  withOAuthToken?: boolean;
};

export const api = fetcher.create<Metadata>({
  baseURL: API_PROXY_BASE_URL,
  headers: {
    'gg-rsn-id': RSN_ID,
    Accept: 'application/json'
  },
  transformResponse: transformGAMEResponse,
  hook: {
    beforeRequest: async (request, options) => {
      if (!options?.metadata?.withOAuthToken) return;
      const token = await getOAuthToken();
      request.headers.set('Authorization', `Bearer ${token}`);
    }
  }
});
