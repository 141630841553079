import { createActor, createMachine } from 'xstate';

const useCaseMachine = createMachine({
  id: 'useCase',
  initial: 'launch',
  states: {
    launch: {
      on: {
        NEW_USER: 'onboarding', // new account creation
        EXISTING_USER: 'onboarding', // first time login
        NAVIGATE_WATCH: 'watch' // authenticated existing user
      }
    },
    onboarding: {
      on: {
        NAVIGATE_WATCH: 'watch', // completed signup
        NAVIGATE_LAUNCH: 'launch' // went back to launch, didn't complete account creation
      }
    },
    account: {
      // manage account
      on: {
        NAVIGATE_WATCH: 'watch', // navigate back to watch/home
        LOGOUT: 'launch' // logout
      }
    },
    watch: {
      on: {
        NAVIGATE_ACCOUNT: 'account', // navigate to manage account
        LOGOUT: 'launch' // logout
      }
    },
    // FUTURE: use cases
    teams: {},
    play: {},
    rewards: {},
    odds: {},
    schedule: {},
    upsell: {},
    videoPlay: {}
  }
});

export const useCaseActor = createActor(useCaseMachine);
useCaseActor.start();
