import { canSubscribe, isFeatureEnabled, isFreeUser, isTVEUser, SubscriptionUtil } from '@game/core';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';
import { getPagePath } from '../../../lib/routing';

const searchSchema = z.object({
  isOnboarding: z.boolean().optional()
});

const ENABLE_CONNECT_TV = true as const;

export const Route = createFileRoute('/_private/ways-to-watch')({
  validateSearch: searchSchema,
  beforeLoad: async ({ context: { user }, search }) => {
    const { isOnboarding } = search;
    const hasAllAccessSubscriptions = await SubscriptionUtil.hasActiveRsnSubscriptions(['yes', 'msg']);
    const isTVE = isTVEUser(user);
    const isSubscriptionAllowed = await canSubscribe();
    if (isFreeUser(user) || isTVE) {
      return {
        isOnboarding: !!isOnboarding,
        enableConnectTV: ENABLE_CONNECT_TV,
        enableSubscribeNow: isSubscriptionAllowed && !(isTVE || hasAllAccessSubscriptions),
        search
      };
    }
    const isUpsellEnabled = await isFeatureEnabled('upsell');
    // TODO: When upsell is not enabled and is not onboarding, it is not clear what the user should see, for now is redirected to subscriptionUnavailable.
    throw redirect({
      to: getPagePath(isUpsellEnabled ? 'products' : isOnboarding ? 'pickFavoriteTeams' : 'subscriptionUnavailable'),
      search
    });
  },
  loader: ({ context: { queryClient, ...rest } }) => {
    return rest;
  }
});
