import { registerDevice } from '../register-device';
import { useActivateDeviceStore } from './store/store';
import { checkAuthenticatedRegCode } from '../../services/api/adobe';
import type { Platform } from '../../lib/platform';

/**
 * Activates the activation code for the user with the given spAccountId loaded from session storage.
 * @returns {Promise<boolean>} True if activation succeeded.
 */
export async function activateDevice(): Promise<boolean> {
  let activate = false;
  try {
    const { activationCode, spAccountId } = useActivateDeviceStore.getState();
    if (!activationCode || !spAccountId) return false;
    const response = await registerDevice(activationCode, spAccountId);
    if (
      response.status === 'success' ||
      (response.status === 'failed' && response.message === 'Device already activated')
    ) {
      activate = true;
    }
  } catch (e) {
    activate = false;
  }
  return activate;
}

export async function checkRegCode(): Promise<boolean> {
  const { regCode } = useActivateDeviceStore.getState();
  if (!regCode) return false;
  return await checkAuthenticatedRegCode({ regCode });
}

type SetDeviceParams = {
  regCode: string;
  activationCode: string;
  platform: string;
  spAccountId: string;
};

export function setDevice(params: SetDeviceParams) {
  const { regCode, platform, activationCode, spAccountId } = params;
  const state = useActivateDeviceStore.getState();
  state.setRegCode(regCode);
  state.setPlatform(platform);
  state.setActivationCode(activationCode);
  state.setSpAccountId(spAccountId);
}

export function clearDevice() {
  const state = useActivateDeviceStore.getState();
  state.setRegCode(undefined);
  state.setPlatform(undefined);
  state.setActivationCode(undefined);
  state.setSpAccountId(undefined);
}

export function getDevice() {
  const state = useActivateDeviceStore.getState();
  return {
    regCode: state.regCode,
    activationCode: state.activationCode,
    platform: state.platform as Platform,
    spAccountId: state.spAccountId
  };
}
