import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isToday from 'dayjs/plugin/isToday';
import { getRSNContentFilter } from '../../../features/content/utils';
import { isFeatureEnabled } from '../../../features/features-manager';
import { isItemNotFoundError } from './error';
import { api } from './fetcher';
import {
  getDeviceSecret,
  getOAuthToken,
  getXDeviceId,
  removeDeviceTokens,
  saveDeviceSecret,
  saveXDeviceId
} from './token-storage';
import {
  type AssetSignedURLBody,
  type AuthTokenResponse,
  type CheckRedeemParams,
  type CheckRedeemResponse,
  type GetAssetSignedUrlArgs,
  type GetAssetSignedUrlResponse,
  type GetChannelsRequest,
  type GetContainersDataParams,
  type GetContentsByIds,
  type GetEPGRequest,
  type GetLiveEventsFilteredParams,
  type GetUpnextRequest,
  type HeartbeatUpdateResponse,
  type LocationLookupRequest,
  type LocationLookupResponse,
  type MoreLikeThisResponse,
  type PaginationParams,
  type QuickplayAiringResponseData,
  type QuickplayCatalogType,
  type QuickplayContainerResponse,
  type QuickplayEPGDataResponse,
  type QuickplayGetChannelsResponse,
  type QuickplayGetContainerContentResponse,
  type QuickplayGetContentsByIdsResponse,
  type QuickplayGetUpnextResponse,
  type QuickplayLiveEventResponseData,
  type QuickplaySearchContentResponse,
  type QuickplaySingleContentResponse,
  type QuickplayStorefrontResponse,
  type RegisterDeviceResponse,
  type SearchContentParams
} from './types';
import { getAuthClientID, getAuthClientSecret, getClientType, getDeviceType, signJWT } from './util';

dayjs.extend(duration);
dayjs.extend(isToday);

const DEFAULT_PAGINATION = {
  pageNumber: '1',
  pageSize: '10'
};

/**
 * Device Location Lookup
 */
export async function deviceLocationLookup(coords?: LocationLookupRequest) {
  const { data } = await api<LocationLookupResponse>('device/location/lookup', {
    method: 'GET',
    search: coords,
    headers: {
      'X-Client-Id': getClientType()
    },
    metadata: {
      withOAuthToken: true
    }
  });
  return data;
}

/**
 * Returns QuickPlay auth token and re-fetches it if expired or not stored
 */
export async function fetchQuickPlayAuthToken() {
  const response = await api<AuthTokenResponse>('oauth2/token', {
    method: 'POST',
    body: new URLSearchParams({
      client_id: getAuthClientID(),
      client_secret: getAuthClientSecret(),
      grant_type: 'client_credentials',
      audience: 'edge-service',
      scope: 'openid'
    })
  });
  return { token: response.access_token, expiresAt: response.expires_in };
}

type DeviceSecret = {
  secret: string;
  jwtExpiresIn: number;
};

/**
 * Registers device using QuickPlay API
 * @param {string} deviceId
 * @param {string} flatToken
 * @returns {DeviceSecret} Secret to sign device token and expiry.
 */
export async function fetchDeviceSecret(deviceId: string, flatToken: string): Promise<DeviceSecret> {
  const cachedSecret = getDeviceSecret();
  if (cachedSecret.secret) {
    const { secret, jwtExpiresIn } = cachedSecret;
    return { secret, jwtExpiresIn };
  }

  const { data } = await api<RegisterDeviceResponse>('device/app/register', {
    method: 'POST',
    body: {
      uniqueId: deviceId
    },
    headers: {
      'X-Authorization': flatToken,
      'X-Client-Id': getClientType()
    },
    metadata: {
      withOAuthToken: true
    }
  });
  const { secret, expiry } = data;
  saveDeviceSecret(secret, expiry);
  return { secret, jwtExpiresIn: expiry };
}

async function generateXDeviceId(deviceId: string, flatToken: string) {
  const cached = getXDeviceId();
  const now = Math.floor(Date.now() / 1000);
  if (cached.jwt && cached.expiresAt > now) return cached.jwt;

  const { secret, jwtExpiresIn } = await fetchDeviceSecret(deviceId, flatToken);
  if (!secret) throw new Error('Unable to fetch Device Secret!');

  const expiry = now + jwtExpiresIn;
  const jwt = await signJWT({ deviceId }, expiry, secret);
  saveXDeviceId(jwt, expiry);

  return jwt;
}

/**
 * Playback authorization. Returns signed url of asset to be played.
 * @param {Object} arg
 * @param {Object} arg.video - Video
 * @param {string} arg.deviceId - Device ID
 * @param {string} arg.flatToken - Evergent flat token
 * @param {string} arg.mediaToken - Media Token generated from Adobe
 * @param {string} arg.streamType - Stream Type 'hls' | 'dash'
 */
export async function getAssetSignedUrl({
  video,
  deviceId,
  flatToken,
  tvodToken,
  mediaToken,
  streamType = 'hls',
  urlParameters
}: GetAssetSignedUrlArgs) {
  const { id: contentId, type: contentType, catalogType } = video;
  const [platformToken, xDeviceId] = await Promise.all([getOAuthToken(), generateXDeviceId(deviceId, flatToken)]);

  const headers = new Headers();
  headers.set('Authorization', `Bearer ${platformToken}`);
  headers.set('X-Authorization', flatToken);
  headers.set('X-Client-Id', getClientType());
  headers.set('X-Device-Id', xDeviceId);

  if (!!video.gamePassAssetId && tvodToken) {
    headers.set('X-Tvod-Authorization', tvodToken);
  }

  if (mediaToken) {
    headers.set('X-Adobe-Authorization', mediaToken);
  }

  const assetRequestBody: AssetSignedURLBody = {
    catalogType,
    contentId,
    contentTypeId: contentType,
    delivery: 'streaming',
    deviceId,
    // TODO: check with quickplay, not all device type is a valid deviceName
    deviceName: 'web',
    disableSsai: false,
    drm: streamType === 'hls' ? 'fairplay' : 'widevine',
    mediaFormat: streamType,
    playbackMode: 'live',
    urlParameters
  };

  return await api<GetAssetSignedUrlResponse>('media/content/authorize', {
    method: 'POST',
    headers,
    body: assetRequestBody
  });
}

export async function updateHeartbeat(deviceId: string, itemId: string, flatToken: string, heartbeatToken: string) {
  return api<HeartbeatUpdateResponse>('user/heartbeat/update', {
    method: 'POST',
    body: {
      deviceId,
      itemId
    },
    headers: {
      'X-Authorization': flatToken,
      'X-Client-Id': getClientType(),
      'X-Heartbeat': heartbeatToken
    },
    metadata: {
      withOAuthToken: true
    }
  });
}

export async function createStream(deviceId: string, flatToken: string) {
  return api('user/stream/create', {
    method: 'PUT',
    body: {
      deviceId
    },
    headers: {
      'X-Authorization': flatToken,
      'X-Client-Id': getClientType()
    },
    metadata: {
      withOAuthToken: true
    }
  });
}

export async function deleteStream(deviceId: string, flatToken: string) {
  return api(`user/stream/delete/${deviceId}`, {
    method: 'DELETE',
    headers: {
      'X-Authorization': flatToken,
      'X-Client-Id': getClientType()
    },
    metadata: {
      withOAuthToken: true
    }
  });
}

export async function checkRedeem({ contentId, contentType, deviceId, flatToken }: CheckRedeemParams) {
  const { data } = await api<CheckRedeemResponse>('media/content/checkredeem', {
    method: 'POST',
    headers: {
      'X-Authorization': flatToken,
      'X-Client-Id': getClientType()
    },
    body: {
      deviceId,
      contentId,
      contentTypeId: contentType,
      catalogType: 'liveevent',
      delivery: 'streaming'
    },
    metadata: {
      withOAuthToken: true
    }
  });

  return { canRedeem: !!data, token: data?.token };
}

/**
 * Common utils for Quickplay API
 */

export function logout() {
  removeDeviceTokens();
}

type GetMoreLikeThisParams = {
  assetId: string;
  zoneId: string;
};

export async function searchMoreLikeThis({ assetId, zoneId }: GetMoreLikeThisParams) {
  const { data } = await api<MoreLikeThisResponse>(`content/morelikethis/${assetId}`, {
    search: {
      reg: zoneId,
      dt: getDeviceType(),
      client: getClientType(),
      pageNumber: '1',
      pageSize: '10',
      st: 'published'
    }
  });

  return data ?? [];
}

export async function getContainersData(params: GetContainersDataParams) {
  const {
    storefrontId,
    tabId,
    zoneId,
    pageNumber = DEFAULT_PAGINATION.pageNumber,
    pageSize = DEFAULT_PAGINATION.pageSize
  } = params;

  const search = {
    reg: zoneId,
    dt: getDeviceType(),
    client: getClientType(),
    pageNumber,
    pageSize
  };
  await setETParam(search);
  const { data } = await api<QuickplayContainerResponse>(`catalog/storefront/${storefrontId}/${tabId}/containers`, {
    method: 'GET',
    search
  });

  return data;
}

// Modifies the search in place.
async function setETParam(search: Record<string, any>) {
  if (!(await isFeatureEnabled('filterContentByRSN'))) return;
  const rsns = await getRSNContentFilter();
  if (!rsns?.length) return;
  search.et = rsns.join(',');
}

export async function getStorefront(zoneId: string) {
  const search = {
    ty: 'storefront',
    reg: zoneId,
    dt: getDeviceType(),
    client: getClientType(),
    ...DEFAULT_PAGINATION
  };
  await setETParam(search);
  const { data } = await api<QuickplayStorefrontResponse>('storefront/list', {
    method: 'GET',
    search
  });

  return data;
}

export async function getAiring(zoneId: string) {
  try {
    const search = {
      reg: zoneId,
      dt: getDeviceType(),
      client: getClientType(),
      ...DEFAULT_PAGINATION
    };
    await setETParam(search);
    const { data } = await api<QuickplayAiringResponseData>('content/airing/live', {
      method: 'GET',
      search
    });

    return data;
  } catch (err) {
    if (!isItemNotFoundError(err)) throw err;
    return [];
  }
}

export async function getLiveEvents(zoneId: string) {
  try {
    const search = {
      reg: zoneId,
      dt: getDeviceType(),
      client: getClientType(),
      ...DEFAULT_PAGINATION
    };
    await setETParam(search);
    const { data } = await api<QuickplayLiveEventResponseData>('content/liveevent/live', {
      method: 'GET',
      search
    });

    return data;
  } catch (err) {
    if (!isItemNotFoundError(err)) throw err;
    return [];
  }
}

export async function getLiveEventsFiltered(params: GetLiveEventsFilteredParams) {
  const { region, endDate, pageNumber, pageSize, startDate, teamExternalId } = params;

  const searchParams = new URLSearchParams({
    reg: region,
    dt: getDeviceType(),
    client: getClientType(),
    pageNumber: pageNumber?.toString() ?? DEFAULT_PAGINATION.pageNumber,
    pageSize: pageSize?.toString() ?? DEFAULT_PAGINATION.pageSize
  });

  if (teamExternalId) searchParams.append('team', teamExternalId);
  if (startDate) searchParams.append('start', startDate.toISOString());
  if (endDate) searchParams.append('end', endDate.toISOString());

  try {
    const { data } = await api<QuickplayLiveEventResponseData>('content/liveevent/filter', {
      method: 'GET',
      search: searchParams
    });
    return data;
  } catch (err) {
    if (!isItemNotFoundError(err)) throw err;
    return [];
  }
}

export async function getSingleContent(params: { id: string; catalogType: QuickplayCatalogType }) {
  const { catalogType, id } = params;

  const { data } = await api<QuickplaySingleContentResponse>(`content/urn/resource/catalog/${catalogType}/${id}`, {
    method: 'GET',
    search: {
      dt: getDeviceType(),
      client: getClientType()
    }
  });

  return data;
}

export async function getSearchedContent(params: SearchContentParams) {
  const { searchTerm, region } = params;

  try {
    const search = {
      reg: region,
      dt: getDeviceType(),
      client: getClientType(),
      cty: 'episode',
      pageNumber: '1',
      pageSize: '10',
      st: 'published',
      term: searchTerm
    };
    await setETParam(search);
    const { data } = await api<QuickplaySearchContentResponse>('content/search', {
      method: 'GET',
      search
    });

    return data ?? [];
  } catch (error) {
    if (!isItemNotFoundError(error)) throw error;

    return [];
  }
}

export async function getContainerContentByUrl({
  zoneId,
  containerUrl,
  pageNumber,
  pageSize
}: PaginationParams & { containerUrl: string; zoneId: string }): Promise<QuickplayGetContainerContentResponse> {
  const search = {
    reg: zoneId,
    dt: getDeviceType(),
    client: getClientType(),
    pageNumber: pageNumber.toString(),
    pageSize: pageSize.toString()
  };
  await setETParam(search);

  const { pathname, searchParams } = new URL(containerUrl);
  const path = pathname.substring(1); // The first character of the pathname is a "/", we need to remove that to work with api fetcher

  Object.entries(search).forEach(([key, value]) => searchParams.set(key, value));

  return await api<QuickplayGetContainerContentResponse>(path, {
    method: 'GET',
    search: searchParams
  });
}

export async function getUpcomingContent({ zoneId, pageSize, pageNumber }: GetUpnextRequest) {
  try {
    const search = {
      reg: zoneId,
      dt: getDeviceType(),
      client: getClientType(),
      pageNumber: pageNumber.toString(),
      pageSize: pageSize.toString()
    };
    const watchConfig = await isFeatureEnabled('watch');
    if (watchConfig.upcoming.filter.rsn) {
      await setETParam(search);
    }
    return await api<QuickplayGetUpnextResponse>('content/liveevent/upnext', {
      method: 'GET',
      search
    });
  } catch (err) {
    if (!isItemNotFoundError(err)) throw err;

    return {
      header: {},
      data: undefined
    };
  }
}

export async function getChannels({ zoneId, pageSize, pageNumber }: GetChannelsRequest) {
  try {
    return await api<QuickplayGetChannelsResponse>('content/urn/resource/catalog/channel', {
      method: 'GET',
      search: {
        reg: zoneId,
        dt: getDeviceType(),
        client: getClientType(),
        pageNumber: pageNumber.toString(),
        pageSize: pageSize.toString()
      }
    });
  } catch (err) {
    if (!isItemNotFoundError(err)) throw err;

    return null;
  }
}

export async function getEPG({ channelId, zoneId, startDate }: GetEPGRequest) {
  const djsStart = dayjs(startDate);
  const start = (djsStart.isToday() ? djsStart : djsStart.startOf('day')).startOf('minutes');
  const end = dayjs(start).endOf('day').startOf('minutes');

  try {
    return await api<QuickplayEPGDataResponse>('content/epg', {
      method: 'GET',
      search: {
        reg: zoneId,
        dt: getDeviceType(),
        client: getClientType(),
        channel: channelId,
        start: start.toISOString(),
        end: end.toISOString(),
        ...DEFAULT_PAGINATION
      }
    });
  } catch (err) {
    if (!isItemNotFoundError(err)) throw err;

    return null;
  }
}

export async function getContentsByIds(params: GetContentsByIds) {
  const { contentIds, zoneId } = params;

  return api<QuickplayGetContentsByIdsResponse>('content', {
    method: 'GET',
    search: {
      reg: zoneId,
      dt: getDeviceType(),
      client: getClientType(),
      ids: encodeURIComponent(contentIds.join(','))
    }
  });
}
