import type { DefaultFetcherOptions, FetcherHook, FetcherOptions, MetadataBase } from './type';
import { toRequest } from './util';
export * from './type';

const HARD_RETRY_LIMIT = 30;

type DefaultFetchHook = FetcherHook<any>;
const globalHooks = {
  beforeRequest: new Set(),
  afterResponse: new Set(),
  beforeError: new Set()
} satisfies { [K in keyof DefaultFetchHook]-?: Set<NonNullable<DefaultFetchHook[K]>> };

export const fetcher = {
  create,
  hook: {
    add: addHook,
    remove: removeHook
  }
};

function create<M extends MetadataBase>(defaultOptions: DefaultFetcherOptions<M>) {
  const defaultTransformResponse = defaultOptions.transformResponse ?? ((response: Response) => response.json());

  async function fetcher<T = any>(input: Request, options?: FetcherOptions<M>): Promise<T> {
    const request = toRequest(input, defaultOptions, options);

    await defaultOptions?.hook?.beforeRequest?.(request, options);
    await triggerGlobalHooks('beforeRequest', request, options);

    const response = await fetch(request);

    await defaultOptions?.hook?.afterResponse?.(response, request, options);
    await triggerGlobalHooks('afterResponse', response, request, options);

    const transformResponse = options?.transformResponse ?? defaultTransformResponse;
    const result = await transformResponse(response, request, options);

    return result as T;
  }

  return async function instance<T = any>(input: string | Request | URL, options?: FetcherOptions<M>): Promise<T> {
    let retryCount = 0;
    while (retryCount <= HARD_RETRY_LIMIT) {
      try {
        const request = toRequest(input, defaultOptions, options);
        return await fetcher(request, options);
      } catch (error) {
        const shouldRetry = options?.retry ?? defaultOptions.retry;
        if (!(await shouldRetry?.(error, retryCount))) {
          const request = toRequest(input, defaultOptions, options);
          await defaultOptions?.hook?.beforeError?.(request, error);
          await triggerGlobalHooks('beforeError', request, error);
          throw error;
        }
        // exponential backoff with jitter
        const backoffTime = Math.pow(2, retryCount) * 1000 + Math.random() * 1000;
        await new Promise((resolve) => setTimeout(resolve, backoffTime));
        retryCount++;
      }
    }

    throw new Error(
      `Hard retry limit reached for ${typeof input === 'string' ? input : input instanceof URL ? input.href : input.url}`
    );
  };
}

async function triggerGlobalHooks<T extends keyof DefaultFetchHook>(
  type: T,
  ...args: Parameters<NonNullable<DefaultFetchHook[T]>>
) {
  for (const hook of globalHooks[type]) {
    await (hook as any)(...args);
  }
}

function addHook<T extends keyof DefaultFetchHook>(type: T, hook: NonNullable<DefaultFetchHook[T]>) {
  globalHooks[type].add(hook as any);
}

function removeHook<T extends keyof DefaultFetchHook>(type: T, hook: NonNullable<DefaultFetchHook[T]>) {
  globalHooks[type].delete(hook as any);
}
