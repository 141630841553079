import type { z } from 'zod';
import { getConfigFromRemote } from '../config-manager';
import { teamConfigSchema } from './schema';
import type { Team } from './type';

export function getAllTeams(): Team[] {
  const teams = getConfigFromRemote(teamConfigSchema);
  return teams.map(toTeams);
}

export async function getTeamsByZone(zone: string): Promise<Team[]> {
  const teams = getConfigFromRemote(teamConfigSchema);
  return teams.filter((team) => team.zone.has(zone)).map(toTeams);
}

function toTeams(team: z.infer<typeof teamConfigSchema>[0]): Team {
  return {
    id: team.id,
    rank: team.rank,
    name: team.name,
    shortName: team.shortName,
    logo: team.logo.href,
    backgroundURL: team.backgroundURL.href,
    code: team.code,
    externalId: team.externalId
  };
}
