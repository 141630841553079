import { toURL } from '@game/core';
import { z } from 'zod';

type TransformResult = { [key: string]: URL[] };

const schema = z
  .object({
    baseURL: z.string().url(),

    skuMatcher: z.array(
      z.object({
        id: z.number(),
        skuRegex: z.string()
      })
    ),
    assets: z.object({
      companyLogos: z.object({
        logos: z.array(
          z.object({
            rsnID: z.number(),
            full: z.object({
              url: z.string(),
              size: z.array(z.number())
            })
          })
        )
      })
    })
  })
  .transform((parsed) => {
    const {
      baseURL,
      skuMatcher,
      assets: { companyLogos }
    } = parsed;
    const subscriptionTypeLogos: TransformResult = {};
    skuMatcher.forEach((matcher) => {
      let logoUrls;

      if (matcher.skuRegex === 'bundle') {
        logoUrls = companyLogos.logos.map((logo) => toURL(baseURL, logo.full.url));
      } else {
        const matchingLogos = companyLogos.logos.filter((logo) => logo.rsnID === matcher.id);
        logoUrls = matchingLogos.map((logo) => toURL(baseURL, logo.full.url));
      }

      subscriptionTypeLogos[matcher.skuRegex] = logoUrls;
    });

    return { subscriptionTypeLogos };
  });

export const manageSubscription = { schema };
