import { GameError } from '../../../features/errors';

export const enum QUICKPLAY_ERROR_CODE {
  ITEM_NOT_FOUND = '40251006',
  SCHEDULE_NOT_FOUND = '40251709'
}

const ITEM_NOT_FOUND_CODES = new Set(['40251006', '40252605', '40251709', '40252502', '40252002', '40251902']);

export function isItemNotFoundError(error: unknown) {
  return (
    error instanceof GameError &&
    error.source === 'quickplay' &&
    (ITEM_NOT_FOUND_CODES.has(error.code) || error.description === 'Item not found')
  );
}
