/**
 * TODO: This is a temporary solution to populate env files in core.
 * We will use try to use all values from the 'build-config',
 * if you need a value that is not from the build config, try to create a PR to update the config
 * otherwise, pass it as a parameter to your function.
 */

// other variables
export const TOKEN_STORAGE_KEY = 'userTokens';
export const SEARCH_ACCOUNT_STORAGE_KEY = 'searchAccount';
export const QUICKPLAY_AUTH_TOKEN = 'quickplayAuthToken';
export const FLAT_TOKEN = 'freeFlatToken';
export const TVOD_TOKEN = 'tvodToken';
export const FLAT_TOKEN_EXPIRY = 'freeFlatTokenExpiresAt';
export const QUICKPLAY_OAUTH_TOKEN = 'quickPlayOAuthToken';
export const QUICKPLAY_OAUTH_TOKEN_EXPIRY = 'quickPlayOAuthTokenExpiresAt';
export const PAYPAL_AUTH_TOKEN = 'paypalAuthToken';
export const COUCH_RIGHTS_END_DATE_KEY = 'couchRightsEndDate';
export const MOCK_COORDS_KEY = 'mockCoords';
export const USER_METADATA_STORAGE_KEY = 'userMetadata';
// X-Device-ID Storage Keys
export const XDI_SECRET = 'deviceSecret';
export const XDI_TOKEN = 'deviceToken';
export const XDI_EXPIRES_IN = 'deviceTokenExpireIn';
export const XDI_EXPIRES_AT = 'deviceTokenExpiresAt';
