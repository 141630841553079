export const handlerPrefix = 'handle' as const;
export type HandlerPrefix = typeof handlerPrefix;

export type StripHandlerPrefix<S extends string | number | symbol> = S extends `${HandlerPrefix}${infer Rest}`
  ? Rest
  : S;

type HandlerConstraint = (...args: any[]) => void;
type HandlersConstraint = Record<string, HandlerConstraint>;

export type EventToHandler<Handlers extends HandlersConstraint> = {
  [K in keyof Handlers as StripHandlerPrefix<K>]: Handlers[K];
};
