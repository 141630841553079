// TODO/Alvis: reuse this in evergent and quickplay token storage, implemtent LocalStorageTokenStorage using ExpirableStorage interface
type Timestamp = number;

interface ExpirableStorage<Data> {
  set(data: Data, expireAt: Timestamp): void;
  get(): Data | null;
}

export class InMemoryTokenStorage<Token> implements ExpirableStorage<Token> {
  private token: Token | null = null;

  private expire: number | null = null;

  set(token: Token, expireAt: Timestamp) {
    this.token = token;
    this.expire = expireAt;
  }

  get(): Token | null {
    if (this.token && this.expire && this.expire > Date.now()) {
      return this.token;
    }
    this.clear();
    return null;
  }

  clear() {
    this.token = null;
    this.expire = null;
  }
}
