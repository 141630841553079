import { toURL } from '@game/core';
import { z } from 'zod';

export const launch = z
  .object({
    baseURL: z.string().url(),
    assets: z.object({
      launchVideo: z.object({
        '1920x1080': z.object({
          fallbackImage: z.string()
        }),
        sources: z.array(
          z.object({
            src: z.string(),
            type: z.string(),
            codecs: z.string()
          })
        )
      }),
      RSNLogo: z.object({
        centerJustified: z.string()
      })
    })
  })
  .transform((values) => {
    const {
      baseURL,
      assets: {
        launchVideo: {
          '1920x1080': { fallbackImage: fallback },
          sources
        },
        RSNLogo: { centerJustified: logo }
      }
    } = values;

    return {
      video: {
        sources: sources.map((source) => {
          return {
            src: toURL(baseURL, source.src).href,
            type: source.type
          };
        })
      },
      fallback: toURL(baseURL, fallback),
      logo: toURL(baseURL, logo)
    };
  });

export type LaunchConfig = z.infer<typeof launch>;
