import { z } from 'zod';
import { queryClient } from '../../../lib/query';
import { isUserLoggedIn } from '../../auth';
import { getUserData } from '../../user';
import { GetCheckLocationQuery } from '../query';
import type { Coords } from '../types';
import * as storage from './storage';

export const LocationMocker = {
  /**
   * Only QA have the ability to override their location
   * @returns a coords object if the user is allowed to mock their location, otherwise null
   */
  async get() {
    if (!isUserLoggedIn()) return null;
    const user = await getUserData();
    if (user.role !== 'QA') return null;

    const mockCoords = storage.getMockCoords();

    const result = mockCoordsSchema.safeParse(mockCoords);

    return result.success ? result.data : null;
  },
  update(coords: Coords) {
    storage.saveMockCoords(coords);
    return queryClient.invalidateQueries({ queryKey: GetCheckLocationQuery().queryKey });
  },
  clear() {
    storage.removeMockCoords();
    return queryClient.invalidateQueries({ queryKey: GetCheckLocationQuery().queryKey });
  }
};

const mockCoordsSchema = z.object({ lat: z.string(), lon: z.string() });
