import type { ContentTeam } from '../content';
import { getFavouriteTeamsSet } from '../favourites/service';
import { getContentZone } from '../zone';
import { getAllTeams, getTeamsByZone } from './service';
import type { Team } from './type';

/**
 * @param {string} [key='externalId'] value to use as key on result map from the team instance. */
export async function getUserFavouriteTeamsMap(key?: 'externalId' | 'id') {
  const userTeamSet = await getFavouriteTeamsSet();
  const teams = getAllTeams();
  const resultMap = new Map<string | number, Team>();
  for (const team of teams) {
    const teamId = team.id.toString();
    if (userTeamSet.has(teamId)) {
      resultMap.set(team[key ?? 'externalId'], team);
    }
  }
  return resultMap;
}

export function getTeamsMap(key: 'shortName' | 'id') {
  const teams = getAllTeams();
  const teamsMap = new Map<string | number, Team>();
  teams.forEach((team) => {
    teamsMap.set(team[key], team);
  });
  return teamsMap;
}

export async function getTeams() {
  const zone = await getContentZone();
  return getTeamsByZone(zone);
}

export function getPrimaryTeam(teams: ContentTeam[]): Team | null {
  const teamsMap = getTeamsMap('shortName');
  const homeTeam = teams.find((t) => t.type === 'home');
  let primaryTeam = homeTeam ? teamsMap.get(homeTeam.shortName) : null;
  if (primaryTeam) return primaryTeam;
  const awayTeam = teams.find((t) => t.type === 'away');
  if (!awayTeam) return null;
  primaryTeam = teamsMap.get(awayTeam.shortName);
  return primaryTeam ?? null;
}
