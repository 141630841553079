export const enum EVERGENT_ERROR_CODE {
  INVALID_REFRESH_TOKEN = 'eV2767',
  INVALID_SESSION_TOKEN = 'eV2124',
  NO_ACCOUNT_FOUND = 'eV2327',
  MAX_TVE_LINKING = 'eV6761',
  ADOBE_RESOURCE_ALREADY_EXISTED = 'eV1144',
  AUTHENTICATION_FAILED = '111111111',
  WRONG_USERNAME_OR_PASSWORD = 'eV2134',
  INVALID_COUPON_CODE = 'eV1839',
  INVALID_COUPON_CODE_FOR_BUSINESS_UNIT = 'eV6046',
  NO_PRODUCTS_ASSOCIATED_WITH_COUPON = 'eV1845',
  ACCOUNT_DOES_NOT_EXISTS = 'eV1744'
}
