import {
  canSubscribe,
  getConfigFromRemote,
  isDTCUser,
  isFeatureEnabled,
  isTVEUserLoggedIn,
  isVIPUser
} from '@game/core';
import { createFileRoute } from '@tanstack/react-router';
import { legal as legalConfig } from '../../../../lib/config';

export const Route = createFileRoute('/_private/_home/account/')({
  beforeLoad: () => ({ config: getConfigFromRemote(legalConfig.schema) }),
  loader: async ({ context: { user, config } }) => {
    const isTVELogin = isTVEUserLoggedIn(user);
    const isDTCLogin = isDTCUser(user);
    const isVIPUserLogin = isVIPUser(user);
    const isSubscriptionAllowed = await canSubscribe();
    const referrals = (await isFeatureEnabled('referrals')) as boolean;
    return {
      config,
      showManageSubscription: isDTCLogin,
      showPurchasePlans: !isDTCLogin && !isVIPUserLogin && isSubscriptionAllowed,
      showManageTvProvider: isTVELogin,
      showConnectTv: !isTVELogin && !isVIPUserLogin,
      features: {
        referrals
      }
    };
  }
});
