import * as braze from '@braze/web-sdk';
import type { User } from '@game/core';

const brazeCustomUserAttribute = (attributeKey: string, attributeValue: any) => {
  braze.getUser()?.setCustomUserAttribute(attributeKey, attributeValue);
};

export const changeBrazeUser = (user: User) => {
  braze.changeUser(user.spAccountId);
};

// Teams
export const addFavTeamsTag = (teamTags: string[]) => {
  brazeCustomUserAttribute('favorite_teams_push', teamTags);
};

// Players
export const addFavPlayers = (players: string[]) => {
  brazeCustomUserAttribute('player_notifications', players);
};
