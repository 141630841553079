import { UAParser } from 'ua-parser-js';
import { getDeviceId } from '../device/device';

export async function getDeviceDetails() {
  const userAgent = UAParser();

  return {
    deviceName: userAgent.browser?.name ?? 'browser',
    deviceType: 'browser',
    brand: '',
    serialNo: await getDeviceId(),
    modelNo: '',
    name: '',
    appType: '',
    locale: '',
    type: ''
  };
}
