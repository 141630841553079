import { apiFetcher } from './fetcher';
import type {
  CheckoutPaymentContextRequest,
  CheckoutPaymentContextSuccessResponse,
  CheckoutPaymentRequest,
  CheckoutPaymentSuccessResponse
} from './types';

export const getPaymentContext = async (request: CheckoutPaymentContextRequest) => {
  const result = await apiFetcher('PAYMENT_CONTEXT', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  });

  if (!result.ok) {
    throw new Error('Get Checkout payment context failed');
  }

  return (await result.json()) as CheckoutPaymentContextSuccessResponse;
};

export const processPayment = async (request: CheckoutPaymentRequest) => {
  const result = await apiFetcher('PAYMENT', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  });

  if (!result.ok) {
    throw new Error('Get Checkout payment process failed');
  }

  return (await result.json()) as CheckoutPaymentSuccessResponse;
};
