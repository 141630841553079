import {
  QUICKPLAY_AUTH_TOKEN,
  QUICKPLAY_OAUTH_TOKEN,
  QUICKPLAY_OAUTH_TOKEN_EXPIRY,
  XDI_EXPIRES_AT,
  XDI_EXPIRES_IN,
  XDI_SECRET,
  XDI_TOKEN
} from '../../../../bootstrap';
import { getQuickPlayOAuthToken, setQuickPlayOAuthToken } from '../../../features/video-playback/token-storage';
import { getFromStorage, removeFromStorage, saveToStorage } from '../../../lib/utils/storage';
import { fetchQuickPlayAuthToken } from './services';

export const saveDeviceSecret = (secret: string, period: number) => {
  saveToStorage(XDI_SECRET, secret);
  saveToStorage(XDI_EXPIRES_IN, period.toString());
};

export const getDeviceSecret = () => ({
  secret: getFromStorage(XDI_SECRET),
  jwtExpiresIn: Number(getFromStorage(XDI_EXPIRES_IN))
});

export const getXDeviceId = () => ({
  jwt: getFromStorage(XDI_TOKEN),
  expiresAt: Number(getFromStorage(XDI_EXPIRES_AT))
});

export const saveXDeviceId = (jwt: string, expiresAt: number) => {
  saveToStorage(XDI_TOKEN, jwt);
  saveToStorage(XDI_EXPIRES_AT, expiresAt.toString());
};

export const resetXDeviceId = () => {
  removeFromStorage(XDI_SECRET);
  removeFromStorage(XDI_TOKEN);
  removeFromStorage(XDI_EXPIRES_IN);
  removeFromStorage(XDI_EXPIRES_AT);
};

export async function getOAuthToken() {
  let token = getQuickPlayOAuthToken();
  if (token && token.expiresAt > Date.now()) {
    return token.token;
  }
  token = await fetchQuickPlayAuthToken();
  setQuickPlayOAuthToken(token.token, token.expiresAt);
  return token.token;
}

export const removeDeviceTokens = () => {
  resetXDeviceId();
  removeFromStorage(QUICKPLAY_OAUTH_TOKEN);
  removeFromStorage(QUICKPLAY_OAUTH_TOKEN_EXPIRY);
  removeFromStorage(QUICKPLAY_AUTH_TOKEN);
};
