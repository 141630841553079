import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);

type DateFormatsType = 'long';

export const DATE_FORMATS = ['YYYY-M-D', 'YYYY-MM-DD', 'YYYY-M-DD', 'YYYY-MM-D'];

export function getDatePartsNumber(date?: string | Date) {
  if (!date) return null;

  const dateObj = dayjs(date);

  return {
    day: dateObj.date(),
    month: dateObj.month(),
    year: dateObj.year()
  };
}

export function getDatePartsFormatted(date?: string | Date) {
  const dateParts = getDatePartsNumber(date);

  if (!dateParts) return null;

  const { day, month, year } = dateParts;

  return {
    day: day.toString().padStart(2, '0'),
    month: (month + 1).toString().padStart(2, '0'),
    year: year.toString()
  };
}

export function getDateFromParts(year?: string, month?: string, day?: string) {
  return year && month && day ? dayjs(`${year}-${month}-${day}`, DATE_FORMATS).toDate() : undefined;
}

const dateFormats = {
  long: 'MMMM DD, YYYY'
};

export function formatDate(date: string | Date, format: DateFormatsType) {
  return dayjs(date).format(dateFormats[format]);
}

export function isUpcoming(date?: string | Date) {
  return dayjs(date).isAfter();
}
