import { getConfigFromRemote } from '@game/core';
import { createFileRoute } from '@tanstack/react-router';
import { manageTvProviderSchema } from '../../../../../lib/config/manageTvProvider';
import { GetTVProvidersQuery } from '../../../../../app/screens/second-screen/util';

export const Route = createFileRoute('/_private/_home/account/manage-tv')({
  loader: async ({ context: { user, queryClient } }) => {
    const { all: providers } = await queryClient.ensureQueryData(GetTVProvidersQuery);
    const provider = providers.find((p) => p.id === user.mvpdId);

    return {
      config: getConfigFromRemote(manageTvProviderSchema),
      mvpdId: user.mvpdId,
      provider
    };
  }
});
