import { emit, getConfigFromRemote, GetHeroCarouselQuery, GetRailsQuery, GetUpcomingGamesQuery } from '@game/core';
import { createFileRoute } from '@tanstack/react-router';
import { heroCarouselSchema } from '../../../lib/config';

export const Route = createFileRoute('/_private/_home/')({
  beforeLoad: async ({ context: { queryClient } }) => {
    emit('NavigateWatch');

    void queryClient.prefetchQuery(GetHeroCarouselQuery());
    void queryClient.prefetchQuery(GetRailsQuery());
    void queryClient.prefetchQuery(GetUpcomingGamesQuery());
    const { heroRotationTimer } = getConfigFromRemote(heroCarouselSchema);

    return {
      heroCarouselAutoRotationTime: heroRotationTimer
    };
  }
});
