import * as Select from '@radix-ui/react-select';
import { twMerge } from '../../utils/tailwind-libs';
import { useSelectContext } from './selectContext';

type SelectItemProps = Select.SelectItemProps;

function Option(props: SelectItemProps) {
  const { children, ...rest } = props;

  const { value } = useSelectContext();

  return (
    <Select.Item
      {...rest}
      className="data-[highlighted]:bg-secondary-900 group flex cursor-pointer items-center justify-between gap-2 px-5 py-4 outline-none"
    >
      <Select.ItemText asChild>
        <span className={twMerge('text-light/60 WebBody1', value === props.value && 'text-light')}>{children}</span>
      </Select.ItemText>
    </Select.Item>
  );
}

Option.displayName = 'Option';

export { Option };
