import * as TogglePrimitive from '@radix-ui/react-toggle';
import { forwardRef, type ComponentPropsWithoutRef, type ElementRef } from 'react';
import { type VariantProps } from 'tailwind-variants';
import { twMerge, twVariants } from '../../utils/tailwind-libs';

const toggleVariants = twVariants({
  base: `
  inline-flex items-center justify-center
  rounded-xl overflow-hidden shadow-md
  transition motion-reduce:transition-none

  data-[state=on]:opacity-80

  disabled:pointer-events-none
  disabled:opacity-50`,

  variants: {
    size: {
      default: `outline-none
      ring-1 ring-transparent ring-offset-secondary-700
      ring-primary-500

      data-[state=on]:ring-primary-800
      data-[state=on]:ring-offset-4

      md:hover:scale-110
      md:hover:ring-2
      md:hover:ring-offset-0
      md:hover:ring-secondary-50

      data-[state=on]:hover:scale-100
      data-[state=on]:hover:ring-4
      data-[state=on]:hover:ring-offset-1
      data-[state=on]:hover:ring-primary-800

      focus-visible:scale-110
      focus-visible:ring-2
      focus-visible:ring-offset-0
      focus-visible:ring-secondary-50
      focus-visible:data-[state=on]:ring-offset-0
      `
    }
  },
  defaultVariants: {
    size: 'default'
  }
});

/**
 * Renders a button with on/off states
 *
 * @param {Boolean} [pressed] The controlled pressed state of the toggle. Must be used in conjunction with onPressedChange.
 * @param {Boolean} [defaultPressed] The pressed state of the toggle when it is initially rendered. Use when you do not need to control its pressed state.
 * @param {Function} [onPressedChange] Event handler called when the pressed state of the toggle changes.
 * @param {Boolean} [disabled] When true, prevents the user from interacting with the toggle.
 * @param {String} [size] size of the toggle
 * @param {Boolean} [asChild=false] whether to use HTML tag other than button for the Toggle component
 * @param {String} [className] className to add to the toggle
 * @param {ReactNode | String} children for now, it's only tested with single <img /> tag as children
 * @param {ForwardedRef<HTMLButtonElement>} [ref] React ref to access the DOM element of the button
 *
 * @example
 * const src = './team-logo.png';
 * return (
 *   <Toggle onPressedChange={(pressed) => setState(pressed)}>
 *     <Image src={src} />
 *   </Toggle>
 * )
 */

const Toggle = forwardRef<
  ElementRef<typeof TogglePrimitive.Root>,
  ComponentPropsWithoutRef<typeof TogglePrimitive.Root> & VariantProps<typeof toggleVariants>
>(({ className, size, ...rest }, ref) => (
  <TogglePrimitive.Root ref={ref} className={twMerge(toggleVariants({ size, className }))} {...rest} />
));

Toggle.displayName = 'Toggle';

export { Toggle, toggleVariants };
