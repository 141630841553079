import { queryOptions } from '@tanstack/react-query';
import { GameConfig } from '../../config/game-config';
import { getRuntimeConfig } from './service';

export const GetRuntimeConfigQuery = queryOptions({
  queryKey: ['runtime-config'],
  queryFn: getRuntimeConfig,
  refetchOnWindowFocus: false,
  staleTime: () => GameConfig.get.runtimeConfigRefetchInterval,
  refetchInterval: () => GameConfig.get.runtimeConfigRefetchInterval
});
