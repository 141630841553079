import { GetActiveSubscriptionsQuery, SubscriptionUtil, getConfigFromRemote } from '@game/core';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { cancelSubscription } from '../../../../../lib/config/cancelSubscription';

export const Route = createFileRoute('/_private/_home/account/cancel-subscription')({
  beforeLoad: async ({ context: { queryClient, userLocation } }) => {
    const { subscriptions } = await queryClient.fetchQuery(GetActiveSubscriptionsQuery);
    const activeSubscriptionsMap = SubscriptionUtil.getActiveSubscriptionsMap(userLocation.zone, subscriptions);
    // For this matter, TVOD subscriptions (GamePass) are not taken into account.
    const currentActiveSubscription = SubscriptionUtil.getCurrentActiveSubscription(activeSubscriptionsMap);

    if(!currentActiveSubscription) throw redirect({ to: '..', from: '/account/cancel-subscription' })

    return {
      config: getConfigFromRemote(cancelSubscription.schema),
      currentActiveSubscription
    };
  }
});
