import { setUserId } from '@convivainc/conviva-js-appanalytics';
import { Constants, type ConvivaDeviceMetadata, type ConvivaMetadata } from '@convivainc/conviva-js-coresdk';
import { z } from 'zod';
import { BITMOVIN } from '../../config/build-config';
import { GameConfig } from '../../config/game-config';
import { queryClient } from '../../lib/query';
import { getPlayerName } from '../bitmovin';
import { getConfigFromRemote } from '../config-manager';
import type { Content, Playback } from '../content';
import { getTVEProvider } from '../tve';
import type { User } from '../user';
import { GetUserQuery } from '../user';
import type { Device } from '../user/types';

function getConvivaSteamType(content: Content) {
  switch (content.contentTypeId) {
    case 'vod':
      return Constants.StreamType.VOD;
    case 'live':
      return Constants.StreamType.LIVE;
    default:
      return Constants.StreamType.UNKNOWN;
  }
}

function getConvivaContentType(content: Content) {
  switch (content.contentTypeId) {
    case 'vod':
      return 'VOD';
    case 'live':
      return 'Live'; // TODO: how to determine 'Live-Linear'
    default:
      return 'Unknown';
  }
}

/** Gets duration in seconds if known. Converts from milliseconds to seconds.
 * @param {Content} content
 * @returns number Duration in seconds.
 */
function getDuration(content: Content) {
  const { duration } = content;
  return duration ? Math.round(duration / 1000) : 0;
}

/**
 * Converts User, Content, Playback contexts to Conviva Content Info
 * @param {User} user
 * @param {Content} content
 * @param {Playback} playback
 */
export async function toConvivaInfo(user: User | null, content: Content, playback: Playback) {
  if (user) {
    setUserId(user.userId);
  }

  const tve = await getTVEProvider(user?.mvpdId);
  // @ts-expect-error - TODO: WIP
  const contentInfo: ConvivaMetadata = {
    [Constants.ASSET_NAME]: `[${content.id}] ${content.title}`,
    [Constants.IS_LIVE]: getConvivaSteamType(content),
    [Constants.PLAYER_NAME]: getPlayerName(),
    [Constants.VIEWER_ID]: user?.spAccountId,
    [Constants.STREAM_URL]: playback.streamUrl,
    [Constants.DEFAULT_RESOURCE]: BITMOVIN.CDN_PROVIDER,
    [Constants.DURATION]: getDuration(content),
    [Constants.ENCODED_FRAMERATE]: 0, // BLOCKED: Need support from Bitmovin
    // content management metadata
    'c3.app.version': GameConfig.get.release,
    'c3.pt.ver': GameConfig.get.version,
    'c3.cm.contentType': getConvivaContentType(content),
    'c3.cm.channel': getChannelName(content) ?? 'NA',
    'c3.cm.brand': 'NA',
    'c3.cm.affiliate': tve?.displayName ?? 'NA',
    'c3.cm.categoryType': 'Sports',
    'c3.cm.name': 'Quickplay',
    'c3.cm.id': content.id,
    // TODO Get seriesName on Content?
    // 'c3.cm.seriesName': content?.seriesName ?? ',
    'c3.cm.seasonNumber': 'NA',
    'c3.cm.showTitle': content.title ?? 'NA',
    'c3.cm.episodeNumber': 'NA',
    'c3.cm.genre': 'Sports',
    'c3.cm.genreList': 'Sports',
    'c3.cm.utmTrackingUrl': GameConfig.get.platform === 'web' ? window.location.href : '',
    userType: user?.type.values().next().value ?? ''
  };
  /* FUTURE: Game info
    contentInfo['fullGameTitle'] = this.gameTitle
    contentInfo['homeTeam'] = this.gameHomeTeam
    contentInfo['awayTeam'] = this.gameAwayTeam
    contentInfo['gameLocation'] = this.gameLocation
    contentInfo['gameStartTime'] = this.gameStartTime
    contentInfo['liveFlag'] = String(this.gameLiveFlag)
    contentInfo['videoMinute'] = this.gameVideoMinute
    contentInfo['videoHour'] = this.gameVideoHour
    contentInfo['videoDay'] = this.gameVideoDay
    contentInfo['leagueType'] = this.gameLeagueType
   */
  return contentInfo;
}

export function toConvivaDevice(device: Device): ConvivaDeviceMetadata {
  return {
    [Constants.DeviceMetadata.CATEGORY]: getDeviceCategory(),
    [Constants.DeviceMetadata.BRAND]: device.brand ?? '',
    [Constants.DeviceMetadata.MODEL]: device.modelNo ?? '',
    [Constants.DeviceMetadata.TYPE]: getDeviceType()
  };
}

const getDeviceCategory = () => {
  switch (GameConfig.get.platform) {
    case 'lg':
      return Constants.DeviceCategory.LG_TV;
    case 'samsung':
      return Constants.DeviceCategory.SAMSUNG_TV;
    case 'vizio':
      return Constants.DeviceCategory.SMART_TV;
    default:
      return Constants.DeviceCategory.WEB;
  }
};

const getDeviceType = () => {
  switch (GameConfig.get.platform) {
    case 'lg':
    case 'samsung':
    case 'vizio':
      return Constants.DeviceType.SMARTTV;
    case 'web:mobile':
      return Constants.DeviceType.MOBILE;
    default:
      return Constants.DeviceType.DESKTOP;
  }
};

const channelsSchema = z.object({
  channels: z.array(
    z.object({
      channelID: z.string(),
      analyticsName: z.string()
    })
  )
});

export function getChannelName(content: Content): string | null {
  const id = content.catalogType === 'channel' ? content.id : content.channelId;
  if (!id) return null;

  const { channels } = getConfigFromRemote(channelsSchema);

  const channel = channels.find((channel) => channel.channelID === id);

  if (channel) {
    return channel.analyticsName;
  }
  return null;
}

export async function getAdBreakInfo(e: any, content: Content, playback: Playback) {
  const user = queryClient.getQueryData(GetUserQuery.queryKey);
  if (!user) return {};
  let position;
  const positions = e?.adBreak?.position ?? '';
  if (positions?.includes('pre')) {
    position = Constants.AdPosition.PREROLL;
  } else if (positions?.includes('mid')) {
    position = Constants.AdPosition.MIDROLL;
  } else if (positions?.includes('post')) {
    position = Constants.AdPosition.POSTROLL;
  } else {
    position = '';
  }
  const mainAssetInfo = await toConvivaInfo(user, content, playback);
  return {
    ...mainAssetInfo,
    'c3.ad.id': e.adBreak.id,
    'c3.ad.position': position,
    [Constants.DURATION]: e?.adBreak?.duration ?? ''
  } as unknown as ConvivaMetadata;
}

export async function getAdInfo(e: any, content: Content, playback: Playback) {
  const user = queryClient.getQueryData(GetUserQuery.queryKey);
  if (!user) return {};

  const mainAssetInfo = await toConvivaInfo(user, content, playback);

  mainAssetInfo[Constants.ASSET_NAME] = e?.ad?.data?.adTitle ?? '';
  return mainAssetInfo;
}

export function formatErrorMessage(code?: string, message?: string) {
  if (code !== undefined && message !== undefined) {
    return `${code}: ${message ?? 'N/A'}`;
  }
  return message !== undefined ? `N/A - ${message}` : 'N/A';
}
