import * as Form from '@radix-ui/react-form';
import { twMerge } from '../../utils/tailwind-libs';
import type { InputErrorMessageProps } from './types';

export function InputErrorMessage(props: InputErrorMessageProps) {
  const { className, children, ...messageProps } = props;

  return (
    <Form.Message {...messageProps} className={twMerge('text-error WebBody3 absolute -bottom-5 left-0', className)}>
      {children}
    </Form.Message>
  );
}
InputErrorMessage.displayName = 'InputErrorMessage';
