import { eventHandler, type StateMachineActor } from './handler';
import { register } from '../../lib/action-handlers/action-handlers';
import { UseCaseCodes, type UseCases } from './types';

let _actor: StateMachineActor | null;

export function initUseCases(actor: StateMachineActor) {
  const unregister = register(eventHandler(actor));
  _actor = actor;

  return () => {
    _actor = null;
    unregister();
  };
}

export function getUseCase() {
  const snapshot = _actor?.getSnapshot();
  let curUseCase: UseCases;
  switch (snapshot.value) {
    case 'onboarding':
      curUseCase = 'ONBOARDING';
      break;
    case 'watch':
      curUseCase = 'WATCH';
      break;
    case 'account':
      curUseCase = 'ACCOUNT';
      break;
    default:
      curUseCase = 'UNKNOWN';
  }
  return curUseCase;
}

export function getUseCaseCode(useCase: UseCases) {
  return UseCaseCodes[useCase];
}
