import { MOCK_COORDS_KEY } from '../../../../bootstrap';
import { getFromStorage, removeFromStorage, saveToStorage } from '../../../lib/utils/storage';
import type { Coords } from '../types';

export function saveMockCoords(coords: Coords) {
  saveToStorage(MOCK_COORDS_KEY, JSON.stringify(coords));
}

export function getMockCoords(): Coords | null {
  const coordsString = getFromStorage(MOCK_COORDS_KEY);
  if (!coordsString) return null;

  try {
    return JSON.parse(coordsString);
  } catch {
    return null;
  }
}

export const removeMockCoords = () => removeFromStorage(MOCK_COORDS_KEY);

/**
 * transforms a lat,long string to a valid request params object
 * @param {string} coords comma-separated geo-location coordinates: lat,lon
 * @example parseCoordinates("34.1340258,-118.3242515")
 * @returns valid location-lookup request object or undefined
 */
export function parseCoordinates(coords: string | null) {
  if (!coords) return;
  const [lat, lon] = coords.split(',');
  if (lat && lon) return { lat, lon };
}
