import { z } from 'zod';

const schema = z.object({
  dtcCancellationReasons: z.array(
    z.object({
      reason: z.string(),
      reasonCode: z.string()
    })
  )
});

export type CancelSubscriptionConfig = z.infer<typeof schema>;

export const cancelSubscription = { schema };
