import './index.css';
// global styles should be imported before App,
// otherwise styles in third party packages might be overwritten by tailwind base
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { GameConfig, initUseCases } from '@game/core';
import { App } from './app';
import { getDeviceDetails } from './lib/services/services';
import { setupAnalytics } from './lib/analytics';
import { version, release } from './version';
import { useCaseActor } from './lib/use-case/lib';

const MOBILE_BREAKPOINT = 600;
const RUNTIME_CONFIG_REFETCH_INTERVAL = 1000 * 60 * 15; // 15 minutes

GameConfig.init({
  version,
  release,
  environment: {
    mode: import.meta.env.NODE_ENV,
    name: import.meta.env.VITE_APP_ENV_NAME
  },
  platform: window.innerWidth < MOBILE_BREAKPOINT ? 'web:mobile' : 'web',
  billingPlatform: null,
  getDeviceDetails,
  runtimeConfigRefetchInterval: RUNTIME_CONFIG_REFETCH_INTERVAL,
  // TODO: remove hard coded bundle discounts
  bundle: {
    percentValue: '30'
  },
  // TODO: Remove as feature flags are on runtime json
  featureFlags: {
    paypal: true,
    applePay: true
  },
  paymentMethods: new Set(['CreditCard', 'PayPal', 'ApplePay', 'TVE'])
});

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then((response) => {
      if (import.meta.env.DEV) {
        console.warn({ message: 'service worker is registered', response });
      }
    });
  });
}

setupAnalytics();
window.addEventListener('pagehide', initUseCases(useCaseActor));

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
