import { emit, getSearchedAccountFromStorage, isFeatureEnabled } from '@game/core';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { getPagePath } from '../../../lib/routing';

export const Route = createFileRoute('/_private/update-profile')({
  beforeLoad: async () => {
    const checkedAccountData = getSearchedAccountFromStorage();

    if (!checkedAccountData?.exists) throw redirect({ to: getPagePath('login') });

    const newsletter = await isFeatureEnabled('newsletter');

    return {
      existingRSN: checkedAccountData.originalRSN,
      newsletter
    };
  },
  loader: () => {
    emit('OnboardExisting');
  }
});
