import { toURL } from '../../..';
import { API_ENDPOINTS, type ENDPOINTS } from './endpointsMap';
import { API_BASE_URL } from '../../../config/build-config';

const API_DEFAULT_HEADERS: HeadersInit = {
  Accept: 'application/json'
};
type FetcherOptions = RequestInit;

/**
 * Fetch API related to the PayPal endpoint
 * @param {ENDPOINTS} endpoint
 * @param {FetcherOptions} options
 */
export const apiFetcher = async (path: ENDPOINTS, options: FetcherOptions) => {
  const { headers, ...rest } = options;
  const url = toURL(API_BASE_URL, API_ENDPOINTS[path]);

  return await fetch(url, {
    headers: {
      ...API_DEFAULT_HEADERS,
      ...headers
    },
    ...rest
  });
};
