import { z } from 'zod';
import { toURL } from '../../../../lib/utils';

const playerSchema = z.object({
  id: z.string(),
  number: z.string(),
  fullName: z.string(),
  position: z.string(),
  headshot: z.string()
});

export const createPlayersSchema = (headshotBaseURL: string, headshotFallbackURL: string) => {
  return z
    .object({
      teams: z.array(
        z.object({
          id: z.number(),
          players: z.array(playerSchema).transform((players) =>
            players.map((player) => ({
              ...player,
              headshot: player.headshot ? toURL(headshotBaseURL, player.headshot).href : headshotFallbackURL
            }))
          )
        })
      )
    })
    .transform(({ teams }) => {
      const teamMap: { [key: string]: z.infer<typeof playerSchema>[] } = {};
      for (const team of teams) {
        teamMap[team.id] = team.players;
      }
      return teamMap;
    });
};
