import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

const searchSchema = z.object({
  pq: z.string().optional() // (Rail id) rail id for fetching the contents playlist
});

export const Route = createFileRoute('/_private/playback/$videoId')({
  validateSearch: searchSchema,
  loaderDeps: ({ search: { pq } }) => ({ pq }),
  beforeLoad: () => {
    return {
      footer: false // explicit hidden on video player screen.
    };
  },
  loader: async ({ deps, params: { videoId }, location, context: { user, userLocation, queryClient } }) => {
    // lazy load for bundle size
    const { loader } = await import('../../../app/screens/play-video/loader');
    return loader({
      videoId,
      playlistId: deps.pq,
      currentPath: location.href,
      queryClient,
      userLocation,
      user
    });
  }
});
