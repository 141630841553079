import { ConfigError } from '../error';

export type URLSearchParamsConstructor = string | string[][] | Record<string, string> | URLSearchParams | undefined;

/**
 * Utility function to create new URLs.
 * @param {URL | string} baseURL Initial url value.
 * @param {string} [path] Path to concatenate to the current path on the baseURL.
 * @param {URLSearchParamsConstructor} [search] Search params to set on the generated URL.
 * @returns {URL} URL instance.
 */
export function toURL(baseURL: URL | string, path?: string, search?: URLSearchParamsConstructor): URL {
  const url = new URL(baseURL);
  if (path != null) {
    url.pathname += path;
  }
  if (search != null) url.search = new URLSearchParams(search).toString();
  return url;
}

export async function fetchConfig(url: URL | RequestInfo) {
  const response = await fetch(url);
  if (!response.ok) {
    throw new ConfigError(response);
  }

  return response.json();
}
