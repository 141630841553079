import { createFileRoute } from '@tanstack/react-router';
import { GetFavoritesQuery, queryClient } from '@game/core';

export const Route = createFileRoute('/_private/favorite/teams')({
  loader: async ({ context: { ...rest } }) => {
    const favorites = await queryClient.fetchQuery(GetFavoritesQuery);
    return {
      favorites,
      ...rest
    };
  }
});
