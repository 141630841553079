import { SCORES } from '../../../config/build-config';
import { toURL } from '../../../lib/utils';

export const apiFetcher = (stationID: number | string) => {
  return fetch(toURL(SCORES.api.liveEvent, `/${stationID}`));
};

export const fetchMultipleStationIds = (stations: string[]) => {
  return fetch(toURL(SCORES.api.liveEvent, '', new URLSearchParams({ ids: stations.join(',') })));
};
