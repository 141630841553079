import * as sportsRadar from '../../services/api/sports-radar';
import { toScore } from './models';

export async function getMultipleScores(stations: string[]) {
  const response = await sportsRadar.getMultipleScores(stations);

  if (!response) return null;

  return new Map(Object.entries(response).map(([stationID, score]) => [stationID, toScore(score)]));
}

export async function getScore(stationID: string) {
  const response = await sportsRadar.getScoresFromStation(stationID);

  if (!response) return null;

  return {
    ...toScore(response),
    stationID
  };
}
