import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type namespace = string;
type LocalizationMap = Record<namespace, Record<string, string>>;
type LocalizationConfig = LocalizationMap & {
  version: {
    value: string;
  };
};

export type LocalizationStore = {
  version: number;
  locale?: string;
  config?: LocalizationConfig;
  setVersion: (version: number) => void;
  setLocale: (locale: string) => void;
  setConfig: (config: LocalizationConfig) => void;
};

export const useLocalizationStore = create<LocalizationStore>((set) => ({
  version: 0,
  config: undefined,
  locale: undefined,
  setVersion: (version: number) =>
    set(() => {
      return {
        version
      };
    }),
  setLocale: (locale: string) => {
    set(() => {
      return {
        locale
      };
    });
  },
  setConfig: (config: LocalizationConfig) => {
    set(() => {
      return {
        config
      };
    });
  }
}));

export type UserLocaleStore = {
  locale?: string;
  setLocale: (locale: string) => void;
};

export const useUserLocaleStore = create<UserLocaleStore>()(
  persist(
    (set) => ({
      locale: undefined,
      setLocale: (locale: string) => set({ locale })
    }),
    {
      name: 'user-locale',
      storage: createJSONStorage(() => localStorage)
    }
  )
);
