import type { MutationFunction } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { emit } from '../../lib/action-handlers/action-handlers';
import { TokenRefreshError } from '../../lib/error';
import { queryClient } from '../../lib/query';
import { tveLogout } from '../tve';
import { GetUserQuery } from '../user';
import { refreshTokenService } from './refresh-token';
import { LocationMocker } from '../check-location';
import { changePassword, forgotPassword, login, logout, resetPassword } from './services';
import { deleteTokens, deleteUserMetadata, getTokens, saveTokens, saveUserMetadata } from './token-storage';
import type {
  ChangePasswordMutationInputData,
  LoginMutationInputData,
  LoginRequestParams,
  RequestPasswordChangeMutationInputData,
  ResetPasswordMutationInputData,
  SocialLoginRequestParams
} from './types';
import { getDeviceDetails } from '../device/lib';

export const loginMutationFunction: MutationFunction<void, LoginMutationInputData> = async (args) => {
  const { email: username, password } = args;

  await signInWithEmailAndPassword({ password, username });
};

export const socialAuthenticationMutationFunction: MutationFunction<void, SocialLoginRequestParams> = async (args) =>
  void socialSignInAuthentication(args);

// TODO: Temp method: Can be removed once user information is fetched and stored.
export const isUserLoggedIn = (): boolean => {
  const tokens = getTokens();

  if (!tokens || isNaN(Number(tokens.expiresIn))) return false;

  const accessTokenExpirationDateTime = dayjs(Number(tokens.expiresIn));

  return accessTokenExpirationDateTime.isAfter(Date.now());
};

/**
 * Login with email and password
 */
export async function signInWithEmailAndPassword(params: LoginRequestParams) {
  const deviceDetails = await getDeviceDetails();
  const response = await login(params, deviceDetails);
  saveTokens(response.tokens);
  saveUserMetadata(response.metadata);
  return response;
}
/**
 * Login with social accounts
 */
export async function socialSignInAuthentication(params: SocialLoginRequestParams) {
  const response = await login(params);
  saveTokens(response.tokens);
  saveUserMetadata(response.metadata);
  return response;
}

/**
 * Logout the user
 */
export async function signOut() {
  const tokens = getTokens();
  emit('Logout');

  if (!tokens) return;

  const { accessToken } = tokens;
  if (!accessToken) return;

  const deviceDetails = await getDeviceDetails();
  deleteUserMetadata();
  await tveLogout();
  await logout(accessToken, deviceDetails);
  deleteTokens();
  await LocationMocker.clear();
  queryClient.removeQueries({ queryKey: GetUserQuery.queryKey });
}

/**
 * Renew user session
 * @throws {TokenRefreshError} If the token refresh fails
 */
export async function renewUserSession() {
  const result = await refreshTokenService.refresh();
  if (result instanceof TokenRefreshError) {
    throw result;
  }
}

export const requestPasswordChangeMutation: MutationFunction<void, RequestPasswordChangeMutationInputData> = async (
  args
) => {
  const { email } = args;

  await forgotPassword(email);
};

export async function requestPasswordChange(email: string) {
  return forgotPassword(email);
}

export const resetPasswordMutationFunction: MutationFunction<void, ResetPasswordMutationInputData> = async (args) => {
  const { email, userToken, newPassword } = args;
  await resetPassword(email, userToken, newPassword);
};

export async function changeToNewPassword(args: ChangePasswordMutationInputData) {
  const { newPassword, confirmPassword } = args;
  await changePassword(newPassword, confirmPassword);
}
