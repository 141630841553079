export const UseCaseCodes = {
  ONBOARDING: 'A0',
  ACCOUNT: 'A1',
  WATCH: 'A2',
  TEAMS: 'A3',
  PLAY: 'A4',
  REWARDS: 'A5',
  ODDS: 'A6',
  SCHEDULE: 'A7',
  UPSELL: 'A8',
  VIDEO: 'A9',
  UNKNOWN: 'U0'
};

export type UseCases = keyof typeof UseCaseCodes;
