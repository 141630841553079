import { queryOptions } from '@tanstack/react-query';
import { z } from 'zod';
import { getConfigFromRemote } from '../config-manager';
import { getLocationData } from './services';

export const configCacheDuration = z.object({
  cacheDuration: z.number()
});

export const DEFAULT_STALE_TIME = 1000 * 60 * 60; // 1 hour

export const GetCheckLocationQuery = (refetchOnMount = false) => {
  const { cacheDuration } = getConfigFromRemote(configCacheDuration);

  return queryOptions({
    queryKey: ['check-location'],
    queryFn: getLocationData,
    staleTime: cacheDuration ?? DEFAULT_STALE_TIME,
    refetchOnMount: refetchOnMount ? 'always' : false
  });
};
