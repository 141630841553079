import { SEARCH_ACCOUNT_STORAGE_KEY } from '../../../bootstrap';
import { getFromStorage, removeFromStorage, saveToStorage } from '../../lib/utils/storage';

type SearchedAccountNonExistentData = {
  email: string;
  exists: false;
};

type SearchedAccountExistentData = {
  email: string;
  exists: true;
  originalRSN: string;
  accountType: string;
  isSocialLogin: boolean;
  hasPassword: boolean;
};

type SearchedAccountData = SearchedAccountExistentData | SearchedAccountNonExistentData;

export function getSearchedAccountData() {
  const data = getFromStorage(SEARCH_ACCOUNT_STORAGE_KEY);

  if (!data) return undefined;

  return JSON.parse(data) as SearchedAccountData;
}

export function saveSearchedAccountData(data: SearchedAccountData) {
  saveToStorage(SEARCH_ACCOUNT_STORAGE_KEY, JSON.stringify(data));
}

export function deleteSearchedAccountData() {
  removeFromStorage(SEARCH_ACCOUNT_STORAGE_KEY);
}
