import {
  addSubscriptions,
  addTVODOrder,
  getActiveSubscriptions as getEvergentActiveSubscriptions,
  getProducts as evergentGetProducts,
  getSubscriptionHistory
} from '../../services/api/evergent';
import { getVideoData } from '../video-playback';
import { checkRedeem, type QuickplayCatalogType } from '../../services/api/quickplay';
import type { GetProductsParams, GetTVODPurchaseTokenParams, Location, Subscription, TVODOrder } from './types';
import { getFlatToken } from '../entitlements/lib';
import { getSubscriptionRequest, getTVODOrderRequest } from './utils';
import { getUserLocation, isUserOutOfMarket } from '../check-location';
import { getConfigFromRemote } from '../config-manager';
import { configSchema } from './schemas';
import { getDeviceId } from '../device/lib';

export async function getProducts(params: GetProductsParams) {
  return evergentGetProducts(params.dmaID);
}

export async function getPurchaseHistory() {
  const history = await getSubscriptionHistory();
  return history.map((s) => {
    return {
      id: s.orderID,
      orderDate: new Date(s.orderDateTimeInMillis),
      title: s.displayName,
      startDate: new Date(s.startDate),
      endDate: new Date(s.validityTill),
      paymentMethod: s.paymentMethod,
      priceCharged: s.priceCharged,
      currency: s.currencyCode,
      currencySymbol: s.currencySymbol
    };
  });
}

export async function addProductSubscription(sub: Subscription, location: Location) {
  return addSubscriptions(getSubscriptionRequest(sub, location));
}

export async function getActiveSubscriptions() {
  return getEvergentActiveSubscriptions();
}

export const getGamePassDetails = async (gamePassID: string, catalogType: QuickplayCatalogType) => {
  const content = await getVideoData({
    catalogType,
    videoId: gamePassID
  });

  const gameDetails = content.gameDetails;
  if (gameDetails?.teams) {
    let awayTeam;
    let homeTeam;

    gameDetails.teams.forEach((team) => {
      const teamDetails = {
        url: team.logoURL || '',
        name: team.shortName,
        tricode: team.tricode
      };
      if (team.type === 'away') awayTeam = teamDetails;
      if (team.type === 'home') homeTeam = teamDetails;
    });
    return { awayTeam, homeTeam, date: content.startDate };
  }
};

export const getTVODPurchaseToken = async (params: GetTVODPurchaseTokenParams) => {
  const { contentId, contentType } = params;
  const flatToken = await getFlatToken();

  if (!flatToken) {
    throw new Error('Could not process TVOD Order - No OVAT Token present');
  }

  const deviceId = await getDeviceId();
  const redeemResult = await checkRedeem({ deviceId, contentId, contentType, flatToken: flatToken.token });

  if (!redeemResult.canRedeem) throw new Error('The given order is not redeemable. Check the product ID');

  return redeemResult.token;
};

export const processTVODOrder = async (order: TVODOrder, location: Location) => {
  const flatToken = await getFlatToken();

  if (!flatToken) {
    throw new Error('Could not process TVOD Order - No OVAT Token present');
  }

  const deviceId = await getDeviceId();

  const redeemResult = await checkRedeem({
    contentId: order.contentId,
    contentType: order.contentType,
    deviceId,
    flatToken: flatToken.token
  });

  if (redeemResult.canRedeem && redeemResult.token) {
    return await addTVODOrder(getTVODOrderRequest(order, location, redeemResult.token));
  } else {
    throw new Error('The given order is not redeemable. Check the product ID');
  }
};

export async function canSubscribe() {
  const location = await getUserLocation();
  if (isUserOutOfMarket(location)) return false;

  const { subscriptionUnavailableZones } = getConfigFromRemote(configSchema);
  return !subscriptionUnavailableZones.includes(location.zone);
}
