import { ADOBE } from '../../config/build-config';
import { GameConfig } from '../../config/game-config';
import { UserNotAuthenticatedError } from '../../lib/error';
import * as adobe from '../../services/api/adobe';
import * as evergent from '../../services/api/evergent';
import { EVERGENT_ERROR_CODE } from '../../services/api/evergent';
import { getTokens, saveTokens } from '../auth';
import { getConfigFromRemote } from '../config-manager';
import { ERROR_SOURCE, GameError } from '../errors';
import { getUserData, invalidateUserData } from '../user';
import { DeviceActivatedError, ResourceAccessDeniedError } from './error';
import { configSchema } from './schema';
import type { GetMediaTokenParams } from './type';
import { clearDevice } from '../activate-device';
import { ADOBE_ERROR_CODE } from '../errors/type';
import { getDeviceDetails, getDeviceId } from '../device/lib';

export async function generateSecondScreenParams() {
  const platform = GameConfig.get.platform;
  const deviceDetails = await getDeviceDetails();
  const result = await evergent.generateDeviceActivationCode(deviceDetails);

  if (result.authenticated) {
    // device already activated
    const tokens = getTokens();
    saveTokens({
      accessToken: result.accessToken,
      refreshToken: result.refreshToken || tokens?.refreshToken || '',
      expiresIn: result.expiresIn
    });
    throw new DeviceActivatedError();
  }

  const { activationCode } = result;
  const { code: regCode, expires } = await adobe.getRegistrationCode(deviceDetails.serialNo);
  const { spAccountId } = await getUserData();
  return { regCode, platform, activationCode, spAccountId, expires };
}

/**
 * Generates a URL for the second screen authentication flow.
 * @throws {DeviceActivatedError} If the device is already activated.
 */
export async function generateSecondScreenURL() {
  const { regCode, platform, activationCode, spAccountId, expires } = await generateSecondScreenParams();
  const config = getConfigFromRemote(configSchema);

  const url = new URL(`${config.secondScreenAuthentication.loginUrl}/${regCode}/${platform}/${activationCode}`);
  url.searchParams.set('spAccountId', btoa(spAccountId));

  return { url, expires };
}

/**
 * Try to log in the device and store the access token if the device is activated.
 * @returns login status
 */
export async function tveLogin(): Promise<boolean> {
  const deviceDetails = await getDeviceDetails();
  const result = await evergent.generateDeviceActivationCode(deviceDetails);
  if (!result.authenticated) return false;

  const tokens = getTokens();
  if (!tokens?.refreshToken) {
    throw new Error('Unable to check device activation status. Token not found');
  }

  saveTokens({
    accessToken: result.accessToken,
    refreshToken: result.refreshToken || tokens.refreshToken,
    expiresIn: result.expiresIn
  });

  return true;
}

/**
 * Completes the device linking process by adding the TVE subscription to the user's account.
 *
 * @throws {@link GameError}
 * The error throw if user reaches the maximum number of TVE linking.
 *
 */
export async function completeDeviceLinking() {
  const deviceId = await getDeviceId();
  const userMetadata = await adobe.getUserMetadata(deviceId);
  if (!userMetadata)
    throw new GameError(ERROR_SOURCE.ADOBE, ADOBE_ERROR_CODE.UNAUTHORIZED, 'Failed to get user metadata');

  const resources = await adobe.preauthorize(deviceId);

  const availableResourcesSet = new Set(ADOBE.TVE_RESOURCE_IDS);
  const authorizedResources = [];
  for (const resource of resources) {
    if (resource.authorized && availableResourcesSet.has(resource.id)) {
      authorizedResources.push(resource.id);
    }
  }

  const { mvpd, zip, userId } = userMetadata;
  try {
    await evergent.addTVESubscription({
      encryptedZip: zip,
      mvpdID: mvpd,
      adobeId: userId,
      adobeResource: authorizedResources
    });
    await invalidateUserData();
  } catch (error) {
    if (!(error instanceof GameError)) throw error;
    switch (error.code) {
      case EVERGENT_ERROR_CODE.ADOBE_RESOURCE_ALREADY_EXISTED:
        // no-op, this error means the user is already add the resources to the account before
        break;
      case EVERGENT_ERROR_CODE.MAX_TVE_LINKING:
        await disconnectTVProvider();
        throw error;
      default:
        throw error;
    }
  }

  return { mvpd, zip };
}

/**
 * checks if a user, identified by a device ID, is authorized to access a specific resource.
 *
 * @param {string} deviceId - The unique identifier of the device associated with the user.
 * @param {string} resourceId - The unique identifier of the resource for which access is being checked.
 *
 * @throws {UserNotAuthenticatedError} If the user is not authenticated (status code 412).
 * @throws {ResourceAccessDeniedError} If access to the resource is denied (any other failing status).
 *
 * @returns {Promise<void>} A promise that resolves if the user is authorized, otherwise it throws an error.
 */
async function checkAuthorization(deviceId: string, resourceId: string): Promise<void> {
  // check if user is authenticated and has access to the resource
  const authorizeResponse = await adobe.authorize(deviceId, resourceId);

  if (!authorizeResponse.ok) {
    switch (authorizeResponse.status) {
      case 412:
        throw new UserNotAuthenticatedError('TVE user is not authenticated');
      default:
        throw new ResourceAccessDeniedError();
    }
  }
}

export function getTVEProvider(mvpd?: string) {
  if (!mvpd) return;
  const {
    tveAuthentication: { tvProviders }
  } = getConfigFromRemote(configSchema);
  return tvProviders.find((provider) => provider.id === mvpd);
}

export async function getMediaToken({
  media,
  deviceId,
  resourceId,
  mvpd
}: GetMediaTokenParams): Promise<string | null> {
  // user is not a tve user
  if (!mvpd) return null;

  const provider = getTVEProvider(mvpd);

  const mediaRSS = provider?.mrss
    ?.replace('{{TITLE}}', resourceId)
    .replace('{{ITEM_TITLE}}', media.title)
    .replace('{{ITEM_GUID}}', media.id);

  const resource = mediaRSS || resourceId;

  await checkAuthorization(deviceId, resource);

  const { serializedToken } = await adobe.getShortMediaToken({
    deviceId,
    resource
  });
  return serializedToken;
}

export async function tveLogout() {
  const deviceId = await getDeviceId();
  await evergent.disconnectTVEAccount(deviceId);
  await adobe.logout(deviceId);
}

export async function disconnectTVProvider() {
  const tokens = getTokens();

  if (!tokens) {
    throw new Error('Unable to logout user. Tokens not found');
  }
  const deviceDetails = await getDeviceDetails();
  await tveLogout();
  await evergent.logout(tokens.accessToken, deviceDetails);
  clearDevice();

  const { spAccountId } = await getUserData();
  const response = await evergent.login(spAccountId, deviceDetails);
  const { accessToken, refreshToken, expiresIn } = response.tokens;

  saveTokens({ accessToken, refreshToken, expiresIn });
  await invalidateUserData();
}
