import { z } from 'zod';

export const secondScreenSuccessSchema = z
  .object({
    'second-screen-success': z.object({
      message: z.string(),
      'platform-androidtv': z.string(),
      'platform-appletv': z.string(),
      'platform-firetv': z.string(),
      'platform-lg': z.string(),
      'platform-roku': z.string(),
      'platform-samsung': z.string(),
      'platform-vizio': z.string(),
      'platform-web': z.string(),
      'platform-generic': z.string(),
      'primary-button': z.string()
    }),
    generic: z.object({
      ok: z.string()
    })
  })
  .transform((values) => {
    const {
      'second-screen-success': {
        message,
        'platform-androidtv': androidtv,
        'platform-appletv': appletv,
        'platform-firetv': firetv,
        'platform-lg': lg,
        'platform-roku': roku,
        'platform-samsung': samsung,
        'platform-vizio': vizio,
        'platform-web': web,
        'platform-generic': generic
      },
      generic: { ok: primaryButton }
    } = values;

    return {
      message,
      androidtv,
      appletv,
      firetv,
      lg,
      roku,
      samsung,
      vizio,
      web,
      generic,
      primaryButton
    };
  });

export type SecondScreenSuccessLocale = z.infer<typeof secondScreenSuccessSchema>;
