import { z } from 'zod';

export const debugConfigSchema = z.object({
  debug: z.object({
    locations: z.array(
      z.object({
        label: z.string(),
        zone: z.string(),
        zipCode: z.string(),
        latitude: z.number(),
        longitude: z.number()
      })
    )
  })
});

export type DebugConfig = z.infer<typeof debugConfigSchema>;
