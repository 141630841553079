import { z } from 'zod';
import { toURL } from '../../lib/utils';

export const teamConfigSchema = z
  .object({
    baseURL: z.string(),
    teams: z.array(
      z.object({
        id: z.number(),
        tri: z.string().length(3),
        rank: z.number(),
        name: z.string(),
        zone: z.array(z.string()),
        shortName: z.string(),
        league: z.string(),
        logo: z.string(),
        cmsInfo: z.object({
          exID: z.string(),
          teamCode: z.string()
        }),
        favoritesCards: z.object({
          web: z.object({
            background: z.string()
          })
        })
      })
    )
  })
  .transform((config) => {
    const { baseURL, teams } = config;
    return teams
      .map((team) => {
        return {
          id: team.id,
          tricode: team.tri,
          rank: team.rank,
          name: team.name,
          shortName: team.shortName,
          externalId: team.cmsInfo.exID,
          code: team.cmsInfo.teamCode,
          zone: new Set(team.zone),
          backgroundURL: toURL(baseURL, team.favoritesCards.web.background),
          logo: toURL(baseURL, team.logo)
        };
      })
      .sort((a, b) => a.rank - b.rank);
  });
