import { GetActiveSubscriptionsQuery, getConfigFromRemote, getPayments, Payments, SubscriptionUtil } from '@game/core';
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';
import { manageSubscription as manageSubscriptionConfig } from '../../../../lib/config';
import { getPagePath } from '../../../../lib/routing';

export const Route = createFileRoute('/_private/_home/account/_subscription')({
  beforeLoad: async ({ context: { queryClient, userLocation, ...rest } }) => {
    const config = getConfigFromRemote(manageSubscriptionConfig.schema);

    const { subscriptions } = await queryClient.fetchQuery(GetActiveSubscriptionsQuery);
    const activeSubscriptionsMap = SubscriptionUtil.getActiveSubscriptionsMap(userLocation.zone, subscriptions);
    // For this matter, TVOD subscriptions (GamePass) are not taken into account.
    const currentActiveSubscription = SubscriptionUtil.getCurrentActiveSubscription(activeSubscriptionsMap);

    const payments = await queryClient.fetchQuery({
      queryKey: ['payments', rest.user.userId],
      queryFn: getPayments
    });

    if (!currentActiveSubscription) throw redirect({ to: getPagePath('account') });

    const currentPayment = payments.payments.find(
      (payment) => payment.orderedProductID === currentActiveSubscription?.opId
    );

    const subscriptionTypeLogos = SubscriptionUtil.getSubscriptionTypeLogos(
      config.subscriptionTypeLogos,
      currentActiveSubscription
    );

    return {
      subscriptionTypeLogos,
      currentActiveSubscription,
      currentPayment,
      paymentMethodsLogos: Payments.getPaymentLogos()
    };
  },
  loader: async ({
    context: { subscriptionTypeLogos, currentActiveSubscription, currentPayment, paymentMethodsLogos }
  }) => {
    const canManageSubscription = SubscriptionUtil.canManageSubscription(currentActiveSubscription);
    const isCouponPayment = SubscriptionUtil.isCouponSubscription(currentActiveSubscription);
    return {
      subscriptionTypeLogos,
      currentActiveSubscription,
      showChangePlan: canManageSubscription,
      showCancelSubscription: canManageSubscription,
      showPlanInfo: !isCouponPayment,
      showPurchaseHistory: !isCouponPayment,
      currentPayment,
      paymentMethodsLogos
    };
  },
  component: Outlet
});
