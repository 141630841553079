import { z } from 'zod';

export const purchaseSuccessful = z
  .object({
    generic: z.object({
      ok: z.string()
    }),
    upsell: z.object({
      'purchase-successful': z.string(),
      'delay-message': z.string()
    })
  })
  .transform((values) => {
    const { 'purchase-successful': purchaseSuccessful, 'delay-message': delayMessage } = values['upsell'];
    const { ok } = values['generic'];
    return {
      purchaseSuccessful,
      ok,
      delayMessage
    };
  });

export type PurchaseSuccessfulLocale = z.infer<typeof purchaseSuccessful>;
