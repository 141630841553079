import type { ElementType } from 'react';
import type { TypographyVariant } from './typography';

export const DEFAULT_VARIANT = 'body1';

// default HTML tags for each Typography variant
export const DEFAULT_ROOT_TAGS: Record<TypographyVariant, ElementType> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  h7: 'div',
  h8: 'div',
  h9: 'div',
  h10: 'div',
  h11: 'div',
  body1: 'p',
  body2: 'p',
  body3: 'p',
  body4: 'div',
  body5: 'div',
  body6: 'div',
  body7: 'div'
};
