import { twMerge } from '../../utils/tailwind-libs';
import { DEFAULT_HEIGHT, DEFAULT_VIEWBOX, DEFAULT_WIDTH, ICON_CONFIG } from './constants';
import icons from './icons.svg';
import type { IconProps } from './types';

/**
 *
 * @param {IconNames} name - the name of the icon
 *
 * @example
 * <Input name="eyes-closed" width={30} height={30} className="fill-emerald-500"/>
 */
function Icon(props: IconProps) {
  const { name, viewBox, className, width = DEFAULT_WIDTH, height = DEFAULT_HEIGHT, ...rest } = props;

  const { baseClassName, viewbox: iconViewbox } = ICON_CONFIG[name];

  const currentViewBox = viewBox ?? iconViewbox ?? DEFAULT_VIEWBOX;

  return (
    <svg
      width={width}
      height={height}
      viewBox={currentViewBox}
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMin"
      className={twMerge(baseClassName, className)}
      {...rest}
    >
      <use href={`${icons}#${name}`} />
    </svg>
  );
}

Icon.displayName = 'Icon';

export { Icon };
