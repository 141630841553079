import type { EventStatus, ScoreResponse, TeamLocationType } from '../../services/api/sports-radar/types';

export function toScore(score: ScoreResponse) {
  const { eventStatus, league, currentInning, currentInningHalf, teams, scheduledPeriods } = score;

  const teamsFormatted = new Map(
    teams.map((team) => [
      team?.teamLocationType,
      {
        shortName: team.abbreviation,
        score: team?.linescores?.total?.score,
        type: team?.teamLocationType
      }
    ])
  );

  const period = league === 'mlb' ? currentInning : scheduledPeriods;

  const payload: Score = { eventStatus, period, teams: teamsFormatted };

  if (league === 'mlb') {
    payload.currentPeriodSegment = currentInningHalf;
  }

  return payload;
}

export type Score = {
  eventStatus: EventStatus;
  period?: string | number;
  teams: Map<string, { shortName: string; score?: number; type: TeamLocationType }>;
  currentPeriodSegment?: string | number;
};
