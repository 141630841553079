import * as evergent from '../../services/api/evergent';
import * as quickplay from '../../services/api/quickplay';
import { deleteEvergentFlatToken, deleteEvergentTVODToken } from '../entitlements/token-storage';
import type { LoginRequestParams, SocialLoginRequestParams } from './types';
import type { DeviceDetails } from '../device/types';
export { forgotPassword } from '../../services/api/evergent';

export async function login(params: LoginRequestParams | SocialLoginRequestParams, deviceDetails?: DeviceDetails) {
  return evergent.login(params, deviceDetails);
}

export async function logout(accessToken: string, deviceDetails?: DeviceDetails) {
  removeSessionTokens();
  await evergent.logout(accessToken, deviceDetails);
}

export function resetPassword(email: string, userToken: string, newPassword: string) {
  return evergent.resetPassword(email, newPassword, userToken);
}

export function removeSessionTokens() {
  quickplay.logout();
  deleteEvergentFlatToken();
  deleteEvergentTVODToken();
}
export function changePassword(newPassword: string, confirmPassword: string) {
  return evergent.changePassword(newPassword, confirmPassword);
}
