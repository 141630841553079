import { addRemoveFavourites } from '../../services/api/evergent';
import { getFavorites as getFavoritesEvergent } from '../../services/api/evergent/services';
import { queryClient } from '../../lib/query';
import { GetFavoritesQuery } from './query';

export async function updateTeams(addIds?: string[], removeIds?: string[]) {
  const response = await addRemoveFavourites('favoriteTeams', addIds, removeIds);
  await queryClient.invalidateQueries({ queryKey: GetFavoritesQuery.queryKey }); // Invalidate as used for watch screen rails ordering
  return response;
}

export async function updatePlayers(addIds?: string[], removeIds?: string[]) {
  const response = addRemoveFavourites('favoritePlayers', addIds, removeIds);
  await queryClient.invalidateQueries({ queryKey: GetFavoritesQuery.queryKey });
  return response;
}

export async function getFavouriteTeams() {
  const response = await queryClient.fetchQuery(GetFavoritesQuery);
  return response?.teams ?? [];
}

export async function getFavouriteTeamsSet() {
  const userFavouriteTeams = await getFavouriteTeams();
  const userTeamSet = new Set<string>();
  for (const teamId of userFavouriteTeams) {
    userTeamSet.add(teamId);
  }
  return userTeamSet;
}

export async function getFavouritePlayers() {
  const response = await queryClient.fetchQuery(GetFavoritesQuery);
  return response?.players ?? [];
}

export async function getFavorites() {
  return getFavoritesEvergent();
}
