import { Helmet, HelmetData } from 'react-helmet-async';
import { APP_NAME, FACEBOOK } from '@game/core';

const helmetData = new HelmetData({});

export function AppHelmet() {
  return (
    <Helmet helmetData={helmetData}>
      <title>{APP_NAME}</title>
      <meta charSet="utf-8" />
      <base href="/" />
      <meta content="width=device-width, initial-scale=1" name="viewport" />
      <meta name="facebook-domain-verification" content={FACEBOOK.VERIFICATION_CODE} />
    </Helmet>
  );
}
