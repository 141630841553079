import { apiFetcher, fetchMultipleStationIds } from './fetcher';
import type { MultipleScoresResponse, ScoreResponse } from './types';

export async function getScoresFromStation(stationID: number | string) {
  const response = await apiFetcher(stationID);

  if (!response.ok) throw new Error('Failed to fetch score');

  try {
    const parsed = (await response.json()) as ScoreResponse;

    return parsed;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- this error will not be used, since it's just returning null
  } catch (_) {
    return null;
  }
}

export async function getMultipleScores(stations: string[]) {
  if (!stations.length) return null;

  const response = await fetchMultipleStationIds(stations);

  if (!response.ok) return null;

  try {
    const parsed = (await response.json()) as MultipleScoresResponse;

    return parsed;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- this error will not be used, since it's just returning null
  } catch (_) {
    return null;
  }
}
