import { z } from 'zod';

export const confirmUpgradePlan = z
  .object({
    'confirm-upgrade-plan': z.object({
      title: z.string(),
      'charge-price-message': z.string(),
      'body-message': z.string().optional(), // TODO: revert optional once localization available
      'upgrade-delay-message': z.string(),
      'upgrade-now': z.string()
    })
  })
  .transform((values) => {
    const {
      title: title,
      'charge-price-message': changePriceMessage,
      'body-message': bodyMessage,
      'upgrade-delay-message': upgradeDelayMessage,
      'upgrade-now': upgradeNow
    } = values['confirm-upgrade-plan'];
    return {
      title,
      changePriceMessage,
      bodyMessage,
      upgradeDelayMessage,
      upgradeNow
    };
  });

export type ConfirmUpgradePlanLocale = z.infer<typeof confirmUpgradePlan>;
