import { queryClient } from '../../lib/query';
import type { Content } from '../content';
import { GetChannelsQuery } from '../schedule';
import { getAllChannels } from './services';

export function getChannels() {
  return getAllChannels();
}

export function getChannelsMap() {
  const allChannels = getChannels();

  return new Map(allChannels.map((channel) => [channel.channelId, channel]));
}

export function getChannelByContent(content: Content) {
  const { channelId } = content;

  const channels = getChannels();

  const channelById = channels.find((channel) => channel.channelId === channelId);

  if (channelById) return channelById;

  const channelByNetId = channels.find((channel) => channel.quickplayNetworkId === content.networkId);

  if (channelByNetId) return channelByNetId;

  for (const channel of channels) {
    const hasChannelNetName = channel.quickplayNetwork.toLowerCase() === content?.network?.toLowerCase();

    const hasChannelProvider = [channel.name?.toLowerCase(), channel.rsnName?.toLowerCase()].includes(
      content.providerName?.toLowerCase()
    );

    if (hasChannelNetName || hasChannelProvider) return channel;
  }
}

export async function getChannelNumber(channelId?: string) {
  if (!channelId) return;
  const channels = await queryClient.ensureQueryData(GetChannelsQuery);
  return channels.find((chn) => chn.id === channelId)?.number;
}
