import { queryOptions } from '@tanstack/react-query';
import { getUser } from './services';

const USER_STALE_TIME = 5 * 60 * 1000;

export const GetUserQuery = queryOptions({
  queryKey: ['user'],
  queryFn: getUser,
  staleTime: USER_STALE_TIME
});
