import { gameEnv } from './env';
import type { InitializationOptions } from './type';

/**
 * GameConfig is a singleton class that holds all the configuration data needed by GAME core.
 */
export class GameConfig {
  private static instance: GameConfig;

  private static options: InitializationOptions;

  /**
   * Grab all data GAME core needs before start
   */
  static init(options: InitializationOptions) {
    GameConfig.options = options;
  }

  static get buildConfigURL() {
    return gameEnv.buildConfigURL;
  }

  static get get() {
    if (!GameConfig.options) {
      throw new Error('GameConfig should get initialized before use');
    }
    let instance = GameConfig.instance;
    if (!instance) {
      instance = new GameConfig();
      GameConfig.instance = instance;
    }
    return instance;
  }

  get versionSummary() {
    return `${this.release} (${this.version})`;
  }

  get release() {
    return GameConfig.options.release;
  }

  get version() {
    return GameConfig.options.version;
  }

  get environment() {
    return GameConfig.options.environment;
  }

  get platform() {
    return GameConfig.options.platform;
  }

  get basePlatform() {
    return this.platform === 'web:mobile' ? 'web' : this.platform;
  }

  get eventHandler() {
    return GameConfig.options.errorHandler;
  }

  get billingPlatform() {
    return GameConfig.options.billingPlatform;
  }

  // TODO: seems like this is not used anywhere, Please remove this if not used
  get payments() {
    return {
      paypal: !!GameConfig.options.featureFlags?.paypal,
      applePay: !!GameConfig.options.featureFlags?.applePay
    };
  }

  get paymentMethods() {
    return GameConfig.options.paymentMethods;
  }

  get runtimeConfigRefetchInterval() {
    return GameConfig.options.runtimeConfigRefetchInterval ?? Infinity;
  }

  get bundle() {
    return { percentValue: GameConfig.options.bundle.percentValue };
  }

  async getDeviceDetails() {
    return GameConfig.options.getDeviceDetails();
  }

  async getDeviceId() {
    return (await this.getDeviceDetails()).serialNo;
  }
}
