import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { forwardRef, type ComponentPropsWithoutRef, type ElementRef, type ReactNode } from 'react';
import { Icon } from '../icon';
import { Typography } from '../typography';

interface AccordionProps {
  items: {
    title: string | ReactNode;
    content: string | ReactNode;
    value: string;
  }[];
}

export const Accordion = (props: AccordionProps) => {
  const { items } = props;
  return items ? (
    <AccordionPrimitive.Root type="single" collapsible>
      {items.map((item) => (
        <AccordionPrimitive.Item key={item.value} value={item.value}>
          <AccordionTrigger>{item.title}</AccordionTrigger>
          <AccordionContent>{item.content}</AccordionContent>
        </AccordionPrimitive.Item>
      ))}
    </AccordionPrimitive.Root>
  ) : null;
};

const AccordionTrigger = forwardRef<
  ElementRef<typeof AccordionPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ children, className, ...props }, forwardedRef) => (
  <AccordionPrimitive.Header>
    <AccordionPrimitive.Trigger className="group flex items-center" {...props} ref={forwardedRef}>
      <Icon width={40} height={40} name="add" className="group-data-[state=open]:hidden" />
      <Icon width={40} height={40} name="minus" className="group-data-[state=closed]:hidden" />
      <Typography variant="h10" className="ml-5">
        {children}
      </Typography>
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));

const AccordionContent = forwardRef<
  ElementRef<typeof AccordionPrimitive.Content>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ children, className, ...props }, forwardedRef) => (
  <AccordionPrimitive.Content {...props} ref={forwardedRef}>
    <Typography className="my-5" variant="body1">
      {children}
    </Typography>
  </AccordionPrimitive.Content>
));
