import { DeviceActivatedError, generateSecondScreenParams, setDevice } from '@game/core';
import { createFileRoute, redirect } from '@tanstack/react-router';
import type { z } from 'zod';
import { GetTVProvidersQuery } from '../../../app/screens/second-screen/util';
import { getPagePath } from '../../../lib/routing';
import { search } from '../products/route';

export type RouteSearchSchema = z.infer<typeof search>;

export const Route = createFileRoute('/_private/connect')({
  validateSearch: search,
  loader: async ({ context: { queryClient } }) => {
    const providers = await queryClient.ensureQueryData(GetTVProvidersQuery);
    try {
      const { activationCode, regCode, platform, spAccountId } = await generateSecondScreenParams();
      setDevice({ activationCode, regCode, platform, spAccountId });
      return { ...providers, regCode };
    } catch (e) {
      if (e instanceof DeviceActivatedError) throw redirect({ to: getPagePath('manageTvProvider') });
      throw e;
    }
  }
});
