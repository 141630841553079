import { getChannelsMap } from '../channels';
import * as content from '../content';
import { getFilteredLiveEvents } from '../content/lib';
import { getRSNMap, sortByRSN } from '../rsns';
import { getUserData, type User } from '../user';
import { getContentZone } from '../zone';
import type { GetChannelEPGRequest, GetChannelsResponse, GetGamesQueryParams } from './type';

const CHANNEL_PAGE_NUMBER = 1;
const CHANNEL_PAGE_SIZE = 20;

function compareRSN(user: User, a: any, b: any) {
  const isASubscribed = hasIntersection(a.entitlements, user.entitlements);
  const isBSubscribed = hasIntersection(b.entitlements, user.entitlements);
  if (isASubscribed !== isBSubscribed) {
    return isASubscribed ? -1 : 1;
  }
  const rsnMap = getRSNMap('name');
  return sortByRSN('schedule', rsnMap.get(a.provider.toUpperCase()), rsnMap.get(b.provider.toUpperCase()));
}

function compareRank(a: any, b: any) {
  const channelMap = getChannelsMap();
  const aChannelRank = channelMap.get(a.id)?.rank ?? 0;
  const bChannelRank = channelMap.get(b.id)?.rank ?? 0;
  return aChannelRank - bChannelRank;
}

export async function getChannels(): Promise<GetChannelsResponse> {
  const user = await getUserData();
  const region = await getContentZone();

  const response = await content.getChannels({
    zoneId: region,
    pageNumber: CHANNEL_PAGE_NUMBER,
    pageSize: CHANNEL_PAGE_SIZE
  });

  if (!response?.data) return [];

  // show subscribed channels first, then sort by rsn priority
  const availableChannels = response.data.sort(compareRank).sort(compareRSN.bind(null, user));

  const channelsMap = getChannelsMap();

  return availableChannels.map((channel) => ({
    id: channel.id,
    name: channel.provider,
    logo: channelsMap.get(channel.id)?.channelLogo.href ?? channel?.logo?.href ?? '',
    number: channel.number
  }));
}

export async function getChannelEPG({ channelId, startDate }: GetChannelEPGRequest) {
  const user = await getUserData();
  const region = await getContentZone();
  return (
    await content.getChannelEPG(
      {
        channelId,
        startDate,
        zoneId: region
      },
      user
    )
  ).data;
}

export async function getScheduledGames(params: GetGamesQueryParams) {
  return getFilteredLiveEvents(params);
}

function hasIntersection(a: Set<string>, b: Set<string>) {
  for (const item of a) {
    if (b.has(item)) {
      return true;
    }
  }
  return false;
}
