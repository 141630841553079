import {
  GameConfig,
  GetActiveSubscriptionsQuery,
  getConfigFromRemote,
  GetContentsDataQuery,
  GetScheduledGamesQuery,
  getTokens,
  isFeatureEnabled,
  SubscriptionUtil,
  type Content,
  type FeaturesPayment
} from '@game/core';
import { createFileRoute, redirect } from '@tanstack/react-router';
import dayjs from 'dayjs';
import { z } from 'zod';
import { productsConfigSchema } from '../../../lib/config';
import { getPagePath } from '../../../lib/routing';

export const search = z.object({
  isOnboarding: z.boolean().optional(),
  contentId: z.string().optional(),
  catalogType: z.enum(['episode', 'channel', 'liveevent', 'show', 'airing']).optional()
});

export const Route = createFileRoute('/_private/products')({
  validateSearch: search,
  beforeLoad: async ({ context: { userLocation, queryClient }, search }) => {
    const tokensInfo = getTokens();

    if (!tokensInfo) throw new Error('User is not authenticated');
    if (userLocation.zone === 'unavailable') {
      throw redirect({ to: getPagePath('subscriptionUnavailable') });
    }

    const { subscriptions } = await queryClient.fetchQuery(GetActiveSubscriptionsQuery);
    const activeSubscriptionsMap = SubscriptionUtil.getActiveSubscriptionsMap(userLocation.zone, subscriptions);
    const currentActiveSubscription = SubscriptionUtil.getCurrentActiveSubscription(activeSubscriptionsMap);

    // If user has both YES and MSG+ subscriptions, and is in onboarding bypass the subscription screen and take the user to favorites
    if (
      search.isOnboarding &&
      activeSubscriptionsMap.has('DTC') &&
      (SubscriptionUtil.userHasBothYesAndMsgSubscriptions(activeSubscriptionsMap.get('DTC')) ||
        SubscriptionUtil.isCouponSubscription(currentActiveSubscription)) // TODO: This is a temporary measure until the user migration is completed. Remove after that.
    ) {
      throw redirect({ to: getPagePath('pickFavoriteTeams') });
    }

    // For this matter, TVOD subscriptions (GamePass) are not taken into account.
    const { contentId } = search;

    const content = [] as Content[];

    if (contentId) {
      const [singleContent] = await queryClient.fetchQuery(GetContentsDataQuery({ contentIds: [contentId] }));
      const contentIsLiveToVOD = singleContent?.isLiveToVOD;
      // If content has gamePassAssetId, it means that content has pt === 'TVOD', therefore is an MSG game according to Evergent
      if (singleContent && singleContent.gamePassAssetId && !contentIsLiveToVOD) content.push(singleContent);
    }

    if (!content.length) {
      const unfilteredContent = await queryClient.ensureQueryData(
        GetScheduledGamesQuery({
          startDate: dayjs().toDate(),
          endDate: dayjs().add(3, 'day').toDate()
        })
      );
      unfilteredContent.forEach((item) => {
        // If content has gamePassAssetId, it means that content has pt === 'TVOD', therefore is an MSG game according to Evergent
        // And user should only see games that are locked, maybe user has purchased one of the games.
        if (item.gamePassAssetId && item.isLocked) content.push(item);
      });
    }

    const paymentFeatures = (await isFeatureEnabled('payments')) as FeaturesPayment;
    // TODO: Refactor this logic for game. This is specific for GOTHAM.
    const isMSGUser = currentActiveSubscription && SubscriptionUtil.isMSGSubscription(currentActiveSubscription);
    const isGamePassAvailable = !isMSGUser && !!content.length && ((await isFeatureEnabled('gamePass')) as boolean);
    const showDefaultGamePass = !!contentId && content.length === 1;
    const isUpsell = !!currentActiveSubscription;
    const isDTCSubscription = SubscriptionUtil.isDTCSubscription(currentActiveSubscription);

    // TODO: revisit to see if we need to pass configure individual isUpsell, isDTCSubscription, etc..
    return {
      currentActiveSubscription,
      isUpsell,
      isDTCSubscription,
      canUpgrade: isUpsell && isDTCSubscription,
      content,
      isOnboarding: search.isOnboarding,
      isGamePassAvailable,
      showDefaultGamePass,
      config: getConfigFromRemote(productsConfigSchema),
      accessToken: tokensInfo.accessToken,
      features: {
        applePay: GameConfig.get.payments.applePay && paymentFeatures.applePay,
        paypal: GameConfig.get.payments.paypal && paymentFeatures.paypal
      }
    };
  },
  loader: ({ context: { ...rest } }) => rest
});
