import { emit, getSearchedAccountFromStorage, isFeatureEnabled } from '@game/core';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { getPagePath } from '../../../lib/routing';

export const Route = createFileRoute('/_private/first-time')({
  beforeLoad: async () => {
    const checkedAccountData = getSearchedAccountFromStorage();

    if (!checkedAccountData?.exists) throw redirect({ to: getPagePath('login') });

    return {
      existingRSN: checkedAccountData.originalRSN
    };
  },
  loader: async () => {
    emit('OnboardExisting');
    const newsletter = await isFeatureEnabled('newsletter');
    return {
      newsletter
    };
  }
});
