import * as RadixModal from '@radix-ui/react-dialog';
import { type JSX, type ReactNode, useCallback, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import { Icon } from '../icon';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

export type ModalProps = RadixModal.DialogProps & {
  children?: ReactNode;
  containerClassName?: string;
  triggerButton?: JSX.Element;
};

/**
 *
 * @param {ReactNode} [children] the content in which will be rendered on the modal
 * @param {React.JSX.Element} [triggerButton] the button used to open a modal, if the page needs one
 * @param {Boolean} [open] a value to control open/closed state
 * @param {Function} [onOpenChange] a function to handle open/close events. Recommended if you pass a value to `open` property, if you want to make this component controllable
 * @param {Boolean} [modal=true] The modality of the dialog. When set to `true`, interaction with outside elements will be disabled and only dialog content will be visible to screen readers.
 * @example
 * <Modal
 *    triggerButton={<Button>Open modal</Button>}
 *    open={modalState}
 *    onOpenChange={(open) => {setModalState(open)}}
 *    modal={true}
 *  >
 *    <div>{...content}</div>
 *  </Modal>
 */
function Modal(props: ModalProps) {
  const { children, onOpenChange, triggerButton, modal = true, containerClassName, ...rest } = props;

  const handleOpenChange = useCallback(
    (open: boolean) => {
      // This portion hides the scroll bar in the back of the modal,
      // since the blur effect was creating a weird background with the browser scroll
      open ? document.body.classList.add('overflow-hidden') : document.body.classList.remove('overflow-hidden');

      onOpenChange?.(open);
    },
    [onOpenChange]
  );

  useEffect(() => {
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  return (
    <RadixModal.Root {...rest} modal={modal} onOpenChange={handleOpenChange}>
      {triggerButton ? <RadixModal.Trigger asChild>{triggerButton}</RadixModal.Trigger> : null}
      <RadixModal.Portal>
        <RadixModal.Overlay className="bg-secondary-700/60 fixed inset-0 z-50 backdrop-blur-xl" />
        <RadixModal.Content className="fixed inset-0 z-50 flex flex-col overflow-y-auto bg-transparent px-5 py-10 md:px-16 md:py-11">
          <VisuallyHidden>
            <RadixModal.Title />
            <RadixModal.Description />
          </VisuallyHidden>
          <RadixModal.Close className="self-end">
            <Icon name="close" width={44} height={44} />
          </RadixModal.Close>
          <div className={twMerge('flex-1', containerClassName)}>{children}</div>
        </RadixModal.Content>
      </RadixModal.Portal>
    </RadixModal.Root>
  );
}

Modal.displayName = 'Modal';

export { Modal };
