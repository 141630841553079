import { getUserLocation, isUserOutOfMarket } from '../check-location';
import { getConfigFromRemote } from '../config-manager';
import { getUserData } from '../user';
import { z } from 'zod';

export async function getUserCurrentZone(): Promise<string | null> {
  const { zone } = await getUserLocation();
  return zone;
}

export async function getSubscriptionZone(): Promise<string | null> {
  const { entitlementsZone } = await getUserData();
  return entitlementsZone || null;
}

export async function getContentZone(): Promise<string> {
  const subscriptionZone = await getSubscriptionZone();
  if (subscriptionZone?.includes('zone')) {
    return subscriptionZone;
  }

  const location = await getUserLocation();
  if (isUserOutOfMarket(location)) {
    const { defaultZone } = getConfigFromRemote(z.object({ defaultZone: z.string() }));
    return defaultZone;
  }

  return location.zone;
}
