import { queryOptions } from '@tanstack/react-query';
import { GameConfig } from '../../config/game-config';

export const GetDeviceIdQuery = queryOptions({
  queryKey: ['device'],
  queryFn: () => {
    return GameConfig.get.getDeviceId();
  }
});

export const GetDeviceDetailsQuery = queryOptions({
  queryKey: ['device-details'],
  queryFn: () => {
    return GameConfig.get.getDeviceDetails();
  }
});
