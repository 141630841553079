import { z } from 'zod';

export const subscriptionUnavailableSchema = z
  .object({
    'subscription-unavailable': z.object({
      title: z.string(),
      'continue-without-subscription': z.string(),
      'connect-tv-provider': z.string(),
      'need-permission2a': z.string(),
      'need-permission2b': z.string(),
      'need-permission1': z.string()
    })
  })
  .transform(({ 'subscription-unavailable': strings }) => ({
    title: strings['title'],
    explanation: strings['need-permission1'],
    explanationLines: [strings['need-permission2a'], strings['need-permission2b']],
    connectTv: strings['connect-tv-provider'],
    cancel: strings['continue-without-subscription']
  }));
