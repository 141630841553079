import { z } from 'zod';

export const easeLiveConfigSchema = z.object({
  accountId: z.string(),
  overlayUrl: z.string(),
  easeLiveUrl: z.string(),
  environment: z.string(),
  projectId: z.object({
    roku: z.object({
      nba: z.string(),
      mlb: z.string(),
      nhl: z.string()
    })
  }),
  easeLiveDefaultStateOn: z.boolean()
});

export type EaseLiveRemoteConfig = z.infer<typeof easeLiveConfigSchema>;
