import type { ValidityMatcherWithMessage } from './types';

export const defaultNativeErrorMessages = {
  badInput: 'Enter input again.',
  patternMismatch: 'It does not match the pattern.',
  rangeOverflow: 'Number exceeded the limit.',
  rangeUnderflow: 'Number must be higher.',
  stepMismatch: 'Error',
  tooLong: 'Input length above maximum.',
  tooShort: 'Input length below minimum.',
  typeMismatch: 'Invalid input type.',
  valueMissing: 'Required field.'
} satisfies Partial<ValidityMatcherWithMessage>;
