import { type RoutePaths } from '@tanstack/react-router';
import { type routeTree } from '../../routeTree.gen';

export const getPagePath = (pageId: PageId): PagePath => PAGE_PATHS_MAP[pageId];

const PAGE_PATHS_MAP = {
  connectTV: '/connect',
  createProfile: '/create-profile',
  firstTimeLogin: '/first-time',
  updateProfile: '/update-profile',
  home: '/',
  launch: '/launch',
  login: '/login',
  logout: '/logout',
  pickFavoriteTeams: '/favorite/teams',
  pickFavoritePlayers: '/favorite/players',
  playback: '/playback/$videoId',
  products: '/products',
  successful: '/products/successful',
  waysToWatch: '/ways-to-watch',
  watch: '/',
  search: '/search',
  odds: '/odds',
  schedule: '/schedule/$type',
  account: '/account',
  content: '/content/$railId',
  upcoming: '/upcoming',
  forgotPassword: '/forgot-password',
  referral: '/account/referral',
  profile: '/account/profile',
  help: '/account/help',
  deleteAccount: '/account/delete',
  contactSupport: '/account/support',
  publicHelp: '/help',
  publicSupport: '/support',
  manageFavorites: '/account/favorites',
  editTeams: '/account/edit/teams',
  editPlayers: '/account/edit/players',
  manageTvProvider: '/account/manage-tv',
  manageSubscription: '/account/subscription',
  managePayment: '/account/subscription/payment',
  purchaseHistory: '/account/subscription/history',
  changePlan: '/account/change-plan',
  cancelSubscription: '/account/cancel-subscription',
  geoBlock: '/geo-block',
  subscriptionUnavailable: '/subscription-unavailable',
  debugPanel: '/account/debug-panel',
  deleteAccountRequest: '/delete',
  termsService: '/terms-service',
  privacyPolicy: '/privacy-policy',
  privacyChoices: '/privacy-choices'
} satisfies Record<string, PagePath>;

export type PagePath = RoutePaths<typeof routeTree>;
export type PageId = keyof typeof PAGE_PATHS_MAP;
