import { z } from 'zod';

export const secondScreenSchema = z
  .object({
    'tve-login': z.object({
      'dont-see': z.string(),
      'search-service-provider': z.string(),
      'choose-tv-provider': z.string()
    }),
    'ways-to-watch': z.object({
      'continue-without-subscription': z.string()
    })
  })
  .transform((values) => {
    const {
      'tve-login': {
        'choose-tv-provider': chooseTvProvider,
        'dont-see': dontSee,
        'search-service-provider': otherPlaceholder
      },
      'ways-to-watch': { 'continue-without-subscription': continueWithoutSubscription }
    } = values;

    return {
      dontSee,
      otherPlaceholder,
      chooseTvProvider,
      continueWithoutSubscription
    };
  });

export type SecondScreenLocale = z.infer<typeof secondScreenSchema>;
