import { z } from 'zod';

const tveSchema = z.object({
  id: z.string(),
  displayName: z.string(),
  logoUrl: z.union([z.string().url(), z.null()]).optional(),
  logoUrlWhite: z.union([z.string().url(), z.null()]).optional(),
  isPremium: z.boolean(),
  mrss: z.string().optional(),
  metaDataZipKey: z.string().optional(),
  appStoreUrl: z.string().url().optional()
});

export const secondScreenConfig = z
  .object({
    tveAuthentication: z.object({
      tvProviders: z.array(tveSchema)
    })
  })
  .transform((parsed) => {
    const {
      tveAuthentication: { tvProviders }
    } = parsed;

    const providers = new Map<string, TVESchema>();

    tvProviders.forEach((p, i) => {
      const provider = { ...p, displayOrder: i } as TVESchema;
      providers.set(p.id, provider);
    });

    return { providers };
  });

export type TVESchema = z.infer<typeof tveSchema> & { displayOrder: number };
