import { toURL } from '@game/core';
import { z } from 'zod';

export const productsConfigSchema = z
  .object({
    baseURL: z.string().url(),
    assets: z.object({
      paymentMethodLogos: z.object({
        creditCard: z.string(),
        paypalLogo: z.string(),
        applePayLogo: z.object({
          light: z.string(),
          dark: z.string()
        })
      })
    }),
    accountLinks: z.object({
      legal: z.object({
        privacyPolicyURL: z.string(),
        termsOfServiceURL: z.string()
      })
    })
  })
  .transform((parsed) => {
    const {
      baseURL,
      assets: { paymentMethodLogos },
      accountLinks: {
        legal: { privacyPolicyURL, termsOfServiceURL }
      }
    } = parsed;

    const { creditCard, paypalLogo, applePayLogo } = paymentMethodLogos;

    return {
      baseURL,
      privacyPolicyURL,
      termsOfUseURL: termsOfServiceURL,
      paymentMethodLogos: {
        creditCard: toURL(baseURL, creditCard),
        paypalLogo: toURL(baseURL, paypalLogo),
        applePayLogo: toURL(baseURL, applePayLogo.light),
        applePayLogoDark: toURL(baseURL, applePayLogo.dark)
      }
    };
  });

export type ProductsConfig = z.infer<typeof productsConfigSchema>;
