import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

const search = z.object({
  teamId: z.number().optional()
});

export const Route = createFileRoute('/_private/_home/account/_favorites/favorites')({
  validateSearch: search
});
