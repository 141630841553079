import { GameConfig, numberToCurrency, SubscriptionUtil, useLocale, type SubscriptionPlan } from '@game/core';
import { Icon, Image, ToggleCheckbox, Typography } from '@game/core-ui';
import dayjs from 'dayjs';
import { Fragment, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import { z } from 'zod';

/**
 * Subscription Card component
 * @param {SubscriptionPlan} plan method to be triggered on a tab being selected. The argument would be the value of the selected tab
 * @param {String} name method to be triggered on a tab being selected. The argument would be the value of the selected tab
 * @param {String} priceDescription method to be triggered on a tab being selected. The argument would be the value of the selected tab
 * @param {Boolean} [hasStarIcon] aria label to be attached to the tabs list
 * @param {Boolean} selected the controlled selected state of the tabs. Must be used in conjunction with onValueChange
 * @param {Function} onSelect the controlled selected state of the tabs. Must be used in conjunction with onValueChange
 *
 * @example
 *  <SubscriptionCard
 *      plan={plan}
 *      name="subscription-plan"
 *      selected={selectedPlan?.productID === plan.productID}
 *      onSelect={setSelectedPlan}
 *      priceDescription={periodType === 'annual' ? locales.taxYearly : locales.taxMonthly}
 *  ></SubscriptionCard>
 */

type SubscriptionCardProps = {
  plan: SubscriptionPlan;
  name: string;
  isBundle?: boolean;
  priceDescription: string;
  selected: boolean;
  onSelect: (plan: SubscriptionPlan) => void;
};

const localeSchema = z
  .object({
    'purchase-plan': z.object({
      'bundle-save': z.string(),
      'pay-in-installments': z.string(),
      'promo-offer-description': z.string(),
      'offer-ends-on-date': z.string(),
      'promo-price-ends-on-renewal': z.string()
    })
  })
  .transform((values) => {
    const {
      'bundle-save': bundleSave,
      'pay-in-installments': payInInstallments,
      'promo-offer-description': promoOfferDescription,
      'offer-ends-on-date': offerEndsOnDate,
      'promo-price-ends-on-renewal': promoPriceEndsOnRenewal
    } = values['purchase-plan'];
    return { bundleSave, payInInstallments, promoOfferDescription, offerEndsOnDate, promoPriceEndsOnRenewal };
  });

export const SubscriptionCard = (props: SubscriptionCardProps) => {
  const { locales } = useLocale({ schema: localeSchema });
  const { plan, name, priceDescription, isBundle, selected, onSelect } = props;
  const { price, promotionalPrice, teamLogos, rsnsLogo } = plan;

  const onClick = useCallback(() => {
    onSelect(plan);
  }, [onSelect, plan]);

  return (
    <ToggleCheckbox
      name={name}
      checked={selected}
      onClick={onClick}
      className={`${isBundle ? 'w-full' : 'w-[9.3rem]'} ring-primary-500 data-[state=on]:ring-4 md:w-max md:hover:ring-4`}
    >
      <div className="bg-secondary-500 peer-checked:bg-secondary-700 relative box-content h-full w-full px-[1.05rem] py-[0.79rem] text-left md:w-[17.7rem] md:py-[1.33rem] md:pl-[1.22rem] md:pr-[6.43rem]">
        {isBundle ? (
          <Icon name="subscription-star" width={71} height={65} className="absolute right-0 top-0"></Icon>
        ) : null}
        <div className="text-primary-500 flex items-center gap-4 self-end">
          {rsnsLogo.map(({ rsnID, url }, index) => (
            <Fragment key={rsnID}>
              {index === 1 && (
                <Typography variant="body2" className="text-[1.48rem] font-normal md:text-[1.64rem]">
                  +
                </Typography>
              )}
              <div className="h-[2.36rem] w-[2.36rem] md:h-[2.84rem] md:w-[2.84rem]">
                <Image width={55} height={55} src={url.href} />
              </div>
            </Fragment>
          ))}
        </div>
        {promotionalPrice ? (
          <div className="bg-primary-500 my-2 w-fit rounded-[0.263rem] px-[0.394rem] py-[0.21rem]">
            <Typography variant="body6" className="text-secondary-500">
              {locales.promoOfferDescription}
            </Typography>
          </div>
        ) : (
          <Typography
            variant="body2"
            className={`${isBundle ? 'block' : 'hidden'} text-primary-500 mt-[0.53rem] md:mt-[0.63rem]`}
          >
            {locales.bundleSave.replace('{percentValue}', GameConfig.get.bundle.percentValue)}
          </Typography>
        )}

        <div
          className={twMerge(
            'my-[0.53rem] flex flex-col items-start gap-2 md:flex-row md:items-end md:gap-[4.75rem]',
            !promotionalPrice && 'md:mt-[2.5rem]'
          )}
        >
          <div className="flex items-end gap-[0.375rem] whitespace-nowrap">
            {promotionalPrice ? (
              <Typography variant="body3" className="self-center text-[0.64rem] line-through md:text-[0.842rem]">
                {numberToCurrency(price)}
              </Typography>
            ) : null}
            <Typography variant="h8" className="text-[0.94rem] md:text-[1.29rem]">
              {promotionalPrice ? numberToCurrency(promotionalPrice) : numberToCurrency(price)}
            </Typography>
            <Typography variant="body4" className="text-[0.65rem] font-normal md:text-[0.77rem] md:font-bold">
              {priceDescription}
            </Typography>
          </div>
        </div>
        {plan.promotionalPriceExpiry ? (
          <>
            <Typography variant="body5" className="hidden md:block">
              {locales.offerEndsOnDate.replace(
                '{offerEndDate}',
                dayjs(plan.promotionalPriceExpiry).format('DD/MM/YYYY')
              )}
            </Typography>
            <Typography variant="body5" className="mb-3 hidden md:block">
              {locales.promoPriceEndsOnRenewal}
            </Typography>
          </>
        ) : (
          <Typography
            variant="body1"
            className={twMerge('mb-3 hidden md:block', SubscriptionUtil.canPayByInstallments(plan) ? '' : 'invisible')}
          >
            {locales.payInInstallments}
          </Typography>
        )}
        <div className="flex w-full gap-[0.08rem] md:gap-[0.19]">
          {teamLogos.map(({ id, url }) => (
            // TODO: set image alt to team shortName
            <div key={id} className="h-[1.31rem] w-[1.31rem] md:h-[1.8rem] md:w-[2.3rem]">
              <Image src={url.href} width={32} height={32} />
            </div>
          ))}
        </div>
      </div>
    </ToggleCheckbox>
  );
};
