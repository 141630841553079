import { z } from 'zod';

const paymentsFeaturesSchema = z.object({
  applePay: z.boolean().optional(),
  paypal: z.boolean().optional()
});

const socialLoginFeaturesSchema = z.object({
  facebook: z.boolean(),
  google: z.boolean(),
  apple: z.boolean()
});

export type FeaturesPayment = z.infer<typeof paymentsFeaturesSchema>;

export type FeaturesSocialLogin = z.infer<typeof socialLoginFeaturesSchema>;

const platformFeaturesSchema = z.object({
  favoritePlayerSelection: z.boolean().optional(),
  couchRights: z.boolean().optional(),
  gamePassAvailable: z.boolean().optional(),
  isMultipleRSNsAllowed: z.boolean().optional(),
  showPlayTab: z.boolean().optional(),
  csaiPrerollLinear: z.boolean().optional(),
  csaiPrerollOverflow: z.boolean().optional(),
  csaiPrerollVod: z.boolean().optional(),
  bannerAds: z.boolean().optional(),
  filterStorefrontByRSN: z.boolean().optional(),
  isLaunchVideoCachingFeatureEnabled: z.boolean().optional(),
  liveScoresOnHero: z.boolean().optional(),
  odds: z.boolean().optional(),
  referrals: z.boolean().optional(),
  newsletter: z.boolean().optional(),
  pickNPlay: z.boolean().optional(),
  payments: paymentsFeaturesSchema.optional(),
  easelive: z.boolean().optional(),
  socialLogin: socialLoginFeaturesSchema.optional(),
  upsellAvailable: z.boolean(),
  heroFavSorting: z.boolean(),
  showForgotPasswordTextBox: z.boolean(),
  watch: z.object({
    upcoming: z.object({
      filter: z.object({
        rsn: z.boolean()
      })
    })
  }),
  heroCarouselAutoRotation: z.boolean().optional()
});

export const featuresConfigSchema = z
  .object({
    featureKeys: z.object({
      favoritePlayerSelection: z.string()
    }),
    featureFlags: z.object({
      web: platformFeaturesSchema,
      samsung: platformFeaturesSchema,
      vizio: platformFeaturesSchema,
      lg: platformFeaturesSchema
    })
  })
  .transform((config) => {
    const {
      featureFlags: { web, samsung, vizio, lg }
    } = config;
    return {
      web: featureFlagsTransformer(web),
      samsung: featureFlagsTransformer(samsung),
      vizio: featureFlagsTransformer(vizio),
      lg: featureFlagsTransformer(lg)
    };
  });

function featureFlagsTransformer(features: z.infer<typeof platformFeaturesSchema>) {
  const {
    favoritePlayerSelection: favoritePlayer,
    couchRights,
    gamePassAvailable: gamePass,
    isMultipleRSNsAllowed: multiRSN,
    showPlayTab: playTab,
    odds,
    referrals,
    newsletter,
    pickNPlay,
    csaiPrerollLinear: csaiLinear,
    csaiPrerollOverflow: csaiOverflow,
    csaiPrerollVod: csaiVod,
    bannerAds,
    filterStorefrontByRSN: filterContentByRSN,
    isLaunchVideoCachingFeatureEnabled: launchVideoCaching,
    liveScoresOnHero: liveScores,
    easelive,
    payments,
    socialLogin,
    upsellAvailable: upsell,
    heroFavSorting,
    showForgotPasswordTextBox,
    watch,
    heroCarouselAutoRotation
  } = features;

  return {
    favoritePlayer,
    couchRights,
    gamePass,
    multiRSN,
    playTab,
    odds,
    referrals,
    newsletter,
    pickNPlay,
    csaiLinear,
    csaiOverflow,
    csaiVod,
    bannerAds,
    filterContentByRSN,
    easelive,
    launchVideoCaching,
    liveScores,
    payments,
    socialLogin,
    upsell,
    watch: {
      favorites: {
        sort: heroFavSorting
      },
      upcoming: {
        filter: watch.upcoming.filter
      }
    },
    heroCarouselAutoRotation,
    loginHelpText: showForgotPasswordTextBox
  };
}

export type FeaturesConfigSchema = z.infer<typeof featuresConfigSchema>;
export type FeaturesPlatform = keyof FeaturesConfigSchema;
export type FeaturesLiveScores = Pick<FeaturesConfigSchema[FeaturesPlatform], 'liveScores'>;
export type FeaturesFilterContent = Pick<FeaturesConfigSchema[FeaturesPlatform], 'filterContentByRSN'>;
export type FeaturesReferrals = Pick<FeaturesConfigSchema[FeaturesPlatform], 'referrals'>;
