import { getConfigFromRemote, getMVPDList, type MVPD } from '@game/core';
import { secondScreenConfig, type TVESchema } from '../../../lib/config/secondScreen';
import { queryOptions } from '@tanstack/react-query';

export const GetTVProvidersQuery = queryOptions({
  queryKey: ['tv-providers'],
  queryFn: getTVProviders
});

export async function getTVProviders() {
  const { providers: providersMap } = getConfigFromRemote(secondScreenConfig);

  const provisioned = await getMVPDList();
  const premium: TvProvider[] = [];
  const other: TvProvider[] = [];
  const all: TvProvider[] = [];

  provisioned.forEach((tve, fallbackOrder) => {
    const override = providersMap.get(tve.id);
    const provider = override ? overrideTve(tve, override) : { ...tve, displayOrder: fallbackOrder };
    all.push(provider);
    (override?.isPremium ? premium : other).push(provider);
  });

  premium.sort(compareByDisplayOrder);
  other.sort(compareByName);
  all.sort(compareByName);

  return { premium, other, all };
}

function overrideTve(tve: MVPD, override: TVESchema) {
  return {
    id: tve.id,
    displayName: override.displayName ?? tve.displayName,
    displayOrder: override.displayOrder,
    logoUrl: override.logoUrl ?? tve.logoUrl
  };
}

function compareByDisplayOrder(a: TvProvider, b: TvProvider) {
  return a.displayOrder - b.displayOrder;
}

function compareByName(a: TvProvider, b: TvProvider) {
  return a.displayName.localeCompare(b.displayName);
}

export type TvProvider = MVPD & { displayOrder: number };
