export class DeviceActivatedError extends Error {
  constructor() {
    super('Device already activated');
    this.name = 'DeviceActivatedError';
  }
}

export class ResourceAccessDeniedError extends Error {
  constructor() {
    super('Resource access denied');
    this.name = 'ResourceAccessDeniedError';
  }
}
