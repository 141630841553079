import { queryClient } from '../../lib/query';
import { isFeatureEnabled } from '../features-manager';
import { getUserData } from '../user';
import { getContentZone } from '../zone';
import { GetContainersQuery } from './query';
import {
  getAirings,
  getContainerContent,
  getContentsByIds,
  getHeroCarouselContent,
  getLiveEventsWithFilters,
  getMoreLikeThis,
  getRailsContainers,
  getSearchedContent,
  getSingleContentData,
  getUpcomingContent
} from './services';
import type {
  Content,
  GetContentsData,
  GetFilteredLiveEventsParams,
  GetRailContentListParams,
  GetSingleContentDataParams,
  GetUpcomingParams
} from './type';
import { getContentSortedByFavorites, getSortedRails, updateChannelContent, updateContentWithScores } from './utils';

export async function getHeroCarousel() {
  const user = await getUserData();
  const region = await getContentZone();

  const liveScoresEnabled = await isFeatureEnabled('liveScores');
  const {
    favorites: { sort }
  } = await isFeatureEnabled('watch');

  let contentList = await getHeroCarouselContent({ region, user });

  if (!contentList) return [];

  // It will check first if there are content with catalogueType channel to fetch content/airing
  // to complete the information.
  if (contentList.some((content) => content.catalogType === 'channel')) {
    contentList = await updateChannelContent(region, contentList, user);
  }

  if (liveScoresEnabled) {
    contentList = await updateContentWithScores(contentList);
  }

  if (sort) {
    contentList = await getContentSortedByFavorites(contentList);
  }

  return contentList ?? [];
}

export async function getSingleContent(params: GetSingleContentDataParams) {
  const user = await getUserData();
  return getSingleContentData(params, user);
}

export async function getSearchedVods(searchTerm: string) {
  const user = await getUserData();
  const region = await getContentZone();

  return await getSearchedContent({ searchTerm, region, user });
}

export async function getUpcoming(params: GetUpcomingParams) {
  const { pageSize, pageNumber = 1 } = params;
  const user = await getUserData();
  const zoneId = await getContentZone();

  return await getUpcomingContent({ pageSize, pageNumber, zoneId, user });
}

export type GetRailsParams = {
  railContentSize: number;
};

export async function getRails(params: GetRailsParams) {
  const { railContentSize } = params;

  const user = await getUserData();
  const region = await getContentZone();

  const railsContainers = await getRailsContainers({ user, region, railContentSize });

  return await getSortedRails(railsContainers);
}

export async function getRailContentList(params: GetRailContentListParams) {
  const user = await getUserData();
  const region = await getContentZone();

  return await getContainerContent({ ...params, zoneId: region }, user);
}

export async function getAllLiveContent() {
  const user = await getUserData();
  const region = await getContentZone();

  return getAirings(region, user);
}

export async function getVODUpNext(assetId: string, playlist?: Content[], fetchMoreLikeThis?: boolean) {
  const user = await getUserData();
  const region = await getContentZone();

  const moreLikeThisPlaylist = fetchMoreLikeThis ? await getMoreLikeThis({ region, user, assetId }) : [];
  const combinedPlaylist = [...(playlist ?? []), ...(moreLikeThisPlaylist ?? [])];
  const combinedPlaylistMap = new Map(combinedPlaylist.map((vod) => [vod.id, vod]));
  const upNextPlaylist = [...combinedPlaylistMap.values()].filter((vod) => !vod.isLocked);

  const currentAssetIndexInPlaylist = upNextPlaylist.findIndex((vod) => vod.id === assetId);
  const upNext = currentAssetIndexInPlaylist >= 0 ? upNextPlaylist[currentAssetIndexInPlaylist + 1] : upNextPlaylist[0];

  return {
    upNextVOD: upNext,
    upNextPlaylist,
    currentIndexInPlaylist: currentAssetIndexInPlaylist,
    isFinal: currentAssetIndexInPlaylist >= upNextPlaylist.length - 1
  };
}

export async function getFilteredLiveEvents(params: GetFilteredLiveEventsParams) {
  const user = await getUserData();
  const region = await getContentZone();

  return getLiveEventsWithFilters({ user, region, ...params });
}

export type GetSeeAllContentParams = {
  railId: string;
};

export async function getSeeAllRail(params: GetSeeAllContentParams) {
  const { railId } = params;
  const user = await getUserData();
  const region = await getContentZone();

  const rails = await queryClient.ensureQueryData(GetContainersQuery({ user, region }));

  if (!rails) return null;

  const currentRail = rails.find((rail) => rail.id === railId);

  if (!currentRail) return null;

  return currentRail;
}

export async function getContentsData(params: GetContentsData) {
  const [user, region] = await Promise.all([getUserData(), getContentZone()]);

  return getContentsByIds({
    user,
    zoneId: region,
    contentIds: params.contentIds
  });
}
