/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as PublicImport } from './routes/_public'
import { Route as PrivateImport } from './routes/_private'
import { Route as PrivateHomeImport } from './routes/_private/_home'
import { Route as PublicTermsServiceRouteImport } from './routes/_public/terms-service/route'
import { Route as PublicSmsTermsAndConditionsRouteImport } from './routes/_public/sms-terms-and-conditions/route'
import { Route as PublicPrivacyPolicyRouteImport } from './routes/_public/privacy-policy/route'
import { Route as PublicPrivacyChoicesRouteImport } from './routes/_public/privacy-choices/route'
import { Route as PublicLoginRouteImport } from './routes/_public/login/route'
import { Route as PublicLaunchRouteImport } from './routes/_public/launch/route'
import { Route as PublicHelpRouteImport } from './routes/_public/help/route'
import { Route as PublicDeleteRouteImport } from './routes/_public/delete/route'
import { Route as PublicCreateProfileRouteImport } from './routes/_public/create-profile/route'
import { Route as PublicChangePasswordRouteImport } from './routes/_public/change-password/route'
import { Route as PrivateWaysToWatchRouteImport } from './routes/_private/ways-to-watch/route'
import { Route as PrivateUpdateProfileRouteImport } from './routes/_private/update-profile/route'
import { Route as PrivateProductsRouteImport } from './routes/_private/products/route'
import { Route as PrivateLogoutRouteImport } from './routes/_private/logout/route'
import { Route as PrivateFirstTimeRouteImport } from './routes/_private/first-time/route'
import { Route as PrivateConnectRouteImport } from './routes/_private/connect/route'
import { Route as PrivateHomeIndexImport } from './routes/_private/_home/index'
import { Route as PublicBetsOddsImport } from './routes/_public/bets_/odds'
import { Route as PublicAuthenticateStatusImport } from './routes/_public/authenticate/status'
import { Route as PrivatePlaybackVideoIdImport } from './routes/_private/playback/$videoId'
import { Route as PrivateFavoriteTeamsImport } from './routes/_private/favorite/teams'
import { Route as PrivateFavoritePlayersImport } from './routes/_private/favorite/players'
import { Route as PrivateContentRailIdImport } from './routes/_private/content/$railId'
import { Route as deeplinkPageSplatImport } from './routes/(deeplink)/page.$'
import { Route as PrivateProductsSuccessfulRouteImport } from './routes/_private/products_/successful/route'
import { Route as PrivateHomeOddsRouteImport } from './routes/_private/_home/odds/route'
import { Route as PrivateHomeAccountIndexImport } from './routes/_private/_home/account/index'
import { Route as PrivateHomeScheduleTypeImport } from './routes/_private/_home/schedule/$type'
import { Route as PrivateHomeAccountSubscriptionImport } from './routes/_private/_home/account/_subscription'
import { Route as PrivateHomeAccountFavoritesImport } from './routes/_private/_home/account/_favorites'
import { Route as PrivateHomeAccountReferralRouteImport } from './routes/_private/_home/account/referral/route'
import { Route as PrivateHomeAccountManageTvRouteImport } from './routes/_private/_home/account/manage-tv/route'
import { Route as PrivateHomeAccountHelpRouteImport } from './routes/_private/_home/account/help/route'
import { Route as PrivateHomeAccountDeleteRouteImport } from './routes/_private/_home/account/delete/route'
import { Route as PrivateHomeAccountDebugPanelRouteImport } from './routes/_private/_home/account/debug-panel/route'
import { Route as PrivateHomeAccountChangePlanRouteImport } from './routes/_private/_home/account/change-plan/route'
import { Route as PrivateHomeAccountCancelSubscriptionRouteImport } from './routes/_private/_home/account/cancel-subscription/route'
import { Route as PublicAuthenticateRegCodePlatformActivationCodeImport } from './routes/_public/authenticate/$regCode.$platform.$activationCode'
import { Route as PrivateHomeAccountFavoritesFavoritesImport } from './routes/_private/_home/account/_favorites/favorites'
import { Route as PrivateHomeAccountSubscriptionSubscriptionPaymentImport } from './routes/_private/_home/account/_subscription.subscription/payment'
import { Route as PrivateHomeAccountSubscriptionSubscriptionHistoryImport } from './routes/_private/_home/account/_subscription.subscription/history'
import { Route as PrivateHomeAccountFavoritesEditPlayersImport } from './routes/_private/_home/account/_favorites/edit/players'

// Create Virtual Routes

const PublicSupportRouteLazyImport = createFileRoute('/_public/support')()
const PublicGeoBlockRouteLazyImport = createFileRoute('/_public/geo-block')()
const PublicForgotPasswordRouteLazyImport = createFileRoute(
  '/_public/forgot-password',
)()
const PrivateUpcomingRouteLazyImport = createFileRoute('/_private/upcoming')()
const PrivateSubscriptionUnavailableRouteLazyImport = createFileRoute(
  '/_private/subscription-unavailable',
)()
const PrivateHomeAccountImport = createFileRoute('/_private/_home/account')()
const PrivateHomeSearchRouteLazyImport = createFileRoute(
  '/_private/_home/search',
)()
const PrivateHomeAccountSupportRouteLazyImport = createFileRoute(
  '/_private/_home/account/support',
)()
const PrivateHomeAccountProfileRouteLazyImport = createFileRoute(
  '/_private/_home/account/profile',
)()
const PrivateHomeAccountSubscriptionSubscriptionIndexLazyImport =
  createFileRoute('/_private/_home/account/_subscription/subscription/')()
const PrivateHomeAccountFavoritesEditTeamsLazyImport = createFileRoute(
  '/_private/_home/account/_favorites/edit/teams',
)()

// Create/Update Routes

const PublicRoute = PublicImport.update({
  id: '/_public',
  getParentRoute: () => rootRoute,
} as any)

const PrivateRoute = PrivateImport.update({
  id: '/_private',
  getParentRoute: () => rootRoute,
} as any)

const PublicSupportRouteLazyRoute = PublicSupportRouteLazyImport.update({
  id: '/support',
  path: '/support',
  getParentRoute: () => PublicRoute,
} as any).lazy(() =>
  import('./routes/_public/support/route.lazy').then((d) => d.Route),
)

const PublicGeoBlockRouteLazyRoute = PublicGeoBlockRouteLazyImport.update({
  id: '/geo-block',
  path: '/geo-block',
  getParentRoute: () => PublicRoute,
} as any).lazy(() =>
  import('./routes/_public/geo-block/route.lazy').then((d) => d.Route),
)

const PublicForgotPasswordRouteLazyRoute =
  PublicForgotPasswordRouteLazyImport.update({
    id: '/forgot-password',
    path: '/forgot-password',
    getParentRoute: () => PublicRoute,
  } as any).lazy(() =>
    import('./routes/_public/forgot-password/route.lazy').then((d) => d.Route),
  )

const PrivateUpcomingRouteLazyRoute = PrivateUpcomingRouteLazyImport.update({
  id: '/upcoming',
  path: '/upcoming',
  getParentRoute: () => PrivateRoute,
} as any).lazy(() =>
  import('./routes/_private/upcoming/route.lazy').then((d) => d.Route),
)

const PrivateSubscriptionUnavailableRouteLazyRoute =
  PrivateSubscriptionUnavailableRouteLazyImport.update({
    id: '/subscription-unavailable',
    path: '/subscription-unavailable',
    getParentRoute: () => PrivateRoute,
  } as any).lazy(() =>
    import('./routes/_private/subscription-unavailable/route.lazy').then(
      (d) => d.Route,
    ),
  )

const PrivateHomeRoute = PrivateHomeImport.update({
  id: '/_home',
  getParentRoute: () => PrivateRoute,
} as any)

const PublicTermsServiceRouteRoute = PublicTermsServiceRouteImport.update({
  id: '/terms-service',
  path: '/terms-service',
  getParentRoute: () => PublicRoute,
} as any).lazy(() =>
  import('./routes/_public/terms-service/route.lazy').then((d) => d.Route),
)

const PublicSmsTermsAndConditionsRouteRoute =
  PublicSmsTermsAndConditionsRouteImport.update({
    id: '/sms-terms-and-conditions',
    path: '/sms-terms-and-conditions',
    getParentRoute: () => PublicRoute,
  } as any).lazy(() =>
    import('./routes/_public/sms-terms-and-conditions/route.lazy').then(
      (d) => d.Route,
    ),
  )

const PublicPrivacyPolicyRouteRoute = PublicPrivacyPolicyRouteImport.update({
  id: '/privacy-policy',
  path: '/privacy-policy',
  getParentRoute: () => PublicRoute,
} as any).lazy(() =>
  import('./routes/_public/privacy-policy/route.lazy').then((d) => d.Route),
)

const PublicPrivacyChoicesRouteRoute = PublicPrivacyChoicesRouteImport.update({
  id: '/privacy-choices',
  path: '/privacy-choices',
  getParentRoute: () => PublicRoute,
} as any).lazy(() =>
  import('./routes/_public/privacy-choices/route.lazy').then((d) => d.Route),
)

const PublicLoginRouteRoute = PublicLoginRouteImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => PublicRoute,
} as any).lazy(() =>
  import('./routes/_public/login/route.lazy').then((d) => d.Route),
)

const PublicLaunchRouteRoute = PublicLaunchRouteImport.update({
  id: '/launch',
  path: '/launch',
  getParentRoute: () => PublicRoute,
} as any).lazy(() =>
  import('./routes/_public/launch/route.lazy').then((d) => d.Route),
)

const PublicHelpRouteRoute = PublicHelpRouteImport.update({
  id: '/help',
  path: '/help',
  getParentRoute: () => PublicRoute,
} as any).lazy(() =>
  import('./routes/_public/help/route.lazy').then((d) => d.Route),
)

const PublicDeleteRouteRoute = PublicDeleteRouteImport.update({
  id: '/delete',
  path: '/delete',
  getParentRoute: () => PublicRoute,
} as any).lazy(() =>
  import('./routes/_public/delete/route.lazy').then((d) => d.Route),
)

const PublicCreateProfileRouteRoute = PublicCreateProfileRouteImport.update({
  id: '/create-profile',
  path: '/create-profile',
  getParentRoute: () => PublicRoute,
} as any).lazy(() =>
  import('./routes/_public/create-profile/route.lazy').then((d) => d.Route),
)

const PublicChangePasswordRouteRoute = PublicChangePasswordRouteImport.update({
  id: '/change-password',
  path: '/change-password',
  getParentRoute: () => PublicRoute,
} as any).lazy(() =>
  import('./routes/_public/change-password/route.lazy').then((d) => d.Route),
)

const PrivateWaysToWatchRouteRoute = PrivateWaysToWatchRouteImport.update({
  id: '/ways-to-watch',
  path: '/ways-to-watch',
  getParentRoute: () => PrivateRoute,
} as any).lazy(() =>
  import('./routes/_private/ways-to-watch/route.lazy').then((d) => d.Route),
)

const PrivateUpdateProfileRouteRoute = PrivateUpdateProfileRouteImport.update({
  id: '/update-profile',
  path: '/update-profile',
  getParentRoute: () => PrivateRoute,
} as any).lazy(() =>
  import('./routes/_private/update-profile/route.lazy').then((d) => d.Route),
)

const PrivateProductsRouteRoute = PrivateProductsRouteImport.update({
  id: '/products',
  path: '/products',
  getParentRoute: () => PrivateRoute,
} as any).lazy(() =>
  import('./routes/_private/products/route.lazy').then((d) => d.Route),
)

const PrivateLogoutRouteRoute = PrivateLogoutRouteImport.update({
  id: '/logout',
  path: '/logout',
  getParentRoute: () => PrivateRoute,
} as any).lazy(() =>
  import('./routes/_private/logout/route.lazy').then((d) => d.Route),
)

const PrivateFirstTimeRouteRoute = PrivateFirstTimeRouteImport.update({
  id: '/first-time',
  path: '/first-time',
  getParentRoute: () => PrivateRoute,
} as any).lazy(() =>
  import('./routes/_private/first-time/route.lazy').then((d) => d.Route),
)

const PrivateConnectRouteRoute = PrivateConnectRouteImport.update({
  id: '/connect',
  path: '/connect',
  getParentRoute: () => PrivateRoute,
} as any).lazy(() =>
  import('./routes/_private/connect/route.lazy').then((d) => d.Route),
)

const PrivateHomeAccountRoute = PrivateHomeAccountImport.update({
  id: '/account',
  path: '/account',
  getParentRoute: () => PrivateHomeRoute,
} as any)

const PrivateHomeIndexRoute = PrivateHomeIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => PrivateHomeRoute,
} as any).lazy(() =>
  import('./routes/_private/_home/index.lazy').then((d) => d.Route),
)

const PrivateHomeSearchRouteLazyRoute = PrivateHomeSearchRouteLazyImport.update(
  {
    id: '/search',
    path: '/search',
    getParentRoute: () => PrivateHomeRoute,
  } as any,
).lazy(() =>
  import('./routes/_private/_home/search/route.lazy').then((d) => d.Route),
)

const PublicBetsOddsRoute = PublicBetsOddsImport.update({
  id: '/bets_/odds',
  path: '/bets/odds',
  getParentRoute: () => PublicRoute,
} as any).lazy(() =>
  import('./routes/_public/bets_/odds.lazy').then((d) => d.Route),
)

const PublicAuthenticateStatusRoute = PublicAuthenticateStatusImport.update({
  id: '/authenticate/status',
  path: '/authenticate/status',
  getParentRoute: () => PublicRoute,
} as any)

const PrivatePlaybackVideoIdRoute = PrivatePlaybackVideoIdImport.update({
  id: '/playback/$videoId',
  path: '/playback/$videoId',
  getParentRoute: () => PrivateRoute,
} as any).lazy(() =>
  import('./routes/_private/playback/$videoId.lazy').then((d) => d.Route),
)

const PrivateFavoriteTeamsRoute = PrivateFavoriteTeamsImport.update({
  id: '/favorite/teams',
  path: '/favorite/teams',
  getParentRoute: () => PrivateRoute,
} as any).lazy(() =>
  import('./routes/_private/favorite/teams.lazy').then((d) => d.Route),
)

const PrivateFavoritePlayersRoute = PrivateFavoritePlayersImport.update({
  id: '/favorite/players',
  path: '/favorite/players',
  getParentRoute: () => PrivateRoute,
} as any).lazy(() =>
  import('./routes/_private/favorite/players.lazy').then((d) => d.Route),
)

const PrivateContentRailIdRoute = PrivateContentRailIdImport.update({
  id: '/content/$railId',
  path: '/content/$railId',
  getParentRoute: () => PrivateRoute,
} as any).lazy(() =>
  import('./routes/_private/content/$railId.lazy').then((d) => d.Route),
)

const deeplinkPageSplatRoute = deeplinkPageSplatImport.update({
  id: '/(deeplink)/page/$',
  path: '/page/$',
  getParentRoute: () => rootRoute,
} as any)

const PrivateProductsSuccessfulRouteRoute =
  PrivateProductsSuccessfulRouteImport.update({
    id: '/products_/successful',
    path: '/products/successful',
    getParentRoute: () => PrivateRoute,
  } as any)

const PrivateHomeOddsRouteRoute = PrivateHomeOddsRouteImport.update({
  id: '/odds',
  path: '/odds',
  getParentRoute: () => PrivateHomeRoute,
} as any).lazy(() =>
  import('./routes/_private/_home/odds/route.lazy').then((d) => d.Route),
)

const PrivateHomeAccountIndexRoute = PrivateHomeAccountIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => PrivateHomeAccountRoute,
} as any).lazy(() =>
  import('./routes/_private/_home/account/index.lazy').then((d) => d.Route),
)

const PrivateHomeAccountSupportRouteLazyRoute =
  PrivateHomeAccountSupportRouteLazyImport.update({
    id: '/support',
    path: '/support',
    getParentRoute: () => PrivateHomeAccountRoute,
  } as any).lazy(() =>
    import('./routes/_private/_home/account/support/route.lazy').then(
      (d) => d.Route,
    ),
  )

const PrivateHomeAccountProfileRouteLazyRoute =
  PrivateHomeAccountProfileRouteLazyImport.update({
    id: '/profile',
    path: '/profile',
    getParentRoute: () => PrivateHomeAccountRoute,
  } as any).lazy(() =>
    import('./routes/_private/_home/account/profile/route.lazy').then(
      (d) => d.Route,
    ),
  )

const PrivateHomeScheduleTypeRoute = PrivateHomeScheduleTypeImport.update({
  id: '/schedule/$type',
  path: '/schedule/$type',
  getParentRoute: () => PrivateHomeRoute,
} as any).lazy(() =>
  import('./routes/_private/_home/schedule/$type.lazy').then((d) => d.Route),
)

const PrivateHomeAccountSubscriptionRoute =
  PrivateHomeAccountSubscriptionImport.update({
    id: '/_subscription',
    getParentRoute: () => PrivateHomeAccountRoute,
  } as any)

const PrivateHomeAccountFavoritesRoute =
  PrivateHomeAccountFavoritesImport.update({
    id: '/_favorites',
    getParentRoute: () => PrivateHomeAccountRoute,
  } as any)

const PrivateHomeAccountReferralRouteRoute =
  PrivateHomeAccountReferralRouteImport.update({
    id: '/account/referral',
    path: '/account/referral',
    getParentRoute: () => PrivateHomeRoute,
  } as any).lazy(() =>
    import('./routes/_private/_home/account/referral/route.lazy').then(
      (d) => d.Route,
    ),
  )

const PrivateHomeAccountManageTvRouteRoute =
  PrivateHomeAccountManageTvRouteImport.update({
    id: '/account/manage-tv',
    path: '/account/manage-tv',
    getParentRoute: () => PrivateHomeRoute,
  } as any).lazy(() =>
    import('./routes/_private/_home/account/manage-tv/route.lazy').then(
      (d) => d.Route,
    ),
  )

const PrivateHomeAccountHelpRouteRoute =
  PrivateHomeAccountHelpRouteImport.update({
    id: '/account/help',
    path: '/account/help',
    getParentRoute: () => PrivateHomeRoute,
  } as any).lazy(() =>
    import('./routes/_private/_home/account/help/route.lazy').then(
      (d) => d.Route,
    ),
  )

const PrivateHomeAccountDeleteRouteRoute =
  PrivateHomeAccountDeleteRouteImport.update({
    id: '/account/delete',
    path: '/account/delete',
    getParentRoute: () => PrivateHomeRoute,
  } as any).lazy(() =>
    import('./routes/_private/_home/account/delete/route.lazy').then(
      (d) => d.Route,
    ),
  )

const PrivateHomeAccountDebugPanelRouteRoute =
  PrivateHomeAccountDebugPanelRouteImport.update({
    id: '/account/debug-panel',
    path: '/account/debug-panel',
    getParentRoute: () => PrivateHomeRoute,
  } as any).lazy(() =>
    import('./routes/_private/_home/account/debug-panel/route.lazy').then(
      (d) => d.Route,
    ),
  )

const PrivateHomeAccountChangePlanRouteRoute =
  PrivateHomeAccountChangePlanRouteImport.update({
    id: '/account/change-plan',
    path: '/account/change-plan',
    getParentRoute: () => PrivateHomeRoute,
  } as any).lazy(() =>
    import('./routes/_private/_home/account/change-plan/route.lazy').then(
      (d) => d.Route,
    ),
  )

const PrivateHomeAccountCancelSubscriptionRouteRoute =
  PrivateHomeAccountCancelSubscriptionRouteImport.update({
    id: '/account/cancel-subscription',
    path: '/account/cancel-subscription',
    getParentRoute: () => PrivateHomeRoute,
  } as any).lazy(() =>
    import(
      './routes/_private/_home/account/cancel-subscription/route.lazy'
    ).then((d) => d.Route),
  )

const PublicAuthenticateRegCodePlatformActivationCodeRoute =
  PublicAuthenticateRegCodePlatformActivationCodeImport.update({
    id: '/authenticate/$regCode/$platform/$activationCode',
    path: '/authenticate/$regCode/$platform/$activationCode',
    getParentRoute: () => PublicRoute,
  } as any)

const PrivateHomeAccountFavoritesFavoritesRoute =
  PrivateHomeAccountFavoritesFavoritesImport.update({
    id: '/favorites',
    path: '/favorites',
    getParentRoute: () => PrivateHomeAccountFavoritesRoute,
  } as any).lazy(() =>
    import('./routes/_private/_home/account/_favorites/favorites.lazy').then(
      (d) => d.Route,
    ),
  )

const PrivateHomeAccountSubscriptionSubscriptionIndexLazyRoute =
  PrivateHomeAccountSubscriptionSubscriptionIndexLazyImport.update({
    id: '/subscription/',
    path: '/subscription/',
    getParentRoute: () => PrivateHomeAccountSubscriptionRoute,
  } as any).lazy(() =>
    import(
      './routes/_private/_home/account/_subscription.subscription/index.lazy'
    ).then((d) => d.Route),
  )

const PrivateHomeAccountFavoritesEditTeamsLazyRoute =
  PrivateHomeAccountFavoritesEditTeamsLazyImport.update({
    id: '/edit/teams',
    path: '/edit/teams',
    getParentRoute: () => PrivateHomeAccountFavoritesRoute,
  } as any).lazy(() =>
    import('./routes/_private/_home/account/_favorites/edit/teams.lazy').then(
      (d) => d.Route,
    ),
  )

const PrivateHomeAccountSubscriptionSubscriptionPaymentRoute =
  PrivateHomeAccountSubscriptionSubscriptionPaymentImport.update({
    id: '/subscription/payment',
    path: '/subscription/payment',
    getParentRoute: () => PrivateHomeAccountSubscriptionRoute,
  } as any).lazy(() =>
    import(
      './routes/_private/_home/account/_subscription.subscription/payment.lazy'
    ).then((d) => d.Route),
  )

const PrivateHomeAccountSubscriptionSubscriptionHistoryRoute =
  PrivateHomeAccountSubscriptionSubscriptionHistoryImport.update({
    id: '/subscription/history',
    path: '/subscription/history',
    getParentRoute: () => PrivateHomeAccountSubscriptionRoute,
  } as any)

const PrivateHomeAccountFavoritesEditPlayersRoute =
  PrivateHomeAccountFavoritesEditPlayersImport.update({
    id: '/edit/players',
    path: '/edit/players',
    getParentRoute: () => PrivateHomeAccountFavoritesRoute,
  } as any).lazy(() =>
    import('./routes/_private/_home/account/_favorites/edit/players.lazy').then(
      (d) => d.Route,
    ),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_private': {
      id: '/_private'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof PrivateImport
      parentRoute: typeof rootRoute
    }
    '/_public': {
      id: '/_public'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof PublicImport
      parentRoute: typeof rootRoute
    }
    '/_private/connect': {
      id: '/_private/connect'
      path: '/connect'
      fullPath: '/connect'
      preLoaderRoute: typeof PrivateConnectRouteImport
      parentRoute: typeof PrivateImport
    }
    '/_private/first-time': {
      id: '/_private/first-time'
      path: '/first-time'
      fullPath: '/first-time'
      preLoaderRoute: typeof PrivateFirstTimeRouteImport
      parentRoute: typeof PrivateImport
    }
    '/_private/logout': {
      id: '/_private/logout'
      path: '/logout'
      fullPath: '/logout'
      preLoaderRoute: typeof PrivateLogoutRouteImport
      parentRoute: typeof PrivateImport
    }
    '/_private/products': {
      id: '/_private/products'
      path: '/products'
      fullPath: '/products'
      preLoaderRoute: typeof PrivateProductsRouteImport
      parentRoute: typeof PrivateImport
    }
    '/_private/update-profile': {
      id: '/_private/update-profile'
      path: '/update-profile'
      fullPath: '/update-profile'
      preLoaderRoute: typeof PrivateUpdateProfileRouteImport
      parentRoute: typeof PrivateImport
    }
    '/_private/ways-to-watch': {
      id: '/_private/ways-to-watch'
      path: '/ways-to-watch'
      fullPath: '/ways-to-watch'
      preLoaderRoute: typeof PrivateWaysToWatchRouteImport
      parentRoute: typeof PrivateImport
    }
    '/_public/change-password': {
      id: '/_public/change-password'
      path: '/change-password'
      fullPath: '/change-password'
      preLoaderRoute: typeof PublicChangePasswordRouteImport
      parentRoute: typeof PublicImport
    }
    '/_public/create-profile': {
      id: '/_public/create-profile'
      path: '/create-profile'
      fullPath: '/create-profile'
      preLoaderRoute: typeof PublicCreateProfileRouteImport
      parentRoute: typeof PublicImport
    }
    '/_public/delete': {
      id: '/_public/delete'
      path: '/delete'
      fullPath: '/delete'
      preLoaderRoute: typeof PublicDeleteRouteImport
      parentRoute: typeof PublicImport
    }
    '/_public/help': {
      id: '/_public/help'
      path: '/help'
      fullPath: '/help'
      preLoaderRoute: typeof PublicHelpRouteImport
      parentRoute: typeof PublicImport
    }
    '/_public/launch': {
      id: '/_public/launch'
      path: '/launch'
      fullPath: '/launch'
      preLoaderRoute: typeof PublicLaunchRouteImport
      parentRoute: typeof PublicImport
    }
    '/_public/login': {
      id: '/_public/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof PublicLoginRouteImport
      parentRoute: typeof PublicImport
    }
    '/_public/privacy-choices': {
      id: '/_public/privacy-choices'
      path: '/privacy-choices'
      fullPath: '/privacy-choices'
      preLoaderRoute: typeof PublicPrivacyChoicesRouteImport
      parentRoute: typeof PublicImport
    }
    '/_public/privacy-policy': {
      id: '/_public/privacy-policy'
      path: '/privacy-policy'
      fullPath: '/privacy-policy'
      preLoaderRoute: typeof PublicPrivacyPolicyRouteImport
      parentRoute: typeof PublicImport
    }
    '/_public/sms-terms-and-conditions': {
      id: '/_public/sms-terms-and-conditions'
      path: '/sms-terms-and-conditions'
      fullPath: '/sms-terms-and-conditions'
      preLoaderRoute: typeof PublicSmsTermsAndConditionsRouteImport
      parentRoute: typeof PublicImport
    }
    '/_public/terms-service': {
      id: '/_public/terms-service'
      path: '/terms-service'
      fullPath: '/terms-service'
      preLoaderRoute: typeof PublicTermsServiceRouteImport
      parentRoute: typeof PublicImport
    }
    '/_private/_home': {
      id: '/_private/_home'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof PrivateHomeImport
      parentRoute: typeof PrivateImport
    }
    '/_private/subscription-unavailable': {
      id: '/_private/subscription-unavailable'
      path: '/subscription-unavailable'
      fullPath: '/subscription-unavailable'
      preLoaderRoute: typeof PrivateSubscriptionUnavailableRouteLazyImport
      parentRoute: typeof PrivateImport
    }
    '/_private/upcoming': {
      id: '/_private/upcoming'
      path: '/upcoming'
      fullPath: '/upcoming'
      preLoaderRoute: typeof PrivateUpcomingRouteLazyImport
      parentRoute: typeof PrivateImport
    }
    '/_public/forgot-password': {
      id: '/_public/forgot-password'
      path: '/forgot-password'
      fullPath: '/forgot-password'
      preLoaderRoute: typeof PublicForgotPasswordRouteLazyImport
      parentRoute: typeof PublicImport
    }
    '/_public/geo-block': {
      id: '/_public/geo-block'
      path: '/geo-block'
      fullPath: '/geo-block'
      preLoaderRoute: typeof PublicGeoBlockRouteLazyImport
      parentRoute: typeof PublicImport
    }
    '/_public/support': {
      id: '/_public/support'
      path: '/support'
      fullPath: '/support'
      preLoaderRoute: typeof PublicSupportRouteLazyImport
      parentRoute: typeof PublicImport
    }
    '/_private/_home/odds': {
      id: '/_private/_home/odds'
      path: '/odds'
      fullPath: '/odds'
      preLoaderRoute: typeof PrivateHomeOddsRouteImport
      parentRoute: typeof PrivateHomeImport
    }
    '/_private/products_/successful': {
      id: '/_private/products_/successful'
      path: '/products/successful'
      fullPath: '/products/successful'
      preLoaderRoute: typeof PrivateProductsSuccessfulRouteImport
      parentRoute: typeof PrivateImport
    }
    '/(deeplink)/page/$': {
      id: '/(deeplink)/page/$'
      path: '/page/$'
      fullPath: '/page/$'
      preLoaderRoute: typeof deeplinkPageSplatImport
      parentRoute: typeof rootRoute
    }
    '/_private/content/$railId': {
      id: '/_private/content/$railId'
      path: '/content/$railId'
      fullPath: '/content/$railId'
      preLoaderRoute: typeof PrivateContentRailIdImport
      parentRoute: typeof PrivateImport
    }
    '/_private/favorite/players': {
      id: '/_private/favorite/players'
      path: '/favorite/players'
      fullPath: '/favorite/players'
      preLoaderRoute: typeof PrivateFavoritePlayersImport
      parentRoute: typeof PrivateImport
    }
    '/_private/favorite/teams': {
      id: '/_private/favorite/teams'
      path: '/favorite/teams'
      fullPath: '/favorite/teams'
      preLoaderRoute: typeof PrivateFavoriteTeamsImport
      parentRoute: typeof PrivateImport
    }
    '/_private/playback/$videoId': {
      id: '/_private/playback/$videoId'
      path: '/playback/$videoId'
      fullPath: '/playback/$videoId'
      preLoaderRoute: typeof PrivatePlaybackVideoIdImport
      parentRoute: typeof PrivateImport
    }
    '/_public/authenticate/status': {
      id: '/_public/authenticate/status'
      path: '/authenticate/status'
      fullPath: '/authenticate/status'
      preLoaderRoute: typeof PublicAuthenticateStatusImport
      parentRoute: typeof PublicImport
    }
    '/_public/bets_/odds': {
      id: '/_public/bets_/odds'
      path: '/bets/odds'
      fullPath: '/bets/odds'
      preLoaderRoute: typeof PublicBetsOddsImport
      parentRoute: typeof PublicImport
    }
    '/_private/_home/search': {
      id: '/_private/_home/search'
      path: '/search'
      fullPath: '/search'
      preLoaderRoute: typeof PrivateHomeSearchRouteLazyImport
      parentRoute: typeof PrivateHomeImport
    }
    '/_private/_home/': {
      id: '/_private/_home/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof PrivateHomeIndexImport
      parentRoute: typeof PrivateHomeImport
    }
    '/_private/_home/account/cancel-subscription': {
      id: '/_private/_home/account/cancel-subscription'
      path: '/account/cancel-subscription'
      fullPath: '/account/cancel-subscription'
      preLoaderRoute: typeof PrivateHomeAccountCancelSubscriptionRouteImport
      parentRoute: typeof PrivateHomeImport
    }
    '/_private/_home/account/change-plan': {
      id: '/_private/_home/account/change-plan'
      path: '/account/change-plan'
      fullPath: '/account/change-plan'
      preLoaderRoute: typeof PrivateHomeAccountChangePlanRouteImport
      parentRoute: typeof PrivateHomeImport
    }
    '/_private/_home/account/debug-panel': {
      id: '/_private/_home/account/debug-panel'
      path: '/account/debug-panel'
      fullPath: '/account/debug-panel'
      preLoaderRoute: typeof PrivateHomeAccountDebugPanelRouteImport
      parentRoute: typeof PrivateHomeImport
    }
    '/_private/_home/account/delete': {
      id: '/_private/_home/account/delete'
      path: '/account/delete'
      fullPath: '/account/delete'
      preLoaderRoute: typeof PrivateHomeAccountDeleteRouteImport
      parentRoute: typeof PrivateHomeImport
    }
    '/_private/_home/account/help': {
      id: '/_private/_home/account/help'
      path: '/account/help'
      fullPath: '/account/help'
      preLoaderRoute: typeof PrivateHomeAccountHelpRouteImport
      parentRoute: typeof PrivateHomeImport
    }
    '/_private/_home/account/manage-tv': {
      id: '/_private/_home/account/manage-tv'
      path: '/account/manage-tv'
      fullPath: '/account/manage-tv'
      preLoaderRoute: typeof PrivateHomeAccountManageTvRouteImport
      parentRoute: typeof PrivateHomeImport
    }
    '/_private/_home/account/referral': {
      id: '/_private/_home/account/referral'
      path: '/account/referral'
      fullPath: '/account/referral'
      preLoaderRoute: typeof PrivateHomeAccountReferralRouteImport
      parentRoute: typeof PrivateHomeImport
    }
    '/_private/_home/account': {
      id: '/_private/_home/account'
      path: '/account'
      fullPath: '/account'
      preLoaderRoute: typeof PrivateHomeAccountImport
      parentRoute: typeof PrivateHomeImport
    }
    '/_private/_home/account/_favorites': {
      id: '/_private/_home/account/_favorites'
      path: '/account'
      fullPath: '/account'
      preLoaderRoute: typeof PrivateHomeAccountFavoritesImport
      parentRoute: typeof PrivateHomeAccountRoute
    }
    '/_private/_home/account/_subscription': {
      id: '/_private/_home/account/_subscription'
      path: ''
      fullPath: '/account'
      preLoaderRoute: typeof PrivateHomeAccountSubscriptionImport
      parentRoute: typeof PrivateHomeAccountImport
    }
    '/_private/_home/schedule/$type': {
      id: '/_private/_home/schedule/$type'
      path: '/schedule/$type'
      fullPath: '/schedule/$type'
      preLoaderRoute: typeof PrivateHomeScheduleTypeImport
      parentRoute: typeof PrivateHomeImport
    }
    '/_private/_home/account/profile': {
      id: '/_private/_home/account/profile'
      path: '/profile'
      fullPath: '/account/profile'
      preLoaderRoute: typeof PrivateHomeAccountProfileRouteLazyImport
      parentRoute: typeof PrivateHomeAccountImport
    }
    '/_private/_home/account/support': {
      id: '/_private/_home/account/support'
      path: '/support'
      fullPath: '/account/support'
      preLoaderRoute: typeof PrivateHomeAccountSupportRouteLazyImport
      parentRoute: typeof PrivateHomeAccountImport
    }
    '/_private/_home/account/': {
      id: '/_private/_home/account/'
      path: '/'
      fullPath: '/account/'
      preLoaderRoute: typeof PrivateHomeAccountIndexImport
      parentRoute: typeof PrivateHomeAccountImport
    }
    '/_private/_home/account/_favorites/favorites': {
      id: '/_private/_home/account/_favorites/favorites'
      path: '/favorites'
      fullPath: '/account/favorites'
      preLoaderRoute: typeof PrivateHomeAccountFavoritesFavoritesImport
      parentRoute: typeof PrivateHomeAccountFavoritesImport
    }
    '/_public/authenticate/$regCode/$platform/$activationCode': {
      id: '/_public/authenticate/$regCode/$platform/$activationCode'
      path: '/authenticate/$regCode/$platform/$activationCode'
      fullPath: '/authenticate/$regCode/$platform/$activationCode'
      preLoaderRoute: typeof PublicAuthenticateRegCodePlatformActivationCodeImport
      parentRoute: typeof PublicImport
    }
    '/_private/_home/account/_favorites/edit/players': {
      id: '/_private/_home/account/_favorites/edit/players'
      path: '/edit/players'
      fullPath: '/account/edit/players'
      preLoaderRoute: typeof PrivateHomeAccountFavoritesEditPlayersImport
      parentRoute: typeof PrivateHomeAccountFavoritesImport
    }
    '/_private/_home/account/_subscription/subscription/history': {
      id: '/_private/_home/account/_subscription/subscription/history'
      path: '/subscription/history'
      fullPath: '/account/subscription/history'
      preLoaderRoute: typeof PrivateHomeAccountSubscriptionSubscriptionHistoryImport
      parentRoute: typeof PrivateHomeAccountSubscriptionImport
    }
    '/_private/_home/account/_subscription/subscription/payment': {
      id: '/_private/_home/account/_subscription/subscription/payment'
      path: '/subscription/payment'
      fullPath: '/account/subscription/payment'
      preLoaderRoute: typeof PrivateHomeAccountSubscriptionSubscriptionPaymentImport
      parentRoute: typeof PrivateHomeAccountSubscriptionImport
    }
    '/_private/_home/account/_favorites/edit/teams': {
      id: '/_private/_home/account/_favorites/edit/teams'
      path: '/edit/teams'
      fullPath: '/account/edit/teams'
      preLoaderRoute: typeof PrivateHomeAccountFavoritesEditTeamsLazyImport
      parentRoute: typeof PrivateHomeAccountFavoritesImport
    }
    '/_private/_home/account/_subscription/subscription/': {
      id: '/_private/_home/account/_subscription/subscription/'
      path: '/subscription'
      fullPath: '/account/subscription'
      preLoaderRoute: typeof PrivateHomeAccountSubscriptionSubscriptionIndexLazyImport
      parentRoute: typeof PrivateHomeAccountSubscriptionImport
    }
  }
}

// Create and export the route tree

interface PrivateHomeAccountFavoritesRouteChildren {
  PrivateHomeAccountFavoritesFavoritesRoute: typeof PrivateHomeAccountFavoritesFavoritesRoute
  PrivateHomeAccountFavoritesEditPlayersRoute: typeof PrivateHomeAccountFavoritesEditPlayersRoute
  PrivateHomeAccountFavoritesEditTeamsLazyRoute: typeof PrivateHomeAccountFavoritesEditTeamsLazyRoute
}

const PrivateHomeAccountFavoritesRouteChildren: PrivateHomeAccountFavoritesRouteChildren =
  {
    PrivateHomeAccountFavoritesFavoritesRoute:
      PrivateHomeAccountFavoritesFavoritesRoute,
    PrivateHomeAccountFavoritesEditPlayersRoute:
      PrivateHomeAccountFavoritesEditPlayersRoute,
    PrivateHomeAccountFavoritesEditTeamsLazyRoute:
      PrivateHomeAccountFavoritesEditTeamsLazyRoute,
  }

const PrivateHomeAccountFavoritesRouteWithChildren =
  PrivateHomeAccountFavoritesRoute._addFileChildren(
    PrivateHomeAccountFavoritesRouteChildren,
  )

interface PrivateHomeAccountSubscriptionRouteChildren {
  PrivateHomeAccountSubscriptionSubscriptionHistoryRoute: typeof PrivateHomeAccountSubscriptionSubscriptionHistoryRoute
  PrivateHomeAccountSubscriptionSubscriptionPaymentRoute: typeof PrivateHomeAccountSubscriptionSubscriptionPaymentRoute
  PrivateHomeAccountSubscriptionSubscriptionIndexLazyRoute: typeof PrivateHomeAccountSubscriptionSubscriptionIndexLazyRoute
}

const PrivateHomeAccountSubscriptionRouteChildren: PrivateHomeAccountSubscriptionRouteChildren =
  {
    PrivateHomeAccountSubscriptionSubscriptionHistoryRoute:
      PrivateHomeAccountSubscriptionSubscriptionHistoryRoute,
    PrivateHomeAccountSubscriptionSubscriptionPaymentRoute:
      PrivateHomeAccountSubscriptionSubscriptionPaymentRoute,
    PrivateHomeAccountSubscriptionSubscriptionIndexLazyRoute:
      PrivateHomeAccountSubscriptionSubscriptionIndexLazyRoute,
  }

const PrivateHomeAccountSubscriptionRouteWithChildren =
  PrivateHomeAccountSubscriptionRoute._addFileChildren(
    PrivateHomeAccountSubscriptionRouteChildren,
  )

interface PrivateHomeAccountRouteChildren {
  PrivateHomeAccountFavoritesRoute: typeof PrivateHomeAccountFavoritesRouteWithChildren
  PrivateHomeAccountSubscriptionRoute: typeof PrivateHomeAccountSubscriptionRouteWithChildren
  PrivateHomeAccountProfileRouteLazyRoute: typeof PrivateHomeAccountProfileRouteLazyRoute
  PrivateHomeAccountSupportRouteLazyRoute: typeof PrivateHomeAccountSupportRouteLazyRoute
  PrivateHomeAccountIndexRoute: typeof PrivateHomeAccountIndexRoute
}

const PrivateHomeAccountRouteChildren: PrivateHomeAccountRouteChildren = {
  PrivateHomeAccountFavoritesRoute:
    PrivateHomeAccountFavoritesRouteWithChildren,
  PrivateHomeAccountSubscriptionRoute:
    PrivateHomeAccountSubscriptionRouteWithChildren,
  PrivateHomeAccountProfileRouteLazyRoute:
    PrivateHomeAccountProfileRouteLazyRoute,
  PrivateHomeAccountSupportRouteLazyRoute:
    PrivateHomeAccountSupportRouteLazyRoute,
  PrivateHomeAccountIndexRoute: PrivateHomeAccountIndexRoute,
}

const PrivateHomeAccountRouteWithChildren =
  PrivateHomeAccountRoute._addFileChildren(PrivateHomeAccountRouteChildren)

interface PrivateHomeRouteChildren {
  PrivateHomeOddsRouteRoute: typeof PrivateHomeOddsRouteRoute
  PrivateHomeSearchRouteLazyRoute: typeof PrivateHomeSearchRouteLazyRoute
  PrivateHomeIndexRoute: typeof PrivateHomeIndexRoute
  PrivateHomeAccountCancelSubscriptionRouteRoute: typeof PrivateHomeAccountCancelSubscriptionRouteRoute
  PrivateHomeAccountChangePlanRouteRoute: typeof PrivateHomeAccountChangePlanRouteRoute
  PrivateHomeAccountDebugPanelRouteRoute: typeof PrivateHomeAccountDebugPanelRouteRoute
  PrivateHomeAccountDeleteRouteRoute: typeof PrivateHomeAccountDeleteRouteRoute
  PrivateHomeAccountHelpRouteRoute: typeof PrivateHomeAccountHelpRouteRoute
  PrivateHomeAccountManageTvRouteRoute: typeof PrivateHomeAccountManageTvRouteRoute
  PrivateHomeAccountReferralRouteRoute: typeof PrivateHomeAccountReferralRouteRoute
  PrivateHomeAccountRoute: typeof PrivateHomeAccountRouteWithChildren
  PrivateHomeScheduleTypeRoute: typeof PrivateHomeScheduleTypeRoute
}

const PrivateHomeRouteChildren: PrivateHomeRouteChildren = {
  PrivateHomeOddsRouteRoute: PrivateHomeOddsRouteRoute,
  PrivateHomeSearchRouteLazyRoute: PrivateHomeSearchRouteLazyRoute,
  PrivateHomeIndexRoute: PrivateHomeIndexRoute,
  PrivateHomeAccountCancelSubscriptionRouteRoute:
    PrivateHomeAccountCancelSubscriptionRouteRoute,
  PrivateHomeAccountChangePlanRouteRoute:
    PrivateHomeAccountChangePlanRouteRoute,
  PrivateHomeAccountDebugPanelRouteRoute:
    PrivateHomeAccountDebugPanelRouteRoute,
  PrivateHomeAccountDeleteRouteRoute: PrivateHomeAccountDeleteRouteRoute,
  PrivateHomeAccountHelpRouteRoute: PrivateHomeAccountHelpRouteRoute,
  PrivateHomeAccountManageTvRouteRoute: PrivateHomeAccountManageTvRouteRoute,
  PrivateHomeAccountReferralRouteRoute: PrivateHomeAccountReferralRouteRoute,
  PrivateHomeAccountRoute: PrivateHomeAccountRouteWithChildren,
  PrivateHomeScheduleTypeRoute: PrivateHomeScheduleTypeRoute,
}

const PrivateHomeRouteWithChildren = PrivateHomeRoute._addFileChildren(
  PrivateHomeRouteChildren,
)

interface PrivateRouteChildren {
  PrivateConnectRouteRoute: typeof PrivateConnectRouteRoute
  PrivateFirstTimeRouteRoute: typeof PrivateFirstTimeRouteRoute
  PrivateLogoutRouteRoute: typeof PrivateLogoutRouteRoute
  PrivateProductsRouteRoute: typeof PrivateProductsRouteRoute
  PrivateUpdateProfileRouteRoute: typeof PrivateUpdateProfileRouteRoute
  PrivateWaysToWatchRouteRoute: typeof PrivateWaysToWatchRouteRoute
  PrivateHomeRoute: typeof PrivateHomeRouteWithChildren
  PrivateSubscriptionUnavailableRouteLazyRoute: typeof PrivateSubscriptionUnavailableRouteLazyRoute
  PrivateUpcomingRouteLazyRoute: typeof PrivateUpcomingRouteLazyRoute
  PrivateProductsSuccessfulRouteRoute: typeof PrivateProductsSuccessfulRouteRoute
  PrivateContentRailIdRoute: typeof PrivateContentRailIdRoute
  PrivateFavoritePlayersRoute: typeof PrivateFavoritePlayersRoute
  PrivateFavoriteTeamsRoute: typeof PrivateFavoriteTeamsRoute
  PrivatePlaybackVideoIdRoute: typeof PrivatePlaybackVideoIdRoute
}

const PrivateRouteChildren: PrivateRouteChildren = {
  PrivateConnectRouteRoute: PrivateConnectRouteRoute,
  PrivateFirstTimeRouteRoute: PrivateFirstTimeRouteRoute,
  PrivateLogoutRouteRoute: PrivateLogoutRouteRoute,
  PrivateProductsRouteRoute: PrivateProductsRouteRoute,
  PrivateUpdateProfileRouteRoute: PrivateUpdateProfileRouteRoute,
  PrivateWaysToWatchRouteRoute: PrivateWaysToWatchRouteRoute,
  PrivateHomeRoute: PrivateHomeRouteWithChildren,
  PrivateSubscriptionUnavailableRouteLazyRoute:
    PrivateSubscriptionUnavailableRouteLazyRoute,
  PrivateUpcomingRouteLazyRoute: PrivateUpcomingRouteLazyRoute,
  PrivateProductsSuccessfulRouteRoute: PrivateProductsSuccessfulRouteRoute,
  PrivateContentRailIdRoute: PrivateContentRailIdRoute,
  PrivateFavoritePlayersRoute: PrivateFavoritePlayersRoute,
  PrivateFavoriteTeamsRoute: PrivateFavoriteTeamsRoute,
  PrivatePlaybackVideoIdRoute: PrivatePlaybackVideoIdRoute,
}

const PrivateRouteWithChildren =
  PrivateRoute._addFileChildren(PrivateRouteChildren)

interface PublicRouteChildren {
  PublicChangePasswordRouteRoute: typeof PublicChangePasswordRouteRoute
  PublicCreateProfileRouteRoute: typeof PublicCreateProfileRouteRoute
  PublicDeleteRouteRoute: typeof PublicDeleteRouteRoute
  PublicHelpRouteRoute: typeof PublicHelpRouteRoute
  PublicLaunchRouteRoute: typeof PublicLaunchRouteRoute
  PublicLoginRouteRoute: typeof PublicLoginRouteRoute
  PublicPrivacyChoicesRouteRoute: typeof PublicPrivacyChoicesRouteRoute
  PublicPrivacyPolicyRouteRoute: typeof PublicPrivacyPolicyRouteRoute
  PublicSmsTermsAndConditionsRouteRoute: typeof PublicSmsTermsAndConditionsRouteRoute
  PublicTermsServiceRouteRoute: typeof PublicTermsServiceRouteRoute
  PublicForgotPasswordRouteLazyRoute: typeof PublicForgotPasswordRouteLazyRoute
  PublicGeoBlockRouteLazyRoute: typeof PublicGeoBlockRouteLazyRoute
  PublicSupportRouteLazyRoute: typeof PublicSupportRouteLazyRoute
  PublicAuthenticateStatusRoute: typeof PublicAuthenticateStatusRoute
  PublicBetsOddsRoute: typeof PublicBetsOddsRoute
  PublicAuthenticateRegCodePlatformActivationCodeRoute: typeof PublicAuthenticateRegCodePlatformActivationCodeRoute
}

const PublicRouteChildren: PublicRouteChildren = {
  PublicChangePasswordRouteRoute: PublicChangePasswordRouteRoute,
  PublicCreateProfileRouteRoute: PublicCreateProfileRouteRoute,
  PublicDeleteRouteRoute: PublicDeleteRouteRoute,
  PublicHelpRouteRoute: PublicHelpRouteRoute,
  PublicLaunchRouteRoute: PublicLaunchRouteRoute,
  PublicLoginRouteRoute: PublicLoginRouteRoute,
  PublicPrivacyChoicesRouteRoute: PublicPrivacyChoicesRouteRoute,
  PublicPrivacyPolicyRouteRoute: PublicPrivacyPolicyRouteRoute,
  PublicSmsTermsAndConditionsRouteRoute: PublicSmsTermsAndConditionsRouteRoute,
  PublicTermsServiceRouteRoute: PublicTermsServiceRouteRoute,
  PublicForgotPasswordRouteLazyRoute: PublicForgotPasswordRouteLazyRoute,
  PublicGeoBlockRouteLazyRoute: PublicGeoBlockRouteLazyRoute,
  PublicSupportRouteLazyRoute: PublicSupportRouteLazyRoute,
  PublicAuthenticateStatusRoute: PublicAuthenticateStatusRoute,
  PublicBetsOddsRoute: PublicBetsOddsRoute,
  PublicAuthenticateRegCodePlatformActivationCodeRoute:
    PublicAuthenticateRegCodePlatformActivationCodeRoute,
}

const PublicRouteWithChildren =
  PublicRoute._addFileChildren(PublicRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof PrivateHomeRouteWithChildren
  '/connect': typeof PrivateConnectRouteRoute
  '/first-time': typeof PrivateFirstTimeRouteRoute
  '/logout': typeof PrivateLogoutRouteRoute
  '/products': typeof PrivateProductsRouteRoute
  '/update-profile': typeof PrivateUpdateProfileRouteRoute
  '/ways-to-watch': typeof PrivateWaysToWatchRouteRoute
  '/change-password': typeof PublicChangePasswordRouteRoute
  '/create-profile': typeof PublicCreateProfileRouteRoute
  '/delete': typeof PublicDeleteRouteRoute
  '/help': typeof PublicHelpRouteRoute
  '/launch': typeof PublicLaunchRouteRoute
  '/login': typeof PublicLoginRouteRoute
  '/privacy-choices': typeof PublicPrivacyChoicesRouteRoute
  '/privacy-policy': typeof PublicPrivacyPolicyRouteRoute
  '/sms-terms-and-conditions': typeof PublicSmsTermsAndConditionsRouteRoute
  '/terms-service': typeof PublicTermsServiceRouteRoute
  '/subscription-unavailable': typeof PrivateSubscriptionUnavailableRouteLazyRoute
  '/upcoming': typeof PrivateUpcomingRouteLazyRoute
  '/forgot-password': typeof PublicForgotPasswordRouteLazyRoute
  '/geo-block': typeof PublicGeoBlockRouteLazyRoute
  '/support': typeof PublicSupportRouteLazyRoute
  '/odds': typeof PrivateHomeOddsRouteRoute
  '/products/successful': typeof PrivateProductsSuccessfulRouteRoute
  '/page/$': typeof deeplinkPageSplatRoute
  '/content/$railId': typeof PrivateContentRailIdRoute
  '/favorite/players': typeof PrivateFavoritePlayersRoute
  '/favorite/teams': typeof PrivateFavoriteTeamsRoute
  '/playback/$videoId': typeof PrivatePlaybackVideoIdRoute
  '/authenticate/status': typeof PublicAuthenticateStatusRoute
  '/bets/odds': typeof PublicBetsOddsRoute
  '/search': typeof PrivateHomeSearchRouteLazyRoute
  '/': typeof PrivateHomeIndexRoute
  '/account/cancel-subscription': typeof PrivateHomeAccountCancelSubscriptionRouteRoute
  '/account/change-plan': typeof PrivateHomeAccountChangePlanRouteRoute
  '/account/debug-panel': typeof PrivateHomeAccountDebugPanelRouteRoute
  '/account/delete': typeof PrivateHomeAccountDeleteRouteRoute
  '/account/help': typeof PrivateHomeAccountHelpRouteRoute
  '/account/manage-tv': typeof PrivateHomeAccountManageTvRouteRoute
  '/account/referral': typeof PrivateHomeAccountReferralRouteRoute
  '/account': typeof PrivateHomeAccountSubscriptionRouteWithChildren
  '/schedule/$type': typeof PrivateHomeScheduleTypeRoute
  '/account/profile': typeof PrivateHomeAccountProfileRouteLazyRoute
  '/account/support': typeof PrivateHomeAccountSupportRouteLazyRoute
  '/account/': typeof PrivateHomeAccountIndexRoute
  '/account/favorites': typeof PrivateHomeAccountFavoritesFavoritesRoute
  '/authenticate/$regCode/$platform/$activationCode': typeof PublicAuthenticateRegCodePlatformActivationCodeRoute
  '/account/edit/players': typeof PrivateHomeAccountFavoritesEditPlayersRoute
  '/account/subscription/history': typeof PrivateHomeAccountSubscriptionSubscriptionHistoryRoute
  '/account/subscription/payment': typeof PrivateHomeAccountSubscriptionSubscriptionPaymentRoute
  '/account/edit/teams': typeof PrivateHomeAccountFavoritesEditTeamsLazyRoute
  '/account/subscription': typeof PrivateHomeAccountSubscriptionSubscriptionIndexLazyRoute
}

export interface FileRoutesByTo {
  '': typeof PublicRouteWithChildren
  '/connect': typeof PrivateConnectRouteRoute
  '/first-time': typeof PrivateFirstTimeRouteRoute
  '/logout': typeof PrivateLogoutRouteRoute
  '/products': typeof PrivateProductsRouteRoute
  '/update-profile': typeof PrivateUpdateProfileRouteRoute
  '/ways-to-watch': typeof PrivateWaysToWatchRouteRoute
  '/change-password': typeof PublicChangePasswordRouteRoute
  '/create-profile': typeof PublicCreateProfileRouteRoute
  '/delete': typeof PublicDeleteRouteRoute
  '/help': typeof PublicHelpRouteRoute
  '/launch': typeof PublicLaunchRouteRoute
  '/login': typeof PublicLoginRouteRoute
  '/privacy-choices': typeof PublicPrivacyChoicesRouteRoute
  '/privacy-policy': typeof PublicPrivacyPolicyRouteRoute
  '/sms-terms-and-conditions': typeof PublicSmsTermsAndConditionsRouteRoute
  '/terms-service': typeof PublicTermsServiceRouteRoute
  '/subscription-unavailable': typeof PrivateSubscriptionUnavailableRouteLazyRoute
  '/upcoming': typeof PrivateUpcomingRouteLazyRoute
  '/forgot-password': typeof PublicForgotPasswordRouteLazyRoute
  '/geo-block': typeof PublicGeoBlockRouteLazyRoute
  '/support': typeof PublicSupportRouteLazyRoute
  '/odds': typeof PrivateHomeOddsRouteRoute
  '/products/successful': typeof PrivateProductsSuccessfulRouteRoute
  '/page/$': typeof deeplinkPageSplatRoute
  '/content/$railId': typeof PrivateContentRailIdRoute
  '/favorite/players': typeof PrivateFavoritePlayersRoute
  '/favorite/teams': typeof PrivateFavoriteTeamsRoute
  '/playback/$videoId': typeof PrivatePlaybackVideoIdRoute
  '/authenticate/status': typeof PublicAuthenticateStatusRoute
  '/bets/odds': typeof PublicBetsOddsRoute
  '/search': typeof PrivateHomeSearchRouteLazyRoute
  '/': typeof PrivateHomeIndexRoute
  '/account/cancel-subscription': typeof PrivateHomeAccountCancelSubscriptionRouteRoute
  '/account/change-plan': typeof PrivateHomeAccountChangePlanRouteRoute
  '/account/debug-panel': typeof PrivateHomeAccountDebugPanelRouteRoute
  '/account/delete': typeof PrivateHomeAccountDeleteRouteRoute
  '/account/help': typeof PrivateHomeAccountHelpRouteRoute
  '/account/manage-tv': typeof PrivateHomeAccountManageTvRouteRoute
  '/account/referral': typeof PrivateHomeAccountReferralRouteRoute
  '/account': typeof PrivateHomeAccountIndexRoute
  '/schedule/$type': typeof PrivateHomeScheduleTypeRoute
  '/account/profile': typeof PrivateHomeAccountProfileRouteLazyRoute
  '/account/support': typeof PrivateHomeAccountSupportRouteLazyRoute
  '/account/favorites': typeof PrivateHomeAccountFavoritesFavoritesRoute
  '/authenticate/$regCode/$platform/$activationCode': typeof PublicAuthenticateRegCodePlatformActivationCodeRoute
  '/account/edit/players': typeof PrivateHomeAccountFavoritesEditPlayersRoute
  '/account/subscription/history': typeof PrivateHomeAccountSubscriptionSubscriptionHistoryRoute
  '/account/subscription/payment': typeof PrivateHomeAccountSubscriptionSubscriptionPaymentRoute
  '/account/edit/teams': typeof PrivateHomeAccountFavoritesEditTeamsLazyRoute
  '/account/subscription': typeof PrivateHomeAccountSubscriptionSubscriptionIndexLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_private': typeof PrivateRouteWithChildren
  '/_public': typeof PublicRouteWithChildren
  '/_private/connect': typeof PrivateConnectRouteRoute
  '/_private/first-time': typeof PrivateFirstTimeRouteRoute
  '/_private/logout': typeof PrivateLogoutRouteRoute
  '/_private/products': typeof PrivateProductsRouteRoute
  '/_private/update-profile': typeof PrivateUpdateProfileRouteRoute
  '/_private/ways-to-watch': typeof PrivateWaysToWatchRouteRoute
  '/_public/change-password': typeof PublicChangePasswordRouteRoute
  '/_public/create-profile': typeof PublicCreateProfileRouteRoute
  '/_public/delete': typeof PublicDeleteRouteRoute
  '/_public/help': typeof PublicHelpRouteRoute
  '/_public/launch': typeof PublicLaunchRouteRoute
  '/_public/login': typeof PublicLoginRouteRoute
  '/_public/privacy-choices': typeof PublicPrivacyChoicesRouteRoute
  '/_public/privacy-policy': typeof PublicPrivacyPolicyRouteRoute
  '/_public/sms-terms-and-conditions': typeof PublicSmsTermsAndConditionsRouteRoute
  '/_public/terms-service': typeof PublicTermsServiceRouteRoute
  '/_private/_home': typeof PrivateHomeRouteWithChildren
  '/_private/subscription-unavailable': typeof PrivateSubscriptionUnavailableRouteLazyRoute
  '/_private/upcoming': typeof PrivateUpcomingRouteLazyRoute
  '/_public/forgot-password': typeof PublicForgotPasswordRouteLazyRoute
  '/_public/geo-block': typeof PublicGeoBlockRouteLazyRoute
  '/_public/support': typeof PublicSupportRouteLazyRoute
  '/_private/_home/odds': typeof PrivateHomeOddsRouteRoute
  '/_private/products_/successful': typeof PrivateProductsSuccessfulRouteRoute
  '/(deeplink)/page/$': typeof deeplinkPageSplatRoute
  '/_private/content/$railId': typeof PrivateContentRailIdRoute
  '/_private/favorite/players': typeof PrivateFavoritePlayersRoute
  '/_private/favorite/teams': typeof PrivateFavoriteTeamsRoute
  '/_private/playback/$videoId': typeof PrivatePlaybackVideoIdRoute
  '/_public/authenticate/status': typeof PublicAuthenticateStatusRoute
  '/_public/bets_/odds': typeof PublicBetsOddsRoute
  '/_private/_home/search': typeof PrivateHomeSearchRouteLazyRoute
  '/_private/_home/': typeof PrivateHomeIndexRoute
  '/_private/_home/account/cancel-subscription': typeof PrivateHomeAccountCancelSubscriptionRouteRoute
  '/_private/_home/account/change-plan': typeof PrivateHomeAccountChangePlanRouteRoute
  '/_private/_home/account/debug-panel': typeof PrivateHomeAccountDebugPanelRouteRoute
  '/_private/_home/account/delete': typeof PrivateHomeAccountDeleteRouteRoute
  '/_private/_home/account/help': typeof PrivateHomeAccountHelpRouteRoute
  '/_private/_home/account/manage-tv': typeof PrivateHomeAccountManageTvRouteRoute
  '/_private/_home/account/referral': typeof PrivateHomeAccountReferralRouteRoute
  '/_private/_home/account': typeof PrivateHomeAccountRouteWithChildren
  '/_private/_home/account/_favorites': typeof PrivateHomeAccountFavoritesRouteWithChildren
  '/_private/_home/account/_subscription': typeof PrivateHomeAccountSubscriptionRouteWithChildren
  '/_private/_home/schedule/$type': typeof PrivateHomeScheduleTypeRoute
  '/_private/_home/account/profile': typeof PrivateHomeAccountProfileRouteLazyRoute
  '/_private/_home/account/support': typeof PrivateHomeAccountSupportRouteLazyRoute
  '/_private/_home/account/': typeof PrivateHomeAccountIndexRoute
  '/_private/_home/account/_favorites/favorites': typeof PrivateHomeAccountFavoritesFavoritesRoute
  '/_public/authenticate/$regCode/$platform/$activationCode': typeof PublicAuthenticateRegCodePlatformActivationCodeRoute
  '/_private/_home/account/_favorites/edit/players': typeof PrivateHomeAccountFavoritesEditPlayersRoute
  '/_private/_home/account/_subscription/subscription/history': typeof PrivateHomeAccountSubscriptionSubscriptionHistoryRoute
  '/_private/_home/account/_subscription/subscription/payment': typeof PrivateHomeAccountSubscriptionSubscriptionPaymentRoute
  '/_private/_home/account/_favorites/edit/teams': typeof PrivateHomeAccountFavoritesEditTeamsLazyRoute
  '/_private/_home/account/_subscription/subscription/': typeof PrivateHomeAccountSubscriptionSubscriptionIndexLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/connect'
    | '/first-time'
    | '/logout'
    | '/products'
    | '/update-profile'
    | '/ways-to-watch'
    | '/change-password'
    | '/create-profile'
    | '/delete'
    | '/help'
    | '/launch'
    | '/login'
    | '/privacy-choices'
    | '/privacy-policy'
    | '/sms-terms-and-conditions'
    | '/terms-service'
    | '/subscription-unavailable'
    | '/upcoming'
    | '/forgot-password'
    | '/geo-block'
    | '/support'
    | '/odds'
    | '/products/successful'
    | '/page/$'
    | '/content/$railId'
    | '/favorite/players'
    | '/favorite/teams'
    | '/playback/$videoId'
    | '/authenticate/status'
    | '/bets/odds'
    | '/search'
    | '/'
    | '/account/cancel-subscription'
    | '/account/change-plan'
    | '/account/debug-panel'
    | '/account/delete'
    | '/account/help'
    | '/account/manage-tv'
    | '/account/referral'
    | '/account'
    | '/schedule/$type'
    | '/account/profile'
    | '/account/support'
    | '/account/'
    | '/account/favorites'
    | '/authenticate/$regCode/$platform/$activationCode'
    | '/account/edit/players'
    | '/account/subscription/history'
    | '/account/subscription/payment'
    | '/account/edit/teams'
    | '/account/subscription'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/connect'
    | '/first-time'
    | '/logout'
    | '/products'
    | '/update-profile'
    | '/ways-to-watch'
    | '/change-password'
    | '/create-profile'
    | '/delete'
    | '/help'
    | '/launch'
    | '/login'
    | '/privacy-choices'
    | '/privacy-policy'
    | '/sms-terms-and-conditions'
    | '/terms-service'
    | '/subscription-unavailable'
    | '/upcoming'
    | '/forgot-password'
    | '/geo-block'
    | '/support'
    | '/odds'
    | '/products/successful'
    | '/page/$'
    | '/content/$railId'
    | '/favorite/players'
    | '/favorite/teams'
    | '/playback/$videoId'
    | '/authenticate/status'
    | '/bets/odds'
    | '/search'
    | '/'
    | '/account/cancel-subscription'
    | '/account/change-plan'
    | '/account/debug-panel'
    | '/account/delete'
    | '/account/help'
    | '/account/manage-tv'
    | '/account/referral'
    | '/account'
    | '/schedule/$type'
    | '/account/profile'
    | '/account/support'
    | '/account/favorites'
    | '/authenticate/$regCode/$platform/$activationCode'
    | '/account/edit/players'
    | '/account/subscription/history'
    | '/account/subscription/payment'
    | '/account/edit/teams'
    | '/account/subscription'
  id:
    | '__root__'
    | '/_private'
    | '/_public'
    | '/_private/connect'
    | '/_private/first-time'
    | '/_private/logout'
    | '/_private/products'
    | '/_private/update-profile'
    | '/_private/ways-to-watch'
    | '/_public/change-password'
    | '/_public/create-profile'
    | '/_public/delete'
    | '/_public/help'
    | '/_public/launch'
    | '/_public/login'
    | '/_public/privacy-choices'
    | '/_public/privacy-policy'
    | '/_public/sms-terms-and-conditions'
    | '/_public/terms-service'
    | '/_private/_home'
    | '/_private/subscription-unavailable'
    | '/_private/upcoming'
    | '/_public/forgot-password'
    | '/_public/geo-block'
    | '/_public/support'
    | '/_private/_home/odds'
    | '/_private/products_/successful'
    | '/(deeplink)/page/$'
    | '/_private/content/$railId'
    | '/_private/favorite/players'
    | '/_private/favorite/teams'
    | '/_private/playback/$videoId'
    | '/_public/authenticate/status'
    | '/_public/bets_/odds'
    | '/_private/_home/search'
    | '/_private/_home/'
    | '/_private/_home/account/cancel-subscription'
    | '/_private/_home/account/change-plan'
    | '/_private/_home/account/debug-panel'
    | '/_private/_home/account/delete'
    | '/_private/_home/account/help'
    | '/_private/_home/account/manage-tv'
    | '/_private/_home/account/referral'
    | '/_private/_home/account'
    | '/_private/_home/account/_favorites'
    | '/_private/_home/account/_subscription'
    | '/_private/_home/schedule/$type'
    | '/_private/_home/account/profile'
    | '/_private/_home/account/support'
    | '/_private/_home/account/'
    | '/_private/_home/account/_favorites/favorites'
    | '/_public/authenticate/$regCode/$platform/$activationCode'
    | '/_private/_home/account/_favorites/edit/players'
    | '/_private/_home/account/_subscription/subscription/history'
    | '/_private/_home/account/_subscription/subscription/payment'
    | '/_private/_home/account/_favorites/edit/teams'
    | '/_private/_home/account/_subscription/subscription/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  PrivateRoute: typeof PrivateRouteWithChildren
  PublicRoute: typeof PublicRouteWithChildren
  deeplinkPageSplatRoute: typeof deeplinkPageSplatRoute
}

const rootRouteChildren: RootRouteChildren = {
  PrivateRoute: PrivateRouteWithChildren,
  PublicRoute: PublicRouteWithChildren,
  deeplinkPageSplatRoute: deeplinkPageSplatRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_private",
        "/_public",
        "/(deeplink)/page/$"
      ]
    },
    "/_private": {
      "filePath": "_private.tsx",
      "children": [
        "/_private/connect",
        "/_private/first-time",
        "/_private/logout",
        "/_private/products",
        "/_private/update-profile",
        "/_private/ways-to-watch",
        "/_private/_home",
        "/_private/subscription-unavailable",
        "/_private/upcoming",
        "/_private/products_/successful",
        "/_private/content/$railId",
        "/_private/favorite/players",
        "/_private/favorite/teams",
        "/_private/playback/$videoId"
      ]
    },
    "/_public": {
      "filePath": "_public.tsx",
      "children": [
        "/_public/change-password",
        "/_public/create-profile",
        "/_public/delete",
        "/_public/help",
        "/_public/launch",
        "/_public/login",
        "/_public/privacy-choices",
        "/_public/privacy-policy",
        "/_public/sms-terms-and-conditions",
        "/_public/terms-service",
        "/_public/forgot-password",
        "/_public/geo-block",
        "/_public/support",
        "/_public/authenticate/status",
        "/_public/bets_/odds",
        "/_public/authenticate/$regCode/$platform/$activationCode"
      ]
    },
    "/_private/connect": {
      "filePath": "_private/connect/route.tsx",
      "parent": "/_private"
    },
    "/_private/first-time": {
      "filePath": "_private/first-time/route.tsx",
      "parent": "/_private"
    },
    "/_private/logout": {
      "filePath": "_private/logout/route.tsx",
      "parent": "/_private"
    },
    "/_private/products": {
      "filePath": "_private/products/route.tsx",
      "parent": "/_private"
    },
    "/_private/update-profile": {
      "filePath": "_private/update-profile/route.tsx",
      "parent": "/_private"
    },
    "/_private/ways-to-watch": {
      "filePath": "_private/ways-to-watch/route.tsx",
      "parent": "/_private"
    },
    "/_public/change-password": {
      "filePath": "_public/change-password/route.tsx",
      "parent": "/_public"
    },
    "/_public/create-profile": {
      "filePath": "_public/create-profile/route.tsx",
      "parent": "/_public"
    },
    "/_public/delete": {
      "filePath": "_public/delete/route.tsx",
      "parent": "/_public"
    },
    "/_public/help": {
      "filePath": "_public/help/route.tsx",
      "parent": "/_public"
    },
    "/_public/launch": {
      "filePath": "_public/launch/route.tsx",
      "parent": "/_public"
    },
    "/_public/login": {
      "filePath": "_public/login/route.tsx",
      "parent": "/_public"
    },
    "/_public/privacy-choices": {
      "filePath": "_public/privacy-choices/route.tsx",
      "parent": "/_public"
    },
    "/_public/privacy-policy": {
      "filePath": "_public/privacy-policy/route.tsx",
      "parent": "/_public"
    },
    "/_public/sms-terms-and-conditions": {
      "filePath": "_public/sms-terms-and-conditions/route.tsx",
      "parent": "/_public"
    },
    "/_public/terms-service": {
      "filePath": "_public/terms-service/route.tsx",
      "parent": "/_public"
    },
    "/_private/_home": {
      "filePath": "_private/_home.tsx",
      "parent": "/_private",
      "children": [
        "/_private/_home/odds",
        "/_private/_home/search",
        "/_private/_home/",
        "/_private/_home/account/cancel-subscription",
        "/_private/_home/account/change-plan",
        "/_private/_home/account/debug-panel",
        "/_private/_home/account/delete",
        "/_private/_home/account/help",
        "/_private/_home/account/manage-tv",
        "/_private/_home/account/referral",
        "/_private/_home/account",
        "/_private/_home/schedule/$type"
      ]
    },
    "/_private/subscription-unavailable": {
      "filePath": "_private/subscription-unavailable/route.lazy.tsx",
      "parent": "/_private"
    },
    "/_private/upcoming": {
      "filePath": "_private/upcoming/route.lazy.tsx",
      "parent": "/_private"
    },
    "/_public/forgot-password": {
      "filePath": "_public/forgot-password/route.lazy.tsx",
      "parent": "/_public"
    },
    "/_public/geo-block": {
      "filePath": "_public/geo-block/route.lazy.tsx",
      "parent": "/_public"
    },
    "/_public/support": {
      "filePath": "_public/support/route.lazy.tsx",
      "parent": "/_public"
    },
    "/_private/_home/odds": {
      "filePath": "_private/_home/odds/route.tsx",
      "parent": "/_private/_home"
    },
    "/_private/products_/successful": {
      "filePath": "_private/products_/successful/route.tsx",
      "parent": "/_private"
    },
    "/(deeplink)/page/$": {
      "filePath": "(deeplink)/page.$.tsx"
    },
    "/_private/content/$railId": {
      "filePath": "_private/content/$railId.tsx",
      "parent": "/_private"
    },
    "/_private/favorite/players": {
      "filePath": "_private/favorite/players.tsx",
      "parent": "/_private"
    },
    "/_private/favorite/teams": {
      "filePath": "_private/favorite/teams.tsx",
      "parent": "/_private"
    },
    "/_private/playback/$videoId": {
      "filePath": "_private/playback/$videoId.tsx",
      "parent": "/_private"
    },
    "/_public/authenticate/status": {
      "filePath": "_public/authenticate/status.tsx",
      "parent": "/_public"
    },
    "/_public/bets_/odds": {
      "filePath": "_public/bets_/odds.tsx",
      "parent": "/_public"
    },
    "/_private/_home/search": {
      "filePath": "_private/_home/search/route.lazy.tsx",
      "parent": "/_private/_home"
    },
    "/_private/_home/": {
      "filePath": "_private/_home/index.tsx",
      "parent": "/_private/_home"
    },
    "/_private/_home/account/cancel-subscription": {
      "filePath": "_private/_home/account/cancel-subscription/route.tsx",
      "parent": "/_private/_home"
    },
    "/_private/_home/account/change-plan": {
      "filePath": "_private/_home/account/change-plan/route.tsx",
      "parent": "/_private/_home"
    },
    "/_private/_home/account/debug-panel": {
      "filePath": "_private/_home/account/debug-panel/route.tsx",
      "parent": "/_private/_home"
    },
    "/_private/_home/account/delete": {
      "filePath": "_private/_home/account/delete/route.tsx",
      "parent": "/_private/_home"
    },
    "/_private/_home/account/help": {
      "filePath": "_private/_home/account/help/route.tsx",
      "parent": "/_private/_home"
    },
    "/_private/_home/account/manage-tv": {
      "filePath": "_private/_home/account/manage-tv/route.tsx",
      "parent": "/_private/_home"
    },
    "/_private/_home/account/referral": {
      "filePath": "_private/_home/account/referral/route.tsx",
      "parent": "/_private/_home"
    },
    "/_private/_home/account": {
      "filePath": "_private/_home/account",
      "parent": "/_private/_home",
      "children": [
        "/_private/_home/account/_favorites",
        "/_private/_home/account/_subscription",
        "/_private/_home/account/profile",
        "/_private/_home/account/support",
        "/_private/_home/account/"
      ]
    },
    "/_private/_home/account/_favorites": {
      "filePath": "_private/_home/account/_favorites.tsx",
      "parent": "/_private/_home/account",
      "children": [
        "/_private/_home/account/_favorites/favorites",
        "/_private/_home/account/_favorites/edit/players",
        "/_private/_home/account/_favorites/edit/teams"
      ]
    },
    "/_private/_home/account/_subscription": {
      "filePath": "_private/_home/account/_subscription.tsx",
      "parent": "/_private/_home/account",
      "children": [
        "/_private/_home/account/_subscription/subscription/history",
        "/_private/_home/account/_subscription/subscription/payment",
        "/_private/_home/account/_subscription/subscription/"
      ]
    },
    "/_private/_home/schedule/$type": {
      "filePath": "_private/_home/schedule/$type.tsx",
      "parent": "/_private/_home"
    },
    "/_private/_home/account/profile": {
      "filePath": "_private/_home/account/profile/route.lazy.tsx",
      "parent": "/_private/_home/account"
    },
    "/_private/_home/account/support": {
      "filePath": "_private/_home/account/support/route.lazy.tsx",
      "parent": "/_private/_home/account"
    },
    "/_private/_home/account/": {
      "filePath": "_private/_home/account/index.tsx",
      "parent": "/_private/_home/account"
    },
    "/_private/_home/account/_favorites/favorites": {
      "filePath": "_private/_home/account/_favorites/favorites.tsx",
      "parent": "/_private/_home/account/_favorites"
    },
    "/_public/authenticate/$regCode/$platform/$activationCode": {
      "filePath": "_public/authenticate/$regCode.$platform.$activationCode.tsx",
      "parent": "/_public"
    },
    "/_private/_home/account/_favorites/edit/players": {
      "filePath": "_private/_home/account/_favorites/edit/players.tsx",
      "parent": "/_private/_home/account/_favorites"
    },
    "/_private/_home/account/_subscription/subscription/history": {
      "filePath": "_private/_home/account/_subscription.subscription/history.tsx",
      "parent": "/_private/_home/account/_subscription"
    },
    "/_private/_home/account/_subscription/subscription/payment": {
      "filePath": "_private/_home/account/_subscription.subscription/payment.tsx",
      "parent": "/_private/_home/account/_subscription"
    },
    "/_private/_home/account/_favorites/edit/teams": {
      "filePath": "_private/_home/account/_favorites/edit/teams.lazy.tsx",
      "parent": "/_private/_home/account/_favorites"
    },
    "/_private/_home/account/_subscription/subscription/": {
      "filePath": "_private/_home/account/_subscription.subscription/index.lazy.tsx",
      "parent": "/_private/_home/account/_subscription"
    }
  }
}
ROUTE_MANIFEST_END */
