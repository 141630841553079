import { z } from 'zod';
import { PASSWORD_MIN_LENGTH } from '../../../constants/forms';

const validatePasswords = (value: z.infer<typeof formSchema>) => {
  return value.new_password === value.confirm_password;
};

const formSchema = z.object({
  new_password: z.string().min(PASSWORD_MIN_LENGTH),
  confirm_password: z.string().min(PASSWORD_MIN_LENGTH)
});

export const resetPasswordFormSchema = formSchema.refine(validatePasswords, {
  path: ['confirm_password']
});

export const resetPasswordSearch = z.object({
  resetPasswordToken: z.string().optional(),
  email: z.string().optional()
});
