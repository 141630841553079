import { z } from 'zod';

export const generic = z
  .object({
    generic: z.object({
      ok: z.string(),
      cancel: z.string()
    })
  })
  .transform((values) => {
    const { ok, cancel } = values['generic'];
    return {
      ok,
      cancel
    };
  });

export type GenericLocale = z.infer<typeof generic>;
