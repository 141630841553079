import { AnalyticsBrowser, type JSONObject } from '@segment/analytics-next';
import { APP_NAME, SEGMENT } from '../../config/build-config';
import { register } from '../../lib/action-handlers/action-handlers';
import { getContent } from '../video-playback';
import { eventHandler } from './handler';
import {
  getPlatformTraits,
  getSubscriberType,
  getUser,
  getWriteKey,
  populate,
  toAdTraits,
  toContentTraits,
  toPlaybackTraits,
  toUserTraits
} from './util';
import { getChannelContent, type Content } from '../content';
import { GameConfig } from '../../config/game-config';
import { type MiddlewareParams } from '@segment/analytics-next/src/plugins/middleware';

const EVENT_BATCHING_SIZE = 10;
const EVENT_BATCHING_TIMEOUT = 30000;

type PlayerInfo = {
  framework: string;
  version: string;
};

// Segment Analytics Instance
let segmentAnalytics: AnalyticsBrowser | undefined = undefined;
let playerInfo: PlayerInfo;
let bitrate: number;
let framerate: number;
let position: number;
let sessionId: string;

export function setPlayerInfo(info: PlayerInfo) {
  playerInfo = info;
}
export function setBitrate(rate: number) {
  bitrate = rate;
}
export function setFramerate(rate: number) {
  framerate = rate;
}
export function setPosition(pos: number) {
  position = pos;
}

export function getPosition() {
  return position;
}
export function setSessionId(id: string) {
  sessionId = id;
}

function setAppContext({ payload, next }: MiddlewareParams) {
  payload.obj.context = payload.obj.context || {};
  const app = (payload.obj.context.app = payload.obj.context.app || {});
  app.name = APP_NAME;
  app.version = GameConfig.get.versionSummary;

  next(payload);
}

function setPlatform({ payload, next }: MiddlewareParams) {
  if (!payload.obj.properties) payload.obj.properties = {};
  const { properties } = payload.obj;
  properties.platform = getPlatformTraits().platform;
  next(payload);
}

export function initSegment() {
  if (segmentAnalytics) {
    throw new Error('Segment is already initialized. Please do not call initSegment multiple times');
  }

  const settings: { writeKey: string } & JSONObject = {
    writeKey: getWriteKey()
  };

  if (SEGMENT.web.CDN_URL) {
    settings.cdnURL = SEGMENT.web.CDN_URL;
  }

  const segmentConfig: JSONObject = {
    deliveryStrategy: {
      strategy: 'batching',
      config: {
        size: EVENT_BATCHING_SIZE,
        timeout: EVENT_BATCHING_TIMEOUT
      }
    }
  };
  if (SEGMENT.web.API_HOST) {
    segmentConfig.apiHost = SEGMENT.web.API_HOST;
  }

  segmentAnalytics = AnalyticsBrowser.load(settings, {
    obfuscate: false,
    disable: false,
    integrations: {
      // ref: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#batching
      'Segment.io': segmentConfig
    }
  });

  void segmentAnalytics.addSourceMiddleware(setAppContext);
  void segmentAnalytics.addSourceMiddleware(setPlatform);
  void segmentAnalytics.addSourceMiddleware(async ({ payload, next }) => {
    const user = await getUser();
    if (payload.type() === 'track') {
      if (!payload.obj.properties) payload.obj.properties = {};
      const { event } = payload.obj;
      if (!event) {
        return;
      }
      const { properties } = payload.obj;

      // TODO: remove this out to it's own middleware to only populate for video events.
      await populateEventProperties(properties, event, playerInfo?.framework, bitrate, framerate, position);

      if (user) {
        properties.subscriber_type = getSubscriberType(user);
      }
    }

    if (payload.type() === 'identify') {
      if (!user) {
        return;
      }

      payload.obj.traits = {
        ...toUserTraits(user),
        ...(payload.obj.traits ?? {})
      };
    }
    next(payload);
  });

  const unregister = register(eventHandler(segmentAnalytics));

  return () => {
    unregister();
    void segmentAnalytics?.reset();
    segmentAnalytics = undefined;
  };
}

function getEventType(eventName: string) {
  switch (eventName) {
    case 'Video Playback Buffer Started':
    case 'Video Playback Buffer Completed':
    case 'Video Playback Started':
    case 'Video Playback Paused':
    case 'Video Playback Resumed':
    case 'Video Playback Exited':
    case 'Video Playback Interrupted':
    case 'Video Playback Seek Started':
    case 'Video Playback Seek Completed':
    case 'Video Playback Completed':
      return 'playback-event';

    case 'Video Ad Break Started':
    case 'Video Ad Break Completed':
    case 'Video Ad Started':
    case 'Video Ad Playing':
    case 'Video Ad Completed':
      return 'ad-event';

    case 'Video Content Started':
    case 'Video Content Playing':
    case 'Video Content Completed':
    case 'Video Quality Updated':
    case 'Video Content Percentage Viewed':
    case 'Video Content Live Time Viewed':
      return 'content-event';

    default:
      return null; // Return null when eventName doesn't match any case
  }
}

/** Populates Event Properties. Modifies properties in-place */

async function populateEventProperties(
  properties: Record<string, any>,
  eventName: string,
  playerName: string,
  bitrate: number,
  framerate: number,
  position: number
) {
  const eventType = getEventType(eventName);
  if (!eventType) return;
  const currentContent = getContent();
  // TODO: Fix airing metadata
  const content = (await getChannelContent(currentContent as Content)) || currentContent;

  if (eventType === 'playback-event') {
    populate(properties, toPlaybackTraits({}, playerName, content, sessionId, bitrate, framerate));
  } else if (eventType === 'ad-event') {
    populate(properties, toAdTraits({}, content));
  } else if (eventType === 'content-event') {
    populate(properties, toContentTraits(content, sessionId, bitrate, framerate, position));
  }
}
