import { queryClient } from '../../lib/query';
import type { AnalyticsEventHandler } from '../analytics';
import { GetUserQuery } from '../user';
import { getAdBreakInfo, getAdInfo, toConvivaInfo, formatErrorMessage } from './util';
import { Constants, type VideoAnalytics, type AdAnalytics } from '@convivainc/conviva-js-coresdk';
import { trackCustomEvent, trackPageView } from '@convivainc/conviva-js-appanalytics';
import type { ApplicationErrorPayload } from '../analytics/handler';
import { getUseCaseCode } from '../use-cases/lib';
import { type Content } from '../content';
import { ERROR_SOURCE, type GameError, getReferenceCode } from '../errors';
import type { UseCases } from '../use-cases';

function getApiErrorMessage(error: GameError, useCase: UseCases) {
  const referenceCode = getReferenceCode({
    error,
    useCase
  });
  return `${error.message}. Ref: ${referenceCode}`;
}

export const eventHandler = defineEventHandler((analytics: VideoAnalytics, adAnalytics: AdAnalytics) => ({
  handlePlayerReady({ framework, version }) {
    const playerInfo = {
      [Constants.FRAMEWORK_NAME]: framework,
      [Constants.FRAMEWORK_VERSION]: version
    };
    analytics.setPlayerInfo(playerInfo);
    adAnalytics.setAdPlayerInfo(playerInfo);
  },

  handlePlaybackContentReady({ content, playback, channel }) {
    const setContentInfo = async () => {
      const user = queryClient.getQueryData(GetUserQuery.queryKey);
      if (!user) return;
      const contentMetadata = await toConvivaInfo(user, channel ?? content, playback);
      analytics.setContentInfo(contentMetadata);
      analytics.reportPlaybackRequested();
    };

    void setContentInfo();
  },

  handlePlaybackContentUpdated({ content, playback }) {
    const updateContentInfo = async () => {
      const user = queryClient.getQueryData(GetUserQuery.queryKey);
      if (!user) return;
      const contentMetadata = await toConvivaInfo(user, content, playback);
      analytics.setContentInfo(contentMetadata);
    };

    void updateContentInfo();
  },

  handlePlayerPlaying() {
    analytics.reportPlaybackMetric(Constants.Playback.PLAYER_STATE, Constants.PlayerState.PLAYING);
  },

  handlePlayerQualityChanged({ bitrate, frameRate }) {
    analytics.reportPlaybackMetric(Constants.Playback.RENDERED_FRAMERATE, frameRate);
    analytics.reportPlaybackMetric(Constants.Playback.BITRATE, bitrate);
  },

  handlePlaybackPaused() {
    analytics.reportPlaybackMetric(Constants.Playback.PLAYER_STATE, Constants.PlayerState.PAUSED);
  },

  handlePlaybackCompleted() {
    analytics.reportPlaybackEnded();
  },

  handlePlaybackBufferingStarted() {
    analytics.reportPlaybackMetric(Constants.Playback.PLAYER_STATE, Constants.PlayerState.BUFFERING);
  },

  handlePlaybackSeeking(position) {
    analytics.reportPlaybackMetric(Constants.Playback.SEEK_STARTED, position);
  },

  handlePlaybackSeeked() {
    analytics.reportPlaybackMetric(Constants.Playback.SEEK_ENDED);
  },

  handlePlaybackError({ code, message }) {
    analytics.reportPlaybackFailed(formatErrorMessage(code, message));
    trackCustomEvent({
      name: 'custom-error',
      data: {
        useCase: getUseCaseCode('VIDEO'),
        source: ERROR_SOURCE.BITMOVIN,
        description: message,
        code: code
      }
    });
  },

  handlePlaybackWarning({ code, message }) {
    analytics.reportPlaybackError(formatErrorMessage(code, message), Constants.ErrorSeverity.WARNING);
  },

  handlePlayHeadTime({ time, isLive }) {
    if (!isLive) {
      analytics.reportPlaybackMetric(Constants.Playback.PLAY_HEAD_TIME, time * 1000);
    }
  },

  handleAdBreakStarted({ type, event, playback, content, channel }) {
    const reportAdBreakStarted = async () => {
      const info = await getAdBreakInfo(event, channel ?? (content as Content), playback);
      const convivaType = type === 'CSAI' ? Constants.AdType.CLIENT_SIDE : Constants.AdType.SERVER_SIDE;
      analytics.reportAdBreakStarted(convivaType, Constants.AdPlayer.CONTENT, info);
    };
    void reportAdBreakStarted();
  },

  handleAdBreakFinished() {
    analytics.reportAdBreakEnded();
  },

  handleAdStarted({ event, playback, content, channel }) {
    const reportAdStarted = async () => {
      const info = await getAdInfo(event, channel ?? (content as Content), playback);
      adAnalytics.reportAdStarted(info);
    };
    void reportAdStarted();
  },
  handleAdFinished() {
    adAnalytics.reportAdEnded();
  },
  handleScreenVisited(title: string) {
    trackPageView({
      title
    });
  },
  handleApplicationError(payload: ApplicationErrorPayload) {
    const { error, useCase, endUserMessage } = payload;
    trackCustomEvent({
      name: 'custom-error',
      data: {
        useCase: getUseCaseCode(useCase),
        source: error.source,
        description: endUserMessage,
        code: error.code,
        apiMessage: getApiErrorMessage(error, useCase)
      }
    });
  },
  handleContentCompleted: async () => {
    analytics.reportPlaybackEnded();
  }
}));

function defineEventHandler(fn: (analytics: VideoAnalytics, adAnalytics: AdAnalytics) => AnalyticsEventHandler) {
  return (analytics: VideoAnalytics, adAnalytics: AdAnalytics) => fn(analytics, adAnalytics);
}
