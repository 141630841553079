import dayjs from 'dayjs';
import { GameConfig } from '../../../config/game-config';
import { type LoginRequestParams, type SocialLoginRequestParams } from '../../../features/auth/types';
import type { DeviceDetails } from '../../../features/device/types';
import { GameError } from '../../../features/errors';
import { SERVICE_TYPE } from '../../../features/subscription-plans/constants';
import { createServiceMap, getServiceMapFromService } from '../../../features/subscription-plans/utils';
import type { CreateUserInput, Device, UpdateUserInput, UserType } from '../../../features/user/types';
import { EVERGENT_ERROR_CODE } from './error';
import { api } from './fetcher';
import {
  type ActiveSubscription,
  type AddOrRemoveFavoriteRequest,
  type AddOrRemoveFavouritesResponse,
  type AddOrUpdateCouchRightsResponse,
  type AddSubscriptionService,
  type AddSubscriptionsRequest,
  type AddTVESubscriptionRequest,
  type AddTVODOrderRequest,
  type AppChannel,
  type ChangePasswordResponse,
  type ChangeServiceRequest,
  type CreateUser,
  type CreateUserResponse,
  type EvergentAccountServiceDetail,
  type EvergentActiveSubscriptionDetail,
  type ForgotPasswordResponse,
  type GenerateDeviceActivationCodeResponse,
  type GenerateReferralCodeParams,
  type GetActiveSubscriptionsResponse,
  type GetContactResponse,
  type GetCouchRightsResponse,
  type GetEntitlementsResponse,
  type GetFavouritesResponse,
  type GetPaymentsResponse,
  type GetProrateResponse,
  type GetSubscriptionHistoryResponse,
  type LoginResponse,
  type OAuthResponse,
  type Payment,
  type PaymentMethod,
  type ProductResponse,
  type ReferralCodeResponse,
  type RemoveSubscriptionRequest,
  type ResetPasswordResponse,
  RESPONSE_CODES,
  type ResumeSubscriptionRequest,
  type SearchAccountResult,
  type SearchAccountSuccessResponse,
  type ServiceType,
  type TokensOrActivationCode,
  type UpdateCardParams,
  type UpdateUser
} from './types';

/**
 * Logs in a user either with username/password, or with spAccountId (This is crazy, but it is what it is)
 */
export async function login(
  args: LoginRequestParams | string | SocialLoginRequestParams,
  deviceDetails?: DeviceDetails
): Promise<LoginResponse> {
  const message =
    typeof args === 'string'
      ? { spAccountID: args }
      : 'socialLoginID' in args && 'socialLoginType' in args
        ? {
            socialLoginID: args.socialLoginID,
            socialLoginType: args.socialLoginType,
            deviceMessage: deviceDetails
          }
        : {
            contactUserName: args.username,
            contactPassword: args.password,
            deviceMessage: deviceDetails
          };

  const result = await api<OAuthResponse>('getOAuthAccessTokenv2', {
    method: 'POST',
    body: {
      GetOAuthAccessTokenv2RequestMessage: message
    }
  });

  const response = result.GetOAuthAccessTokenv2ResponseMessage;

  return {
    tokens: {
      accessToken: response.accessToken,
      refreshToken: response.refreshToken,
      expiresIn: response.expiresIn
    },
    metadata: {
      accountRole: response.accountRole
    }
  };
}

/**
 * Invalidates the user tokens
 * @param {string} accessToken
 * @param {DeviceDetails} [deviceDetails]
 */
export async function logout(accessToken: string, deviceDetails?: DeviceDetails) {
  // TODO/Alvis: consider making deviceDetails as a required parameter
  const logoutRequest: { deviceDetails?: DeviceDetails } = {};

  if (deviceDetails) {
    logoutRequest.deviceDetails = deviceDetails;
  }

  await api('logOutUser', {
    method: 'POST',
    body: {
      LogOutUserRequestMessage: logoutRequest
    },
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
}

function getChannelAppId(appChannels: AppChannel[]) {
  const channel = GameConfig.get.billingPlatform;
  return channel ? appChannels.find((appChannel) => appChannel.appChannel === channel)?.appID : undefined;
}

function getProductsRequestMessageOption(dmaID: string) {
  const options: Record<string, string> = {
    dmaID,
    returnTVOD: 'T', // hardcoded for now, need to understand this better to send the proper value
    returnPromotions: 'T'
  };
  if (GameConfig.get.billingPlatform) {
    options.returnAppChannels = 'T';
  }
  return options;
}

export async function getProducts(dmaID: string) {
  let response: ProductResponse;
  try {
    response = await api<ProductResponse>('getProducts', {
      method: 'POST',
      body: {
        GetProductsRequestMessage: getProductsRequestMessageOption(dmaID)
      },
      metadata: { withToken: true }
    });
  } catch (e) {
    if (e instanceof GameError && e.code === EVERGENT_ERROR_CODE.NO_ACCOUNT_FOUND) {
      return [];
    }
    throw e;
  }

  const products = response.GetProductsResponseMessage.productsResponseMessage;

  if (!products) return [];
  const hasBillingPlatform = !!GameConfig.get.billingPlatform;
  return products.map((product) => ({
    productName: product.productName,
    displayName: product.displayName,
    retailPrice: product.retailPrice,
    displayOrder: product.displayOrder,
    productID: product.productID,
    sku: product.skuORQuickCode,
    ovpSKU: product.ovpSKU,
    promotions: product.promotions,
    channelID: hasBillingPlatform && product.appChannels ? getChannelAppId(product.appChannels) : undefined,
    serviceType: product.serviceType
  }));
}

export async function searchAccount(email: string, throwOnError?: boolean): Promise<SearchAccountResult> {
  try {
    const response = await api('searchAccountV2', {
      method: 'POST',
      body: {
        SearchAccountV2RequestMessage: { email }
      }
    });

    // TODO: double check directAccount logic after bringing in new RSN
    const {
      accountType,
      socialLoginType,
      directAccount = 0,
      accountRole,
      isPasswordExists
    } = response.SearchAccountV2ResponseMessage as SearchAccountSuccessResponse;

    const socialLoginTypeLowerCase = socialLoginType?.toLowerCase();

    return {
      accountRole,
      directAccount,
      accountType,
      isSocialLogin: socialLoginTypeLowerCase == 'google' || socialLoginTypeLowerCase == 'apple',
      exists: true,
      hasPassword: isPasswordExists === 'true'
    };
  } catch (err) {
    if (err instanceof GameError && err.code === EVERGENT_ERROR_CODE.NO_ACCOUNT_FOUND && !throwOnError) {
      return { exists: false };
    }
    throw err;
  }
}

/** Gets the user type from the given active services.
 * @param {Map<ServiceType, Map<string, EvergentAccountServiceDetail>>} activeServiceMap
 * MVPD - User containing multiple TVE services.
 * MVPD OOM - User containing only a single TVE service - Out of Market users will not have multiple TVE services.
 * DTC - User containing at least one DTC service, but no TVE services.
 * VIP - User containing at least one VIP service.
 * FREE - User containing no services. */
export function getUserType(
  activeServiceMap: Map<ServiceType, Map<string, EvergentAccountServiceDetail>>
): Set<UserType> {
  const userTypeSet = new Set<UserType>();
  if (!activeServiceMap.size) {
    return userTypeSet.add(SERVICE_TYPE.FREE);
  }
  const vipServiceMap = activeServiceMap.get(SERVICE_TYPE.VIP);
  if (vipServiceMap?.size) {
    return userTypeSet.add(SERVICE_TYPE.VIP);
  }
  const tveServiceMap = activeServiceMap.get(SERVICE_TYPE.TVE);
  if (tveServiceMap?.size) {
    userTypeSet.add(tveServiceMap.size > 1 ? 'MVPD' : 'MVPD OOM');
  }
  const dtcServiceMap = activeServiceMap.get(SERVICE_TYPE.DTC);
  if (dtcServiceMap?.size) {
    userTypeSet.add(SERVICE_TYPE.DTC);
  }
  if (!tveServiceMap?.size && !dtcServiceMap?.size) {
    userTypeSet.add(SERVICE_TYPE.FREE);
  }
  return userTypeSet;
}

export async function getContact() {
  const response = await api<GetContactResponse>('getContact', {
    method: 'POST',
    body: {
      GetContactRequestMessage: {}
    },
    metadata: { withToken: true }
  });

  if (response.GetContactResponseMessage.responseCode === RESPONSE_CODES.ERROR)
    throw new Error('Failed to get user data');

  const {
    cpCustomerID,
    spAccountID,
    contactMessage: [user],
    billingAddress
  } = response.GetContactResponseMessage;

  return {
    fullName: user.nickName,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email.toLowerCase(),
    userId: user.contactID,
    dob: user.dateOfBirth ? dayjs(user.dateOfBirth).toDate() : undefined,
    gender: user.gender,
    cpCustomerId: cpCustomerID,
    spAccountId: spAccountID,
    zipCode: billingAddress?.zipCode
  };
}

/**
 * Generate a device activation code if user is not authenticated
 * or return the user tokens if the user is authenticated
 * @param {DeviceDetails} device
 */
// TODO: This function should not return tokens
// THIS should only return activationCode
export async function generateDeviceActivationCode(device: DeviceDetails): Promise<TokensOrActivationCode> {
  const result = await api<GenerateDeviceActivationCodeResponse>('generateDeviceActivationCode', {
    method: 'POST',
    body: {
      GenerateDeviceActivationCodeRequestMessage: {
        deviceDetails: device
      }
    }
  });

  const response = result.GenerateDeviceActivationCodeResponseMessage;

  if ('accessToken' in response) {
    // device already activated
    return {
      authenticated: true,
      accessToken: response.accessToken,
      refreshToken: response.refreshToken,
      expiresIn: response.expiresIn
    };
  }

  if ('activationCode' in response) {
    // device not yet activated
    return {
      authenticated: false,
      activationCode: response.activationCode
    };
  }

  // TODO: Throw Game Error
  throw new Error(response.failureMessage[0].errorMessage);
}

export async function addRemoveFavourites(
  type: 'favoriteTeams' | 'favoritePlayers',
  addIds?: string[],
  removeIds?: string[]
): Promise<AddOrRemoveFavouritesResponse> {
  let requestBody: AddOrRemoveFavoriteRequest | null = null;

  if (type === 'favoriteTeams') {
    requestBody = {
      favouriteType: type,
      favoriteTeamsToAdd: addIds,
      favoriteTeamsToRemove: removeIds
    };
  }

  if (type === 'favoritePlayers') {
    requestBody = {
      favouriteType: type,
      favoritePlayersToAdd: toPlayers(addIds),
      favoritePlayersToRemove: toPlayers(removeIds)
    };
  }

  return await api('addOrRemoveFavourites', {
    method: 'POST',
    body: {
      AddOrRemoveFavouritesRequestMessage: requestBody
    },
    metadata: { withToken: true }
  });
}

function toPlayer(playerId: string) {
  return { playerId };
}

function toPlayers(playerIds?: string[]) {
  return playerIds?.map(toPlayer);
}

/**
 * Registers device.
 * @param {string} activationCode Code to activate a device for a given account given the spAccountId.
 * @param {string} spAccountId Unique identifier.
 * @returns {{status: 'success'} | {status: 'failed', message: string} | {status: 'error'}}
 */
export async function registerDevice(
  activationCode: string,
  spAccountId: string
): Promise<{ status: 'success' } | { status: 'failed'; message: string } | { status: 'error' }> {
  try {
    const result = await api<OAuthResponse>('getOAuthAccessTokenv2', {
      method: 'POST',
      body: {
        GetOAuthAccessTokenv2RequestMessage: {
          spAccountID: spAccountId
        }
      }
    });

    if (!result) {
      return {
        status: 'failed',
        message: 'Unable to parse access token'
      };
    }

    const authResponse = result.GetOAuthAccessTokenv2ResponseMessage;

    const { accessToken } = authResponse;

    if (!accessToken) {
      return {
        status: 'failed',
        message: 'Unable to retrieve access token'
      };
    }

    const response = await api('registerDevice', {
      method: 'POST',
      body: {
        RegisterDeviceRequestMessage: {
          activationCode
        }
      },
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      metadata: { withToken: false }
    });
    const { responseCode } = response.RegisterDeviceResponseMessage ?? {};
    if (responseCode === RESPONSE_CODES.SUCCESS) {
      return { status: 'success' };
    }
    const [{ errorMessage }] = response.RegisterDeviceResponseMessage.failureMessage;
    return { status: 'failed', message: errorMessage };
  } catch (err) {
    if (err instanceof GameError && err.source === 'proxy') {
      return {
        status: 'failed',
        message: `Unable to register device: ${err.code} - ${err.message}`
      };
    }
    return { status: 'error' };
  }
}

export async function createUser(user: CreateUserInput, zoneId?: string, device?: Device) {
  const requestBody: CreateUser = {
    customerUsername: user.email,
    customerPassword: user.password,
    email: user.email,
    firstName: user.fullName,
    dmaId: zoneId,
    dateOfBirth: user.dob?.getTime(),
    gender: user.gender,
    isGenerateJWT: true,
    referralCode: user?.referralCode || null
  };

  if (device) {
    requestBody.deviceMessage = {
      serialNo: device.serialNo,
      deviceName: device.name,
      deviceType: device.type,
      modelNo: device.modelNo,
      appType: device.appType,
      locale: device.locale,
      userAgent: device.userAgent
    };
  }

  const response = await api('createUser', {
    method: 'POST',
    body: {
      CreateUserRequestMessage: requestBody
    }
  });

  const result = response.CreateUserResponseMessage as CreateUserResponse;

  return {
    cpCustomerId: result.cpCustomerID,
    accessToken: result.accessToken,
    refreshToken: result.refreshToken,
    expiresIn: result.expiresIn
  };
}

/**
 * Updates a user
 * @param {UpdateUserInput} user
 */
export async function updateUserProfile(user: UpdateUserInput) {
  const body: UpdateUser = {
    email: user.email,
    firstName: user.fullName,
    dateOfBirth: null
  };

  const dateOfBirth = user.dob?.getTime();

  if (dateOfBirth) {
    body.dateOfBirth = dateOfBirth;
  }

  if (user.gender) {
    body.gender = user.gender;
  }

  const response = await api('updateProfile', {
    method: 'POST',
    body: {
      UpdateProfileRequestMessage: body
    },
    metadata: { withToken: true }
  });

  const result = response.UpdateProfileResponseMessage;

  if (result.responseCode === RESPONSE_CODES.ERROR) {
    // TODO: Throw Game Error
    throw new Error('Update User Operation Failed');
  }
}

export async function getFavorites(): Promise<{ teams: string[]; players: string[] }> {
  const response = await api<GetFavouritesResponse>('getFavourites', {
    method: 'POST',
    body: {
      GetFavouritesRequestMessage: {}
    },
    metadata: { withToken: true }
  });

  const getFavouritesResponse = response as GetFavouritesResponse;
  const { favourites } = getFavouritesResponse.GetFavouritesResponseMessage;

  return {
    teams: favourites?.favoriteTeams ?? [],
    players: favourites?.favoritePlayers?.map((pl) => pl.playerId) ?? []
  };
}

export async function addSubscriptions(request: AddSubscriptionsRequest) {
  const { channel } = request;

  const service: AddSubscriptionService = {
    serviceId: request.serviceID,
    serviceType: 'PRODUCT',
    quantity: '1'
  };

  if (channel === 'Samsung Checkout' || channel === 'Vizio') {
    service.appServiceId = request.appServiceId;
  }

  const response = await api('addSubscriptions', {
    method: 'POST',
    body: {
      AddSubscriptionRequestMessage: {
        dmaID: request.dmaID,
        ...(request.channel === 'Vizio' && { isSkipDefaultPromo: false }),
        ...((request.channel === 'ApplePay' || request.channel === 'PayPalAccount') &&
          request.couponCode && { couponCode: request.couponCode }),
        service: [service],
        paymentmethodInfo: request.paymentInfo
      }
    },
    metadata: { withToken: true }
  });

  const result = response.AddSubscriptionsResponseMessage;

  return {
    success: true,
    orderID: result.orderID
  };
}

export async function changeService({ oldServiceID, newServiceID }: ChangeServiceRequest) {
  const response = await api('changeService', {
    method: 'POST',
    body: {
      ChangeServiceRequestMessage: {
        oldServiceID,
        newServiceID
      }
    },
    metadata: { withToken: true }
  });

  return response.ChangeServiceResponseMessage;
}

export async function removeSubscription({ serviceID, serviceType, reasonCode }: RemoveSubscriptionRequest) {
  const response = await api('removeSubscription', {
    method: 'POST',
    body: {
      RemoveSubscriptionRequestMessage: {
        serviceID,
        serviceType,
        reasonCode
      }
    },
    metadata: { withToken: true }
  });

  return response.RemoveSubscriptionResponseMessage;
}

export async function resumeSubscription(params: ResumeSubscriptionRequest) {
  const response = await api('reactivateService', {
    method: 'POST',
    body: {
      ReactivateServiceRequestMessage: params
    },
    metadata: { withToken: true }
  });

  return response.ResumeSubscriptionResponseMessage;
}

export async function getEntitlements() {
  const response = await api<GetEntitlementsResponse>('getEntitlements', {
    method: 'POST',
    body: {
      GetEntitlementsRequestMessage: {}
    },
    metadata: { withToken: true }
  });

  const { AccountServiceMessage, tvodToken, ovatToken, ovatTokenExpiry, dmaID } =
    response.GetEntitlementsResponseMessage;

  const entitlements = new Set<string>();
  const activeServiceMap = createServiceMap();

  for (const service of AccountServiceMessage) {
    const serviceEndDate = dayjs(service.validityEndDate);
    const now = dayjs();

    if (now.isBefore(serviceEndDate)) {
      if (service.ovpSKU) entitlements.add(service.ovpSKU);
      const serviceMap = getServiceMapFromService(service, activeServiceMap);
      serviceMap.set(service.serviceID, service);
      service.vodItems?.forEach((item) => entitlements.add(item.assetId));
    }
  }

  return {
    entitlements,
    activeServiceMap,
    tvodToken,
    ovatToken,
    ovatTokenExpiry,
    dmaID
  };
}

/**
 * Returns Evergent flatToken (ovatToken) and re-fetches it if expired or not stored
 */
export const fetchFlatToken = async (accessToken: string) => {
  const result = await api<GetEntitlementsResponse>('getEntitlements', {
    method: 'POST',
    body: {
      GetEntitlementsRequestMessage: {}
    },
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });

  const { ovatToken, ovatTokenExpiry } = result.GetEntitlementsResponseMessage;

  const ovatExpireTimestamp = Date.now() + ovatTokenExpiry * 1000;
  return {
    token: ovatToken,
    expire: ovatExpireTimestamp
  };
};

export async function addOrUpdateCouchRights(
  deviceID: string,
  dmaID: string,
  currentZipCode: string
): Promise<AddOrUpdateCouchRightsResponse> {
  return await api('addOrUpdateCouchRights', {
    method: 'POST',
    body: {
      AddOrUpdateCouchRightsRequestMessage: {
        deviceID,
        dmaID,
        currentZipCode
      }
    },
    metadata: { withToken: true }
  });
}

export async function getCouchRights(deviceID: string): Promise<GetCouchRightsResponse> {
  return api('getCouchRights', {
    method: 'POST',
    body: {
      GetCouchRightsRequestMessage: {
        deviceID
      }
    },
    metadata: { withToken: true }
  });
}

export async function forgotPassword(email: string): Promise<ForgotPasswordResponse> {
  return api('forgotContactPassword', {
    method: 'POST',
    body: {
      ForgotContactPasswordRequestMessage: {
        email
      }
    }
  });
}

export async function addTVESubscription(request: AddTVESubscriptionRequest) {
  return await api('addTVESubscription', {
    method: 'POST',
    body: {
      AddTVESubscriptionRequestMessage: request
    },
    metadata: { withToken: true }
  });
}

export async function getPayments(): Promise<GetPaymentsResponse> {
  const response = await api('getPayments', {
    method: 'POST',
    body: {
      GetPaymentsRequestMessage: {}
    },
    metadata: { withToken: true }
  });

  const result = response.GetPaymentsResponseMessage;

  return {
    payments:
      result.AccountPayments?.map(
        (_: any): Payment => ({
          orderedProductID: _.orderedProductID,
          maskedCardNumber: _.cardNumber,
          paymentMethodID: _.paymentMethodID
        })
      ) ?? []
  };
}

export async function getPaymentMethods() {
  const response = await api('getPaymentMethods', {
    method: 'POST',
    body: {
      GetPaymentMethodsRequestMessage: {}
    },
    metadata: { withToken: true }
  });

  return response.GetPaymentMethodsResponseMessage.PaymentMethods as PaymentMethod[];
}

export async function updatePaymentMethod(newCardDetails: UpdateCardParams) {
  try {
    await api('updatePaymentInfo', {
      method: 'POST',
      body: {
        UpdatePaymentInfoRequestMessage: newCardDetails
      },
      metadata: { withToken: true }
    });

    return { success: true, error: null };
  } catch (error) {
    return { success: false, error };
  }
}

export async function getActiveSubscriptions() {
  const response = await api<GetActiveSubscriptionsResponse>('getActiveSubscriptions', {
    method: 'POST',
    body: {
      GetActiveSubscriptionsRequestMessage: {
        // returnUpgradablePlans: 'true', // FUTURE: Implement upgradable plans
        returnTVOD: 'T'
      }
    },
    metadata: { withToken: true }
  });

  const result = response.GetActiveSubscriptionsResponseMessage;

  return {
    subscriptions: result.AccountServiceMessage?.map(
      (_: EvergentActiveSubscriptionDetail): ActiveSubscription => ({
        serviceType: _.serviceType,
        subscriptionType: _.subscriptionType,
        price: _.retailPrice,
        name: _.serviceName,
        displayName: _.displayName,
        id: _.serviceID,
        sku: _.serviceID,
        opId: _.opId,
        startDate: dayjs(_.startDate).toDate(),
        endDate: dayjs(_.validityTill).toDate(),
        isValid: dayjs(_.validityTill).isAfter(dayjs()),
        paymentMethod: _.paymentMethod,
        ovpSKU: _.ovpSku,
        // upgradablePlans: _.upgradablePlans, // FUTURE: Implement upgradable plans
        isCancelled: !_.cancellable
      })
    )
  };
}

export async function getSubscriptionHistory() {
  const response = await api<GetSubscriptionHistoryResponse>('getSubscriptionHistory', {
    method: 'POST',
    body: { GetSubscriptionHistoryRequestMessage: {} },
    metadata: { withToken: true }
  });

  const result = response.GetSubscriptionHistoryResponseMessage;
  if (result.responseCode === RESPONSE_CODES.ERROR) throw new Error("Failed to get user's subscription history");

  return result.AccountServiceMessage || [];
}

export async function resetPassword(
  email: string,
  contactPassword: string,
  userToken: string
): Promise<ResetPasswordResponse> {
  return api<ResetPasswordResponse>('resetPassword', {
    method: 'POST',
    body: {
      ResetPasswordRequestMessage: {
        email,
        contactPassword,
        userToken
      }
    }
  });
}

export async function changePassword(newPassword: string, confirmPassword: string): Promise<ChangePasswordResponse> {
  return api<ChangePasswordResponse>('changePassword', {
    method: 'POST',
    body: {
      ChangePasswordRequestMessage: {
        oldPassword: '',
        newPassword: newPassword,
        confirmNewpassword: confirmPassword
      }
    },
    metadata: { withToken: true }
  });
}

export async function generateReferralCode(params: GenerateReferralCodeParams) {
  const data = await api<ReferralCodeResponse>('generateReferralCode', {
    method: 'POST',
    body: {
      GenerateReferralCodeRequestMessage: {
        initiatedBy: 'evWeb', // TODO: This is the real value, but maybe we need to manage it better?
        ...params
      }
    },
    metadata: { withToken: true }
  });

  return {
    referralCode: data.GenerateReferralCodeResponseMessage.referralCode
  };
}

export async function addTVODOrder(request: AddTVODOrderRequest) {
  const requestBody = {
    AddTVODOrderRequestMessage: {
      assetType: 'TVOD',
      dmaID: request.dmaID,
      purchaseToken: request.purchaseToken,
      paymentmethodInfo: { ...request.paymentInfo },
      ...(request.channel === 'Samsung Checkout' && { price: request.price })
    }
  };

  const response = await api('addTVODOrder', {
    method: 'POST',
    body: requestBody,
    metadata: { withToken: true }
  });

  const result = response.AddTVODOrderResponseMessage;

  return {
    success: true,
    orderID: result.orderID
  };
}

export function removeDeviceFromAccount(deviceId: string) {
  return api('removeDeviceForAccount', {
    method: 'POST',
    body: {
      RemoveDeviceReqMessage: {
        serialNo: deviceId
      }
    },
    metadata: { withToken: true }
  });
}

export function disconnectTVEAccount(deviceId: string) {
  return api('disconnectTVEAccount', {
    method: 'POST',
    body: {
      DisconnectTVEAccountRequestMessage: {
        deviceID: deviceId
      }
    },
    metadata: { withToken: true }
  });
}

export async function getProrate(dmaID: string, serviceId: string, couponCode: string, zipCode: string) {
  const response = await api<GetProrateResponse>('getProrate', {
    method: 'POST',
    body: {
      GetProrateRequestMessage: {
        dmaID,
        itemsInfo: [
          {
            serviceType: 'Product',
            serviceId,
            quantity: '1'
          }
        ],
        coupons: [
          {
            couponCode
          }
        ],
        address: { zipCode }
      }
    },
    metadata: { withToken: true }
  });
  return {
    newPrice: response.GetProrateResponseMessage.totalPriceAfterTax,
    couponCode
  };
}
