import { getConfigFromRemote, useLocale } from '@game/core';
import { Typography } from '@game/core-ui';
import { Link } from '@tanstack/react-router';
import { z } from 'zod';
import { getPagePath } from '../lib/routing';

const localeSchema = z
  .object({
    settings: z.object({
      'help-support': z.string()
    }),
    legalScreen: z.object({
      termsOfService: z.string(),
      privacyPolicy: z.string(),
      privacyChoices: z.string(),
      noticeCaResidents: z.string()
    }),
    footer: z.object({
      copyright: z.string(),
      downloadApps: z.string()
    })
  })
  .transform(({ legalScreen, settings, footer }) => ({
    help: settings['help-support'],
    privacy: legalScreen.privacyPolicy,
    privacyChoices: legalScreen.privacyChoices,
    noticeCaResidents: legalScreen.noticeCaResidents,
    terms: legalScreen.termsOfService,
    copyright: footer.copyright,
    download: footer.downloadApps
  }));

const configSchema = z
  .object({
    accountLinks: z.object({
      legal: z.object({
        termsOfServiceURL: z.string().url(),
        privacyPolicyURL: z.string().url()
      })
    })
  })
  .transform(({ accountLinks: { legal } }) => {
    const caNoticeURL = new URL(legal.privacyPolicyURL);
    caNoticeURL.hash = 'noticeCA';
    const yourPrivacyURL = new URL(legal.privacyPolicyURL);
    yourPrivacyURL.hash = 'yourrightschoices';
    return {
      links: {
        terms: new URL(legal.termsOfServiceURL),
        privacy: new URL(legal.privacyPolicyURL),
        caNotice: caNoticeURL,
        yourPrivacy: yourPrivacyURL
      }
    };
  });

export function Footer() {
  const { locales } = useLocale({ schema: localeSchema });
  const { links } = getConfigFromRemote(configSchema);
  return (
    <div className="bg-secondary-700 flex max-h-fit justify-center p-5">
      <div>
        <Typography
          variant="body3"
          className="text-secondary-100 mb-2 flex cursor-default flex-wrap justify-center gap-x-3"
        >
          <Link to={getPagePath('publicHelp')}>{locales.help}</Link>|
          <a href={links.terms.href} target="_blank" rel="noreferrer">
            {locales.terms}
          </a>
          |
          <a href={links.privacy.href} target="_blank" rel="noreferrer">
            {locales.privacy}
          </a>
          |
          <a href={links.yourPrivacy.href} target="_blank" rel="noreferrer">
            {locales.privacyChoices}
          </a>
          |
          <a href={links.caNotice.href} target="_blank" rel="noreferrer">
            {locales.noticeCaResidents}
          </a>
          |<span className="text-center">{locales.copyright}</span>
        </Typography>
        <Typography variant="body4" className="text-secondary-100 text-center">
          {locales.download}
        </Typography>
      </div>
    </div>
  );
}
