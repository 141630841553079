import { Control, Field } from '@radix-ui/react-form';
import { useCallback, useRef, type ReactNode } from 'react';
import { Toggle } from '../toggle';

type CheckboxToggleProps = {
  name: string;
  onClick?: (value: boolean) => void;
  checked?: boolean;
  value?: string | number;
  children: ReactNode;
  className?: string;
  defaultPressed?: boolean;
};

/**
 * ToggleCheckbox component
 * @param {String} name used to set name prop of input, in order to catch on the form
 * @param {Function} [onClick] the URL to the image
 * @param {Boolean} [checked] the value to be passed on image height
 * @param {String | Number} [value] the value to be passed on image height
 * @param {ReactNode} children A function to be triggered when user click on the checkbox
 * @param {Boolean} [defaultPressed] default checked value
 * @param {String} [className] className to apply to the toggle checkbox
 *
 * @example
 * <ToggleCheckbox name={name} checked={selected} onClick={onClick}>
        <div>Content</div>
    </ToggleCheckBox>
 */

export const ToggleCheckbox = (props: CheckboxToggleProps) => {
  const { name, children, onClick, checked, className, defaultPressed, value } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const handlePress = useCallback(
    (value: boolean) => {
      if (checked != undefined) {
        onClick?.(value);
        return;
      }

      if (inputRef.current) {
        inputRef.current.checked = value;
      }
    },
    [checked, onClick]
  );

  return (
    <Toggle onPressedChange={handlePress} pressed={checked} className={className} defaultPressed={defaultPressed}>
      <Field name={name} className="h-full w-full">
        <Control asChild>
          <input
            id={name}
            ref={inputRef}
            type="checkbox"
            value={value}
            checked={checked}
            className="peer sr-only"
            defaultChecked={defaultPressed}
          />
        </Control>
        {children}
      </Field>
    </Toggle>
  );
};
