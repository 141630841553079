import { API_PROXY_BASE_URL, RSN_ID } from '../../config/build-config';
import { TokenRefreshError } from '../../lib/error';
import { fetcher } from '../../lib/fetcher';
import { transformGAMEResponse } from '../../services/api/game-proxy';
import { GameError } from '../errors';
import { removeSessionTokens } from './services';
import { deleteTokens, getTokens, saveTokens, resetXDeviceId } from './token-storage';

type RefreshTokenResponse = {
  RefreshTokenResponseMessage: {
    accessToken: string;
    refreshToken: string;
    expiresIn: string;
  };
};

/**
 * Handle the concurrent refresh token request.
 */
class RefreshTokenService {
  private refreshing: ReturnType<typeof this.refreshToken> | null = null;

  /**
   * @throws {@link TokenRefreshError} throw when refresh token not found or refresh api failed
   */
  public async refresh(): Promise<RefreshTokenResponse['RefreshTokenResponseMessage'] | TokenRefreshError> {
    const tokens = getTokens();
    if (!tokens?.refreshToken) {
      return new TokenRefreshError('Token is not found when trying to refresh');
    }

    let data: RefreshTokenResponse | undefined = undefined;

    try {
      if (this.refreshing) {
        const response = await this.refreshing;
        return response.RefreshTokenResponseMessage;
      }

      this.refreshing = this.refreshToken(tokens.refreshToken);
      data = await this.refreshing;

      this.onRefreshTokenSuccess(data);

      return data.RefreshTokenResponseMessage;
    } catch (e) {
      const message = e instanceof GameError ? e.description : 'Failed to refresh token';
      deleteTokens();
      return new TokenRefreshError(message, data);
    } finally {
      this.cleanup();
      this.refreshing = null;
    }
  }

  private async refreshToken(token: string): Promise<RefreshTokenResponse> {
    return await api<RefreshTokenResponse>('refreshToken', {
      method: 'POST',
      body: {
        RefreshTokenRequestMessage: {
          refreshToken: token
        }
      }
    });
  }

  private onRefreshTokenSuccess(data: RefreshTokenResponse) {
    const { RefreshTokenResponseMessage: message } = data;

    saveTokens({
      accessToken: message.accessToken,
      refreshToken: message.refreshToken,
      expiresIn: message.expiresIn
    });
  }

  private cleanup() {
    removeSessionTokens();
    resetXDeviceId();
  }
}

export const refreshTokenService = new RefreshTokenService();

const api = fetcher.create({
  baseURL: API_PROXY_BASE_URL,
  headers: {
    'gg-rsn-id': RSN_ID
  },
  transformResponse: transformGAMEResponse
});
