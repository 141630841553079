import { getSearchedAccountFromStorage, isFeatureEnabled, type FeaturesSocialLogin } from '@game/core';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { getPagePath } from '../../../lib/routing';
import { redirectSearchSchema } from '../../../lib/routing/redirect-search';

export const Route = createFileRoute('/_public/login')({
  validateSearch: redirectSearchSchema,
  beforeLoad: async ({ search }) => {
    const data = getSearchedAccountFromStorage();
    const socialLogin = (await isFeatureEnabled('socialLogin')) as FeaturesSocialLogin;

    if (!data?.exists) throw redirect({ to: getPagePath('launch') });

    const { originalRSN, isSocialLogin, email, hasPassword } = data;

    return {
      originalRSN,
      isSocialLogin,
      email,
      hasPassword,
      redirect: search.r,
      features: {
        socialLogin
      }
    };
  }
});
