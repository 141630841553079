import { getConfigFromRemote } from '@game/core';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';
import { scheduleConfigSchema } from '../../../../lib/config/schedule';
import { getPagePath } from '../../../../lib/routing';

const routeParamsSchema = z.enum(['games', 'channels']);

export const Route = createFileRoute('/_private/_home/schedule/$type')({
  beforeLoad: ({ params: { type } }) => {
    const parsedParam = routeParamsSchema.safeParse(type);

    if (!parsedParam.success) throw redirect({ to: getPagePath('schedule'), params: { type: 'games' } });

    const { scheduleDaysLimit, scheduleMonthsLimit } = getConfigFromRemote(scheduleConfigSchema);

    return {
      type: parsedParam.data,
      numberOfAvailableMonths: scheduleMonthsLimit,
      numberOfAvailableDays: scheduleDaysLimit
    };
  }
});
