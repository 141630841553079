import { createFileRoute } from '@tanstack/react-router';
import { getUserData } from '@game/core';

export const Route = createFileRoute('/_private/_home/account/delete')({
  beforeLoad: async () => {
    const user = await getUserData();

    return {
      user: user
    };
  },
  loader: ({ context: { queryClient, ...rest } }) => rest
});
