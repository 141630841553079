import { z } from 'zod';

export const helpLocalesSchema = z
  .object({
    helpScreen: z.object({
      help: z.string(),
      contactSupport: z.string(),
      helpAndFAQ: z.string(),
      pickAndPlay: z.string()
    })
  })
  .transform((values) => values.helpScreen);

export type HelpLocale = z.infer<typeof helpLocalesSchema>;
