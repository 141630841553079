import { z } from 'zod';

export const launch = z
  .object({
    launch: z.object({
      email: z.string(),
      'email-address': z.string(),
      'enter-email-address': z.string(),
      'enter-email-login-register': z.string(),
      'enter-email-login': z.string(),
      'enter-valid-email': z.string(),
      'login-register-description': z.string(),
      'login-description': z.string(),
      'sign-in': z.string(),
      login: z.string(),
      'subscribe-now': z.string(),
      'subscribe-now-description': z.string(),
      'login-error-title': z.string(),
      'login-error-description': z.string(),
      register: z.string()
    })
  })
  .transform((values) => {
    const {
      email,
      'email-address': emailAddress,
      'enter-email-address': emailValidationMissing,
      'enter-email-login-register': enterEmailLoginRegister,
      'enter-email-login': enterEmailLogin,
      'enter-valid-email': emailValidation,
      login,
      'subscribe-now': subscribeNow,
      'subscribe-now-description': subscribeNowDescription,
      register,
      'login-register-description': loginRegisterDescription,
      'login-description': loginDescription,
      'login-error-title': loginErrorTitle,
      'login-error-description': loginErrorDescription,
      'sign-in': signIn
    } = values['launch'];
    return {
      email,
      emailAddress,
      emailValidation,
      emailValidationMissing,
      enterEmailLoginRegister,
      enterEmailLogin,
      login,
      subscribeNow,
      subscribeNowDescription,
      register,
      loginRegisterDescription,
      loginErrorTitle,
      loginErrorDescription,
      loginDescription,
      signIn
    };
  });

export type LaunchLocale = z.infer<typeof launch>;
