import { GetSeeAllRailQuery } from '@game/core';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { getPagePath } from '../../../lib/routing';

export const Route = createFileRoute('/_private/content/$railId')({
  beforeLoad: async ({ params: { railId }, context: { queryClient } }) => {
    const currentRail = await queryClient.fetchQuery(
      GetSeeAllRailQuery({
        railId
      })
    );

    if (!currentRail) throw redirect({ to: getPagePath('watch') });

    const { containerUrl, title, cardStyle, id } = currentRail;

    if (!containerUrl) throw redirect({ to: getPagePath('watch') });

    return {
      title,
      cardStyle,
      containerUrl,
      railId: id
    };
  }
});
