import { GameConfig } from '../../config/game-config';
import { findRSN } from '../rsns';
import type { ConfigChannel } from './types';

export function toChannel(channel: ConfigChannel) {
  const { channelLogos, ...rest } = channel;
  const rsn = findRSN(channel.rsnId);

  return {
    ...rest,
    rsnName: rsn?.name,
    channelLogo: channelLogos[GameConfig.get.basePlatform],
    logo: rsn?.logo,
    logoLarge: rsn?.logoLarge,
    defaultHeroBg: rsn?.defaultHeroBg
  };
}
