import { type QueryClient } from '@tanstack/react-query';
import { type z } from 'zod';
import { toURL } from '../../../lib/utils';
import { getConfigFromRemote } from '../../config-manager';
import { localization, transformer } from '../config/localization';
import { fetchLocalizationConfig } from '../services';
import { useLocalizationStore, useUserLocaleStore } from '../store';

import { ERROR_SOURCE, GameError } from '../../errors';
import { CONFIG_ERROR_CODE } from '../../errors/type';

const STALE_TIME = 1000 * 60 * 60; // 60 minutes
export const loadLocalization = async (queryClient: QueryClient) => {
  const parsedConfig = getConfigFromRemote(localization.schema);
  if (parsedConfig) {
    const { defaultLocale, supportedLocales, localeURL } = transformer(parsedConfig);
    const userLocale = getUserLocale();
    const locale = userLocale && supportedLocales.includes(userLocale) ? userLocale : defaultLocale;
    const url = toURL(localeURL, `/${locale}.json`);

    const config = await queryClient.ensureQueryData({
      queryKey: ['localization', locale],
      queryFn: () => {
        return fetchLocalizationConfig(url);
      },
      staleTime: STALE_TIME
    });

    useLocalizationStore.getState().setConfig(config);
    useLocalizationStore.getState().setVersion(config.version);
  }
};

export const getLocalizationConfig = <T extends z.Schema>(schema: T): z.infer<T> => {
  const { config } = useLocalizationStore.getState();
  const parsedConfig = schema.safeParse(config);
  if (parsedConfig.success) {
    return parsedConfig.data;
  }
  throw new GameError(ERROR_SOURCE.CONFIG, CONFIG_ERROR_CODE.LOCALIZATION, parsedConfig.error.message);
};

export function getUserLocale() {
  const { locale } = useUserLocaleStore.getState();
  return locale;
}

export function setUserLocale(locale: string) {
  useUserLocaleStore.getState().setLocale(locale);
}
