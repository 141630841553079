import { FLAT_TOKEN, FLAT_TOKEN_EXPIRY, TVOD_TOKEN } from '../../../bootstrap';
import { getFromStorage, removeFromStorage, saveToStorage } from '../../lib/utils/storage';

export const getEvergentFlatToken = () => {
  const token = getFromStorage(FLAT_TOKEN);
  if (token) {
    return {
      token,
      expire: Number(getFromStorage(FLAT_TOKEN_EXPIRY))
    };
  }
  return null;
};

export const setEvergentFlatToken = (token: string, tokenExpire: number) => {
  saveToStorage(FLAT_TOKEN, token);
  saveToStorage(FLAT_TOKEN_EXPIRY, tokenExpire.toString());
};

export const deleteEvergentFlatToken = () => {
  removeFromStorage(FLAT_TOKEN);
  removeFromStorage(FLAT_TOKEN_EXPIRY);
};

export const getEvergentTVODToken = () => {
  const token = getFromStorage(TVOD_TOKEN);
  return token ?? null;
};

export const setEvergentTVODToken = (token: string) => {
  saveToStorage(TVOD_TOKEN, token);
};

export const deleteEvergentTVODToken = () => {
  removeFromStorage(TVOD_TOKEN);
};
