import { type z } from 'zod';
import { getLocalizationConfig } from '../lib';

type UseLocalizeParams<T extends z.Schema> = {
  schema: T;
};

export function useLocale<T extends z.Schema>(params: UseLocalizeParams<T>) {
  const { schema } = params;
  return {
    locales: getLocalizationConfig(schema)
  };
}
