import { z } from 'zod';
import { toURL } from '../../lib/utils';

const paymentMethodSchema = z.object({
  subscriptionPaymentMethod: z.object({
    platform: z.string(),
    logo: z.string().url()
  })
});

export const paymentConfigSchema = z
  .object({
    baseURL: z.string().url(),
    iOS: paymentMethodSchema,
    android: paymentMethodSchema,
    androidTV: z.object({
      fireTV: paymentMethodSchema
    }),
    roku: paymentMethodSchema,
    samsung: paymentMethodSchema,
    vizio: paymentMethodSchema,
    assets: z.object({
      paymentMethodLogos: z.object({
        paypalLogo: z.string()
      })
    })
  })
  .transform((config) => {
    const {
      baseURL,
      iOS: {
        subscriptionPaymentMethod: { logo: applePayLogo }
      },
      android: {
        subscriptionPaymentMethod: { logo: googlePayLogo }
      },
      androidTV: {
        fireTV: {
          subscriptionPaymentMethod: { logo: amazonPayLogo }
        }
      },
      roku: {
        subscriptionPaymentMethod: { logo: rokuPayLogo }
      },
      samsung: {
        subscriptionPaymentMethod: { logo: samsungPayLogo }
      },
      vizio: {
        subscriptionPaymentMethod: { logo: vizioAccountLogo }
      },
      assets: {
        paymentMethodLogos: { paypalLogo: payPalLogo }
      }
    } = config;

    return {
      AmazonPay: {
        logo: toURL(amazonPayLogo)
      },
      ApplePay: {
        logo: toURL(applePayLogo)
      },
      AppleAppStore: {
        logo: toURL(applePayLogo)
      },
      GooglePay: {
        logo: toURL(googlePayLogo)
      },
      RokuPay: {
        logo: toURL(rokuPayLogo)
      },
      SamsungPay: {
        logo: toURL(samsungPayLogo)
      },
      VizioAccount: {
        logo: toURL(vizioAccountLogo)
      },
      PayPal: {
        logo: toURL(baseURL, payPalLogo)
      }
    };
  });
