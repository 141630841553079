import { z } from 'zod';

export const gameErrors = z
  .object({
    errors: z.object({
      'generic-error': z.string(),
      'error-from-game-description': z.string(),
      'generic-title': z.string(),
      'generic-message': z.string(),
      'evergent-error': z.string(),
      'account-cID-error': z.string(),
      'account-doesnt-exist': z.string(),
      'account-exists': z.string(),
      'account-spID-error': z.string(),
      'account-Id-nonexistent': z.string(),
      'annual-device-limit': z.string(),
      'authentication-failed': z.string(),
      'concurrent-device-limit': z.string(),
      'content-unavailable': z.string(),
      'content-unavailable-blackout': z.string(),
      'content-unavailable-ended': z.string(),
      'content-unavailable-login-again': z.string(),
      'content-unavailable-reinstall': z.string(),
      'content-unavailable-try-later': z.string(),
      'content-unavailable-relaunch': z.string(),
      'content-unavailable-unauthorized': z.string(),
      'content-unavailable-outside-region': z.string(),
      'content-unavailable-outside-region-roaming': z.string(),
      'content-unavailable-outside-usa': z.string(),
      'enter-valid-email': z.string(),
      'game-pass-token-unavailable': z.string(),
      'generic-evergent-error': z.string(),
      'generic-playback-error': z.string(),
      'invalid-contactId': z.string(),
      'invalid-coupon-code': z.string(),
      'invalid-email': z.string(),
      'invalid-parental-control-pin': z.string(),
      'invalid-phone-or-email': z.string(),
      'invalid-session-token': z.string(),
      'invalid-userID': z.string(),
      'login-limit': z.string(),
      'max-stream-limit': z.string(),
      'missing-parameter': z.string(),
      'no-network-connection': z.string(),
      'parental-controls-inactive': z.string(),
      'password-over-length': z.string(),
      'password-under-length': z.string(),
      'request-error': z.string(),
      'tve-session-expired': z.string(),
      'tve-link-limit': z.string(),
      'userID-has-spaces': z.string(),
      'userID-over-length': z.string(),
      'userID-under-length': z.string(),
      'wrong-username': z.string(),
      'default-quickplay-error': z.string(),
      'default-evergent-error': z.string(),
      'default-bitmovin-error': z.string(),
      'default-heartbeat-error': z.string(),
      'default-generic-error': z.string()
    })
  })
  .transform((values) => {
    return values.errors;
  });

export type GameErrorLocale = z.infer<typeof gameErrors>;
