import { QueryClient, QueryClientProvider as TanstackQueryClientProvider } from '@tanstack/react-query';
import type { ReactNode } from 'react';
import { GameConfig } from '../config/game-config';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'always'
    },
    mutations: {
      networkMode: 'always',
      throwOnError: () => !GameConfig.get.eventHandler,
      onError: async (error) => GameConfig.get.eventHandler?.(error)
    }
  }
});

export function QueryClientProvider({ children }: { children: ReactNode }) {
  return <TanstackQueryClientProvider client={queryClient}>{children}</TanstackQueryClientProvider>;
}
