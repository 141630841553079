import { queryOptions } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { getConfigFromRemote, GetRuntimeConfigQuery } from '../config-manager';
import { GetUserQuery } from '../user';
import { configCacheDuration } from './schema';
import { getChannelEPG, getChannels, getScheduledGames } from './service';
import type { GetGamesQueryParams } from './type';

const CHANNEL_STALE_TIME = 1000 * 60 * 60; // 1 hour
const CHANEL_EPG_STALE_TIME = 1000 * 60 * 60; // 1 hour

export const GetChannelsQuery = queryOptions({
  queryKey: [...GetRuntimeConfigQuery.queryKey, 'channels'],
  queryFn: getChannels,
  staleTime: CHANNEL_STALE_TIME
});

export const GetChannelEPGQuery = (channelId: string, date: Date) => {
  const { cacheDuration } = getConfigFromRemote(configCacheDuration);

  const dateKey = dayjs(date).format('MM-DD');

  return queryOptions({
    queryKey: [...GetChannelsQuery.queryKey, 'epg', channelId, dateKey],
    queryFn: () => getChannelEPG({ channelId, startDate: date }),
    staleTime: cacheDuration ?? CHANEL_EPG_STALE_TIME
  });
};

export const GetScheduledGamesQuery = (params: GetGamesQueryParams) => {
  const { endDate, startDate, ...rest } = params;

  const { cacheDuration } = getConfigFromRemote(configCacheDuration);

  const endDateKey = dayjs(endDate).format('MM-DD');

  const startDateKey = dayjs(startDate).format('MM-DD');

  return queryOptions({
    queryKey: [...GetUserQuery.queryKey, 'scheduled-games', { ...rest, endDateKey, startDateKey }],
    queryFn: async () => getScheduledGames(params),
    staleTime: cacheDuration ?? CHANEL_EPG_STALE_TIME
  });
};
