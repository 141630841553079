import { toURL } from '@game/core';
import { z } from 'zod';

const schema = z.object({
  baseURL: z.string(),
  assets: z.object({
    headshotPlaceholder: z.object({
      full: z.string()
    })
  })
});

type RemoteConfigPickFavoritePlayersConfig = z.infer<typeof schema>;

export const transformer = (config: RemoteConfigPickFavoritePlayersConfig) => {
  const {
    baseURL,
    assets: {
      headshotPlaceholder: { full }
    }
  } = config;

  return {
    headshotPlaceholder: toURL(baseURL, full)
  };
};

export const playersConfigSchema = schema.transform((values) => {
  const {
    baseURL,
    assets: {
      headshotPlaceholder: { full }
    }
  } = values;

  return {
    headshotPlaceholder: toURL(baseURL, full)
  };
});

export type PickFavoritePlayersConfig = z.infer<typeof playersConfigSchema>;

export const pickFavoritePlayers = { schema, transformer };
