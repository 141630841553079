import { GetFavoritesQuery } from '@game/core';
import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/_private/_home/account/_favorites')({
  loader: async ({ context: { queryClient } }) => {
    const favorites = await queryClient.fetchQuery(GetFavoritesQuery);

    return {
      favorites
    };
  },
  component: Outlet
});
