import { Analytics, Constants, type ConvivaOptions } from '@convivainc/conviva-js-coresdk';
import { convivaAppTracker } from '@convivainc/conviva-js-appanalytics';
import { PerformanceTimingPlugin } from '@convivainc/conviva-js-appanalytics-performance-timing';
import { ErrorTrackingPlugin, enableErrorTracking } from '@convivainc/conviva-js-appanalytics-error-tracking';
import {
  LinkClickTrackingPlugin,
  enableButtonClickTracking,
  enableLinkClickTracking
} from '@convivainc/conviva-js-appanalytics-click-tracking';
import { getPlayerName } from '../bitmovin';
import { ANALYTICS_CONFIG, CONVIVA } from '../../config/build-config';
import { GameConfig } from '../../config/game-config';
import { toConvivaDevice } from './util';
import { eventHandler } from './handler';
import { register } from '../../lib/action-handlers/action-handlers';

export function initConviva() {
  const gateway = CONVIVA.GATEWAY_URL;
  const options: ConvivaOptions = gateway
    ? {
        [Constants.GATEWAY_URL]: gateway,
        [Constants.LOG_LEVEL]: Constants.LogLevel.INFO
      }
    : {};

  const customerKey = getCustomerKey();

  Analytics.init(customerKey, null, options);

  // Init app tracker
  convivaAppTracker({
    appId: getPlayerName(),
    appVersion: GameConfig.get.release,
    convivaCustomerKey: customerKey,
    contexts: {
      performanceTiming: true
    },
    plugins: [PerformanceTimingPlugin(), ErrorTrackingPlugin(), LinkClickTrackingPlugin()]
  });

  GameConfig.get.getDeviceDetails().then((device) =>
    Analytics.setDeviceMetadata(
      toConvivaDevice({
        brand: device.brand,
        name: device.deviceName,
        type: device.deviceType,
        serialNo: device.serialNo,
        modelNo: device.modelNo ?? '',
        appType: '',
        locale: ''
      })
    )
  );

  const videoAnalytics = Analytics.buildVideoAnalytics();
  const adAnalytics = Analytics.buildAdAnalytics(videoAnalytics);

  const unregister = register(eventHandler(videoAnalytics, adAnalytics));

  enableErrorTracking();
  enableLinkClickTracking();
  enableButtonClickTracking();

  return () => {
    adAnalytics.release();
    videoAnalytics.release();
    Analytics.release();
    unregister();
  };
}

function getCustomerKey() {
  const conviva = ANALYTICS_CONFIG['conviva'];
  if (!conviva) {
    throw new Error('Conviva analytics config is not found in build config');
  }
  return conviva.keyInfo.key;
}
