import * as RadixModal from '@radix-ui/react-dialog';
import { Button } from '../button';
import { Typography } from '../typography';
import { Modal, type ModalProps } from './modal';

type Props = ModalProps & {
  title: string;
  description?: string;
  buttonText?: string;
};

function MessageModal(props: Props) {
  const { title, description, buttonText, ...rest } = props;
  return (
    <Modal {...rest}>
      <main className="mx-auto flex h-full w-[80%] items-center">
        <section className="flex h-[80%] w-full flex-col justify-center ">
          <div className="mb-10 flex flex-col items-center text-center md:mb-[14.5rem] md:pt-[6.25rem]">
            <Typography variant="h6" className="mb-10">
              {title}
            </Typography>
            <Typography variant="body3">{description}</Typography>
          </div>

          <RadixModal.Close asChild>
            <Button variant="primary" className="mx-auto max-w-[35rem]">
              {buttonText}
            </Button>
          </RadixModal.Close>
        </section>
      </main>
    </Modal>
  );
}

MessageModal.displayName = 'MessageModal';

export { MessageModal };
