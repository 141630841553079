import type { DefaultFetcherOptions, FetchInput, FetcherOptions } from './type';

export function toRequest(
  input: FetchInput,
  createOptions: DefaultFetcherOptions<any>,
  options?: FetcherOptions<any>
): Request {
  if (input instanceof Request) {
    return input;
  }

  const { body, search, headers = {}, method } = options ?? {};

  let url: URL;
  if (typeof input === 'string') {
    url = new URL(input, createOptions.baseURL);
  } else {
    url = input;
  }

  if (search) {
    appendSearchParams(url.searchParams, search ?? {});
  }

  const mergedHeaders = mergeHeaders(createOptions.headers ?? {}, headers);

  return new Request(url, {
    method,
    headers: mergedHeaders,
    body: body ? toBodyInit(body, mergedHeaders) : undefined
  });
}

export function mergeHeaders(...headerInits: HeadersInit[]): Headers {
  const merged = new Headers();
  headerInits.forEach((headerInit) => {
    if (headerInit instanceof Headers) {
      for (const [key, value] of headerInit) {
        merged.set(key, value);
      }
      return;
    }
    const header = new Headers(headerInit);
    for (const [key, value] of header) {
      merged.set(key, value);
    }
  });

  return merged;
}

export function toBodyInit(value: any, headers: Headers): BodyInit {
  if (isBodyInit(value)) {
    return value;
  }

  headers.set('Content-Type', 'application/json');
  headers.set('Accept', 'application/json');
  return JSON.stringify(value);
}

function isBodyInit(value: any): value is BodyInit {
  return (
    typeof value === 'string' ||
    value instanceof ArrayBuffer ||
    value instanceof Blob ||
    value instanceof FormData ||
    value instanceof URLSearchParams ||
    value instanceof ReadableStream
  );
}

export function appendSearchParams(
  searchParams: URLSearchParams,
  search: Record<string, string | number | string[]> | URLSearchParams
) {
  if (search instanceof URLSearchParams) {
    for (const [key, value] of search) {
      searchParams.append(key, value);
    }
    return;
  }

  for (const [key, value] of Object.entries(search ?? {})) {
    if (Array.isArray(value)) {
      searchParams.append(key, value.join(','));
    }
    searchParams.append(key, value.toString());
  }
}
