import {
  type Affiliate,
  emit,
  getUseCase,
  getUserLocation,
  isUserOutOfCountry,
  loadConfig,
  loadLocalization,
  setUserLocale,
  useAffiliateStore
} from '@game/core';
import { type QueryClient } from '@tanstack/react-query';
import {
  createRootRouteWithContext,
  Navigate,
  Outlet,
  ScrollRestoration,
  useLocation,
  useMatches,
  useRouteContext
} from '@tanstack/react-router';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { Suspense, useEffect } from 'react';
import { z } from 'zod';
import { GameErrorComponent } from '../components/error/error-component';
import { Footer } from '../components/footer';

type RootRouteContext = {
  queryClient: QueryClient;
  isUserOutOfCountry: boolean;
  footer: boolean;
};

const search = z.object({
  locale: z.string().optional(),
  affiliate: z.string().optional()
});

export const Route = createRootRouteWithContext<RootRouteContext>()({
  validateSearch: search,
  beforeLoad: async ({ context: { queryClient }, search }) => {
    await loadConfig();
    const { locale, affiliate } = search;
    const { setAffiliate } = useAffiliateStore.getState();
    if (affiliate) setAffiliate(affiliate as Affiliate);
    if (locale) {
      setUserLocale(locale);
      dayjs.locale(locale);
    }
    await loadLocalization(queryClient);
    const userLocation = await getUserLocation();

    return {
      isUserOutOfCountry: isUserOutOfCountry(userLocation),
      userLocation
    };
  },
  errorComponent: ({ error }) => {
    return (
      <GameErrorComponent
        error={error}
        useCase={getUseCase()}
        reset={() => {
          // TODO: reset?
        }}
      />
    );
  },
  loader: ({ context: { isUserOutOfCountry } }) => {
    return { isUserOutOfCountry };
  },
  component: Root,
  notFoundComponent: () => <Navigate to="/" />
});

function Root() {
  const { pathname } = useLocation();

  useEffect(() => {
    emit('ScreenVisited', pathname);
  }, [pathname]);

  const matches = useMatches();
  const currentRoute = matches[matches.length - 1];
  const { footer = true } = useRouteContext({ from: currentRoute.routeId });

  return (
    <div className="bg-secondary-700 text-light min-h-screen w-full">
      <Suspense>
        <div className="flex min-h-screen flex-col">
          <ScrollRestoration />
          <div className="flex flex-1 flex-col overflow-y-auto">
            <Outlet />
          </div>
          {footer ? <Footer /> : null}
        </div>
      </Suspense>
    </div>
  );
}
