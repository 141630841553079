import type { EvergentAccountRole } from './types';
import type { UserRole } from '../../../features/user/types';

export function toUserRole(accountRole?: EvergentAccountRole): UserRole {
  switch (accountRole) {
    case 'QA User':
      return 'QA';
    case 'VIP User':
      return 'VIP';
    default:
      return 'REG';
  }
}
