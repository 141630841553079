import { Checkout } from '../../services/api/checkout';
import type { CheckoutPaymentContextRequest, CheckoutPaymentRequest } from '../../services/api/checkout/types';
import { getConfigFromRemote } from '../config-manager';
import { paymentConfigSchema } from './schema';

export async function getPaymentContext(request: CheckoutPaymentContextRequest) {
  return Checkout.getPaymentContext(request);
}

export async function processPayment(request: CheckoutPaymentRequest) {
  return Checkout.processPayment(request);
}

export function getPaymentLogos() {
  return getConfigFromRemote(paymentConfigSchema);
}
