import { createFileRoute } from '@tanstack/react-router';
import { getConfigFromRemote } from '@game/core';
import { legal as legalConfig } from '../../../lib/config';

export const Route = createFileRoute('/_public/privacy-policy')({
  beforeLoad: () => ({ config: getConfigFromRemote(legalConfig.schema) }),
  loader: async ({ context: { config } }) => {
    return {
      config
    };
  }
});
