import { type Actor } from 'xstate';

export type UseCaseEventHandlers = {
  handleOnboardExisting(): void | Promise<void>;
  handleOnboard(): void | Promise<void>;
  handleNavigateLaunch(): void | Promise<void>;
  handleNavigateWatch(): void | Promise<void>;
  handleNavigateAccount(): void | Promise<void>;
  handleLogout(): void | Promise<void>;
};

type UseCaseEventHandler = Partial<UseCaseEventHandlers>;

export type StateMachineActor = Actor<any>;

function defineEventHandler(fn: (actor: StateMachineActor) => UseCaseEventHandler) {
  return (actor: StateMachineActor) => fn(actor);
}

export const eventHandler = defineEventHandler((actor: StateMachineActor) => ({
  handleOnboardExisting(): void {
    actor.send({ type: 'EXISTING_USER' });
  },
  handleOnboard() {
    actor.send({ type: 'NEW_USER' });
  },
  handleNavigateLaunch(): void {
    actor.send({ type: 'NAVIGATE_LAUNCH' });
  },
  handleNavigateWatch(): void {
    actor.send({ type: 'NAVIGATE_WATCH' });
  },
  handleNavigateAccount(): void {
    actor.send({ type: 'NAVIGATE_ACCOUNT' });
  },
  handleLogout(): void {
    actor.send({ type: 'LOGOUT' });
  }
}));
