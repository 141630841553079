import { ADOBE, completeDeviceLinking, emit, getExternalTveLoginUrl, tveLogin, useLocale } from '@game/core';
import { Image, Select, Typography } from '@game/core-ui';
import { useMutation } from '@tanstack/react-query';
import { Link, Navigate } from '@tanstack/react-router';
import { useCallback, useEffect, useMemo, useState, type ComponentProps, type ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { Spinner } from '../../../components/spinner/Spinner';
import { secondScreenSchema } from '../../../lib/locale/second-screen';
import { getPagePath } from '../../../lib/routing';
import type { TvProvider } from './util';

type SecondScreenProps = {
  premium: TvProvider[];
  other: TvProvider[];
  regCode: string;
  redirect?: ComponentProps<typeof Navigate>;
  header?: ReactNode;
  external?: boolean;
};

export const TVE_LOGIN_STORAGE_KEY = 'SECOND_SCREEN_STATUS';

export function SecondScreen(props: SecondScreenProps) {
  const { premium, other, regCode, header, redirect, external } = props;
  const { locales } = useLocale({ schema: secondScreenSchema });
  const [activated, setActivated] = useState(false);
  const [linked, setLinked] = useState(false);

  const selectTVEHandler = useCallback(
    (mvpdId: string) => {
      const loginUrl = getExternalTveLoginUrl({
        regCode,
        mvpdId,
        domainName: ADOBE.AUTHENTICATE_DOMAIN_NAME,
        redirectUrl: `${window.location.origin}/authenticate/status?ex=${!!external}` // ADOBE.AUTHENTICATE_REDIRECT_URL
      });
      if (external) {
        // in external flow (QR login) we don't need this window anymore
        // so we're opening the adobe login URL in the same window
        location.href = loginUrl.href;
      } else {
        // in web flow this window should remain open to finish up TVE login
        // after TV provider login is finished successfully
        window.open(loginUrl);
      }
    },
    [external, regCode]
  );

  const otherOptions = useMemo(() => {
    return other.map((option) => ({ label: option.displayName, value: option.id }));
  }, [other]);

  useEffect(() => {
    // 3rd party login to tv provider is happening in another tab,
    // as soon as it's confirmed that the login was successful (in `/authenticate/status` route)
    // the TVE_LOGIN_STORAGE_KEY in localStorage is set to 'success'
    const storageHandler = (e: StorageEvent) => {
      if (e.storageArea !== localStorage || e.key !== TVE_LOGIN_STORAGE_KEY) return;
      setActivated(e.newValue === 'success');
      localStorage.removeItem(TVE_LOGIN_STORAGE_KEY);
    };
    window.addEventListener('storage', storageHandler);
    return () => window.removeEventListener('storage', storageHandler);
  }, []);

  const { mutate: linkDevice, isPending } = useMutation({
    mutationFn: async () => {
      // try updating user tokens by calling TVE login
      await tveLogin();
      return completeDeviceLinking();
    },
    onSuccess: ({ mvpd }) => {
      emit('TVProviderConnected', { mvpd });
      setLinked(true);
    }
  });

  useEffect(() => {
    // device linking should only happen in web flow, NOT the external flow (QR login)
    if (!external && activated) linkDevice();
  }, [activated, external, linkDevice]);

  if (external && activated) return <Navigate to={getPagePath('watch')} />;
  if (redirect && linked) return <Navigate {...redirect} />;

  return (
    <div className="flex min-h-screen w-full flex-col px-5 py-10 md:pb-14 md:pt-20">
      <main className="flex h-full flex-1 flex-col items-center gap-9 md:justify-between">
        <header className="flex w-full max-w-[34.5rem] flex-col items-center gap-5">
          {header}
          <Typography variant="h6" className="text-center">
            {locales.chooseTvProvider}
          </Typography>
        </header>
        {isPending && <Spinner />}
        <section className={twMerge('flex flex-1 flex-col items-center justify-center', isPending && 'opacity-50')}>
          <div className="border-secondary-100 mt-4 grid h-[94.5px] w-[289.8px] grid-cols-3 grid-rows-2 items-center divide-x divide-y divide-[#D0D0D0] rounded-md border bg-white sm:h-[126px] sm:w-[386.4px] md:h-[210px] md:w-[644px]">
            {premium?.map((tve) => (
              <Image
                className="h-full w-full cursor-pointer object-scale-down p-3 md:p-5"
                key={tve.id}
                src={tve.logoUrl ?? ''}
                alt={tve.displayName}
                onClick={!isPending ? selectTVEHandler.bind(null, tve.id) : undefined}
              />
            ))}
          </div>
          <div className="mt-9 flex flex-col gap-4">
            <Typography variant="body1">{locales.dontSee}</Typography>
            <Select
              disabled={isPending}
              placeholder={locales.otherPlaceholder}
              options={otherOptions}
              onValueChange={selectTVEHandler}
            />
          </div>
        </section>
        <Link {...redirect} className="text-primary-500 relative z-10 mb-1">
          {locales.continueWithoutSubscription}
        </Link>
      </main>
    </div>
  );
}
