import { createFileRoute } from '@tanstack/react-router';
import { activateDevice, checkRegCode, clearDevice, getDevice, useLocale, type Platform } from '@game/core';
import { Button, Typography } from '@game/core-ui';
import { secondScreenSuccessSchema, type SecondScreenSuccessLocale } from '../../../lib/locale/second-screen-success';
import { TVE_LOGIN_STORAGE_KEY } from '../../../app/screens/second-screen/SecondScreen';
import { z } from 'zod';

const search = z.object({
  ex: z.boolean().optional()
});

export const Route = createFileRoute('/_public/authenticate/status')({
  validateSearch: search,
  beforeLoad: async () => {
    localStorage.removeItem(TVE_LOGIN_STORAGE_KEY);
    try {
      const regCodeActivated = await checkRegCode();
      if (!regCodeActivated) throw new Error('RegCode not activated');

      const activated = await activateDevice();
      if (!activated) throw new Error('Device not activated');

      localStorage.setItem(TVE_LOGIN_STORAGE_KEY, 'success');
      clearDevice();

      return { activated };
    } catch (error) {
      return { error };
    }
  },
  loader: async ({ context: { activated, error } }) => {
    const { regCode, platform } = getDevice();
    return { regCode, platform, activated, error };
  },
  component: RouteComponent
});

function RouteComponent() {
  const { platform, error } = Route.useLoaderData();
  const { ex: external } = Route.useSearch();
  const { locales } = useLocale({ schema: secondScreenSuccessSchema });
  const { title, description } = getMessages(locales, platform, error);

  return (
    <main className="bg-secondary-700 text-light mx-auto flex h-screen min-h-screen w-full flex-col items-center justify-center px-5 text-center">
      <Typography variant="h3">{title}</Typography>
      {description ? (
        <Typography variant="h10" as="p" className="mt-5">
          {description}
        </Typography>
      ) : null}

      {!external && (
        <Button variant="primary" className="mt-10 max-w-[17rem]" onClick={() => window.close()}>
          {locales.primaryButton}
        </Button>
      )}
    </main>
  );
}

function getMessages(locales: SecondScreenSuccessLocale, platform: Platform, error: unknown) {
  if (error) {
    // TODO: Localize error title
    const title = 'Failed to register device';
    const description = error instanceof Error ? error.message : undefined;
    return { title, description };
  }

  const platformValue = locales[platform as keyof SecondScreenSuccessLocale] ?? locales.generic;
  const title = locales.message.replace('{platform}', platformValue);
  return { title };
}
