import { useLocale } from '@game/core';
import { Icon } from '@game/core-ui';
import { useCallback, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { appSidebarLocaleSchema } from '../../lib/locale';
import { SidebarLink } from './sidebar-link';

type AppSideBarProps = {
  features: Record<string, object | boolean>;
};

export function AppSideBar(props: AppSideBarProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const {
    locales: { watchScreen: sidebarLocales, generic }
  } = useLocale({ schema: appSidebarLocaleSchema });
  const { features } = props;

  const handleOpenMenu = useCallback(() => {
    setIsExpanded(true);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setIsExpanded(false);
  }, []);

  return (
    <>
      <button
        className="bg-secondary-900 absolute left-5 top-10 z-30 flex h-11 w-11 items-center justify-center md:hidden"
        onClick={handleOpenMenu}
        title={generic.menu}
      >
        <Icon name="menu" />
      </button>
      <aside
        className={twMerge(
          'group/sidebar bg-secondary-900 fixed left-0 top-0 z-40 flex h-full  w-[5.8125rem] translate-x-[-100%] flex-col items-start justify-center gap-8 overflow-hidden pl-[1.89rem] opacity-0',
          'md:w-[5.8125rem] md:translate-x-0 md:gap-12 md:opacity-100',
          'transition-[width,opacity,transform] duration-300',
          isExpanded && 'w-full translate-x-0 opacity-100 md:w-[14rem]'
        )}
        data-expanded={isExpanded}
        onMouseEnter={handleOpenMenu}
        onMouseLeave={handleCloseMenu}
      >
        <SidebarLink path="search" iconName="search" label={sidebarLocales.search} onClick={handleCloseMenu} />
        <SidebarLink path="watch" iconName="watch" label={sidebarLocales.watch} onClick={handleCloseMenu} />
        <SidebarLink
          path="schedule"
          iconName="schedule"
          label={sidebarLocales.schedule}
          routeParams={{ type: 'games' }}
          onClick={handleCloseMenu}
        />
        {features.odds ? <SidebarLink path="odds" iconName="odds" label={sidebarLocales.odds} /> : null}
        <SidebarLink path="account" iconName="account" label={sidebarLocales.account} onClick={handleCloseMenu} />
        <button
          className={twMerge('hidden md:hidden', isExpanded && 'absolute right-5 top-10 block')}
          onClick={handleCloseMenu}
        >
          <Icon name="close" width={32.5} height={32.5} />
        </button>
      </aside>
    </>
  );
}
