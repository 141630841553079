import { generateUUID } from '@game/core';
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';

const DEVICE_ID_KEY = 'DEVICE_ID';

export async function getDeviceId() {
  let deviceId = localStorage.getItem(DEVICE_ID_KEY);

  if (deviceId) return deviceId;

  deviceId = (await getFingerprint()) || generateUUID();

  localStorage.setItem(DEVICE_ID_KEY, deviceId);

  return deviceId;
}
