import { datadogRum, type RumInitConfiguration } from '@datadog/browser-rum';
import { DATADOG } from '../../config/build-config';
import { GameConfig } from '../../config/game-config';

export function initDatadog() {
  // https://docs.datadoghq.com/real_user_monitoring/browser/setup/#setup
  const config: RumInitConfiguration = {
    applicationId: DATADOG.APPLICATION_ID,
    clientToken: DATADOG.CLIENT_TOKEN,
    site: DATADOG.SITE,
    service: 'watch',
    env: GameConfig.get.environment.name,
    version: GameConfig.get.release,
    sessionSampleRate: DATADOG.SESSION.SAMPLE_RATE,
    sessionReplaySampleRate: DATADOG.SESSION.REPLAY_RATE,
    trackResources: DATADOG.TRACK.RESOURCES,
    trackLongTasks: DATADOG.TRACK.LONG_TASKS,
    trackUserInteractions: DATADOG.TRACK.USER_INTERACTIONS,
    defaultPrivacyLevel: DATADOG.DEFAULT_PRIVACY_LEVEL,
    allowFallbackToLocalStorage: true,
    allowedTracingUrls: DATADOG.ALLOW_TRACING_URLS.map((tracingConfig) => {
      return tracingConfig.regex ? new RegExp(tracingConfig.value) : tracingConfig.value;
    })
  };
  datadogRum.init(config);
  if (GameConfig.get.environment.mode === 'development') {
    console.debug('datadog rum config:', config);
  }
  return () => {};
}
