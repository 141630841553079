import { z } from 'zod';

export const connectTV = z
  .object({
    'tve-login': z.object({
      step2of3: z.string(),
      'choose-tv-provider': z.string(),
      'iframe-authentication-title': z.string()
    })
  })
  .transform((values) => {
    const {
      step2of3,
      'choose-tv-provider': chooseTvProvider,
      'iframe-authentication-title': authenticationTitle
    } = values['tve-login'];

    return {
      step2of3,
      chooseTvProvider,
      authenticationTitle
    };
  });

export type ConnectTVLocale = z.infer<typeof connectTV>;
