import { z } from 'zod';

export const configSchema = z.object({
  baseURL: z.string(),
  assets: z.object({
    companyLogos: z.object({
      logos: z.array(
        z.object({
          rsnID: z.number(),
          full: z.object({
            url: z.string(),
            size: z.array(z.number())
          })
        })
      )
    })
  }),
  skuMatcher: z.array(
    z.object({
      id: z.number(),
      skuRegex: z.string()
    })
  ),
  teams: z.array(
    z.object({
      id: z.number(),
      rsn: z.array(z.number()),
      zone: z.array(z.string()).optional(),
      logo: z.string()
    })
  ),
  subscriptionUnavailableZones: z.array(z.string())
});

export type RSNLogoType = z.infer<typeof configSchema>['assets']['companyLogos']['logos'][0];
