import { extendTailwindMerge } from 'tailwind-merge';
import { createTV } from 'tailwind-variants';

const twOptions = {
  override: {
    classGroups: {
      'font-size': [
        {
          text: [
            '9xl',
            '8xl',
            '7xl',
            '6xl',
            '5xl',
            '4xl',
            '3xl',
            '2xl',
            'xl',
            'base',
            'sm',
            'xs',
            'mob9xl',
            'mob8xl',
            'mob7xl',
            'mob6xl',
            'mob5xl',
            'mob4xl',
            'mob3xl',
            'mob2xl',
            'mobXl',
            'mobBase',
            'mobSm',
            'mobXs'
          ]
        }
      ]
    }
  }
};

export const twMerge = extendTailwindMerge(twOptions);

export const twVariants = createTV({
  twMerge: true,
  twMergeConfig: twOptions
});
