import { generateReferral, getConfigFromRemote } from '@game/core';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

const referralProgramSchema = z.object({
  referral: z.object({
    referralProgram: z.string()
  })
});

export const Route = createFileRoute('/_private/_home/account/referral')({
  loader: async () => {
    const {
      referral: { referralProgram }
    } = getConfigFromRemote(referralProgramSchema);

    const { referralCode } = await generateReferral({ referralProgram });

    return {
      referralCode
    };
  }
});
