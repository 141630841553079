import { z } from 'zod';

export const manageTvProviderSchema = z.object({
  baseURL: z.string(),
  web: z.object({
    commonAssetsPath: z.string()
  }),
  tveAuthentication: z.object({
    tvProviders: z.array(
      z.object({
        id: z.string(),
        logoUrlWhite: z.string().nullable(),
        displayName: z.string()
      })
    )
  }),
  assets: z.object({
    companyLogos: z.object({
      logos: z.array(
        z.object({
          rsnID: z.number(),
          full: z.object({
            url: z.string()
          })
        })
      )
    })
  })
});

export type ManageTvProviderConfig = z.infer<typeof manageTvProviderSchema>;
