import { Slot } from '@radix-ui/react-slot';
import { forwardRef, type ButtonHTMLAttributes, type ReactNode } from 'react';
import { type VariantProps } from 'tailwind-variants';
import { twMerge, twVariants } from '../../utils/tailwind-libs';
import { Typography } from '../typography';

const buttonVariants = twVariants({
  slots: {
    button: twMerge(
      'relative w-full px-[3.875rem] py-[1rem] inline-flex items-center justify-center whitespace-nowrap rounded-md cursor-pointer',
      'ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2',
      'dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300',
      'aria-disabled:pointer-events-none aria-disabled:opacity-40 aria-disabled:cursor-auto',
      'disabled:pointer-events-none disabled:opacity-40 disabled:cursor-auto'
    ),
    label: 'text-secondary-900'
  },
  variants: {
    variant: {
      primary: {
        button: 'bg-primary-500 hover:bg-primary-300 active:bg-primary-700',
        label: 'text-secondary-900'
      },
      secondary: {
        button: 'bg-transparent border-solid border-2 border-primary-500',
        label: 'text-primary-500'
      }
    },
    size: {
      default: {
        button: 'w-full px-4 py-3.5'
      }
    }
  },
  defaultVariants: {
    size: 'default',
    variant: 'primary'
  }
});

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    asChild?: boolean;
    children: ReactNode | string;
    leftIcon?: ReactNode;
    rightIcon?: ReactNode;
    labelClass?: string;
  };

/**
 * Button component
 * @param {String} [className] className to add to the button
 * @param {String} [variant] variant of the button
 * @param {String} [size] size of the button
 * @param {Boolean} [asChild=false] whether to use HTML tag other than button for the Button component
 * @param {ReactNode | String} children content to be displayed in the button, for example, button texts
 * @param {String} [role="button"] role attribute to be applied to the button
 * @param {ForwardedRef<HTMLButtonElement>} [ref] React ref to access the DOM element of the button
 * @param {ReactNode} [icon] icon to be displayed at the left-hand side of the button content
 *
 * @example
 * <Button variant="primary" onClick={()=>(alert("button clicked"))}>Primary Button</Button>
 * @example
 * <Button variant="primary" onClick={()=>(alert("button clicked"))} className='w-80'>Primary Button</Button>
 * @example
 * <Button variant="primary" asChild role='link'>
 *   <Link to={'/'}>Home</Link>
 * </Button>
 * @example
 * <Link to={'/'} className={buttonVariants({ variant: "primary" })} role='link' >Click here</Link>
 */

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    className,
    variant,
    size,
    asChild = false,
    children,
    role = 'button',
    leftIcon,
    rightIcon,
    labelClass,
    ...rest
  } = props;

  const Comp = asChild ? Slot : 'button';

  const { button, label } = buttonVariants({ variant, size });

  return (
    <Comp className={twMerge(button(), className)} ref={ref} role={role} {...rest}>
      {leftIcon ? <div className="absolute left-6">{leftIcon}</div> : null}
      <Typography variant="h10" className={twMerge(label(), labelClass)}>
        {children}
      </Typography>
      {rightIcon ? <div className="absolute right-6">{rightIcon}</div> : null}
    </Comp>
  );
});
Button.displayName = 'Button';

export { Button, buttonVariants };
