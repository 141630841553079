import type { AnalyticsBrowser, UserTraits } from '@segment/analytics-next';
import { type AnalyticsEventHandler } from '../analytics';
import { setBitrate, setFramerate, setPlayerInfo, setPosition } from './segment';
import { getAdBreakInfo, getAdInfo, getUser } from './util';

function defineEventHandler(fn: (analytics: AnalyticsBrowser) => AnalyticsEventHandler) {
  return (analytics: AnalyticsBrowser) => fn(analytics);
}

const HEART_BEAT_INTERVAL = 60000 as const;

// Segment Action Handler
export const eventHandler = defineEventHandler((analytics) => {
  let heartBeatId: number | null = null;

  const clearHeartBeat = () => {
    if (heartBeatId) {
      window.clearInterval(heartBeatId);
      heartBeatId = null;
    }
  };

  const startHeartBeat = () => {
    if (heartBeatId) return;
    heartBeatId = window.setInterval(async () => {
      await analytics.track('Video Content Playing');
    }, HEART_BEAT_INTERVAL);
  };

  const identify = async (traits?: UserTraits) => {
    const user = await getUser();
    if (user) {
      await analytics.identify(user.spAccountId, traits);
    } else if (traits) {
      // anonymous user
      await analytics.identify(traits);
    }
  };

  const reset = async () => {
    await analytics.reset();
  };

  return {
    handleScreenVisited: async (title: string) => {
      await analytics.page(title);
    },

    /**
     * User event
     */
    handleReferralCodeCopied: async (referralCode: string) => {
      await identify({
        referral_id: referralCode
      });
      await analytics.track('Referral Code Copied', { referral_code: referralCode });
    },

    handleReferralInviteSent: async (referralCode: string) => {
      await analytics.track('Referral Invite Sent', { referral_code: referralCode });
    },

    handleReferralFriendClicked: async () => {
      await analytics.track('Referral Friend Clicked');
    },

    handleAfterCheckAccount: async ({ email, migratedFrom, rsns }) => {
      let result: string;
      if (!rsns?.length) {
        result = 'new';
      } else if (rsns.length > 1) {
        result = 'both';
      } else {
        result = rsns[0].toLowerCase();
      }
      await analytics.track('LoginRegister Clicked', {
        email,
        email_check_result: result,
        migrated_from: migratedFrom
      });
    },

    handleAccountCreated: async ({ email, zone = '', migratedFrom, referralCode = '' }) => {
      await identify({ referral_id: referralCode });
      await analytics.track('Account Created', {
        opted_in: !!email,
        user_type: migratedFrom ? 'migrated' : 'new',
        migrated_from: migratedFrom ? migratedFrom.toLowerCase() : '',
        zone
      });
    },

    handleLoginSuccessful: async ({ migratedFrom, zone, username }) => {
      await identify();
      await analytics.track('Signed In', { migrated_from: migratedFrom, zone_current: zone, username });
    },
    handleLogout: reset,
    handleInvalidSession: reset,
    handleTVProviderConnected: async ({ mvpd }) => {
      await identify();
      await analytics.track('TV Provider Connected', { mvpd });
    },

    handleSubscriptionButtonClicked: async ({ isTVEUpsell, plan }) => {
      await analytics.track('Subscribe Now Button Clicked', {
        is_tve_upsell: isTVEUpsell,
        package_selected: plan.sku,
        price_selected: plan.price.toString(),
        // “monthly”, “annual” or “gamepass” for gotham
        term_selected: plan.term.toLowerCase()
      });
    },

    handleSubscriptionSuccessful: async (payload) => {
      const {
        billingPlatform,
        promoCode,
        referralCode,
        serviceType,
        sku,
        plan: { name, price, term, startDate, endDate },
        zoneId
      } = payload;

      await identify({
        subscription_billing_platform: billingPlatform,
        subscription_start_date: startDate.toISOString(),
        subscription_end_date: endDate.toISOString(),
        subscription_last_modified_date: new Date().toISOString(),
        subscription_period_unit: term.toLowerCase(),
        subscription_promo_code: promoCode ?? '',
        subscription_status: 'active',
        subscriber_type: serviceType
      });

      if (payload.isGamePass) {
        await analytics.track('Gamepass Purchased', {
          title: name,
          price: price,
          event_date: startDate.toISOString()
        });
      } else {
        await analytics.track('Subscription Started', {
          package_selected: sku,
          price_selected: price.toString(),
          term_selected: term.toLowerCase(),
          start_date: startDate.toISOString(),
          end_date: endDate.toISOString(),
          promo_code: promoCode ?? '',
          referral_code: referralCode ?? '',
          service_type: serviceType,
          subscription_id: sku,
          zone: zoneId,
          billing_platform: billingPlatform
        });
      }
    },

    handleConnectWithProviderClicked: async () => {
      await analytics.track('Connect With Provider Clicked');
    },

    handleContinuedWithoutSubscription: async () => {
      await analytics.track('Continued Without Subscription');
    },

    handleFavoriteTeamsUpdated: async ({ teams }) => {
      const teamIds = teams.map((t) => t.id);
      const teamNames = teams.map((t) => t.shortName);

      await identify({
        favorite_teams_ids: teamIds,
        favorite_teams_names: teamNames
      });
      await analytics.track('Favorite Teams Picked', { teams_selected: teamNames });
    },

    handleFavoritePlayersUpdated: async (players) => {
      await analytics.track('Favorite Players Picked', { players_selected: players });
    },

    handleNotificationPreferenceUpdate: async (enabled) => {
      await identify({ push_opt_in: enabled });
      await analytics.track('Notification Preference Updated', { push_opt_in: enabled });
    },

    handleSubscriptionCancelled: async (reason) => {
      await identify({
        subscription_date_cancelled: new Date().toISOString(),
        subscription_status: 'cancelled'
      });
      await analytics.track('Subscription Cancelled', { reason_given: reason });
    },

    handleConfirmTVProviderOnly: async () => {
      await analytics.track('TV Provider Only Clicked');
    },

    handleSearchingUpdated: async (keyword) => {
      await analytics.track('Searched For', { search_term: keyword });
    },

    handleSearchResultClicked: async () => {
      await analytics.track('Search Result Clicked');
    },

    handleProfileUpdated: async () => {
      await identify();
    },

    /**
     * Media Player Events
     */
    handlePlayerReady({ framework, version }) {
      setPlayerInfo({
        framework,
        version
      });
    },
    handlePlaybackBufferingStarted: async () => {
      await analytics.track('Video Playback Buffer Started');
    },

    handlePlaybackBufferingEnded: async () => {
      await analytics.track('Video Playback Buffer Completed');
    },

    handlePlaybackStarted: async (time) => {
      startHeartBeat();
      if (time === 0) {
        await analytics.track('Video Playback Started');
      } else {
        await analytics.track('Video Playback Resumed');
      }
    },

    handlePlaybackPaused: async () => {
      clearHeartBeat();
      await analytics.track('Video Playback Paused');
    },

    handlePlaybackExited: async () => {
      // TODO:
      await analytics.track('Video Playback Exited');
    },

    handlePlaybackInterrupted: async () => {
      // TODO:
      await analytics.track('Video Playback Interrupted');
    },

    handlePlaybackSeeking: async (position) => {
      setPosition(position);
      // TODO: Populate
      await analytics.track('Video Playback Seek Started');
    },

    handlePlaybackSeeked: async () => {
      // TODO: Populate
      await analytics.track('Video Playback Seek Completed');
    },

    handlePlaybackCompleted: async () => {
      clearHeartBeat();
      await analytics.track('Video Playback Completed');
    },

    handleContentStarted: async () => {
      await analytics.track('Video Content Started');
    },
    handlePlayHeadTime({ time }) {
      setPosition(time);
    },
    handleContentCompleted: async () => {
      clearHeartBeat();
      await analytics.track('Video Content Completed');
    },

    // TODO:
    handlePlayerQualityChanged: async ({ bitrate, frameRate }) => {
      // bitrate
      // framerate
      // droppedFrames
      // startupTime
      setBitrate(bitrate);
      setFramerate(frameRate);
      await analytics.track('Video Quality Updated');
    },

    // Ads
    handleAdBreakStarted: async ({ sessionId, event }) => {
      await analytics.track('Video Ad Break Started', getAdBreakInfo(event, sessionId));
    },

    handleAdBreakFinished: async ({ sessionId, event }) => {
      await analytics.track('Video Ad Break Completed', getAdBreakInfo(event, sessionId));
    },

    handleAdStarted: async ({ sessionId, event }) => {
      await analytics.track('Video Ad Started', getAdInfo(event, sessionId));
    },

    handleAdFinished: async ({ sessionId, event }) => {
      await analytics.track('Video Ad Completed', getAdInfo(event, sessionId));
    }
  };
});
