import { importJWK, SignJWT } from 'jose';
import { GameConfig } from '../../../config/game-config';
import { QUICKPLAY } from '../../../config/build-config';

// OTHER UTILITIES

export function getDeviceType(): string {
  const platform = GameConfig.get.platform;
  switch (platform) {
    case 'web':
      return 'web';
    case 'web:mobile':
      return 'mobileweb';
    case 'lg':
      return 'lgwebostv';
    case 'vizio':
      return 'viziotv';
    case 'samsung':
      return 'samsungtizentv';
    default:
      throw new Error(`Unable to get quickplay device type for Platform ${platform}`);
  }
}

export function getClientType() {
  const platform = GameConfig.get.platform;
  switch (GameConfig.get.platform) {
    case 'web':
      return 'game-gotham-web';
    case 'web:mobile':
      return 'game-gotham-mobileweb';
    case 'lg':
      return 'game-gotham-lgwebostv';
    case 'vizio':
      return 'game-gotham-viziotv';
    case 'samsung':
      return 'game-gotham-samsungtizentv';
    default:
      throw new Error(`Unable to get quickplay client type for Platform ${platform}`);
  }
}

export function getAuthClientID() {
  const platform = GameConfig.get.platform;
  switch (platform) {
    case 'web':
    case 'web:mobile':
      return 'webclient-ui-app';
    case 'lg':
    case 'vizio':
    case 'samsung':
      return 'tv-ui-app';
    default:
      throw new Error(`Unable to get quickplay auth client id for Platform ${platform}`);
  }
}

export function getAuthClientSecret() {
  const platform = GameConfig.get.platform;
  switch (platform) {
    case 'web':
    case 'web:mobile':
      return QUICKPLAY.web.CLIENT_SECRET;
    case 'lg':
      return QUICKPLAY.lg.CLIENT_SECRET;
    case 'vizio':
      return QUICKPLAY.vizio.CLIENT_SECRET;
    case 'samsung':
      return QUICKPLAY.samsung.CLIENT_SECRET;
    default:
      throw new Error(`Unable to get quickplay auth client secret for Platform ${platform}`);
  }
}

/**
 * sign JWT token
 * @param {object} payload
 * @param {number} expiry expiration date-time in seconds
 * @param {object} key base64 string
 * @returns {string} signed JWT token
 */
export async function signJWT(payload: Record<string, string>, expiry: number, key: string): Promise<string> {
  const alg = 'HS256';
  const secretKey = await importJWK({ kty: 'oct', k: key, alg });
  return new SignJWT(payload)
    .setProtectedHeader({ alg })
    .setAudience('playback-auth-service')
    .setIssuedAt()
    .setExpirationTime(expiry)
    .sign(secretKey);
}
