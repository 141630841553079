import { isFeatureEnabled } from '@game/core';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_private/_home/account/help')({
  beforeLoad: async () => {
    const pickNPlay = (await isFeatureEnabled('pickNPlay')) as boolean;

    return {
      features: {
        pickNPlay
      }
    };
  }
});
