import { z } from 'zod';

export const configSchema = z.object({
  tveAuthentication: z.object({
    tvProviders: z.array(
      z.object({
        id: z.string(),
        displayName: z.string(),
        mrss: z.string().optional(),
        logoUrlWhite: z.string().nullable()
      })
    )
  }),
  secondScreenAuthentication: z.object({
    loginUrl: z.string()
  })
});
