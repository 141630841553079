import * as Form from '@radix-ui/react-form';
import { useMemo, useState } from 'react';
import { twMerge } from '../../utils/tailwind-libs';
import { Icon } from '../icon';
import { defaultNativeErrorMessages } from './constants';
import { InputErrorMessage } from './errorMessage';
import type { InputProps } from './types';

/**
 * Input component
 * @param {String} name used to set name prop of input, in order to catch on the form
 * @param {String} label the string for rendering the label of the input
 * @param {String} [type] input type. Note that if you pass type="password", the input will have an extra icon to handle typed information visibility
 * @param {Object} [classes] an object with the components as key to pass classes to overwriting/extending css
 * @param {Object} [nativeErrorMessages] an object to pass custom text messages for native input errors for cases like `required`, `min`, `max`...
 * @param {Object[]} [customValidations] an array of objects with the custom error message and validation function
 *
 * @example
 * <Input
 *  name="email"
 *  label="Email"
 *  classes={{
 *    container: "bg-emerald-500",
 *    label: "text-xl",
 *    input: "bg-violet-800",
 *    errorMessage: "text-white"
 *  }}
 *  nativeErrorMessages={{
 *    valueMissing: "Email is missing!",
 *    ...
 *  }}
 *  customValidations={[
 *    {
 *      message: "Custom message",
 *      validation: (inputValue, formData) => inputValue !== email@test.com // Probably a Zod/Yup checking will be happening in here
 *    }
 *  ]}
 * />
 */
export function Input(props: InputProps) {
  const {
    name,
    label,
    className,
    classes,
    nativeErrorMessages,
    customValidations,
    type,
    externalValidationState,
    externalValidationMessage,
    id,
    ...rest
  } = props;
  const [currentType, setCurrentType] = useState(type);

  /**
   * The purpose of this is to consider only the needed validations, otherwise even if we
   * didn't pass "required", the input would still consider rendering InputErrorMessage
   * for the "valueMissing" error type...
   */
  const nativeMatchersToRender = useMemo(() => {
    const currentMatchers = {
      ...defaultNativeErrorMessages,
      ...nativeErrorMessages
    };

    const matchers = new Map<keyof typeof currentMatchers, string>();

    matchers.set('badInput', currentMatchers.badInput);

    !!props.pattern && matchers.set('patternMismatch', currentMatchers.patternMismatch);
    !!props.max && matchers.set('rangeOverflow', currentMatchers.rangeOverflow);
    !!props.min && matchers.set('rangeUnderflow', currentMatchers.rangeUnderflow);
    !!props.step && matchers.set('stepMismatch', currentMatchers.stepMismatch);
    !!props.maxLength && matchers.set('tooLong', currentMatchers.tooLong);
    !!props.minLength && matchers.set('tooShort', currentMatchers.tooShort);
    !!props.required && matchers.set('valueMissing', currentMatchers.valueMissing);
    (type === 'email' || type === 'url') && matchers.set('typeMismatch', currentMatchers.typeMismatch);

    return [...matchers];
  }, [props, type, nativeErrorMessages]);

  const hasCustomValidations = !!customValidations?.length;

  const isPasswordType = type === 'password';

  function handlePasswordButtonClick() {
    setCurrentType((prev) => (prev === 'password' ? 'text' : 'password'));
  }

  return (
    <Form.Field
      name={name}
      id={id}
      className={twMerge(
        'h-input-default border-secondary-300 bg-secondary-600 group relative flex items-center rounded-md border px-5 pb-1 pt-4',
        'data-[invalid=true]:border-error',
        'focus-within:bg-secondary-800 transition-transform focus-within:scale-[1.04]',
        'has-[:disabled]:opacity-40',
        props.readOnly && 'focus-within:bg-secondary-600 opacity-40 focus-within:scale-100',
        classes?.container
      )}
    >
      <Form.Control asChild>
        <input
          {...rest}
          type={currentType}
          placeholder={rest.placeholder ?? label}
          className={twMerge(
            'text-light WebBody1 peer w-full bg-transparent outline-none [appearance:textfield] placeholder:text-transparent',
            className,
            classes?.input
          )}
        />
      </Form.Control>
      {isPasswordType ? (
        <div className="absolute bottom-0 right-0 top-0 flex items-center pr-5">
          <button className="cursor-pointer" type="button" onClick={handlePasswordButtonClick}>
            <Icon name={currentType === 'password' ? 'eyes-closed' : 'eyes-open'} />
          </button>
        </div>
      ) : null}
      <Form.Label
        className={twMerge(
          'text-light/60 WebBody6 absolute bottom-0 left-0 top-0 flex cursor-text pl-5 pt-2 transition-transform',
          'peer-focus:WebBody6 peer-placeholder-shown:WebBody1 peer-placeholder-shown:items-center peer-placeholder-shown:pt-0 peer-focus:h-fit peer-focus:pt-2',
          isPasswordType ? 'right-12' : 'right-0',
          classes?.label
        )}
      >
        {label}
      </Form.Label>
      {externalValidationState ? (
        <InputErrorMessage className={classes?.errorMessage}>{externalValidationMessage}</InputErrorMessage>
      ) : null}
      {!externalValidationState &&
        nativeMatchersToRender.map(([matcher, message]) => (
          <InputErrorMessage key={matcher} match={matcher} className={classes?.errorMessage}>
            {message}
          </InputErrorMessage>
        ))}
      {!externalValidationState && hasCustomValidations
        ? customValidations.map((matcher) => (
            <InputErrorMessage key={matcher.message} match={matcher.validation} className={classes?.errorMessage}>
              {matcher.message}
            </InputErrorMessage>
          ))
        : null}
    </Form.Field>
  );
}
Input.displayName = 'Input';
