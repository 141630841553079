import { z } from 'zod';

export const pickFavoriteTeamsSchema = z
  .object({
    'pick-teams': z.object({
      step3of3: z.string(),
      'pick-follow': z.string(),
      'your-favorite-teams': z.string(),
      'follow-all': z.string(),
      next: z.string()
    })
  })
  .transform((values) => {
    const {
      'pick-teams': {
        'pick-follow': pickFollow,
        'your-favorite-teams': yourFavoriteTeams,
        'follow-all': followAll,
        ...rest
      }
    } = values;

    return {
      mainTitle: `${pickFollow} ${yourFavoriteTeams}`,
      followAll,
      ...rest
    };
  });

export type PickFavoriteTeamsLocale = z.infer<typeof pickFavoriteTeamsSchema>;
