import { emit, getUserData, getUserLocation, isUserLoggedIn, isUserOutOfCountry, isVIPUser } from '@game/core';
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';
import { changeBrazeUser } from '../lib/braze/actions';
import { getPagePath } from '../lib/routing';

export const Route = createFileRoute('/_private')({
  beforeLoad: async ({ matches }) => {
    if (!isUserLoggedIn()) {
      emit('InvalidSession');
      throw redirect({ to: getPagePath('launch'), search: { r: matches[matches.length - 1].pathname } });
    }

    const userLocation = await getUserLocation();

    const user = await getUserData();
    changeBrazeUser(user);

    if (isUserOutOfCountry(userLocation) && !isVIPUser(user)) {
      throw redirect({ to: getPagePath('geoBlock') });
    }

    return {
      user,
      userLocation
    };
  },
  loader: ({ context: { ...rest } }) => {
    return rest;
  },
  component: Outlet
});
