import { z } from 'zod';
import { APP_BUNDLE_ID, APP_NAME } from '@game/core';
import { generateUUID, toURL, replaceTag } from '../../lib/utils';
import type { Video } from '../../services/api/quickplay'; // TODO: Remove use ContentV2
import { getConfigFromRemote } from '../config-manager';
import type { Content } from '../content';
import { isDTCUser, isTVEUser, type User } from '../user';
import type { AdsConfig } from '../video-playback';
import type { SSAIParameters } from './types';

export function getSSAIAdParameters(video: Video | Content, ads: AdsConfig, user: User) {
  const adConfigParams = getAdParametersFromConfig();
  const { id: contentId, type: contentType } = video;
  const {
    device: { platform, sessionKey, id, type, limitAdTracking }
  } = ads;

  const baseUrl = toURL(adConfigParams.PLAYBACK_DESCRIPTION_URL);
  const url = toURL(baseUrl, ads.videoPath);
  const profs = replaceAdPlaceholders(adConfigParams.prof, platform, contentType);
  const csid = replaceAdPlaceholders(adConfigParams.csid, platform, contentType);
  const vcid2 = [adConfigParams.freeWheelNetworkID, user.spAccountId || user.cpCustomerId].join(':');

  const urlParameters: SSAIParameters = {
    'ads.goth_description_url': encodeURIComponent(baseUrl.href),
    'ads.goth_url': encodeURIComponent(url.href),
    'ads.goth_vid': contentId,
    'ads.goth_ppid': user.spAccountId,
    'ads.goth_ad_mode': contentType,
    'ads.goth_session_key': sessionKey,
    'ads.goth_nw': adConfigParams.freeWheelNetworkID,
    'ads.goth_mind': adConfigParams.mind,
    'ads.goth_maxd': adConfigParams.maxd,
    'ads.goth_fwuid': getPlanType(user, video),
    'ads.goth_fw_zipcode': ads.zipCode,
    'ads.goth_prof': profs,
    'ads.goth_csid': csid,
    'ads.goth_vprn': generateUUID(),
    'ads.goth_fw_vcid2': vcid2,
    'ads.goth_msid': APP_BUNDLE_ID,
    'ads.goth_an': APP_NAME,
    'ads.goth_session': sessionKey,
    'ads.goth_rdid': id,
    'ads.goth_idtype': type,
    'ads.goth_fw_did': `${type}:${id}`,
    'ads.goth_is_lat': limitAdTracking ? '1' : '0'
  };

  return urlParameters;
}

export function getAdParametersFromConfig() {
  const config = getConfigFromRemote(
    z.object({
      adParameters: z.object({
        PLAYBACK_DESCRIPTION_URL: z.string(),
        ptgt: z.string(),
        freeWheelBaseURL: z.string(),
        freeWheelNetworkID: z.string(),
        doubleClickBaseURL: z.string(),
        vrdu: z.string(),
        mind: z.string(),
        maxd: z.string(),
        prof: z.string(),
        csid: z.string()
      })
    })
  );

  return config.adParameters;
}

// UTILS
// =====

export function replaceAdPlaceholders(template: string, platform: string, contentType: string) {
  let str = replaceTag(template, 'content-type', contentType);
  if (str) str = replaceTag(str, 'platform', platform);
  return str || '';
}

export type ADS_PLAN_TYPE = 'DTC-PPG' | 'DTC-ANNUAL' | 'DTC-MONTHLY' | 'TVE' | 'NONE';

export function getPlanType(user: User, video: Video | Content): ADS_PLAN_TYPE {
  if (isDTCUser(user)) {
    if (video.gamePassAssetId && user.entitlements.has(video.gamePassAssetId)) {
      return 'DTC-PPG';
    }
    return Array.from(user.entitlements).some((value) => value.includes('dtc') && value.includes('annual'))
      ? 'DTC-ANNUAL'
      : 'DTC-MONTHLY';
  }
  if (isTVEUser(user)) return 'TVE';

  return 'NONE';
}
