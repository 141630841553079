import { useEffect, useState } from 'react';
import { twMerge } from '../../utils/tailwind-libs';
import type { ImageProps } from './types';

/**
 * Image component
 * @param props
 * @example
 * <Image
 *  width={235}
 *  height={61}
 *  src="https://config-dev.gothamsports.com/Assets/FanduelLogoFull.png"
 * />
 * @example
 *  <Image
 *   width={235}
 *   height={61}
 *   src="https://config-dev.gothamsports.com/Assets/FanduelLogoFull.png"
 *   className="w-1/2"
 *  />
 * @example
 * <div className='w-1/2'>
 *  <Image
 *   width={235}
 *   height={61}
 *   src="https://config-dev.gothamsports.com/Assets/FanduelLogoFull.png"
 *   fill={true}
 *  />
 * </div>
 */

export function Image(props: ImageProps) {
  const { width, height, className, fill, src, alt = '', loading = 'lazy', fallbackURL, ...rest } = props;

  const [error, setError] = useState(false);
  const onError = () => setError(true);
  const currentSrc = error && fallbackURL ? fallbackURL : src;

  useEffect(() => setError(false), [props.src]);

  return (
    <img
      width={width}
      height={height}
      className={twMerge(fill && 'h-full w-full object-cover', className)}
      src={currentSrc}
      alt={alt}
      loading={loading}
      onError={onError}
      {...rest}
    />
  );
}
