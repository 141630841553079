import { TOKEN_STORAGE_KEY, USER_METADATA_STORAGE_KEY } from '../../../bootstrap';
import { getFromStorage, removeFromStorage, saveToStorage } from '../../lib/utils/storage';
import type { EvergentAccountRole } from '../../services/api/evergent/types';
export {
  saveDeviceSecret,
  getDeviceSecret,
  getXDeviceId,
  saveXDeviceId,
  resetXDeviceId,
  removeDeviceTokens
} from '../../services/api/quickplay';

type StoredToken = {
  accessToken: string;
  refreshToken: string;
  expiresIn: string;
};

type StoredUserMetadata = {
  accountRole?: EvergentAccountRole;
};

export const getTokens = () => {
  const tokens = getFromStorage(TOKEN_STORAGE_KEY);

  if (!tokens) return undefined;

  return JSON.parse(tokens) as StoredToken;
};

export const saveTokens = (tokens: StoredToken) => {
  saveToStorage(TOKEN_STORAGE_KEY, JSON.stringify(tokens));
};

export const deleteTokens = () => {
  removeFromStorage(TOKEN_STORAGE_KEY);
};

export const saveUserMetadata = (metadata: StoredUserMetadata) => {
  saveToStorage(USER_METADATA_STORAGE_KEY, JSON.stringify(metadata), true);
};

export const getUserMetadata = () => {
  const metadata = getFromStorage(USER_METADATA_STORAGE_KEY);
  if (!metadata) return undefined;
  return JSON.parse(metadata) as StoredUserMetadata;
};

export const deleteUserMetadata = () => {
  removeFromStorage(USER_METADATA_STORAGE_KEY);
};
