import type {
  QuickplayChannelData,
  QuickplayContainerData,
  QuickplayContent,
  QuickplayStorefront
} from '../../services/api/quickplay/types';
import { isEntitled } from '../entitlements';
import type { User } from '../user';
import type { CardStyle, Content, QuickplayPaginatedParams } from './type';
import {
  getCardStyle,
  getContainerImageAspectRatio,
  getContentDates,
  getContentEntitlements,
  getContentGameDetails,
  getContentStatus,
  getDescription,
  getGamePassAssetId,
  getImageAssetUrl,
  getImageSizeByCardStyle,
  getImageUrl,
  getIsLIve,
  getPlaybackProps,
  getQPEntitlements,
  getTeamsKeys,
  getTitle,
  hasNextPage,
  TYPE_MAP
} from './utils';

export function toStorefront(storefront: QuickplayStorefront) {
  return {
    storefrontId: storefront?.id,
    tabId: storefront?.t?.[0]?.id
  };
}

export function toContainer(container: QuickplayContainerData, user: User) {
  const cardStyle = getCardStyle(container);

  const platformAR = getContainerImageAspectRatio(container);

  const defaultAR = getContainerImageAspectRatio(container, true);

  const initialContent =
    container.cd?.map((content) => toContainerContent(content, user, { cardStyle, defaultAR, platformAR })) ?? [];

  return {
    title: container.lon[0].n,
    description: container.lod[0].n,
    containerUrl: container.i[0].cu,
    type: container.con_tg, // live | regular | yankees | knicks | promotional
    id: container.id,
    cardStyle,
    initialContent,
    model: container?.lo,
    contentTotalNumber: container.i[0].count,
    platformImageAspectRatio: platformAR,
    defaultImageAspectRatio: defaultAR
  };
}

export function toContent(content: QuickplayContent, user: User): Content {
  const gamePassAssetId = getGamePassAssetId(content);

  const { startDate, endDate } = getContentDates(content);

  const heroImage = getImageUrl(content, { size: { width: '1920' } });

  const gameDetails = getContentGameDetails(content);

  const entitlements = getContentEntitlements(content);

  return {
    // Props for playback
    ...getPlaybackProps(content),

    // Props for displaying on screen
    title: getTitle(content),
    description: getDescription(content),
    duration: content.vrt ?? 0,
    startDate,
    endDate,
    network: content.net,
    providerName: content.pn,
    heroImage,
    thumbnail: getImageUrl(content, { size: { width: '640' } }),
    isLive: getIsLIve(content),
    isPreRecorded: content.ep_ty === 'prerecorded',
    gameDetails,
    status: getContentStatus(startDate, endDate),

    // Important metadata
    id: content.id,
    key: content.key,
    channelId: content.cid,
    channelNumber: content.cn,
    type: TYPE_MAP[content.cty],
    gamePassAssetId,
    networkId: content.net_id,
    entitlements,
    catalogType: content.cty,
    isGame: !!gameDetails,
    isLocked: !isEntitled(user, entitlements),
    teamsKeys: getTeamsKeys(content),
    isLiveToVOD: !!content.l2v,
    isPriority: content.tg?.includes('priority') ?? false,

    // props for analytics
    segment: {
      deviceType: content.ent?.[0]?.dt ?? '',
      genre: content.pgm?.log?.[0]?.n,
      program: content.pgm?.lostl?.[0]?.n,
      airDate: content.pgm?.oadt,
      sport: content.pgm?.spt_ty,
      team: content.zn_tm,
      supportedZone: content.zn,
      programType: content.pgm?.pgm_ty,
      gameId: content.ex_id ?? content.gameId ?? content.pgm?.ex_id ?? content.pgm?.gameId ?? ''
    }
  };
}

export function toContainerContent(
  content: QuickplayContent,
  user: User,
  preferredAspectRatios: { defaultAR?: string; platformAR?: string; cardStyle: CardStyle }
) {
  const formattedContent = toContent(content, user);

  const size = getImageSizeByCardStyle(preferredAspectRatios.cardStyle);

  formattedContent.thumbnail = getImageUrl(content, { size, preferredAspectRatios });

  return formattedContent;
}

export function toChannel(channel: QuickplayChannelData) {
  return {
    id: channel.id,
    entitlements: getQPEntitlements(channel?.ent),
    logo: getImageAssetUrl(channel, { size: { width: '300' }, extension: 'png' }),
    provider: channel.pn,
    number: channel.cn
  };
}

export function toPaginatedContentList(qpData: QuickplayPaginatedParams, user: User) {
  const { data, header } = qpData;

  if (!data) {
    return {
      content: [],
      hasNextPage: false
    };
  }

  return {
    hasNextPage: hasNextPage(header),
    content: data.map((content) => toContent(content, user))
  };
}
