import { getPurchaseHistory, numberToCurrency, useLocale } from '@game/core';
import { twMerge, Typography } from '@game/core-ui';
import { createFileRoute } from '@tanstack/react-router';
import dayjs from 'dayjs';
import { z } from 'zod';

export const Route = createFileRoute('/_private/_home/account/_subscription/subscription/history')({
  loader: getPurchaseHistory,
  component: PurchaseHistory
});

function PurchaseHistory() {
  const data = Route.useLoaderData();
  const { locales } = useLocale({ schema: localeSchema });

  return (
    <div className="mx-auto min-h-screen max-w-[44rem] px-5 py-10 md:pb-14 md:pt-20">
      <Typography variant="h3" className="mb-32 text-center uppercase">
        {locales.title}
      </Typography>
      <PurchaseList items={data} locales={locales} />
    </div>
  );
}

function PurchaseList({ items, locales }: PurchaseListProps) {
  if (!items.length) return <Typography>{locales.empty}</Typography>;
  return items.map((s, idx) => <PurchaseDetails isFirst={!idx} key={s.id} {...s} />);
}

function PurchaseDetails({
  orderDate,
  title,
  startDate,
  endDate,
  paymentMethod,
  priceCharged,
  currency,
  isFirst
}: SubscriptionDetails & { isFirst: boolean }) {
  const period = [startDate, endDate].map(formatDate).join(' - ');

  return (
    <div className={twMerge('border-secondary-400', !isFirst && 'mt-5 border-t-2 pt-5')}>
      <Typography variant="body3" className="mb-5">
        {formatDate(orderDate)}
      </Typography>
      <Typography variant="body2" className="mb-2">
        {title}
      </Typography>
      <Typography variant="body3" className="mb-5">
        {period}
      </Typography>
      <Typography variant="body3" className="mb-5">
        {paymentMethod}
      </Typography>
      <Typography variant="body3">{numberToCurrency(priceCharged, { currency })}</Typography>
    </div>
  );
}

const formatDate = (d: Date) => dayjs(d).format('MMM D, YYYY');

const localeSchema = z
  .object({ settings: z.object({ 'purchase-history': z.string(), 'no-purchase-history': z.string() }) })
  .transform(({ settings: s }) => ({ title: s['purchase-history'], empty: s['no-purchase-history'] }));

type PurchaseListProps = { items: SubscriptionDetails[]; locales: { empty: string } };
type SubscriptionDetails = Awaited<ReturnType<typeof getPurchaseHistory>>[number];
