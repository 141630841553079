import { queryOptions } from '@tanstack/react-query';
import { GetUserQuery } from '../user';
import { canSubscribe, getActiveSubscriptions, getTVODPurchaseToken } from './services';
import { GetCheckLocationQuery } from '../check-location';
import type { GetTVODPurchaseTokenParams } from './types';

const ACTIVE_SUBSCRIPTIONS_STALE_TIME = 5 * 60 * 1000;
const CAN_SUBSCRIBE_STALE_TIME = 5 * 60 * 1000;

export const GetActiveSubscriptionsQuery = queryOptions({
  queryKey: [...GetUserQuery.queryKey, 'subscriptions'],
  queryFn: getActiveSubscriptions,
  staleTime: ACTIVE_SUBSCRIPTIONS_STALE_TIME
});

export const GetPurchaseTokenQuery = (params: GetTVODPurchaseTokenParams) =>
  queryOptions({
    queryKey: ['purchaseToken', params.contentId],
    queryFn: () => getTVODPurchaseToken(params)
  });

export const GetCanSubscribeQuery = queryOptions({
  queryKey: [...GetCheckLocationQuery().queryKey, 'canSubscribe'],
  queryFn: canSubscribe,
  staleTime: CAN_SUBSCRIBE_STALE_TIME
});
