import { createFileRoute } from '@tanstack/react-router';
import { deleteSearch } from '../../../app/screens/delete-account/constants';
import { searchAccount, type User } from '@game/core';

export const Route = createFileRoute('/_public/delete')({
  validateSearch: deleteSearch,
  beforeLoad: async ({ search }) => {
    const { email } = search;
    const userData = await searchAccount(email);
    if (!userData) {
      throw new Error('User does not exist');
    }
    const user = { email } as User;
    return { user };
  }
});
