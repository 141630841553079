import { queryClient } from '../../lib/query';
import * as qp from '../../services/api/quickplay';
import { isEntitled } from '../entitlements';
import { type User } from '../user';
import { toChannel, toContainer, toContent, toPaginatedContentList, toStorefront } from './models';
import { GetContainersQuery, GetStorefrontQuery } from './query';
import type {
  GetChannelEPGParams,
  GetChannelsParams,
  GetContainerContentParams,
  GetContainersParams,
  GetContentsByIds,
  GetLiveEventsWithFiltersParams,
  GetSearchedContentParams,
  GetSingleContentDataParams,
  GetUpcomingContentParams
} from './type';
import { getImageUrl, getSpecificAspectRatioImage, hasNextPage, updateChannelContent } from './utils';

export async function getStorefront(region: string) {
  const storefrontResponse = await qp.getStorefront(region);

  if (!storefrontResponse.length) return null;

  for (const storefront of storefrontResponse) {
    if (storefront.t.length) {
      return toStorefront(storefront);
    }
  }
}

export async function getContainers(params: GetContainersParams) {
  const { region, user, pageNumber, pageSize } = params;
  const storefront = await queryClient.fetchQuery(GetStorefrontQuery(region));

  if (!storefront) return [];

  const { storefrontId, tabId } = storefront;

  const containers = await qp.getContainersData({ storefrontId, tabId, zoneId: region, pageNumber, pageSize });

  return containers.filter((container) => container?.cd).map((container) => toContainer(container, user));
}

export async function getContainerContent(params: GetContainerContentParams, user: User) {
  const { containerUrl, ...rest } = params;

  const content = await qp.getContainerContentByUrl({ containerUrl, ...rest });

  return toPaginatedContentList(content, user);
}

export async function getAirings(region: string, user: User) {
  const airings = await qp.getAiring(region);

  return airings.map((content) => toContent(content, user));
}

export async function getLiveEvents(region: string, user: User) {
  const liveEvents = await qp.getLiveEvents(region);

  return liveEvents.map((content) => toContent(content, user));
}

export type GetHeroCarouselContainerParams = {
  region: string;
  user: User;
};

export async function getHeroCarouselContainer(params: GetHeroCarouselContainerParams) {
  const containers = await queryClient.ensureQueryData(GetContainersQuery(params));

  const heroCarouselContainer = containers.find((container) => container?.model === 'banner');

  return heroCarouselContainer || null;
}

export type GetHeroCarouselContentParams = {
  region: string;
  user: User;
};

export async function getHeroCarouselContent(params: GetHeroCarouselContentParams) {
  const heroCarouselContainer = await getHeroCarouselContainer(params);

  if (!heroCarouselContainer) return null;

  const { containerUrl } = heroCarouselContainer;

  const heroCarouselData = await getContainerContent(
    { zoneId: params.region, containerUrl, pageNumber: 1, pageSize: 100 },
    params.user
  );

  return heroCarouselData.content;
}

export type GetRailsContainersParams = {
  region: string;
  user: User;
  railContentSize: number;
};

export async function getRailsContainers(params: GetRailsContainersParams) {
  const { railContentSize, ...rest } = params;
  const containers = await queryClient.fetchQuery(GetContainersQuery({ ...rest }));

  const filteredContainers = containers.filter(
    (container) => container?.model !== 'banner' && container.type !== 'live'
  );

  return filteredContainers.map((container) => ({
    ...container,
    initialContent: container.initialContent.slice(0, railContentSize),
    hasNextPage: container.contentTotalNumber > railContentSize
  }));
}

export async function getSingleContentData(params: GetSingleContentDataParams, user: User) {
  const response = await qp.getSingleContent(params);

  return toContent(response, user);
}

export async function getSearchedContent(params: GetSearchedContentParams) {
  const { user, ...rest } = params;

  const searchedContent = await qp.getSearchedContent(rest);

  return searchedContent.map((content) => toContent(content, user));
}

export async function getUpcomingContent(params: GetUpcomingContentParams) {
  const { user, ...rest } = params;

  const upNext = await qp.getUpcomingContent(rest);

  const { header, data } = upNext;

  if (!data) {
    return {
      hasNextPage: false,
      content: []
    };
  }

  return {
    hasNextPage: hasNextPage(header),
    content: data.map((content) => {
      const formatted = toContent(content, user);

      formatted.type = 'overflow';
      formatted.isLocked = !isEntitled(user, formatted.entitlements);
      formatted.thumbnail = getSpecificAspectRatioImage({
        content,
        aspectRatio: '1-16x9',
        size: { width: '368' }
      });

      return formatted;
    })
  };
}

export async function getChannels(params: GetChannelsParams) {
  const channels = await qp.getChannels(params);

  if (!channels?.data) {
    return {
      hasNextPage: false,
      content: []
    };
  }

  const { header, data } = channels;

  // Filter the data to include only channels with 'regular' channel type (chty)
  // This avoids passing overflow channel IDs to content/epg
  return {
    hasNextPage: hasNextPage(header),
    data: data.filter((channel) => channel.chty === 'regular').map(toChannel)
  };
}

export async function getChannelEPG(params: GetChannelEPGParams, user: User) {
  const epg = await qp.getEPG(params);

  if (!epg?.data?.[0].airing.length) {
    return {
      hasNextPage: false,
      data: []
    };
  }

  return {
    hasNextPage: false,
    data: epg.data[0].airing.map((content) => {
      const data = toContent(content, user);
      data.thumbnail = getImageUrl(content, { size: { width: '640' }, preferredAspectRatios: { defaultAR: '1:1' } });
      return data;
    })
  };
}

type GetMoreLikeThisParams = {
  assetId?: string;
  region: string;
  user: User;
};

export async function getMoreLikeThis(params: GetMoreLikeThisParams) {
  const { region, user, assetId } = params;

  if (!assetId) return [];

  const result = await qp.searchMoreLikeThis({ zoneId: region, assetId });

  if (!result.length) return [];

  return result.map((content) => toContent(content, user));
}

export async function getLiveEventsWithFilters(params: GetLiveEventsWithFiltersParams) {
  const { user, team, ...rest } = params;

  const filteredLiveEvents = await qp.getLiveEventsFiltered({ ...rest, teamExternalId: team?.externalId });

  return filteredLiveEvents.map((content) => {
    const formattedContent = toContent(content, user);

    formattedContent.thumbnail = getSpecificAspectRatioImage({
      content,
      aspectRatio: '1-1x1',
      size: { width: '208' }
    });

    return formattedContent;
  });
}

export async function getContentsByIds(params: GetContentsByIds) {
  const contents = await qp.getContentsByIds(params);

  if (!contents.data.length) return [];

  let contentList = contents.data.map((content) => toContent(content, params.user));

  if (contentList.some((content) => content.catalogType === 'channel')) {
    contentList = await updateChannelContent(params.zoneId, contentList, params.user);
  }

  return contentList;
}
