import { v4 as uuid } from 'uuid';

export function generateUUID(): string {
  return uuid();
}

export function generateRandomNumber(digits: number): string {
  if (digits < 1) throw new Error('Number of digits must be at least 1.');
  const min = Math.pow(10, digits - 1);
  const max = Math.pow(10, digits) - 1;
  return (Math.floor(Math.random() * (max - min + 1)) + min).toString();
}
