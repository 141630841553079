import { z } from 'zod';

export const confirmSubscription = z
  .object({
    'confirm-subscription': z.object({
      'subscription-confirmed-title': z.string(),
      'subscription-cancel-description': z.string()
    })
  })
  .transform((values) => {
    const {
      'subscription-confirmed-title': subscriptionConfirmed,
      'subscription-cancel-description': subscriptionCancelDescription
    } = values['confirm-subscription'];
    return {
      subscriptionConfirmed,
      subscriptionCancelDescription
    };
  });

export type ConfirmSubscriptionLocale = z.infer<typeof confirmSubscription>;
