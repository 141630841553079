export const numberToCurrency = (value: number, options?: Intl.NumberFormatOptions) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    ...options
  }).format(value);
};

export const numberToOrdinal = (externalNumber: number | string) => {
  const number = typeof externalNumber === 'string' ? Number(externalNumber) : externalNumber;

  if (typeof externalNumber === 'string' && isNaN(number)) return externalNumber;

  let ord = 'th';
  if (number % 10 === 1 && number % 100 !== 11) {
    ord = 'st';
  } else if (number % 10 === 2 && number % 100 !== 12) {
    ord = 'nd';
  } else if (number % 10 === 3 && number % 100 !== 13) {
    ord = 'rd';
  }
  return `${number}${ord}`;
};
