import type { EventProperties } from '@segment/analytics-next';
import { z } from 'zod';
import { SEGMENT } from '../../config/build-config';
import { GameConfig } from '../../config/game-config';
import { getPlatformCaption } from '../../lib/platform';
import { queryClient } from '../../lib/query';
import { isUserLoggedIn } from '../auth';
import { getConfigFromRemote } from '../config-manager';
import type { Content } from '../content';
import { GetUserQuery, isDTCUser, isTVEUser, isVIPUser, type User } from '../user';
import { getPosition } from './segment';

type Traits = Record<string, string | number | boolean>;

export function getSubscriberType(user: User | null) {
  if (!user) return null;
  if (user.type.has('MVPD OOM') || user.type.has('MVPD')) return 'tve';
  else if (user.type.has('DTC')) return 'dtc';
  return 'free';
}

export async function getUser(): Promise<User | null> {
  if (!isUserLoggedIn()) {
    return null;
  }
  try {
    return await queryClient.ensureQueryData(GetUserQuery);
  } catch {
    return null;
  }
}

/** Transforms a User to the identity user traits
 *  @param {User} [user]
 */
export function toUserTraits(user: User | null): Traits {
  if (!user) return {};
  return {
    user_id: user.spAccountId,
    username: user.email,
    first_name: user.firstName ?? '',
    last_name: user.lastName ?? '',
    full_name: user.fullName ?? '',
    email: user.email,
    birthdate: user.dob?.toISOString() ?? '',
    gender: user.gender ?? '',
    tve_status: isTVEUser(user),
    subscriber_type: getSubscriberType(user) ?? '',
    mvpd: user.mvpdId ?? '', // TODO: get display name friendly mvpd label
    user_flag: isVIPUser(user)
  };
}

export function toAdTraits(ad: object, content?: Content | null): any {
  return {
    content: toContentTraits(content)
  };
}

export function toContentTraits(
  content?: Content | null,
  sessionId?: string,
  bitrate?: number,
  framerate?: number,
  position?: number
): Traits {
  const user = queryClient.getQueryData(GetUserQuery.queryKey);
  if (!user || !content) return {};

  const { id, title, description, duration, gameDetails, network, catalogType, segment, gamePassAssetId } = content;
  const { deviceType, genre, program, airDate, sport, team, supportedZone: zone, programType, gameId } = segment;
  const platform = getPlatformTraits();
  const subscription_type = getSubscriberType(user);
  const subscriber_package = getSubscriberPackage(user, content);

  return {
    ...platform,
    session_id: sessionId ?? '',
    subscription_type: subscription_type ?? '',
    asset_id: id ?? '',
    title: title ?? '',
    description: description ?? '',
    total_length: duration ?? 0,
    channel: network ?? '',
    livestream: catalogType === 'liveevent',
    league: gameDetails?.league ?? '',
    device_type: deviceType ?? '',
    genre: genre ?? '',
    program: program ?? '',
    airDate: airDate ?? '',
    sport: sport ?? '',
    series: program ?? '',
    team: Array.isArray(team) ? team.join(',') : (team ?? ''),
    zone: Array.isArray(zone) ? zone.join(',') : (zone ?? ''),
    program_type: programType ?? '',
    gamepass_purchase: !!gamePassAssetId,
    initiated_from_location: 'marquee', // TODO: This is static value for now
    publisher: getChannelName(content) ?? '',
    bitrate: bitrate ?? 0,
    framerate: framerate ?? 0,
    position: position ?? 0,
    gameId: gameId ?? '',
    subscriber_package: subscriber_package ?? '',
    content_type: getContentType(content)
  };
}

const channelsSchema = z.object({
  channels: z.array(
    z.object({
      channelID: z.string(),
      analyticsName: z.string()
    })
  )
});

export function getChannelName(content: Content): string | null {
  const id = content.catalogType === 'channel' ? content.id : content.channelId;
  if (!id) return null;

  const { channels } = getConfigFromRemote(channelsSchema);

  const channel = channels.find((channel) => channel.channelID === id);

  if (channel) {
    return channel.analyticsName;
  }
  return null;
}

export function toPlaybackTraits(
  playback: object,
  playerName: string,
  content?: Content | null,
  sessionId?: string,
  bitrate?: number,
  framerate?: number
) {
  const user = queryClient.getQueryData(GetUserQuery.queryKey);
  if (!user) return {};
  if (!content) return {};
  const { id, duration, gameDetails, catalogType, segment, gamePassAssetId } = content;
  const { program, sport, team, supportedZone: zone, programType, gameId } = segment;
  const platform = getPlatformTraits();
  const subscription_type = getSubscriberType(user);
  const subscriber_package = getSubscriberPackage(user, content);

  return {
    ...platform,
    session_id: sessionId ?? '',
    subscription_type: subscription_type ?? '',
    subscriber_type: subscription_type ?? '', // alias for subscription_type
    total_length: duration ?? 0,
    content_asset_id: id,
    video_player: playerName,
    bitrate: bitrate ?? 0,
    framerate: framerate ?? 0,
    livestream: catalogType === 'liveevent',
    team: Array.isArray(team) ? team.join(',') : (team ?? ''),
    zone: Array.isArray(zone) ? zone.join(',') : (zone ?? ''),
    program_type: programType ?? '',
    gamepass_purchase: !!gamePassAssetId,
    initiated_from_location: 'marquee', // TODO: This is static value for now
    series: program ?? '',
    sport: sport ?? '',
    league: gameDetails?.league ?? '',
    ad_enabled: false, // TODO : Detect ad blocker and set value
    position: getPosition(),
    gameId: gameId,
    subscriber_package: subscriber_package ?? '',
    content_type: getContentType(content)
  };
}

export function getPlatformTraits() {
  return {
    platform: getPlatformCaption(GameConfig.get.platform)
  };
}

export function getAdBreakInfo(event: any, sessionId?: string | null) {
  const { adBreak } = event;
  const adBreakPosition = adBreak?.position ?? '';
  let type;
  if (adBreakPosition.includes('pre')) {
    type = 'pre-roll';
  } else if (adBreakPosition.includes('mid')) {
    type = 'mid-roll';
  } else if (adBreakPosition.includes('post')) {
    type = 'post-roll';
  }

  return {
    pod_id: adBreak?.id ?? '',
    pod_length: adBreak?.ads?.length ?? 0,
    type,
    position: 0,
    session_id: sessionId ?? ''
  };
}

export function getAdInfo(event: any, sessionId?: string | null) {
  const { ad } = event;

  return {
    asset_id: ad.id,
    title: ad.data.adTitle,
    position: 0,
    total_length: ad.duration,
    load_type: ad.isLinear ? 'linear' : 'dynamic',
    session_id: sessionId ?? ''
  };
}

export function populate(properties: EventProperties, traits: Traits) {
  for (const [trait, value] of Object.entries(traits)) {
    properties[trait] = value;
  }
}

function getSubscriberPackage(user: User | null, video: Content) {
  if (!user) return null;

  if (isDTCUser(user)) {
    if (video.gamePassAssetId && user.entitlements.has(video.gamePassAssetId)) {
      return 'DTC-PPG';
    }

    const userEntitlements = Array.from(user.entitlements);
    if (userEntitlements.some((value) => value.includes('dtc'))) {
      let packageName = userEntitlements.some((value) => value.includes('annual')) ? 'DTC-ANNUAL' : 'DTC-MONTHLY';

      if (userEntitlements.some((value) => value.includes('bundle'))) {
        packageName += '-GOTHAM';
      } else if (userEntitlements.some((value) => value.includes('msg'))) {
        packageName += '-MSG';
      } else if (userEntitlements.some((value) => value.includes('yes'))) {
        packageName += '-YES';
      }
      return packageName;
    }
  }
  if (isTVEUser(user)) return 'TVE';

  return null;
}

function getContentType(content: Content): string {
  switch (content.catalogType) {
    case 'show':
      return 'vod';
    case 'liveevent':
      return 'live event';
    case 'airing':
      return 'channel';
    default:
      return content.catalogType;
  }
}

export function getWriteKey() {
  const platform = GameConfig.get.platform;
  switch (platform) {
    case 'web':
    case 'web:mobile':
      return SEGMENT.web.WRITE_KEY;
    case 'lg':
      return SEGMENT.lg.WRITE_KEY;
    case 'vizio':
      return SEGMENT.vizio.WRITE_KEY;
    case 'samsung':
      return SEGMENT.samsung.WRITE_KEY;
    default:
      throw new Error(`Unable to get segment write key for Platform ${platform}`);
  }
}
