import { z } from 'zod';
import { toURL } from '../../../../lib/utils';

export const playerConfigSchema = z
  .object({
    baseURL: z.string(),
    players: z.object({
      version: z.number(),
      fileURL: z.string(),
      headshotsPath: z.string()
    }),
    assets: z.object({
      headshotPlaceholder: z.object({
        hd: z.string()
      })
    })
  })
  .transform((config) => {
    const {
      baseURL,
      players: { version, fileURL, headshotsPath }
    } = config;
    return {
      version: version.toString(),
      url: toURL(baseURL, fileURL).href,
      headshotBaseURL: toURL(baseURL, headshotsPath).href,
      headshotFallbackURL: toURL(baseURL, config.assets.headshotPlaceholder.hd).href
    };
  });
