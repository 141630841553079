import { RSN_DIRECT_ACCOUNTS } from '../../config/build-config';
import { deleteSearchedAccountData, getSearchedAccountData, saveSearchedAccountData } from './searched-account-storage';
import { searchAccountByEmail } from './service';

export function getSearchedAccountFromStorage() {
  return getSearchedAccountData();
}

export async function searchAccount(email: string, throwOnError?: boolean) {
  deleteSearchedAccountData();

  const result = await searchAccountByEmail(email, throwOnError);

  if (!result.exists) {
    const data = { ...result, email };

    saveSearchedAccountData(data);

    return data;
  }

  const { directAccount, exists, ...rest } = result;

  // TODO: double check directAccount logic after bringing in new RSN
  const originalRSN = RSN_DIRECT_ACCOUNTS[directAccount] ?? RSN_DIRECT_ACCOUNTS[0];

  const data = {
    ...rest,
    exists,
    originalRSN,
    email
  };

  saveSearchedAccountData(data);

  return data;
}
