import { z } from 'zod';

export const watchConfigSchema = z
  .object({
    web: z.object({
      mediaFormat: z.object({
        default: z.string(),
        safari: z.string()
      })
    })
  })
  .transform((parsed) => {
    const {
      web: {
        mediaFormat: { safari, default: defaultFormat }
      }
    } = parsed;

    return {
      safariMediaFormat: safari.toLowerCase(),
      defaultMediaFormat: defaultFormat.toLowerCase()
    };
  });

export const heroCarouselSchema = z
  .object({
    web: z.object({
      heroRotationTimer: z.number().optional().default(10000)
    })
  })
  .transform((values) => {
    const { heroRotationTimer } = values.web;
    return {
      heroRotationTimer
    };
  });
