import type { ComponentProps, ElementType } from 'react';
import { type VariantProps } from 'tailwind-variants';
import { twMerge, twVariants } from '../../utils/tailwind-libs';
import { DEFAULT_ROOT_TAGS, DEFAULT_VARIANT } from './constants';

const typographyVariants = twVariants({
  base: 'text-light',
  variants: {
    variant: {
      h1: 'WebH1',
      h2: 'WebH2',
      h3: 'WebH3',
      h4: 'WebH4',
      h5: 'WebH5',
      h6: 'WebH6',
      h7: 'WebH7',
      h8: 'WebH8',
      h9: 'WebH9',
      h10: 'WebH10',
      h11: 'WebH11',
      body1: 'WebBody1',
      body2: 'WebBody2',
      body3: 'WebBody3',
      body4: 'WebBody4',
      body5: 'WebBody5',
      body6: 'WebBody6',
      body7: 'WebBody7'
    }
  },
  defaultVariants: {
    variant: DEFAULT_VARIANT
  }
});

export function Typography(props: TypographyProps) {
  const { variant, className, children, as: tag } = props;

  // set the html tag to render;
  // priority: `as` prop > default variant config > div as default
  const Root = tag || (variant && DEFAULT_ROOT_TAGS[variant]) || 'div';

  return <Root className={twMerge(typographyVariants({ variant }), className)}>{children}</Root>;
}

Typography.displayName = 'Typography';

export type TypographyVariant = Required<VariantProps<typeof typographyVariants>>['variant'];

type TypographyProps = ComponentProps<'div'> &
  VariantProps<typeof typographyVariants> & {
    as?: ElementType;
  };
