import type { FeaturesConfigSchema, FeaturesPlatform } from './schema/config';
import { fetchFeatures } from './service/service';
import { GameConfig } from '../../config/game-config';

export type Features = keyof FeaturesConfigSchema[FeaturesPlatform];

export async function isFeatureEnabled<F extends Features>(feature: F) {
  const featureFlags = await fetchFeatures(GameConfig.get.basePlatform);
  return featureFlags[feature];
}
