import type {
  AdobeMVPD,
  CheckAuthenticatedRegCodeParams,
  GetAuthenticateUrlParams,
  GetMediaTokenParams,
  GetShortMediaTokenResponse,
  GetPreauthorizedResourcesResponse,
  GetRegistrationCodeResponse,
  GetUserMetadataResponse,
  MVPD
} from './types';
import { APIError } from '../../../lib/error';
import { InMemoryTokenStorage } from './token-storage';
import { ADOBE } from '../../../config/build-config';

const tokenStorage = new InMemoryTokenStorage<string>();

// ref: https://clearbridgemobile.atlassian.net/wiki/spaces/YMD/pages/2921922562/Vendor+Adobe
export async function getRegistrationCode(deviceId: string): Promise<GetRegistrationCodeResponse> {
  const searchParams = new URLSearchParams();
  searchParams.set('deviceId', deviceId);

  const accessToken = await getClientToken();
  const response = await fetch(`${ADOBE.PASS_DOMAIN}/reggie/v1/${ADOBE.REQUESTOR_ID}/regcode`, {
    method: 'POST',
    body: searchParams,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json'
    }
  });

  if (!response.ok) {
    // TODO: should throw an api error
    throw new APIError(response, 'Failed to get registration code', await response.json());
  }

  return response.json();
}

async function registerClient() {
  const registerClientUrl = new URL(ADOBE.PASS_DOMAIN);
  registerClientUrl.pathname = '/o/client/register';
  const response = await fetch(registerClientUrl, {
    method: 'POST',
    body: JSON.stringify({
      software_statement: ADOBE.SOFTWARE_STATEMENT
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const { client_id, client_secret } = await response.json();
  return {
    client_id,
    client_secret
  };
}

async function getClientToken(): Promise<string> {
  const token = tokenStorage.get();
  if (token) return token;

  const { client_id, client_secret } = await registerClient();
  const tokenUrl = new URL(ADOBE.PASS_DOMAIN);
  tokenUrl.pathname = '/o/client/token';
  const response = await fetch(tokenUrl, {
    method: 'POST',
    body: new URLSearchParams({ client_id, client_secret, grant_type: 'client_credentials' }).toString(),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
  });
  const { access_token, expires_in } = await response.json();
  tokenStorage.set(access_token, new Date(Date.now() + expires_in * 1000).getTime());

  return access_token;
}

/**
 * Generates the authentication url with the provided reg code. Sets the redirect url to hit the success route.
 * @param {GetAuthenticateUrlParams} params
 * @returns {URL} Authentication url that activates the reg code.
 */
export function getAuthenticateUrl(params: GetAuthenticateUrlParams): URL {
  const { regCode, mvpdId, domainName, redirectUrl } = params;

  const authenticateUrl = new URL(`${ADOBE.PASS_DOMAIN}/api/v1/authenticate`);
  const searchParams = authenticateUrl.searchParams;
  searchParams.set('reg_code', regCode);
  searchParams.set('requestor_id', ADOBE.REQUESTOR_ID);
  searchParams.set('mso_id', mvpdId);
  searchParams.set('domain_name', domainName);
  searchParams.set('noflash', 'true');
  searchParams.set('no_iframe', 'true');
  searchParams.set('redirect_url', redirectUrl);

  return authenticateUrl;
}

/**
 * Checks the authentication status for the given reg code.
 * @param {CheckAuthenticatedRegCodeParams} params
 * @returns {boolean} True if reg code is authenticated.
 */
export async function checkAuthenticatedRegCode(params: CheckAuthenticatedRegCodeParams): Promise<boolean> {
  const accessToken = await getClientToken();
  const checkAuthnUrl = new URL(`${ADOBE.PASS_DOMAIN}/api/v1/checkauthn/${params.regCode}`);
  checkAuthnUrl.searchParams.set('requestor', ADOBE.REQUESTOR_ID);
  const response = await fetch(checkAuthnUrl, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json'
    }
  });
  return response.status === 200;
}

/**
 * Retrieves user metadata based on the provided device ID.
 *
 * This function fetches metadata such as ZIP code and MVPD (Multichannel Video Programming Distributor) for a given device by making an API call to Adobe's server.
 *
 * @param deviceId - The unique identifier for the device.
 *
 * @throws {@link APIError}
 * Throws a status code 412 if the device associated with the given `deviceId` is not authenticated yet.
 *
 * @returns An object containing the user's ZIP code and MVPD information.
 *
 */
export async function getUserMetadata(deviceId: string) {
  const accessToken = await getClientToken();

  const url = new URL(`${ADOBE.PASS_DOMAIN}/api/v1/tokens/usermetadata`);
  url.searchParams.set('requestor', ADOBE.REQUESTOR_ID);
  url.searchParams.set('deviceId', deviceId);

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json'
    }
  });

  const { data } = (await response.json()) as GetUserMetadataResponse;
  return {
    zip: data.zip,
    mvpd: data.mvpd,
    userId: data.userID
  };
}

export async function preauthorize(deviceId: string) {
  const accessToken = await getClientToken();
  const url = new URL(`${ADOBE.PASS_DOMAIN}/api/v1/preauthorize`);
  url.searchParams.set('requestor', ADOBE.REQUESTOR_ID);
  url.searchParams.set('deviceId', deviceId);
  url.searchParams.set('resource', ADOBE.TVE_RESOURCE_IDS.join(','));

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json'
    }
  });

  const { resources } = (await response.json()) as GetPreauthorizedResourcesResponse;
  return resources;
}

export async function authorize(deviceId: string, resourceId: string): Promise<Response> {
  const accessToken = await getClientToken();
  const url = new URL(`${ADOBE.PASS_DOMAIN}/api/v1/authorize`);
  url.searchParams.set('requestor', ADOBE.REQUESTOR_ID);
  url.searchParams.set('deviceId', deviceId);
  url.searchParams.set('resource', resourceId);

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json'
    }
  });

  return response;
}

export async function getShortMediaToken({ deviceId, resource }: GetMediaTokenParams) {
  const accessToken = await getClientToken();
  const url = new URL(`${ADOBE.PASS_DOMAIN}/api/v1/tokens/media`);
  url.searchParams.set('requestor', ADOBE.REQUESTOR_ID);
  url.searchParams.set('deviceId', deviceId);
  url.searchParams.set('resource', resource);

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json'
    }
  });

  if (!response.ok) {
    throw new APIError(response, 'Failed to get short media token', await response.json());
  }

  const json = (await response.json()) as GetShortMediaTokenResponse;
  return json;
}

export async function logout(deviceId: string) {
  const accessToken = await getClientToken();
  const url = new URL(`${ADOBE.PASS_DOMAIN}/api/v1/logout`);

  url.searchParams.set('requestor', ADOBE.REQUESTOR_ID);
  url.searchParams.set('deviceId', deviceId);

  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json'
    }
  });

  if (!response.ok) {
    throw new APIError(response, 'Failed to logout', await response.text());
  }

  tokenStorage.clear();
}

export async function getMVPDList(): Promise<MVPD[]> {
  try {
    const accessToken = await getClientToken();
    const url = new URL(ADOBE.PASS_DOMAIN);
    url.pathname = `/api/v1/config/${ADOBE.REQUESTOR_ID}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json'
      }
    });

    const {
      requestor: {
        mvpds: { mvpd: mvpdList }
      }
    } = await response.json();
    if (!mvpdList?.length) return [];
    return mvpdList.map((mvpd: AdobeMVPD) => {
      return {
        id: mvpd.id.value,
        displayName: mvpd.displayName.value,
        logoUrl: mvpd.logoUrl.visible ? mvpd.logoUrl.value : undefined
      };
    });
  } catch {
    return [];
  }
}
