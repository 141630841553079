import { Button, Typography } from '@game/core-ui';
import { useNavigate } from '@tanstack/react-router';
import { useCallback } from 'react';
import type { PurchaseSuccessfulLocale } from '../../../../lib/locale';
import { getPagePath } from '../../../../lib/routing';

type PurchaseSuccessfulProps = {
  locales: PurchaseSuccessfulLocale;
  delayMinutes: number;
};

export function PurchaseSuccessful({ locales, delayMinutes }: PurchaseSuccessfulProps) {
  const navigate = useNavigate();

  const goToWatch = useCallback(() => {
    navigate({ to: getPagePath('watch') });
  }, [navigate]);
  return (
    <main className="min-w-screen flex h-screen w-full flex-col items-center py-10">
      <div className="mx-auto flex h-screen w-full flex-col justify-between px-4 pt-24 md:max-w-xl md:text-center">
        <div className="flex flex-col">
          <Typography className="font-bold" variant="h4">
            {locales.purchaseSuccessful}
          </Typography>
          <Typography className="mt-3 md:mt-60">
            {locales.delayMessage.replace('{delayMinutes}', delayMinutes.toString())}
          </Typography>
        </div>
        <Button onClick={goToWatch}>{locales.ok}</Button>
      </div>
    </main>
  );
}
