import { z } from 'zod';

export const loginSchema = z
  .object({
    login: z.object({
      'login-with': z.string(),
      'we-recognize': z.string(),
      email: z.string(),
      password: z.string(),
      'forgot-password': z.string(),
      login: z.string(),
      'login-error': z.string(),
      'unable-to-login': z.string(),
      ok: z.string(),
      'help-box-text': z.string(),
      'reset-password-link-text': z.string(),
      'forgot-your-password': z.string()
    }),
    'create-profile': z.object({
      'enter-password': z.string(),
      'invalid-password': z.string()
    })
  })
  .transform((values) => {
    const {
      login: {
        'we-recognize': weRecognize,
        'forgot-password': forgotPassword,
        'login-error': loginError,
        'login-with': loginWith,
        'unable-to-login': unableToLogin,
        'help-box-text': helperCopy,
        'reset-password-link-text': resetPwd,
        'forgot-your-password': forgotYourPwd,
        ...rest
      },
      'create-profile': { 'enter-password': enterPassword, 'invalid-password': invalidPassword }
    } = values;

    return {
      ...rest,
      weRecognize,
      forgotPassword,
      loginError,
      loginWith,
      unableToLogin,
      enterPassword,
      invalidPassword,
      helperCopy,
      resetPwd,
      forgotYourPwd
    };
  });
