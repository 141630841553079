import secureLocalStorage from 'react-secure-storage';

/* Saves the key/value pair to storage */
export function saveToStorage(key: string, value: string, encrypt?: boolean) {
  if (encrypt) return secureLocalStorage.setItem(key, value);
  localStorage.setItem(key, value);
}

/* Retrieves a value by the provided key */
export function getFromStorage(key: string): string | null {
  return secureLocalStorage.getItem(key)?.toString() || localStorage.getItem(key);
}

/* Removes an item from storage */
export function removeFromStorage(key: string) {
  localStorage.removeItem(key);
  secureLocalStorage.removeItem(key);
}
