import { z } from 'zod';

export const pickFavoritePlayersSchema = z
  .object({
    'pick-players': z.object({
      step3of3: z.string(),
      'pick-follow-your-favorite-players': z.string(),
      'maybe-later': z.string(),
      next: z.string()
    })
  })
  .transform((values) => {
    const {
      'pick-players': {
        step3of3,
        'pick-follow-your-favorite-players': pickFollowYourFavoritePlayers,
        'maybe-later': maybeLater,
        next
      }
    } = values;
    return {
      step3of3,
      pickFollowYourFavoritePlayers,
      maybeLater,
      next
    };
  });

export type PickFavoritePlayersLocale = z.infer<typeof pickFavoritePlayersSchema>;
