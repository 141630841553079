import { twMerge } from '../../utils/tailwind-libs';

type ProgressIndicatorProps = {
  totalSteps: number;
  currentStep: number;
};

/**
 * Component to build the Progress Indicator for steps.
 *
 * @param {Number} totalSteps number of total steps of the process
 * @param {Number} currentStep number of the current step (starting at 1)
 *
 * @example
 * <ProgressIndicator currentStep={1} totalSteps={3} />
 */
function ProgressIndicator(props: ProgressIndicatorProps) {
  const { totalSteps, currentStep } = props;

  return (
    <div className="flex w-full items-center gap-[0.1875rem] md:gap-2.5">
      {Array(totalSteps)
        .fill(1)
        .map((_, index) => {
          return (
            <div
              key={index}
              className={twMerge(
                'bg-primary-500 h-1.5 flex-1',
                currentStep < index + 1 && 'bg-primary-900',
                index === 0 && 'rounded-l-full',
                index + 1 === totalSteps && 'rounded-r-full'
              )}
            />
          );
        })}
    </div>
  );
}

ProgressIndicator.displayName = 'ProgressIndicator';

export { ProgressIndicator };
