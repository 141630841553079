import { isFeatureEnabled } from '@game/core';
import { createFileRoute, Outlet } from '@tanstack/react-router';
import { AppSideBar } from '../../components';

// TODO: For now I'm keeping this function, because we don't know if this logic will be reverted or not
// function pageHasSidebar(path: string) {
//   return !/account\/.*/.test(path);
// }

export const Route = createFileRoute('/_private/_home')({
  beforeLoad: async () => {
    const odds = await isFeatureEnabled('odds');
    const liveScores = await isFeatureEnabled('liveScores');
    const heroCarouselAutoRotation = await isFeatureEnabled('heroCarouselAutoRotation');

    return {
      footer: true,
      features: {
        odds,
        liveScores,
        heroCarouselAutoRotation
      }
    };
  },
  component: RouteComponent
});

function RouteComponent() {
  const { features } = Route.useRouteContext();

  return (
    <div className="bg-secondary-900 flex-1">
      <AppSideBar features={features} />
      <main className="md:pl-[5.8125rem]">
        <Outlet />
      </main>
    </div>
  );
}
