import { getConfigFromRemote, GetFavoritesQuery, isFeatureEnabled, queryClient } from '@game/core';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';
import { playersConfigSchema } from '../../../lib/config/pick-favorite-players';
import { getPagePath } from '../../../lib/routing';

const search = z.object({
  teamId: z.number().optional()
});

export const Route = createFileRoute('/_private/favorite/players')({
  validateSearch: search,
  beforeLoad: async () => {
    const isEnabled = await isFeatureEnabled('favoritePlayer');
    if (!isEnabled) throw redirect({ to: getPagePath('launch') });
    return { config: getConfigFromRemote(playersConfigSchema) };
  },
  loader: async ({ context: { ...rest } }) => {
    const favorites = await queryClient.fetchQuery(GetFavoritesQuery);
    return {
      favorites,
      ...rest
    };
  }
});
