import { queryClient } from '../../lib/query';
import { saveTokens } from '../auth';
import { GetUserQuery } from './query';
import { create, update } from './services';
import type { CreateUserInput, Device, UpdateUserInput, User } from './types';

export async function getUserData(): Promise<User> {
  return queryClient.fetchQuery(GetUserQuery);
}

export async function invalidateUserData() {
  await queryClient.invalidateQueries({ queryKey: GetUserQuery.queryKey });
}

export async function createUserProfile(user: CreateUserInput, zoneId: string, device: Device) {
  const response = await create(user, zoneId, device);
  saveTokens({
    accessToken: response.accessToken,
    refreshToken: response.refreshToken,
    expiresIn: response.expiresIn
  });
  return response;
}

export async function updateUserProfile(user: UpdateUserInput) {
  const result = await update(user);
  await invalidateUserData();
  return result;
}

export const isTVEUser = (user: User) => user.type.has('MVPD') || user.type.has('MVPD OOM');

export const isDTCUser = (user: User) => user.type.has('DTC');

export const isFreeUser = (user: User) => user.type.has('FREE');

export const isTVEUserLoggedIn = (user: User) => !!user.mvpdId && isTVEUser(user);

export const isQAUser = (user: User) => user.role === 'QA';

export const isVIPUser = (user: User) => user.type.has('VIP');
