import { emit, type GameError, queryClient, type UseCases, useGameError, useLocale } from '@game/core';
import { Button, Typography } from '@game/core-ui';
import { type ErrorComponentProps, useNavigate, useRouter } from '@tanstack/react-router';
import { gameErrors, generic } from '../../lib/locale';
import { getPagePath, type PageId } from '../../lib/routing';

type GameErrorComponentProps = ErrorComponentProps & { error: Error | GameError; useCase?: UseCases; path?: PageId };

export function GameErrorComponent(props: GameErrorComponentProps) {
  const { error, useCase = 'UNKNOWN', path = 'watch' } = props;
  const { locales: genericLocales } = useLocale({ schema: generic });
  const { getErrorMeta } = useGameError(gameErrors);
  const { title, gameError, endUserMessage } = getErrorMeta(error, useCase);
  const router = useRouter();
  const navigate = useNavigate();

  emit('ApplicationError', { error: gameError, useCase, endUserMessage });

  return (
    <div className="mx-auto flex h-screen min-h-screen w-full flex-col items-center justify-center px-5 text-center">
      <Typography variant="h3" as="h1" className="mb-5 uppercase">
        {title}
      </Typography>
      <Typography variant="h10" as="p" className="mb-16">
        {endUserMessage}
      </Typography>
      <Button
        variant="primary"
        onClick={async () => {
          await router.invalidate();
          queryClient.clear();
          void navigate({ to: getPagePath(path) });
        }}
        className="max-w-[33.25rem]"
      >
        {genericLocales.ok}
      </Button>
    </div>
  );
}
