import { z } from 'zod';

export const appSidebarLocaleSchema = z.object({
  generic: z.object({
    menu: z.string()
  }),
  watchScreen: z.object({
    watch: z.string(),
    odds: z.string(),
    search: z.string(),
    schedule: z.string(),
    account: z.string()
  })
});
