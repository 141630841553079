import { create } from 'zustand';
import { type RemoteConfig } from './types';

export type ConfigStore = {
  version: number;
  config?: RemoteConfig;
  setVersion: (version: number) => void;
  setConfig: (config: RemoteConfig) => void;
};

export const useRemoteConfigStore = create<ConfigStore>((set) => ({
  version: 0,
  config: undefined,
  setVersion: (version: number) =>
    set(() => {
      return {
        version
      };
    }),
  setConfig: (config: RemoteConfig) => {
    set(() => {
      return {
        config
      };
    });
  }
}));
